import React, { useState } from 'react';
import { FlatList } from 'react-native';

import { Box, ShadowBox } from '@components/Restyle';
import SearchInput from '@components/shared/SearchInput/SearchInput.web';
import { TeamListItem } from '@components/Team/TeamListItem';
import { Team } from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import theme from '@themes/theme';

export type ProjectsTeamPopupProps = {
  closePopFn?: () => void;
  onSelectTeam?: (
    item: Pick<Team, 'id' | 'name' | 'avatar' | 'usersCount' | 'personal'>
  ) => void;
  selectedTeamIds?: string[];
};

export const ProjectsTeamPopup: React.FC<ProjectsTeamPopupProps> = ({
  closePopFn,
  onSelectTeam,
  selectedTeamIds = [],
}) => {
  const { teams } = useListTeamsFromQuery();

  const [search, setSearch] = useState<string>('');
  const [hoveredId, setHoveredId] = useState('');

  return (
    <ShadowBox
      flex={1}
      width={295}
      alignItems='flex-start'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <>
        <Box
          marginHorizontal='m'
          marginTop='m'
          style={{ width: 262, height: 40 }}
          flexDirection='row'
          alignItems='center'>
          <SearchInput
            value={search}
            height={40}
            onTextChanged={(value: string) => {
              setSearch(value);
            }}
            placeHolder='Search Team'
          />
        </Box>
        <Box mb='s' flex={1} style={{ width: 295, maxHeight: 300 }}>
          <FlatList
            data={teams?.filter((t) =>
              t.name.trim().toLowerCase().includes(search.trim().toLowerCase())
            )}
            keyExtractor={(item) => item?.id}
            renderItem={({ item }) => (
              <Box
                style={{
                  backgroundColor:
                    hoveredId !== '' && hoveredId == item?.id
                      ? theme.colors.grey01
                      : theme.colors.white,
                }}
                px='m'
                py='xs'
                onMouseEnter={() => {
                  setHoveredId(item?.id);
                }}
                onMouseLeave={() => {
                  setHoveredId('');
                }}>
                <TeamListItem
                  isSelectable={true}
                  filterVal={search}
                  isSmall
                  iconSize='l'
                  team={item}
                  onSelect={() => {
                    onSelectTeam?.(item);
                    closePopFn?.();
                  }}
                  hideTrailCheckmark={true}
                  isSelected={selectedTeamIds.some((t) => t === item.id)}
                />
              </Box>
            )}
            ListFooterComponent={() => <Box marginBottom='m' />}
            ListHeaderComponent={() => <Box marginTop='m' />}
          />
        </Box>
      </>
    </ShadowBox>
  );
};
