import {
  BottomSheetBackdropProps,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TextInput } from 'react-native';

import { Alert } from '@components/Alert';
import { DateSelect } from '@components/FormModals/DateSelect';
import ProjectSelect from '@components/FormModals/ProjectSelect';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { BottomSheetBackdrop } from '@components/Modals/BottomSheetBackdrop';
import { Box } from '@components/Restyle';
import { TaskCreateModalForm } from '@components/Tasks/TaskCreateModalForm';
import { FormValues } from '@components/Tasks/TaskFormStack';
import { Chat } from '@graphql/generated';
import useSelectedMembers from '@hooks/useSelectedMembers';
import theme from '@themes/theme';
import { getTaskRoleEnumValue } from '@utils/tasks';

export type TaskCreateFormStackParamList = {
  'task-create-modal-form': undefined;
  'project-select': undefined;
  'member-select': undefined;
  'date-select': { field: FormValues['startDate' | 'dueDate'] };
  'dependency-select': undefined;
};

interface TaskCreateBottomSheetComponentProps {
  parentProject?: boolean;
  isCopyTask?: boolean;
  taskname?: string;
  selectedList?: [];
  values?: FormValues;
  chatId?: Chat['id'];
  isAssignTask?: boolean;
  goBackOnComplete?: boolean;
}

export const TaskCreateBottomSheet = forwardRef(
  (
    {
      parentProject,
      isCopyTask,
      taskname,
      selectedList,
      chatId,
      isAssignTask = false,
      goBackOnComplete,
    }: TaskCreateBottomSheetComponentProps,
    forwardRef
  ) => {
    const navigation = useNavigation();
    const { t } = useTranslation();

    const { setSelectedMembers, selectedMembers } = useSelectedMembers();
    const [modalPosition, setModalPosition] = useState(0);
    const [blankTaskForm, setBlankTaskForm] = useState(true);

    const updateMember = (userId: string, role: string) => {
      const members = selectedMembers?.map((u) => {
        if (u.id !== userId) return u;
        else {
          return { ...u, role: getTaskRoleEnumValue(role) };
        }
      });
      setSelectedMembers(members);
    };

    const { isValid, submitForm, setFieldValue, isSubmitting, values } =
      useFormikContext<FormValues>();

    // refs
    const bottomSheetTaskFormRef = useRef<BottomSheetModal>(null);
    useImperativeHandle(forwardRef, () => bottomSheetTaskFormRef.current);
    const bottomSheetProjectModalRef = useRef<BottomSheetModal>(null);
    const bottomSheetDateModalRef = useRef<BottomSheetModal>(null);

    // variables
    const snapPoints = ['70%', '70%', '95%'];

    const inputRef = useRef<TextInput>(null);
    // callbacks
    const handleFormSheetChange = (index: number) => {
      setModalPosition(index);
      if (index === -1) {
        navigation.goBack();
      } else if (index === 1) {
        inputRef.current?.focus();
      }
    };
    const dismissTaskFormModal = () => {
      if (bottomSheetTaskFormRef.current) {
        bottomSheetTaskFormRef.current.dismiss();
      }
    };
    const openProjectModal = () => {
      if (bottomSheetProjectModalRef.current) {
        bottomSheetProjectModalRef.current.present();
      }
    };
    const dismissProjectModal = () => {
      if (bottomSheetProjectModalRef.current) {
        bottomSheetProjectModalRef.current.dismiss();
      }
    };
    const openDateModal = useCallback(() => {
      if (bottomSheetDateModalRef.current) {
        bottomSheetDateModalRef.current.present();
      }
    }, []);
    const dismissDateModal = useCallback(() => {
      if (bottomSheetDateModalRef.current) {
        bottomSheetDateModalRef.current.dismiss();
      }
    }, []);

    useEffect(() => {
      if (bottomSheetTaskFormRef.current) {
        bottomSheetTaskFormRef.current.present();
      }
    }, [bottomSheetTaskFormRef]);

    useEffect(() => {
      setFieldValue('users', selectedMembers);
    }, [selectedMembers]);

    useEffect(() => {
      !isCopyTask && setSelectedMembers([]);
      return () => {
        !isCopyTask && setSelectedMembers([]);
      };
    }, []);

    const handleComponent = () => <Box style={styles.closeLine} />;
    const handleComponentScreen = () => <Box style={styles.closeLineScreen} />;

    const discardAlert = (ref: React.RefObject<BottomSheetModal>) => {
      Alert.alert(
        t('models:discardTask.title'),
        t('models:discardTask.message'),
        [
          {
            text: t('shared:cancel'),
          },
          {
            text: t('shared:discard'),
            style: 'destructive',
            onPress: () => {
              ref.current?.dismiss();
            },
          },
        ]
      );
    };

    const renderBackdrop = (
      props: BottomSheetBackdropProps,
      ref: React.RefObject<BottomSheetModal>
    ) => (
      <BottomSheetBackdrop
        {...props}
        closeSheet={() =>
          blankTaskForm ? ref.current?.dismiss() : discardAlert(ref)
        }
      />
    );

    const handleForm = (isFormBlank: boolean) => {
      setBlankTaskForm(isFormBlank);
    };

    return (
      <>
        <BottomSheetModal
          keyboardBehavior='fillParent'
          name='Task Form'
          index={1}
          ref={bottomSheetTaskFormRef}
          snapPoints={['50%', '75%', '90%']}
          handleComponent={handleComponent}
          backdropComponent={(props) =>
            renderBackdrop(props, bottomSheetTaskFormRef)
          }
          onChange={handleFormSheetChange}>
          <TaskCreateModalForm
            textInputRef={inputRef}
            isCreateTask
            taskname={taskname}
            members={selectedMembers}
            goBackOnComplete={goBackOnComplete}
            onFormValidityChange={handleForm}
            onSave={() => {
              if (isAssignTask && chatId !== '' && values.users.length == 0) {
                Alert.alert('Please assign a chat member to this task.');
                return;
              }
              submitForm();
              setSelectedMembers([]);
            }}
            disabled={!isValid || isSubmitting}
            onCancel={() => {
              setSelectedMembers([]);
              dismissTaskFormModal();
            }}
            selectedList={selectedList}
            updateMember={(member, role) => updateMember(member.id, role)}
            addMembers={() => {
              if (chatId) {
                navigation.navigate('add-task-modal-members', {
                  chatId: chatId,
                });
              } else {
                navigation.navigate('task-add-members-stack', {
                  selectedProjectId: values.projectId,
                  isCreateTaskForMobile: true,
                });
              }
            }}
            onProjectPress={openProjectModal}
            onDatePress={openDateModal}
            parentProject={parentProject}
          />
        </BottomSheetModal>

        <BottomSheetModal
          name='Project Modal'
          index={modalPosition}
          stackBehavior='push'
          enableContentPanningGesture={false}
          enableHandlePanningGesture={false}
          ref={bottomSheetProjectModalRef}
          snapPoints={['100%', '100%', '100%']}
          handleComponent={handleComponentScreen}>
          <>
            <SecondaryHeader
              title='Select a Project'
              topWrap={false}
              isProject
              dismissProject={dismissProjectModal}
            />
            <ScrollView>
              <ProjectSelect
                isBottomSheet
                onSelectFinish={dismissProjectModal}
              />
            </ScrollView>
          </>
        </BottomSheetModal>

        <BottomSheetModal
          name='Date Modal'
          index={modalPosition}
          stackBehavior='push'
          ref={bottomSheetDateModalRef}
          snapPoints={snapPoints}
          handleComponent={handleComponent}>
          <DateSelect
            isBottomSheet
            onSelectFinish={dismissDateModal}
            formField='dueDate'
          />
        </BottomSheetModal>
      </>
    );
  }
);

const styles = StyleSheet.create({
  closeLine: {
    alignSelf: 'center',
    width: 56,
    height: 4,
    borderRadius: 2.5,
    backgroundColor: theme.colors.grey03,
    margin: theme.spacing.l,
  },

  closeLineScreen: {
    margin: theme.spacing.l,
  },
});

// STACK MODALS EXAMPLE -> https://github.com/gorhom/react-native-bottom-sheet/blob/master/example/app/src/screens/modal/StackExample.tsx
