import { useState } from 'react';
import { RefreshControl } from 'react-native';

import {
  Project,
  ProjectMemberRole,
  ProjectSortOption,
  useListProjectsWithoutSpecifiedRolesQuery,
} from '@graphql/generated';
import {
  logError,
  LogGraphError,
  LogNetworkError,
} from '@utils/logNonFatalError';

/**
 * Retrieves a list of projects
 */
//changed the size from 10 to 25 to stop project selection modal blinking due to loading data in chunks in single scroll
const DEFAULT_PER_PAGE_COUNT = 25;

interface ListProjectsWithoutSpecifiedRolesFromQueryProps {
  roles: [ProjectMemberRole];
  before: string;
  after?: string;
  first?: number;
  last?: number;
  sortBy?: ProjectSortOption;
}

export const useListProjectsWithoutSpecifiedRolesFromQuery = (
  props?: ListProjectsWithoutSpecifiedRolesFromQueryProps
) => {
  const {
    roles = undefined,
    before = undefined,
    after = undefined,
    first = DEFAULT_PER_PAGE_COUNT,
    last = undefined,
    sortBy = undefined,
  } = props || {};

  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, error, refetch, fetchMore } =
    useListProjectsWithoutSpecifiedRolesQuery({
      variables: {
        sortBy,
        roles: roles || [],
        first,
        last,
        before,
        after,
      },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        if (error.networkError) {
          const event: LogNetworkError = {
            message: 'List Projects without specific roles Network Failure',
            errorMessage: error.message,
            statusCode:
              'statusCode' in error.networkError
                ? error.networkError.statusCode
                : -1,
          };
          logError(event);
        }
        error.graphQLErrors.forEach((graphError) => {
          const event: LogGraphError = {
            message: 'List Projects GraphQL Error',
            errorMessage: graphError.message,
            operationName: 'listProjectsWithoutSpecifiedRoles',
          };
          logError(event);
        });
      },
    });

  const refreshControl = (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refetch();
      }}
    />
  );

  const fetchFromCursor = () => {
    if (!projects || !pageInfo?.hasNextPage || loading) return;

    const cursor = pageInfo?.endCursor;
    fetchMore({
      variables: {
        after: cursor,
        first,
      },
    });
  };

  const { listProjectsWithoutSpecifiedRoles } = data || {
    listProjectsWithoutSpecifiedRoles: undefined,
  };
  const { edges = [], pageInfo } = listProjectsWithoutSpecifiedRoles || {
    edges: [],
  };
  const projects =
    edges?.reduce((acc, curr) => {
      if (curr && curr.node && curr.node !== undefined) {
        return [...acc, curr.node as Project];
      } else {
        return acc;
      }
    }, [] as Project[]) || [];

  return {
    projects,
    loading,
    error,
    pageInfo,
    refetch,
    fetchFromCursor,
    refreshing,
    setRefreshing,
    refreshControl,
  };
};
