import React, { useState } from 'react';

import ChatAttachments from '@components/Chat/ChatAttachments.web';
import { WebIcon } from '@components/shared/Icon/WebIcon';

import { ChatInputOptionsBarProps } from './ChatInputOptionsBar';
import Box from '../Box/Box';
import Icon from '../shared/Icon/Icon';

export const ChatInputOptionsBar = ({
  onSend,
  onTagPress,
  onImagePress,
  disableSend,
  onDocumentPress,
  onEmojiPress,
  chatId,
  focus = true,
  onCompletedTask,
  onPlusSelect,
}: ChatInputOptionsBarProps) => {
  const [isHovered, setIsHovered] = useState(false);
  if (!chatId) return null;

  const iconColor = focus ? 'textPrimary' : 'grey04';
  const onHoverIn = () => setIsHovered(true);
  const onHoverOut = () => setIsHovered(false);

  return (
    <Box flexDirection='row' paddingHorizontal='m'>
      <Box justifyContent='space-between' flexDirection='row'>
        <Box
          py='xxs'
          style={{ padding: 10 }}
          borderRadius='xs'
          pointerEvents='auto'
          onMouseEnter={onHoverIn}
          onMouseLeave={onHoverOut}
          backgroundColor={isHovered ? 'grey01' : 'white'}>
          <ChatAttachments
            iconColor={iconColor}
            onPress={() => {
              onPlusSelect && onPlusSelect();
              onHoverOut();
            }}
            onCompletedTask={() => {
              onCompletedTask && onCompletedTask();
            }}
          />
        </Box>
        <Box marginLeft='xxs'>
          <WebIcon
            accessibilityLabel='Tag'
            name='Hash'
            padding='xxxs'
            margin='xxs'
            borderRadius='xs'
            variant='m'
            color={iconColor}
            onPress={onTagPress}
          />
        </Box>

        <Box marginLeft='xxs'>
          <WebIcon
            accessibilityLabel='Add File'
            name='File'
            padding='xxxs'
            margin='xxs'
            borderRadius='xs'
            variant='m'
            color={iconColor}
            onPress={onDocumentPress}
          />
        </Box>

        <Box marginLeft='xxs'>
          <WebIcon
            accessibilityLabel='Gallery'
            name='Image'
            padding='xxxs'
            margin='xxs'
            borderRadius='xs'
            variant='m'
            color={iconColor}
            onPress={onImagePress}
          />
        </Box>
        <Box marginLeft='xxs'>
          <WebIcon
            name='Emoji'
            padding='xxxs'
            margin='xxs'
            borderRadius='xs'
            variant='m'
            color={iconColor}
            onPress={onEmojiPress}
          />
        </Box>
      </Box>
      <Box flex={1} alignItems='flex-end'>
        <Icon
          accessibilityLabel='Send message'
          name='Send_v2'
          backgroundColor={disableSend ? 'grey02' : 'black'}
          color='white'
          variant='m'
          onPress={onSend}
          disabled={disableSend}
        />
      </Box>
    </Box>
  );
};
