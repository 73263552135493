import AsyncStorage from '@react-native-async-storage/async-storage';
import { Audio, AVPlaybackStatus } from 'expo-av';
import React, { useEffect, useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { ProgressBar } from 'react-native-paper';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Document, LocalFile } from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import { useGetDocumentsByClientIdsQuery } from '@hooks/useGetDocumentsByClientIdsQuery';
import theme from '@themes/theme';
import { formatToMMSS } from '@utils/voiceUtils';

interface VoiceCardInterface {
  voice: Document | LocalFile;
  onDelete?: (voice: LocalFile) => void;
  onLongPress?: () => void;
}
Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
const UPDATE_INTERVAL = 100;

export const VoiceCard: React.FC<VoiceCardInterface> = ({
  voice,
  onDelete,
  onLongPress,
}) => {
  const [sound, setSound] = useState<Audio.Sound>();

  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [pausedPosition, setPausedPosition] = useState<number>(0);
  const [recordTime, setRecordTime] = useState(0);
  const { playingVoiceMemoId, setPlayingVoiceMemoId } = useChatInput();
  const [playingCurrentVoiceFile, setPlayingCurrentVoiceFile] = useState(false);
  const { checkDocumentURL } = useGetDocumentsByClientIdsQuery();

  const [uri, setUri] = useState<string>('');

  const voiceAssetIsLocal = (
    voiceAsset: Document | LocalFile
  ): voiceAsset is LocalFile => {
    return (
      (voiceAsset as LocalFile)?.url?.startsWith('file:') ||
      (voiceAsset as LocalFile)?.url?.startsWith('data:')
    );
  };

  const setUriFromCache = async () => {
    const url = await AsyncStorage.getItem(voice.clientId);
    if (!url) {
      await checkDocumentURL([voice as Document]);
      setTimeout(async () => {
        await setUriFromCache();
      }, 1000);
      return;
    }
    setUri(url);
  };
  useEffect(() => {
    if (voiceAssetIsLocal(voice)) {
      setUri(voice.url);
    } else {
      setUriFromCache();
    }
  }, [voice.clientId]);

  const isSending = uri?.startsWith('file:') && !voiceAssetIsLocal(voice);

  const statusUpdate = (d: AVPlaybackStatus) => {
    if (!d.isLoaded) return;
    setLoading(!d.isLoaded);

    // Update your UI for the playing state
    const { durationMillis, positionMillis } = d;

    if (positionMillis === undefined || durationMillis === undefined) return;

    const seconds =
      Math.floor(durationMillis / 1000) - Math.floor(positionMillis / 1000);
    setPausedPosition(positionMillis);
    setRecordTime(seconds);
    setProgress(positionMillis / durationMillis);

    // reached end of playback, reset
    if (d.didJustFinish) {
      setPlayingVoiceMemoId('');
      setPausedPosition(0);
      setProgress(1);
    }
  };

  const playRecording = async () => {
    if (!uri) return;
    setLoading(true);
    setPlayingVoiceMemoId(voice.id);
    const { sound: createdSound } = await Audio.Sound.createAsync(
      {
        uri,
      },
      {
        positionMillis: progress === 1 ? 0 : pausedPosition,
        progressUpdateIntervalMillis: UPDATE_INTERVAL,
      }
    );

    if (!createdSound) return;

    createdSound.setOnPlaybackStatusUpdate(statusUpdate);
    setSound(createdSound);

    createdSound.playAsync();
  };

  const pausePlaying = async () => {
    await sound?.pauseAsync().then(() => setPlayingVoiceMemoId(''));
  };

  useEffect(() => {
    return sound
      ? () => {
          setPlayingVoiceMemoId('');
          sound.unloadAsync();
        }
      : undefined;
  }, []);

  useEffect(() => {
    setPlayingCurrentVoiceFile(playingVoiceMemoId === voice.id);
    // there is a different voice file being played. pause.
    if (playingVoiceMemoId && !playingCurrentVoiceFile && sound) {
      // call pause here causes Android to have a few more status updates.
      // call unloadAsync to immediately stop
      sound.unloadAsync();
    }
  }, [playingVoiceMemoId, playingCurrentVoiceFile]);

  return (
    <Box
      flexDirection='row'
      alignItems='center'
      borderColor='grey02'
      borderWidth={1}
      borderRadius='xs'
      padding='xs'
      backgroundColor={loading ? 'grey01' : 'white'}
      opacity={isSending ? 0.3 : 1}
      minWidth={Platform.OS === 'web' ? 320 : 220}>
      <TouchableOpacity
        onLongPress={onLongPress}
        onPress={() => {
          if (loading || !uri) return;
          playingCurrentVoiceFile ? pausePlaying() : playRecording();
        }}
        hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
        <Icon
          name={playingCurrentVoiceFile ? 'PauseCircle' : 'PlayCircle'}
          variant='l'
          color={loading || !uri ? 'grey03' : 'black'}
        />
      </TouchableOpacity>
      <Box flex={1} marginHorizontal='xs'>
        <TouchableOpacity onLongPress={onLongPress}>
          <ProgressBar
            style={{
              height: 4,
              backgroundColor: theme.colors.grey05,
            }}
            progress={progress}
            color={theme.colors.grey03}
          />
        </TouchableOpacity>
      </Box>
      <TouchableOpacity onLongPress={onLongPress}>
        <Text
          variant='metadata'
          color='grey05'
          textAlign='center'
          // set a width so the recordTime changing will not affect the progress bar flex
          style={{ width: 42 }}>
          {(playingCurrentVoiceFile || pausedPosition) && progress !== 1
            ? formatToMMSS(recordTime || 0)
            : formatToMMSS(voice?.duration || 0)}
        </Text>
      </TouchableOpacity>
      {onDelete && (
        <TouchableOpacity onPress={() => onDelete(voice as LocalFile)}>
          <Icon name='X2' variant='l' marginLeft='xs' color='textPrimary' />
        </TouchableOpacity>
      )}
    </Box>
  );
};
