import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';

import { AlphaSortSlider } from '@components/AlphaSortSlider/AlphaSortSlider';
import EmptyStateNoContacts from '@components/EmptyState/EmptyStateNoContacts';
import EmptyStateNoSearch from '@components/EmptyState/EmptyStateNoSearch';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { useListContactUsersQuery } from '@graphql/generated';
import theme from '@themes/theme';

import Contact, { ContactType } from './Contact';

export interface ContactListProps {
  contacts: ContactType[];
  taskTagUsers?: ContactType[];
  onPress: (item: ContactType) => void;
  filterVal?: string;
  alphabetize?: boolean;
  headerSpace?: keyof typeof theme.spacing;
  footerSpace?: number;
  isEditing?: boolean;
  isInviting?: boolean;
  loading?: boolean;
  selected?: string[];
  shouldDisplayPhoneContactLabel?: boolean;
  disabledRoles?: string[];
  showMessageIcon?: boolean;
  onMessagePress?: (item: ContactType) => void;
  excludeList?: ContactType[];
  showEmptyStateNoSearch?: boolean;
  showEmptyStateNoContacts?: boolean;
  disabledUsersId?: string[];
}

const ESTIMATED_CONTACT_ITEM_SIZE = 40;

const ContactList: React.FC<ContactListProps> = ({
  contacts,
  taskTagUsers,
  filterVal,
  alphabetize = false,
  onPress,
  headerSpace,
  footerSpace,
  isEditing,
  isInviting,
  loading,
  selected,
  shouldDisplayPhoneContactLabel,
  disabledRoles,
  disabledUsersId,
  showMessageIcon = false,
  showEmptyStateNoSearch = false,
  showEmptyStateNoContacts = false,
  onMessagePress,
}) => {
  const { t } = useTranslation();

  const [sortedFilteredContacts, setSortedFilteredContacts] =
    useState<ContactType[]>(contacts);

  const { refetch } = useListContactUsersQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, []);

  const [alphabetizedSectionList, setAlphabetizedSectionList] = useState<
    (ContactType | string)[]
  >([]);
  const [alphabetizeList, setAlphabetizeList] = useState<string[]>([]);
  const flashListRef = useRef<FlashList<string>>(null);
  const [selectedContact, setSelectedContact] = useState<
    ContactType | undefined
  >();
  const [friendRequestSuccess, setFriendRequestSuccess] = useState(false);
  const taskTagUserIds = taskTagUsers?.map((i) => i.id);

  useEffect(() => {
    const filtered = filterVal
      ? contacts.filter((contact) =>
          contact.displayName.toLowerCase().includes(filterVal.toLowerCase())
        )
      : contacts;

    // Set both filtered and complete contact lists

    setSortedFilteredContacts(
      filtered.sort((a, b) =>
        a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
      )
    );
  }, [contacts.length, filterVal]);

  useEffect(() => {
    if (!alphabetize) return;
    // Only call when filteredContacts change, not on length alone
    if (sortedFilteredContacts.length > 0 || filterVal !== '') {
      getAlphabetizedSectionList();
    }
  }, [sortedFilteredContacts]);

  const sortContacts = () => {
    const numsSpecials: ContactType[] = []; // numbers and ASCII before A
    const letters: ContactType[] = []; // letters
    const asterisks: ContactType[] = []; // ASCII after Z

    sortedFilteredContacts.forEach((s) => {
      const firstChar = s.displayName?.[0];
      if (!firstChar) return;

      const charCode = firstChar.charCodeAt(0);

      if (/[a-z]/i.test(firstChar)) {
        letters.push(s); // Letters A-Z or a-z
      } else if (charCode < 65) {
        numsSpecials.push(s); // Characters before 'A'
      } else {
        asterisks.push(s); // Characters after 'Z'
      }
    });

    // Helper function to sort by displayName's first character's char code
    const sortByCharCode = (arr: ContactType[]) =>
      arr.sort(
        (a, b) =>
          a.displayName[0].charCodeAt(0) - b.displayName[0].charCodeAt(0)
      );

    // Sort each category
    sortByCharCode(numsSpecials);
    letters.sort((a, b) =>
      a.displayName[0]
        .toUpperCase()
        .localeCompare(b.displayName[0].toUpperCase())
    );
    sortByCharCode(asterisks);

    return { letters, numsSpecials, asterisks };
  };

  const getAlphabetizedSectionList = () => {
    const tmpAlphabetizeList = ['#'];
    const { letters, numsSpecials, asterisks } = sortContacts();

    const formattedAlpha: (ContactType | string)[] = [];
    const formattedNumSpecial: (ContactType | string)[] = numsSpecials.length
      ? ['#', ...numsSpecials]
      : [];
    const formattedAsterisk: (ContactType | string)[] = asterisks.length
      ? ['*', ...asterisks]
      : [];

    letters.forEach((obj) => {
      const key = obj.displayName.charAt(0).toUpperCase();

      // Check if the key is already added, if not, add it before the contact
      if (!formattedAlpha.includes(key)) {
        formattedAlpha.push(key);
      }
      formattedAlpha.push(obj);
    });

    // Add A-Z to the alphabetize list
    for (
      let charCode = 'A'.charCodeAt(0);
      charCode <= 'Z'.charCodeAt(0);
      charCode++
    ) {
      tmpAlphabetizeList.push(String.fromCharCode(charCode));
    }

    // Add asterisk section to the alphabetize list
    tmpAlphabetizeList.push('*');

    setAlphabetizeList(tmpAlphabetizeList);
    setAlphabetizedSectionList([
      ...formattedNumSpecial,
      ...formattedAlpha,
      ...formattedAsterisk,
    ]);
  };

  if (loading) return <ActivityIndicatorLoading testID='loading-indicator' />;
  const toggleFriendRequestSuccess = (friendRequest: boolean) => {
    setFriendRequestSuccess(friendRequest);
  };

  const renderContact = (item: ContactType, disabled?: boolean) => {
    const isTaskTagUser = taskTagUserIds?.includes(item.id);
    return (
      <Contact
        key={item.id}
        toggleFriendRequestSuccess={
          isTaskTagUser ? toggleFriendRequestSuccess : () => {}
        }
        isTaskTagUser={isTaskTagUser}
        contact={{ ...item, roleLabel: item.roleLabel }}
        filterVal={filterVal}
        onPress={() => {
          if (!isEditing) {
            setSelectedContact((current) => {
              return current?.id === item.id ? undefined : item;
            });
          }
          onPress(item);
        }}
        isEditing={isEditing}
        isSelected={selected?.some((i) => i === item.id) || item.isAssigned}
        isInviting={isInviting}
        shouldDisplayPhoneContactLabel={shouldDisplayPhoneContactLabel}
        disabled={disabled || item.isAssigned || isTaskTagUser}
        showMessageIcon={showMessageIcon}
        onMessagePress={() => onMessagePress?.(item)}
      />
    );
  };

  const contactsAndUsersList = () => {
    return [
      ...sortedFilteredContacts,
      t('shared:moreOnTaskTag'),
      ...(taskTagUsers || []),
    ];
  };

  if (taskTagUsers && taskTagUsers.length > 0) {
    // section list

    return (
      <View style={{ flex: 1 }}>
        <FlashList
          keyboardDismissMode='on-drag'
          ItemSeparatorComponent={() => <Box marginTop='m' />}
          ListFooterComponent={() => <Box marginBottom='listFooter' />}
          data={contactsAndUsersList()}
          renderItem={({ item }) => {
            if (typeof item === 'string') {
              return (
                <Box
                  mt='s'
                  mb='s'
                  marginHorizontal='m'
                  pt='l'
                  borderTopWidth={1}
                  borderColor='grey02'>
                  <Text variant='labelSmall'>{item}</Text>
                </Box>
              );
            } else {
              return renderContact(item);
            }
          }}
          getItemType={(item) => {
            // To achieve better performance, specify the type based on the item
            return typeof item === 'string' ? 'sectionHeader' : 'row';
          }}
          estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
        />

        {friendRequestSuccess && Platform.OS !== 'web' && (
          <View style={{ marginBottom: theme.spacing.l }}>
            <Box
              backgroundColor='black'
              opacity={0.8}
              paddingHorizontal='s'
              paddingVertical='xs'
              borderRadius='xs'
              alignSelf='center'>
              <Text
                color='white'
                variant='labelSmall'
                lineHeight={16}
                textAlign='center'>
                {t('models:chat.connectionRequestSend')}
              </Text>
            </Box>
          </View>
        )}
      </View>
    );
  }

  const getListEmptyComponent = () => {
    if (showEmptyStateNoSearch) {
      return (
        <Box
          px={Platform.OS !== 'web' ? undefined : 'l'}
          style={{ marginTop: -theme.spacing.listFooter / 2 }}>
          <EmptyStateNoSearch showSmall />
        </Box>
      );
    }
    if (showEmptyStateNoContacts) {
      return (
        <Box
          px={Platform.OS !== 'web' ? undefined : 'l'}
          style={{ marginTop: -theme.spacing.listFooter / 2 }}>
          <EmptyStateNoContacts showSmall />
        </Box>
      );
    }
    return undefined;
  };

  return (
    <>
      {alphabetize ? (
        <Box flex={1} position='relative'>
          <FlashList
            ref={flashListRef}
            extraData={selected}
            showsVerticalScrollIndicator={false}
            estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
            keyboardDismissMode='on-drag'
            ItemSeparatorComponent={() => <Box marginTop='m' />}
            ListFooterComponent={() => <Box paddingBottom='listFooter' />}
            ListEmptyComponent={getListEmptyComponent()}
            data={alphabetizedSectionList}
            renderItem={({ item }) => {
              if (typeof item === 'string') {
                return (
                  <Box
                    backgroundColor='grey01'
                    paddingVertical='xs'
                    paddingLeft='m'>
                    <Text color='textPrimary' variant='labelEmphasized'>
                      {item}
                    </Text>
                  </Box>
                );
              }
              // On the web, keep clicking after clicking the contact
              else if (onMessagePress) {
                return renderContact(item, false);
              } else {
                const disabled =
                  (!!item.roleLabel &&
                    disabledRoles?.includes(item.roleLabel)) ||
                  selectedContact?.id === item.id ||
                  disabledUsersId?.includes(item.id);

                return renderContact(item, disabled);
              }
            }}
            getItemType={(item) => {
              // To achieve better performance, specify the type based on the item
              return typeof item === 'string' ? 'sectionHeader' : 'row';
            }}
          />
          {Platform.OS !== 'web' && alphabetizedSectionList.length > 0 && (
            <AlphaSortSlider
              flashListRef={flashListRef}
              alphabetizeList={alphabetizeList}
            />
          )}
        </Box>
      ) : (
        <FlashList
          extraData={selected}
          estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
          keyboardDismissMode='on-drag'
          data={sortedFilteredContacts}
          renderItem={({ item }) => renderContact(item)}
          ItemSeparatorComponent={() => <Box marginTop='m' />}
          ListHeaderComponent={() =>
            headerSpace ? <Box marginBottom={headerSpace} /> : null
          }
          ListFooterComponent={() =>
            showEmptyStateNoSearch ? undefined : (
              <Box
                style={{
                  paddingBottom:
                    footerSpace !== undefined
                      ? footerSpace
                      : theme.spacing.listFooter,
                }}
              />
            )
          }
          ListEmptyComponent={
            showEmptyStateNoSearch ? (
              <Box
                px={Platform.OS !== 'web' ? undefined : 'l'}
                style={{ marginTop: -theme.spacing.listFooter / 2 }}>
                <EmptyStateNoSearch showSmall />
              </Box>
            ) : undefined
          }
        />
      )}
    </>
  );
};

export default ContactList;
