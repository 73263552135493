import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableWithoutFeedback } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { MessageCheckin } from '@graphql/generated';
import { useLocation } from '@utils/locationUtils';

import { Box, Text } from '../Restyle';

type CheckInProps = {
  __typename: 'check in';
  checkin: MessageCheckin;
  error?: boolean;
  folderName?: string;
  tags?: string[];
  onClose?: () => void;
  onLongPress?: () => void;
};

export const CheckInCard: React.FC<CheckInProps> = (props) => {
  const { t } = useTranslation('format');
  const { checkin, error, folderName, tags, onClose, onLongPress } = props;
  const { address, city, state, zipcode, latitude, longitude } = checkin;
  const { openAddress: openAddressFnc } = useLocation();

  const openAddress = () => {
    const newAddress = `${address} ${t('checkInCardLocation', {
      city,
      state,
      zipcode,
    })}`;
    openAddressFnc(newAddress, latitude, longitude);
  };

  return (
    <TouchableWithoutFeedback onLongPress={onLongPress}>
      <Box
        borderColor={error ? 'alertRed' : 'grey02'}
        backgroundColor='white'
        borderRadius='xs'
        borderWidth={1}
        marginVertical={Platform.OS === 'web' ? 's' : 'none'}
        padding='xs'
        overflow='hidden'>
        <Box flex={1} flexDirection='row' alignItems='center' overflow='hidden'>
          <Icon
            variant='l'
            name='MapFlat'
            backgroundColor='grey01'
            onPress={openAddress}
          />

          <Box marginLeft='xs' flex={1}>
            <TouchableOpacity onPress={openAddress}>
              <Text
                color='blue'
                variant='labelEmphasized'
                fontSize={14}
                lineHeight={21}
                numberOfLines={1}>
                {address}
              </Text>
              <Text
                style={{ color: '#91929E' }}
                variant='metadata'
                lineHeight={15}
                marginTop='xxxs'
                numberOfLines={1}>
                {t('checkInCardLocation', { city, state, zipcode })}
              </Text>
            </TouchableOpacity>
          </Box>

          {onClose && (
            <TouchableOpacity
              onPress={() => onClose()}
              hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
              <Icon
                name='X2'
                variant='l'
                marginTop='xxxs'
                color='textPrimary'
              />
            </TouchableOpacity>
          )}
        </Box>
        {error && (
          <Box flexDirection='row' marginTop='s'>
            <Icon variant='s' name='AlertCircle' color='alertRed' />
            <Text variant='metadata' color='alertRed' marginLeft='xs'>
              {t('models:chat.checkin.checkinError')}
            </Text>
          </Box>
        )}
        <Box marginTop='xxs'>
          <Box flexDirection='row' flexWrap='wrap'>
            {folderName && (
              <Tag
                labelVariant='labelSmall'
                variant='secondaryReversed'
                prefixMarginRight='xxs'
                padding='xxs'
                marginRight='xxs'
                marginTop='xxs'
                borderRadius='xxs'
                label={folderName}
                prefix={<Icon variant='xs' name='Folder' color='white' />}
              />
            )}
            {tags?.map((t) => (
              <Tag
                key={t}
                labelVariant='labelSmall'
                prefixMarginRight='xxs'
                variant='reversed'
                padding='xxs'
                borderRadius='xxs'
                label={t}
                marginRight='xxs'
                marginTop='xxs'
                prefix={<Icon variant='xs' name='Hash' color='white' />}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </TouchableWithoutFeedback>
  );
};
