export const SUBSCRIPTION_STATUS = {
  UNSUBSCRIBED: 'unsubscribed',
  SUBSCRIBED: 'subscribed',
  LAPSED: 'lapsed',
} as const;

export const SESSION_SELECTED_TEAM_NAME: string =
  'selectedTeamName_Subscription' as const;

export const WEB_PLAN_NAME: string = 'team' as const;
export const MOBILE_PLAN_NAME: string = 'plus' as const;
