import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';

import useMe from '@hooks/useMe';

import Modal from './Modal';
import CheckSelect from '../../components/shared/Select/CheckSelect';
import { ModalHeader } from '../Headers/ModalHeader';
import { Box } from '../Restyle';

export type TeamRolesAssignable = 'Admin' | 'Member';

const TeamMemberRoleSelectModal: React.FC = () => {
  const navigation = useNavigation();
  const { shareInviteLink } = useMe();

  const [selected, setSelected] = useState<TeamRolesAssignable>('Member');

  const options: TeamRolesAssignable[] = ['Admin', 'Member'];

  return (
    <Modal>
      <Box marginTop='m'>
        <ModalHeader
          title='Select Roles'
          submitLabel='Invite'
          onCancel={() => navigation.pop()}
          onSubmit={shareInviteLink}
        />

        <Box marginTop='xl'>
          {options.map((i, index) => (
            <Box
              key={i}
              marginBottom={index + 1 !== options.length ? 'm' : undefined}>
              <CheckSelect
                label={i}
                isSelected={i === selected}
                onPress={() => setSelected(i)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default TeamMemberRoleSelectModal;
