import AsyncStorage from '@react-native-async-storage/async-storage';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import JSZip from 'jszip';
import { createContext, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { Document, LocalFile } from '@graphql/generated';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { useSaveImage } from '@hooks/useSaveImage';

type DownloadFilesContext = {
  selectedFiles: (Document | LocalFile | undefined)[];
  setSelectedFiles: React.Dispatch<
    React.SetStateAction<(Document | LocalFile | undefined)[]>
  >;
  downloadingErrorFiles: (Document | LocalFile | undefined)[];
  setDownloadingErrorFiles: React.Dispatch<
    React.SetStateAction<(Document | LocalFile | undefined)[]>
  >;
  downloadingSuccessFiles: (Document | LocalFile | undefined)[];
  setDownloadingSuccessFiles: React.Dispatch<
    React.SetStateAction<(Document | LocalFile | undefined)[]>
  >;
  downloadFile: () => void;
  downloadFiles: (
    files: (Document | LocalFile | undefined)[],
    isRetryDownloadErrorFiles?: boolean,
    isMedia?: boolean
  ) => void;
  showMsg: boolean;
  setShowMsg: React.Dispatch<React.SetStateAction<boolean>>;
  isDownloading: boolean;
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>;
};

const downloadFilesContext = createContext<DownloadFilesContext | undefined>(
  undefined
);

export const DownloadFilesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = downloadFilesContext;
  const { activeIndex, previewDocuments } = usePreviewDocuments();
  const [selectedFiles, setSelectedFiles] = useState<
    (Document | LocalFile | undefined)[]
  >([]);
  const [downloadingErrorFiles, setDownloadingErrorFiles] = useState<
    (Document | LocalFile | undefined)[]
  >([]);
  const [downloadingSuccessFiles, setDownloadingSuccessFiles] = useState<
    (Document | LocalFile | undefined)[]
  >([]);
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadingSuccessFile, setDownloadingSuccessFile] = useState<
    Document | LocalFile | undefined
  >();
  const [downloadingErrorFile, setDownloadingErrorFile] = useState<
    Document | LocalFile | undefined
  >();
  const [isRetryDownloadErrorFiles, setIsRetryDownloadErrorFiles] =
    useState<boolean>(false);
  const [isMedia, setIsMedia] = useState<boolean>(false);

  useEffect(() => {
    if (
      (downloadingSuccessFiles.length > 0 ||
        downloadingErrorFiles.length > 0) &&
      selectedFiles.length === 0
    ) {
      setTimeout(
        () => {
          setIsDownloading(false);
          setShowMsg(false);
          setDownloadingSuccessFiles([]);
          setDownloadingErrorFiles([]);
        },
        downloadingErrorFiles.length > 0 ? 10000 : 5000
      );
    } else if (selectedFiles.length > 0 || downloadingSuccessFiles.length > 0) {
      !showMsg && downloadingSuccessFiles.length > 0 && setShowMsg(true);
    }
  }, [selectedFiles, downloadingSuccessFiles, downloadingErrorFiles]);

  const { saveImage } = useSaveImage();
  const getFileInfo = async (item?: Document | LocalFile) => {
    const file = item ? item : previewDocuments?.[activeIndex];
    let fileURL;
    if (file.file.url) {
      fileURL = file.file.url;
    } else {
      if (file.isImage) {
        fileURL = file.file.cdnBaseUrl + file.file.path;
      } else {
        fileURL = await AsyncStorage.getItem(file.clientId);
      }
    }
    if (isMedia && file?.contentType?.startsWith('image')) {
      await saveImage(fileURL, getFileName(file), false);
    }

    const fileUri = encodeURI(
      FileSystem.documentDirectory + (file ? getFileName(file) : '')
    );

    let fileInfo = await FileSystem.getInfoAsync(fileUri);
    if (!fileInfo.exists) {
      await FileSystem.downloadAsync(file ? fileURL : '', fileUri)
        .then(({ uri }) => {
          console.log('Finished downloading to ', uri);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    fileInfo = await FileSystem.getInfoAsync(fileUri);
    if (!fileInfo.exists) {
      setDownloadingErrorFile(file);
    }
    return fileInfo;
  };

  const getFileName = (item?: Document | LocalFile) => {
    const file = item ? item : previewDocuments?.[activeIndex];
    let name = '';
    switch (file?.contentType) {
      case 'audio/m4a':
        name = file?.id + '.m4a';
        break;
      case 'audio/caf':
        name = file?.id + '.caf';
        break;
      case 'application/octet-stream':
        if (file?.name) {
          name = file?.name + '.png';
        } else {
          name = file?.id + '.png';
        }
        break;
      default:
        name = file ? file?.name : '';
    }
    return name;
  };

  useEffect(() => {
    if (!isDownloading && selectedFiles.length > 0) {
      setIsDownloading(true);

      Platform.OS === 'web'
        ? (async () => await downloadFilesWeb(selectedFiles as Document[]))()
        : selectedFiles.map(async (file) => {
            await doDownloadFile(file);
          });
    }
  }, [selectedFiles]);

  useEffect(() => {
    setDownloadingSuccessFiles([
      ...downloadingSuccessFiles,
      downloadingSuccessFile,
    ]);

    if (downloadingSuccessFile && isRetryDownloadErrorFiles) {
      const downloadingErrorFiles1 = downloadingErrorFiles.filter(
        (f) => f?.id !== downloadingSuccessFile?.id
      );
      setDownloadingErrorFiles(downloadingErrorFiles1);
    }

    const selectedFiles1 = selectedFiles.filter(
      (f) => downloadingSuccessFile?.id !== f?.id
    );
    setSelectedFiles(selectedFiles1);
  }, [downloadingSuccessFile]);

  useEffect(() => {
    setDownloadingErrorFiles([...downloadingErrorFiles, downloadingErrorFile]);
    const selectedFiles1 = selectedFiles.filter(
      (f) => downloadingErrorFile?.id !== f?.id
    );
    setSelectedFiles(selectedFiles1);
  }, [downloadingErrorFile]);

  const downloadFiles = (
    files: (Document | LocalFile | undefined)[],
    isRetryDownloadErrorFiles?: boolean,
    isMedia?: boolean
  ) => {
    setIsRetryDownloadErrorFiles(!!isRetryDownloadErrorFiles);
    setIsMedia(!!isMedia);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const downloadFile = () => {
    previewDocuments &&
      setSelectedFiles([...selectedFiles, previewDocuments[activeIndex]]);
  };

  const downloadBlob = (blob: Blob, fileName: string) => {
    if (Platform.OS !== 'web') return;

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const downloadFilesWeb = async (items: Document[]) => {
    if (items?.length === 0) return;
    if (Platform.OS !== 'web') return;

    if (items?.length === 1) {
      const singleItem = items?.[0];
      let fileURL;
      if (singleItem.isImage) {
        fileURL = singleItem.file.cdnBaseUrl + singleItem.file.path;
      } else {
        fileURL = await AsyncStorage.getItem(singleItem.clientId);
      }
      const singleResponse = await fetch(fileURL);
      const singleBlob = await singleResponse?.blob();

      const singleFileName = singleItem?.name;
      downloadBlob(singleBlob, singleFileName);
      setIsDownloading(false);
      setSelectedFiles([]);
      return;
    }

    const itemsNewFormatted = items?.map((fileItem, index) => {
      const splitName = fileItem.name?.split('.');
      return {
        ...fileItem,
        name: `${splitName?.[0] || 'file'}${index}.${splitName?.[1] || ''}`,
      };
    });

    const zip = new JSZip();

    for (const document of itemsNewFormatted) {
      let fileURL;
      if (document.isImage) {
        fileURL = document.file.cdnBaseUrl + document.file.path;
      } else {
        fileURL = await AsyncStorage.getItem(document.clientId);
      }
      const response = await fetch(fileURL);
      const blob = await response?.blob();

      const fileName = document?.name;
      zip.file(fileName, blob);
    }

    const zipContent = await zip.generateAsync({ type: 'blob' });

    downloadBlob(zipContent, 'documents.zip');
    setIsDownloading(false);
    setSelectedFiles([]);
  };

  const doDownloadFile = async (item?: Document | LocalFile) => {
    const file = item ? item : previewDocuments?.[activeIndex];

    const fileInfo = await getFileInfo(file);
    if (fileInfo.exists) {
      setDownloadingSuccessFile(file);

      if (isMedia && file?.contentType?.startsWith('image')) return;

      if (Platform.OS === 'android') {
        let downloadDir = await AsyncStorage.getItem('@download_dir');
        if (!downloadDir) {
          const permission =
            await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync(
              'content://com.android.externalstorage.documents/tree/primary:Download/document/primary:Download'
            );
          if (!permission.granted) {
            return false;
          }
          AsyncStorage.setItem('@download_dir', permission.directoryUri);
          downloadDir = permission.directoryUri;
        }
        console.log('download_dir', downloadDir);
        const destinationUri =
          await FileSystem.StorageAccessFramework.createFileAsync(
            downloadDir,
            file ? getFileName(file) : '',
            file?.contentType ? file.contentType : ''
          );
        const contents =
          await FileSystem.StorageAccessFramework.readAsStringAsync(
            fileInfo.uri,
            { encoding: FileSystem.EncodingType.Base64 }
          );
        await FileSystem.StorageAccessFramework.writeAsStringAsync(
          destinationUri,
          contents,
          { encoding: FileSystem.EncodingType.Base64 }
        );
      } else if (Platform.OS === 'ios') {
        await Sharing.shareAsync(fileInfo.uri);
      }
    }
  };

  return (
    <Provider
      value={{
        selectedFiles,
        setSelectedFiles,
        downloadingSuccessFiles,
        setDownloadingSuccessFiles,
        downloadingErrorFiles,
        setDownloadingErrorFiles,
        downloadFile,
        downloadFiles,
        showMsg,
        setShowMsg,
        isDownloading,
        setIsDownloading,
      }}>
      {children}
    </Provider>
  );
};

const useDownloadFiles = (): DownloadFilesContext => {
  const context = useContext(downloadFilesContext);
  if (context === undefined) {
    throw new Error('useDownloadFiles must be used within a Provider');
  }
  return context;
};

export default useDownloadFiles;
