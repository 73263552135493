import dayjs from 'dayjs';
import React from 'react';

import { DateRangePicker2 } from '@components/DateRangePicker/DateRangePicker2';
import { DateRange } from '@components/DateRangePicker/DateRangePickerModal';
import { Box, ShadowBox } from '@components/Restyle';

export type PopupTaskFilterDuedateProps = {
  onSelectDueDate?: (dr: DateRange) => void;
  selecteds?: DateRange;
};

export const PopupTaskFilterDuedate: React.FC<PopupTaskFilterDuedateProps> = ({
  onSelectDueDate,
  selecteds = {},
}) => {
  return (
    <ShadowBox
      flex={1}
      width={504}
      alignItems='center'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <Box flex={1} style={{ width: 504 }}>
        <DateRangePicker2
          onSelect={(d1, d2) => {
            onSelectDueDate?.({
              start: d1 ? dayjs(d1) : undefined,
              end: d2 ? dayjs(d2) : undefined,
            });
          }}
          start={selecteds.start?.toDate()}
          end={selecteds.end?.toDate()}></DateRangePicker2>
      </Box>
    </ShadowBox>
  );
};
