import mime from 'mime';
import { Alert, Platform } from 'react-native';
import {
  pick,
  types as documentTypes,
  isCancel,
} from 'react-native-document-picker';
import { launchImageLibrary } from 'react-native-image-picker';
import uuid from 'react-native-uuid';

import { contentType, documentSize } from '@utils/fileUtils';

import { LocalFile } from '../graphql/generated';

export const selectImageFromGallery = async (
  selectionLimit?: number
): Promise<LocalFile[] | undefined> => {
  const RNFS = await import('react-native-fs');

  if (Platform.OS !== 'android') {
    const result = await launchImageLibrary({
      mediaType: 'mixed',
      quality: 0.9,
      selectionLimit: selectionLimit || 1,
    });

    if (!result.didCancel) {
      return (
        result?.assets?.map((item): LocalFile => {
          const clientId = uuid.v4().toString();

          if (!item.fileSize && item.uri) {
            item.fileSize = documentSize(item.uri);
          }

          if (!item.type && item.uri?.startsWith('data:')) {
            item.type = contentType(item.uri);
          }

          let ext =
            item.fileName?.split('.').pop() ||
            mime.getExtension(item.type || '');
          if (!ext) ext = 'jpg';

          const fileName = `${clientId}.${ext}`;
          const destPath = `${RNFS.DocumentDirectoryPath}/${fileName}`;
          const newItem = {
            ...item,
            uri: decodeURI(item?.uri || ''),
          };

          try {
            RNFS.copyFile(newItem.uri, destPath);
          } catch (err) {
            console.error('Cache image failed:', err);
          }

          // NOTE: cdnBaseUrl and path values are empty string to satisfy
          // Image type constraints. These are ignored by local images and
          // included when an Image is returned from the server.
          return {
            __typename: 'LocalFile',
            id: clientId,
            name: fileName,
            size: item.fileSize,
            clientId,
            url: `file://${destPath}`,
            cdnBaseUrl: '',
            path: '',
            contentType: item.type || 'application/octet-stream',
            isImage: Platform.select({
              default: true,
              web: item.type?.startsWith('image'),
            }),
            isAudio: false,
            isVideo: !item.type?.startsWith('image'),
            isPreviewable: false,
            thumbnail: '',
          };
        }) || []
      );
    }
  } else {
    let result;
    try {
      result = await pick({
        type: [documentTypes.images, documentTypes.video],
        allowMultiSelection: true,
        copyTo: 'cachesDirectory',
      });
      if (result.length > selectionLimit) {
        Alert.alert(`You can only select up to ${selectionLimit} files.`);
        return [];
      }
    } catch (e) {
      if (isCancel(e)) {
        return undefined;
      } else {
        throw e;
      }
    }

    if (result) {
      return (
        result?.map((item): LocalFile => {
          const uri = (item.fileCopyUri || item.uri).toString();
          const { name, type, size } = item;
          const clientId = uuid.v4().toString();

          let ext = name?.split('.').pop() || mime.getExtension(type || '');
          if (!ext) ext = 'jpg';

          const fileName = `${clientId}.${ext}`;
          const destPath = `${RNFS.DocumentDirectoryPath}/${fileName}`;

          try {
            RNFS.copyFile(uri, destPath);
          } catch (err) {
            console.error('Cache image failed:', err);
          }

          console.log('file size is: ', size);
          return {
            __typename: 'LocalFile',
            id: clientId,
            name: fileName,
            size: size,
            clientId,
            url: `file://${destPath}`,
            cdnBaseUrl: '',
            path: '',
            contentType: type || 'application/octet-stream',
            isImage: Platform.select({
              default: true,
              web: item.type?.startsWith('image'),
            }),
            isAudio: false,
            isVideo: !item.type?.startsWith('image'),
            isPreviewable: true,
            thumbnail: '',
          };
        }) || []
      );
    }
  }
};
