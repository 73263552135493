import React from 'react';
import { ListRenderItemInfo, Platform, RefreshControl } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { Box } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { TaskTagType } from '@src/types/task';
import theme from '@themes/theme';

import TaskTag from './TaskTag';

interface TaskTagListProps {
  filterVal?: string;
  taskTags: TaskTagType[];
  onSelect: (item: TaskTagType) => void;
  refetch: () => void;
  /** Controls loading indicator when fetching additional tasks */
  refreshing: boolean;
  setRefreshing: React.Dispatch<React.SetStateAction<boolean>>;
  /** Removes the padding that wraps the container and elements */
  withoutPadding?: true;
  onRefresh: () => void;
  listEmptyComponent?: JSX.Element;
  loading?: boolean;
  fetchMore?: () => void;
  showsVerticalScrollIndicator?: boolean;
  showProject?: boolean;
  showMember?: boolean;
}

export const TaskTagList = ({
  filterVal,
  taskTags,
  onSelect,
  withoutPadding,
  onRefresh,
  refreshing = false,
  listEmptyComponent,
  loading,
  fetchMore,
  showsVerticalScrollIndicator = true,
  showProject = true,
  showMember = true,
}: TaskTagListProps) => {
  const itemSeparatorComponent = () => (
    <Box
      height={1}
      marginTop='m'
      marginBottom='s'
      backgroundColor={showProject ? 'grey02' : 'grey01'}
    />
  );

  if (loading) return <ActivityIndicatorLoading />;

  return (
    <FlatList
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      ListEmptyComponent={listEmptyComponent}
      keyboardDismissMode='on-drag'
      keyboardShouldPersistTaps='always'
      data={taskTags}
      style={{
        paddingHorizontal:
          Platform.OS === 'web'
            ? theme.spacing.xs
            : (!withoutPadding && theme.spacing.m) || undefined,
      }}
      renderItem={({ item }: ListRenderItemInfo<TaskTagType>) => (
        <TaskTag
          key={item.id}
          taskName={item.name}
          projectName=''
          members={item.members}
          filterVal={filterVal}
          showMember={showMember}
          onSelect={() => {
            onSelect(item);
          }}
        />
      )}
      ItemSeparatorComponent={itemSeparatorComponent}
      ListFooterComponent={
        (!withoutPadding && (() => <Box paddingBottom='xs' />)) || undefined
      }
      ListHeaderComponent={
        (!withoutPadding && (() => <Box marginTop='xs' />)) || undefined
      }
      refreshing={refreshing}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      onEndReached={fetchMore}
      listKey={Date.now()}
    />
  );
};
