import {
  ApolloClient,
  ApolloProvider,
  from,
  NormalizedCacheObject,
  HttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import ReactNativeAsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/react-native';
import { AsyncStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import fetch from 'cross-fetch';
import { createClient } from 'graphql-ws';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AppState, Platform } from 'react-native';
import WebSocket from 'ws';

import {
  GetDraftMessageDocument,
  ListDraftDocumentsDocument,
  ListDraftMessagesDocument,
  typeDefs,
} from '@graphql/generated';
import { getApiConfig } from '@utils/getApiConfig';
import { AsyncStorage } from '@utils/storage';

import { initializeCache } from './initializeCache';

type ApolloClientContext = {
  client: ApolloClient<NormalizedCacheObject> | undefined;
  clearCache?: () => Promise<void>;
  clearChatCache?: () => Promise<void>;
  recentStatusCode?: number;
  wsConnected?: boolean;
  persistor?: CachePersistor<NormalizedCacheObject>;
};

const graphConfig = getApiConfig();

const apolloClientContext = createContext<ApolloClientContext | undefined>(
  undefined
);

export const setupApolloClient = async ({
  setPersistor,
  setWSConnected,
  setRecentStatusCode,
  ws = true,
}: {
  setPersistor?: (persistor: CachePersistor<NormalizedCacheObject>) => void;
  setWSConnected?: () => void;
  setRecentStatusCode?: (statusCode: unknown) => void;
  ws: boolean;
}) => {
  const cache = initializeCache();
  const storage = new AsyncStorageWrapper(ReactNativeAsyncStorage);

  const newPersistor = new CachePersistor({
    cache,
    storage,
    debug: process.env.NODE_ENV === 'dev' || __DEV__,
    trigger: 'write',
  });

  setPersistor?.(newPersistor);

  // await before instantiating ApolloClient, else queries might run before the cache is persisted
  await newPersistor.restore();

  // get the authentication token from local storage if it exists
  const getToken = async () => {
    try {
      const value = await AsyncStorage.getItem('authToken');

      if (value !== null) {
        return value;
      }
    } catch (e) {
      // error reading value
    }
  };

  const wsLink =
    ws &&
    new GraphQLWsLink(
      createClient({
        webSocketImpl: process.env.NODE_ENV === 'test' ? WebSocket : undefined,
        url: `${graphConfig.webSocketUrl}/graphql`,
        connectionParams: async () => {
          const token = await getToken();
          return { token };
        },
        shouldRetry: (errOrCloseEvent) => {
          if (
            errOrCloseEvent.code === undefined ||
            (errOrCloseEvent.code >= 1000 && errOrCloseEvent.code <= 1013)
          ) {
            // NOTE: these code values are derived from here
            // https://github.com/enisdenjo/graphql-ws/blob/2e6eb138b47bf30220c8048f1ba10f0782ded589/src/client.ts#L1008
            return true;
          }
          return false;
        },
        retryAttempts: Platform.OS === 'web' ? 50 : undefined,
        on: {
          connected: setWSConnected,
          // closed: setWSDisconnected,
          // error: setWSDisconnected,
        },
      })
    );

  const httpLink = new HttpLink({
    uri: `${graphConfig.apiUrl}/graphql`,
    fetch: process.env.NODE_ENV === 'test' ? fetch : undefined,
  });

  const authLink = setContext(async (_foo, { headers }) => {
    const token = await getToken();

    return {
      headers: {
        ...headers,
        authorization: `Bearer: ${token}`,
      },
    };
  });

  const splitLink = wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);

          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    Sentry.captureException(
      new Error('GraphQL error: ' + JSON.stringify(graphQLErrors))
    );
    graphQLErrors?.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      // EVICT cache object if error is resource not found
      const breakpoint = /\[(?<resource>.+)\s(?<id>\d+)\] not found/;
      const errorEntityMatch = message.match(breakpoint);

      if (errorEntityMatch?.groups?.id) {
        const id = errorEntityMatch?.groups?.id;
        const __typename = errorEntityMatch?.groups?.resource;

        const identifiedResource = cache.identify({
          __typename,
          id,
        });
        cache.evict({ id: identifiedResource });
        cache.gc();
      }
    });

    if (networkError?.statusCode === 401) {
      setRecentStatusCode?.(networkError?.statusCode);
    }
  });

  // Initialize Apollo Client
  const newClient = new ApolloClient({
    cache,
    link: errorLink.concat(from([authLink, splitLink])),
    defaultOptions: {
      watchQuery: { fetchPolicy: 'cache-and-network' },
      mutate: { errorPolicy: 'all' },
    },
    resolvers: {
      Query: {
        listDraftDocuments: (_root, args, { cache }) => {
          const drafts = cache.readQuery({
            query: ListDraftDocumentsDocument,
            variables: {
              chatId: args.chatId,
            },
          });
          return drafts;
        },
        listDraftMessages: (_root, _args, { cache }) => {
          const drafts = cache.readQuery({
            query: ListDraftMessagesDocument,
          });
          if (!drafts) {
            // Fixes intermitent crashes during app reload and possibly other states
            return undefined;
          }
          return drafts;
        },
        getDraftMessage: (_root, args, { cache }) => {
          const draft = cache.readQuery({
            query: GetDraftMessageDocument,
            variables: {
              chatId: args.chatId,
            },
          });
          return draft;
        },
      },
      Mutation: {
        updateDraftMessage: (_root, args, { cache }) => {
          const {
            body,
            tagsCollection,
            localFiles,
            chatId,
            replyMessage,
            checkin,
          } = args.attributes;

          const drafts = cache.writeQuery({
            query: GetDraftMessageDocument,
            data: {
              getDraftMessage: {
                __typename: 'DraftMessage',
                body: body || null,
                tagsCollection,
                localFiles,
                chatId,
                replyMessage: replyMessage || null,
                checkin: checkin || null,
              },
            },
            variables: { chatId: args.chatId },
          });
          return drafts;
        },
      },
    },
    typeDefs,
  });

  return newClient;
};

export const ApolloClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = apolloClientContext;
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >(undefined);
  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject>>();
  const [recentStatusCode, _setRecentStatusCode] = useState<
    number | undefined
  >();
  const [appState, setAppState] = useState(AppState.currentState);
  const [isWSConnected, setIsWSConnected] = useState(true);
  const _setWSConnected = useCallback(
    () => setIsWSConnected(true),
    [setIsWSConnected]
  );

  const clearChatCache = async () => {
    const cache = client?.cache;
    if (!cache) return;

    cache.modify({
      id: 'ROOT_QUERY',
      fields(fieldValue, details) {
        const invalidateRegex = /(chat|messages)/g;
        if (invalidateRegex.test(details.fieldName.toLowerCase())) {
          return details.DELETE;
        }
        return fieldValue;
      },
    });
    const ids = cache.gc();
    if (__DEV__ && process.env.NODE_ENV !== 'test') {
      console.log('-=-after cache-=-', cache.extract());
      console.log({ idsRemoved: ids });
    }
  };

  const clearCache = async () => {
    await client?.clearStore();
    await persistor?.purge();

    return void 0;
  };

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      setAppState(nextAppState);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    if (appState === 'active') {
      console.log('------about to restore');
      persistor?.restore();
    }
  }, [appState]);

  if (!client) {
    if (!loading) {
      setLoading(true);
      setupApolloClient({ setPersistor, ws: true }).then(setClient);
    }
    return null;
  }

  return (
    <Provider
      value={{
        client,
        clearCache,
        clearChatCache,
        recentStatusCode,
        wsConnected: isWSConnected,
        persistor,
      }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </Provider>
  );
};

export const useApolloClient = (): ApolloClientContext => {
  const context = useContext(apolloClientContext);
  if (context === undefined) {
    throw new Error('useApolloClient must be used within a Provider');
  }
  return context;
};
