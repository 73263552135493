import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

import { Message } from '@graphql/generated';

export const getMessageFromCache = (
  client: ApolloClient<NormalizedCacheObject>,
  cacheIdentifier: string | null | undefined
): Message | undefined | null => {
  if (!cacheIdentifier) return null;

  return client.cache.readFragment({
    id: cacheIdentifier,
    fragment: MessageDetailForQueryFragmentDoc1,
    fragmentName: 'MessageDetail',
  });
};

export const MessageDetailForQueryFragmentDoc1 = gql`
  fragment MessageDetail on Message {
    id
    chatId
    clientId
    body
    createdAt
    isSender
    isDraft
    publishedAt
    authorId
    cursor
    readAt
    isRetry
    readReceipts {
      ...UserDetail
    }
    checkin {
      ...checkinDetail
    }
    author {
      id
      name
      firstName
      lastName
      createdAt
      avatar {
        id
        cdnBaseUrl
        path
        isPreviewable
        thumbnail
      }
    }
    replyId
    replyMessage {
      id
      chatId
      clientId
      body
      createdAt
      isSender
      isDraft
      publishedAt
      authorId
      author {
        id
        name
        firstName
        lastName
        createdAt
      }
      attachments {
        ... on MessageTask {
          id
          authorId
          author {
            id
            name
            avatar {
              id
              cdnBaseUrl
              path
              isPreviewable
              thumbnail
            }
          }
          task {
            id
            projectId
            name
          }
        }
        ... on MessageProject {
          id
          authorId
          author {
            id
            name
            avatar {
              id
              cdnBaseUrl
              path
              isPreviewable
              thumbnail
            }
          }
          project {
            id
            name
          }
        }
        ... on Document {
          id
          name
          contentType
          createdAt
          size
          isImage
          clientId
          isAudio
          duration
          file {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
          owner {
            id
            name
            firstName
            lastName
            createdAt
          }
        }
        ... on MessageTaskAssignment {
          id
          messageId
          assignees {
            ...UserDetail
          }
          task {
            id
            name
            deletedAt
            completedAt
          }
        }
        ... on MessageTaskComplete {
          id
          messageId
          completor {
            id
            name
            avatar {
              id
              cdnBaseUrl
              path
              thumbnail
            }
          }
        }
      }
    }
    attachments {
      ... on MessageTask {
        id
        authorId
        author {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
        }
        task {
          id
          projectId
          deletedAt
          name
        }
      }
      ... on MessageProject {
        id
        authorId
        author {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            isPreviewable
            thumbnail
          }
        }
        project {
          id
          name
        }
      }
      ... on Document {
        id
        name
        contentType
        createdAt
        size
        isImage
        clientId
        isAudio
        duration
        file {
          id
          cdnBaseUrl
          path
          isPreviewable
          thumbnail
        }
        owner {
          id
          name
          firstName
          lastName
          createdAt
        }
      }
      ... on MessageTaskAssignment {
        id
        messageId
        assignees {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            thumbnail
          }
        }
        task {
          id
          name
          deletedAt
          completedAt
        }
      }
      ... on MessageTaskComplete {
        id
        messageId
        completor {
          id
          name
          avatar {
            id
            cdnBaseUrl
            path
            thumbnail
          }
        }
      }
    }
    reaction {
      ... on MessageReaction {
        id
        reaction
        count
        user {
          id
        }
      }
    }
  }
`;
