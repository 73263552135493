import {
  backgroundColor,
  BackgroundColorProps,
  border,
  BorderProps,
  createRestyleComponent,
  spacing,
  SpacingProps,
} from '@shopify/restyle';
import React from 'react';
import { ViewStyle } from 'react-native';

import documentIcons from '../../../../themes/documentIcons';
import Images from '../../../../themes/images';
import theme, { Theme } from '../../../../themes/theme';

/*
  Example pre-defined size:
    <Icon name="Activity1" variant="s" color="magentaDark" />

  Example custom size:
    <Icon name="Activity1" color="magentaDark" width={30} height={30} />
*/

// TODO: the empty `''` type is a stop gap until the unknow file type is added.
const FileType: Record<string, keyof typeof documentIcons | ''> = {
  // Types from node_modules/react-native-document-picker/lib/typescript/index.d.ts

  // all
  'text/rtf': 'txt',

  // iOS
  'public.audio': 'mp3',
  'public.comma-separated-values-text': 'xls',
  'com.microsoft.word.doc': 'doc',
  'org.openxmlformats.wordprocessingml.document': 'doc',
  'public.image': 'jpg',
  'com.adobe.pdf': 'pdf',
  'public.plain-text': 'txt',
  'com.microsoft.powerpoint.ppt': 'ppt',
  'org.openxmlformats.presentationml.presentation': 'ppt',
  'public.movie': 'mov',
  'com.microsoft.excel.xls': 'xls',
  'org.openxmlformats.spreadsheetml.sheet': 'xls',
  'public.zip-archive': 'zip',

  // android
  'audio/*': 'mp3',
  'text/csv': 'csv',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'doc',
  'image/*': 'jpg',
  'application/pdf': 'pdf',
  'text/plain': 'txt',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'ppt',
  'video/*': 'mp4',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
  'application/zip': 'zip',

  //web
  'application/x-zip-compressed': 'zip',
  'text/html': 'html',
  'text/css': 'css',
  'application/javascript': 'js',
  'application/json': 'js',
  'application/xml': 'xml',
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/bmp': 'bmp',
  'image/webp': 'webp',
  'image/tiff': 'tif',
  'image/svg+xml': 'svg',
  'audio/mpeg': 'mp3',
  'audio/wav': 'mp3',
  'video/mp4': 'mp4',
  'video/x-msvideo': 'avi',
  'video/quicktime': 'mov',
  'video/webm': 'webm',
  'video/x-flv': 'flv',
  'application/x-tar': 'zip',
  'application/gzip': 'zip',
  'application/vnd.rar': 'zip',
  'application/octet-stream': 'dat',
  'application/x-iso9660-image': 'iso',
  'application/postscript': 'ps',
  'application/x-httpd-php': 'php',
  'application/sql': 'sql',
  'application/x-apple-diskimage': 'dmg',
  'audio/aac': 'aac',
  'audio/midi': 'midi',
  'audio/flac': 'fla',

  // default
  '3ds': 'ds',
  ds: 'ds',
  aac: 'aac',
  ai: 'ai',
  avi: 'avi',
  bmp: 'bmp',
  cad: 'cad',
  cdr: 'cdr',
  css: 'css',
  csv: 'csv',
  dat: 'dat',
  dll: 'dll',
  dmg: 'dmg',
  doc: 'doc',
  docx: 'doc',
  eps: 'eps',
  file: 'file',
  fla: 'fla',
  flv: 'flv',
  folder: 'folder',
  gif: 'gif',
  gz: 'zip',
  html: 'html',
  indd: 'indd',
  iso: 'iso',
  jpg: 'jpg',
  js: 'js',
  midi: 'midi',
  mov: 'mov',
  mp3: 'mp3',
  mpg: 'mpg',
  pdf: 'pdf',
  php: 'php',
  png: 'png',
  ppt: 'ppt',
  pptx: 'ppt',
  ps: 'ps',
  psd: 'psd',
  raw: 'raw',
  sql: 'sql',
  svg: 'svg',
  tif: 'tif',
  txt: 'txt',
  wmv: 'wmv',
  xls: 'xls',
  xlsx: 'xls',
  xml: 'xml',
  zip: 'zip',
  mp4: 'mp4',
  jpeg: 'jpeg',
  quicktime: 'quicktime',
  webm: 'webm',
  webp: 'webp',
  unknown: 'unknown',
};

export type IconSizes = 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type RestyleProps = BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  SpacingProps<Theme> & {
    name?: keyof typeof Images | keyof typeof documentIcons;
    fileType?: keyof typeof documentIcons | keyof typeof FileType | string;
    variant?: IconSizes;
    padding?: keyof typeof theme.spacing;
    borderRadius?: keyof typeof theme.spacing;
    width?: number;
    height?: number;
    style?: ViewStyle;
    isCircle?: boolean;
    color?: keyof typeof theme.colors;
    testId?: string;
  };

const RestyleIcon = createRestyleComponent<
  RestyleProps & {
    children: React.ReactNode;
  },
  Theme
>([border, spacing, backgroundColor]);

const iconNameFromFileType = (fileType: string): keyof typeof documentIcons => {
  let iconName = 'doc';
  if (fileType in FileType) {
    iconName = FileType[fileType as keyof typeof FileType];
  } else if (fileType in documentIcons) {
    iconName = fileType;
  } else if (fileType.includes('/')) {
    const genericType1 = fileType.split('/')[1];
    const genericType2 = fileType.split('/')[0] + '/*';
    if (genericType1 in FileType) {
      iconName = FileType[genericType1 as keyof typeof FileType];
    } else if (genericType2 in FileType) {
      iconName = FileType[genericType2 as keyof typeof FileType];
    } else {
      iconName = iconNameFromFileType(
        fileType.slice(fileType.indexOf('/') + 1)
      );
    }
  }

  // If any of the above sets an invalid value, set it back to doc.
  if (!iconName || !(iconName in documentIcons)) {
    iconName = 'unknown';
  }

  return iconName as keyof typeof documentIcons;
};

const Icon = ({
  name = 'doc',
  variant = 'm',
  width,
  height,
  style,
  isCircle,
  fileType,
  padding,
  borderRadius,
  testId,
  ...rest
}: RestyleProps) => {
  let iconName = name;

  if (fileType) {
    iconName = iconNameFromFileType(fileType);
  }

  const allIcons = { ...Images, ...documentIcons };
  const Comp = allIcons[iconName];

  let size: number;

  switch (variant) {
    case 'xxxs':
      size = 10;
      break;

    case 'xxs':
      size = 12;
      break;

    case 'xs':
      size = 14;
      break;

    case 's':
      size = 16;
      break;

    case 'm':
      size = 20;
      break;

    case 'l':
      size = 24;
      break;

    case 'xl':
      size = 28;
      break;

    case 'xxl':
      size = 32;
      break;
  }
  return (
    <RestyleIcon
      {...rest}
      padding={padding || (rest.backgroundColor && 'xs')}
      borderRadius={borderRadius || (rest.backgroundColor && 'xs')}
      style={isCircle ? { borderRadius: 24 } : {}}
      name={name}
      testId={testId}>
      <Comp
        color={
          theme.colors[
            (rest.color as keyof (typeof theme)['colors']) || 'black'
          ]
        }
        width={width || size}
        height={height || size}
        style={style}
      />
    </RestyleIcon>
  );
};

export default Icon;
