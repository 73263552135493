import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

import {
  DocumentEdge,
  useDocumentsByClientIdsQuery,
  Document,
} from '@graphql/generated';

export const useGetDocumentsByClientIdsQuery = () => {
  const [clientIds, setClientIds] = useState<string[]>([]);

  const { refetch: urlRefetch } = useDocumentsByClientIdsQuery({
    variables: { clientIds },
    skip: clientIds.length == 0,
    onCompleted: (rtndata) => {
      rtndata.documentsByClientIds?.forEach(async (newItem) => {
        await AsyncStorage.setItem(newItem.clientId, newItem.file.url);
      });
    },
    onError: (e) => {
      console.log('rtndata', e);
    },
  });

  useEffect(() => {
    if (clientIds.length > 0) {
      urlRefetch();
    }
  }, [clientIds, urlRefetch]);

  const checkDocumentURL = async (edges: DocumentEdge[] | Document[]) => {
    if ((edges.length ?? 0) > 0) {
      let docs: Document[];
      if (edges[0].__typename === 'DocumentEdge') {
        docs = (edges as DocumentEdge[]).map((ed: DocumentEdge) => ed.node);
      } else {
        docs = edges as Document[];
      }
      await checkDocumentURLStep2(docs);
    }
  };

  const checkDocumentURLStep2 = async (docs: Document[]) => {
    if ((docs.length ?? 0) > 0) {
      const clientIdsNeedsToRefetchUrl: string[] = [];
      const now = new Date();
      for (const item of docs!) {
        if (
          item &&
          item.__typename === 'Document' &&
          !item.isImage &&
          item.clientId
        ) {
          const url = await AsyncStorage.getItem(item.clientId);
          if (!url) {
            clientIdsNeedsToRefetchUrl.push(item.clientId);
          } else {
            const urlParams = new URLSearchParams(url!.split('?')[1]);

            const amzDate = urlParams.get('X-Amz-Date');
            const amzExpires = urlParams.get('X-Amz-Expires');

            if (amzDate && amzExpires) {
              const requestTime = new Date(
                `${amzDate.substring(0, 4)}-${amzDate.substring(
                  4,
                  6
                )}-${amzDate.substring(6, 8)}T${amzDate.substring(
                  9,
                  11
                )}:${amzDate.substring(11, 13)}:${amzDate.substring(13, 15)}Z`
              );

              // here - 10 * 60 * 60 is for 10 hours active, will refetch
              const expirationDate = new Date(
                requestTime.getTime() +
                  (parseInt(amzExpires, 10) - 10 * 60 * 60) * 1000
              );
              if (expirationDate < now) {
                await AsyncStorage.removeItem(item.clientId);
                clientIdsNeedsToRefetchUrl.push(item.clientId);
              }
            }
          }
        }
      }
      if (clientIdsNeedsToRefetchUrl.length > 0) {
        setClientIds(clientIdsNeedsToRefetchUrl);
      }
    }
  };

  return {
    checkDocumentURL,
  };
};
