import { useNavigation, useRoute } from '@react-navigation/native';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Platform,
  Pressable,
  TouchableOpacity,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { Alert } from '@components/Alert/index.web';
import Avatar from '@components/Avatar/Avatar';
import CachedImage from '@components/CachedImage/CachedImage';
import { HeaderGoBack } from '@components/HeaderGoBack/HeaderGoBack';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { removeProjectLimitSnackBarNextTime } from '@components/Projects/ProjectForm.web';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  GetTeamDocument,
  ListTeamsDocument,
  LocalFile,
  MemberAttributes,
  Price,
  Product,
  TeamRole,
  useCreateStripeSubscriptionMutation,
  useListProductsQuery,
  useListTeamsQuery,
  useRemoveTeamMemberMutation,
} from '@graphql/generated';
import useFileProcessor from '@hooks/useFileProcessor';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { FormValues } from '@navigation/payments/create-subscription-stack';
import { BillingCycle, PlanName } from '@root/types';
import { MOBILE_PLAN_NAME, WEB_PLAN_NAME } from '@src/constants/constants';
import theme from '@themes/theme';

export const CreateSubscription: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { me } = useMe();
  const { data } = useListTeamsQuery({ fetchPolicy: 'cache-only' });
  const { listTeams } = data || {};
  const navigator = useNavigation();
  const { t } = useTranslation();
  const { data: listProductsData } = useListProductsQuery();

  const { setUpgradeTeamPlanOpen } = useWebDrawer();

  const route = useRoute();

  const { teamId } = route?.params || {};

  const { team: teamDetail } = useTeamFromQuery({
    teamId,
  });

  const hasSavedPaymentMethod =
    teamDetail?.plan?.payment && teamDetail?.plan?.payment !== null;

  const leftdays = (-dayjs().diff(
    teamDetail?.plan?.expiration,
    'day'
  )).toString();

  const trialingDaysLeft =
    (teamDetail?.plan?.status ?? '') === 'trialing' && !leftdays.startsWith('-')
      ? leftdays.toString()
      : '30';

  const [createStripeSubscription] = useCreateStripeSubscriptionMutation({
    onCompleted: () => {
      setLoading(false);
      removeProjectLimitSnackBarNextTime(me?.id, '0');
      removeProjectLimitSnackBarNextTime(me?.id, teamId);
    },
    refetchQueries: [{ query: ListTeamsDocument }],
    onError: () => setLoading(false),
  });

  const { listProducts } = listProductsData || {};
  const stripe = useStripe();
  const elements = useElements();

  const [showError, setShowError] = useState(false);
  const [payment, setPayment] = useState(elements?.getElement('payment'));

  const personalTeam = listTeams?.find((t) => t?.personal);

  type PlanWithPrices = {
    [id: Product['id']]: {
      perMonth: Price['amount'];
      perYear: Price['amount'];
    };
  };
  const stripeOptions: StripePaymentElementOptions = {};

  const [removeTeamMember] = useRemoveTeamMemberMutation();

  // convert Stripe products in to an easier to use format
  const plans: PlanWithPrices =
    listProducts?.reduce((acc, product) => {
      const prices = {
        perMonth:
          product?.prices?.find((price) => price?.interval === 'month')
            ?.amount || 0,
        perYear:
          product?.prices?.find((price) => price?.interval === 'year')
            ?.amount || 0,
      };
      return { ...acc, [product['id']]: prices };
    }, {}) || {};

  const { values, isValid, setFieldValue, setFieldTouched } =
    useFormikContext<FormValues>();
  const { users, plan, priceId, billingCycle, name, logo } = values;

  const { team } = useTeamFromQuery({ teamId: teamId as unknown as string });
  const isPersonal = team?.personal ?? false;

  const isSavedPaymentMethodExpired = team?.plan?.payment?.expMonth
    ? dayjs(
        team?.plan?.payment?.expMonth + '/01/' + team?.plan?.payment?.expYear
      )
        .add(1, 'month')
        .isSameOrBefore(dayjs())
    : false;

  const expiration = `${
    isSavedPaymentMethodExpired ? 'Expired!' : 'Expiration:'
  } ${
    ((team?.plan?.payment?.expMonth?.length ?? 0) === 1 ? '0' : '') +
    team?.plan?.payment?.expMonth
  }/${team?.plan?.payment?.expYear}`;

  const [useNewPaymentMethod, setUseNewPaymentMethod] = useState(
    teamId === undefined ||
      teamId === '' ||
      !hasSavedPaymentMethod ||
      isSavedPaymentMethodExpired
  );

  const filteredProducts = listProducts?.filter((plan) =>
    plan?.name
      .toLowerCase()
      .includes(Platform.OS === 'web' ? WEB_PLAN_NAME : MOBILE_PLAN_NAME)
  );

  const [displayPlan, setDisplayPlan] = React.useState(
    !team?.plan && (filteredProducts?.length ?? 0) > 0
      ? filteredProducts![0].id
      : plan
  );

  const usersAndMe = () => {
    return users;
  };

  const sortedUsers = [...(usersAndMe() || [])]?.sort((u) =>
    u?.role !== TeamRole?.Owner ? 1 : -1
  );

  const setPlan = (plan: PlanName) => {
    const product = listProducts?.find((p) => p?.id === plan);
    setFieldValue('plan', plan);
    product && setFieldValue('planName', product?.name);

    const price = product?.prices?.find(
      (price) => price?.interval === billingCycle
    );
    price && setFieldValue('priceId', price?.externalId);
    price && setFieldValue('price', subtotalCost());

    if (!personalTeam) return;

    if (!product?.allowMembers) {
      setFieldValue('teamId', personalTeam?.id);
    } else {
      setFieldValue('teamId', null);
    }
  };

  const setBillingCycle = (billingCycle: BillingCycle) => {
    setFieldValue('billingCycle', billingCycle);
  };

  const selectedProduct = listProducts?.find((p) => p?.id === displayPlan);

  const subtotalCost = () => {
    if (!listProducts?.length || !displayPlan) return 0;
    const multiplier = !selectedProduct?.allowMembers
      ? 1
      : Math.max(users.length, 1);

    const planCost =
      billingCycle === 'year'
        ? plans?.[displayPlan]?.perYear
        : plans?.[displayPlan]?.perMonth;
    return Math.ceil(planCost * multiplier);
  };
  const subtotalValue = subtotalCost();

  const subtotalLabel = `$${(subtotalValue / 100)?.toFixed(2)}/${
    billingCycle === 'year' ? 'yr' : 'mo'
  }`;

  const originalCost = () => {
    if (!listProducts?.length || !displayPlan) return 0;
    let multiplier = Math.max(users.length, 1);
    if (teamId && teamDetail && team?.plan?.status === 'active')
      multiplier = users.length - teamDetail.users.length;
    const selectedPlan = plans?.[displayPlan];
    return billingCycle === 'year'
      ? selectedPlan?.perMonth * 12 * multiplier
      : 1;
  };

  const teamIcon = () => {
    if (isPersonal) {
      return (
        <Box
          width={24}
          height={24}
          borderRadius='xxs'
          backgroundColor='greenSecondary'
          justifyContent='center'
          alignItems='center'>
          <Icon variant='s' name='Office' color='white' />
        </Box>
      );
    } else {
      return <Icon name='TT24' variant='l' color='textPrimary' />;
    }
  };

  const originalCostLabel = `$${(originalCost() / 100)?.toFixed(2)}`;

  const calculateAnnualSavingPercentage = () => {
    if (!displayPlan) return undefined;
    const selectedPlan = plans?.[displayPlan];
    if (!selectedPlan) {
      return undefined;
    }

    const monthPerYear = selectedPlan?.perMonth * 12;
    const percentage =
      ((monthPerYear - selectedPlan?.perYear) / monthPerYear) * 100;
    return Math.trunc(percentage);
  };

  const percentageSaved =
    billingCycle === 'year' ? calculateAnnualSavingPercentage() : undefined;

  const selectedTeamName = undefined;
  const currentTeam = undefined;

  const PlanItem = (product: Product) => {
    return (
      <Box flexDirection='row' alignItems='center'>
        <Box mr='xs'>
          <RadioButton
            isSelected={displayPlan === product.id}
            onPress={() => {
              setPlan(product.id);
              setDisplayPlan(product.id);
            }}
          />
        </Box>
        <Box mr='xs'>
          <Icon
            name={
              product?.name?.toLowerCase()?.includes(MOBILE_PLAN_NAME)
                ? 'Zap'
                : 'Star'
            } // TODO get this from metadata?
            backgroundColor={
              product?.name?.toLowerCase()?.includes(MOBILE_PLAN_NAME)
                ? 'orange'
                : 'purple'
            } // TODO get this from metadata?
            color='background'
          />
        </Box>
        <Text variant='labelEmphasized' mr='xs'>
          {product.name}
        </Text>
        <Box>
          <Icon name='HelpCircle' color='grey04' />
        </Box>
      </Box>
    );
  };

  const PlanItemWeb = (product: Product, isMonthly: boolean) => {
    const monthPrice = product.prices.filter((t) => t.interval === 'month');
    const yearlyPrice = product.prices.filter((t) => t.interval === 'year');

    const discountedPrice = yearlyPrice && yearlyPrice[0].amount / 12;

    const price = monthPrice.length > 0 ? monthPrice[0].amount : 0;

    return (
      <Box
        key={(isMonthly ? '1' : '2') + product.id}
        flex={1}
        borderColor='grey02'
        borderWidth={1}
        borderRadius='xs'
        flexDirection='row'
        accessibilityLabel={product.name}
        p='m'
        marginTop='xs'
        alignItems='center'>
        <RadioButton
          isSelected={
            displayPlan === product.id &&
            billingCycle === (isMonthly ? 'month' : 'year')
          }
          // Users can switch to an annual plan at any time, but once switched,
          // they cannot downgrade back to a monthly plan
          disabled={isMonthly && team?.plan?.interval === 'year'}
          onPress={() => {
            if (
              displayPlan === product.id &&
              billingCycle === (isMonthly ? 'month' : 'year')
            ) {
              setDisplayPlan(null);
              setPlan(null);
            } else {
              setDisplayPlan(product.id);
              setPlan(product.id);

              setBillingCycle(isMonthly ? 'month' : 'year');
            }
          }}
        />
        <Icon
          marginHorizontal='s'
          name={
            product?.name?.toLowerCase()?.includes(MOBILE_PLAN_NAME)
              ? 'Zap'
              : 'Star'
          } // TODO get this from metadata?
          backgroundColor={
            product?.name?.toLowerCase()?.includes(MOBILE_PLAN_NAME)
              ? 'orange'
              : 'purple'
          } // TODO get this from metadata?
          color='background'
        />
        <Box flex={1}>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='labelEmphasized' mb='xxxs' color='textPrimary'>
              {product.name + ' Plan'}
            </Text>
            <Box style={{ marginHorizontal: 6 }}>
              <Icon name='HelpCircle' color='grey04' />
            </Box>

            {!isMonthly && (
              <Box
                style={{
                  backgroundColor: '#DCF2EC',
                  borderRadius: 4,
                  padding: 4,
                }}>
                <Text variant='metadata' color='greenSecondary'>
                  {percentageSavedWeb(product)}% off
                </Text>
              </Box>
            )}
          </Box>
          <Text variant='webBodySecondary' color='grey04'>
            {'Billed ' + (isMonthly ? 'Monthly' : 'Annually')}
          </Text>
        </Box>
        <Box
          alignContent='flex-end'
          justifyContent='flex-end'
          alignItems='flex-end'>
          <Box flexDirection='row' marginRight='xxxs'>
            <Text
              variant={
                isMonthly ? 'labelEmphasized' : 'labelDisabledStrikeThrough'
              }
              mt='xxs'
              marginRight='xxs'>
              ${(price / 100)?.toFixed(0)}
            </Text>
            {!isMonthly && (
              <Text variant='labelEmphasized' mb='xxxs' color='textPrimary'>
                ${(discountedPrice / 100).toFixed(0)}
              </Text>
            )}
          </Box>
          <Text variant='webBodySecondary' color='grey04'>
            per user per month
          </Text>
        </Box>
      </Box>
    );
  };

  const teamPlan = listProducts?.find((p) => p?.allowMembers);
  const teamPlanSelected = teamPlan?.id;

  const switchToTeamPlan = () => {
    if (!teamPlan) return;
    setDisplayPlan(teamPlan?.id);
    setPlan(teamPlan?.id);
  };

  const { processFiles } = useFileProcessor();

  const submitForm = async () => {
    if (!elements || !stripe || !priceId || !me) return;

    if (useNewPaymentMethod) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.log(submitError);
        return;
      }
    }

    setLoading(true);

    const members: MemberAttributes[] = users.map((u) => ({
      userId: u.id,
      role: u.role,
    }));

    // new subscription
    let avatarBlobId = undefined;
    if (values.logo) {
      const logoDocument: Document = {
        ...values.logo,
        owner: me!,
        createdAt: new Date().toISOString,
        expired: false,
        file: { ...values.logo, __typename: 'File' },
        __typename: 'Document',
      };

      await processFiles([logoDocument])
        .then(async (response) => {
          const { blobId } = response[0];
          avatarBlobId = blobId;
        })
        .catch((err) => {
          alert(err);
        });
    }

    const { data: subscriptionData } = await createStripeSubscription({
      variables: {
        teamAttributes:
          teamId !== '' && teamId !== null && teamDetail
            ? undefined
            : {
                name: name || me.firstName || 'name - missing',
                longitude: values.location?.longitude,
                latitude: values.location?.latitude,
                address: values.location?.address,
                skillIds: values.skillIds || [],
                members,
                avatarBlobId,
              },
        stripeSubscriptionAttributes: {
          name: plan || '',
          teamId: teamId ?? '',
          quantity: members.length,
          priceId: priceId,
        },
      },
    });
    const { createStripeSubscription: subscriptionResponse } =
      subscriptionData || {};

    if (!subscriptionResponse) {
      showSubscripeFail();
      return;
    }
    const { team, clientSecret, intentType } = subscriptionResponse;
    const { id: createdTeamId } = team;

    const confirmAction =
      intentType === 'payment' ? stripe.confirmPayment : stripe.confirmSetup;
    const { error } = await confirmAction({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.STRIPE_REDIRECT_BASE_URL}/my-account/complete/${createdTeamId}/${plan}`,
      },
    });

    if (error) {
      showSubscripeFail();
    }
  };
  const showSubscripeFail = () => {
    if (Platform.OS === 'web') setShowError(true);
    else {
      Alert.alert(
        t('models:teams.subscriptions.failureAlert.title'),
        t('models:teams.subscriptions.failureAlert.message')
      );
    }
  };

  const maskEmail = (email: string) => {
    if (!email.includes('@')) return '';
    const [localPart, domain] = email.split('@');

    if (domain.length < 10) {
      return `${localPart[0]}***@${domain}`;
    } else {
      return `${localPart[0]}***@${domain.substring(
        0,
        3
      )} ... ${domain.substring(domain.length - 5)}`;
    }
  };

  useEffect(() => {
    const product = listProducts?.find((p) => p.id === displayPlan);
    const price = product?.prices.find(
      (price) => price.interval === billingCycle
    );
    price && setFieldValue('priceId', price.externalId);
    price && setFieldValue('price', subtotalCost());
  }, [billingCycle, subtotalValue]);

  useEffect(() => {
    if (!payment) return;

    payment.on('change', (e) => {
      setFieldValue('paymentComplete', e.complete);
    });

    return () => {
      // Stripe documentation indicated this should be done, even though TS is yelling about it
      (payment as any)?.removeAllListeners();
    };
  }, [payment]);

  useEffect(() => {
    if (!useNewPaymentMethod) {
      setFieldValue('paymentComplete', false);
    }
  }, [useNewPaymentMethod]);

  useEffect(() => {
    if (Boolean(team?.id) && !isPersonal) {
      setFieldValue('name', team?.name);
      setFieldValue('teamId', team?.id);
    }
  }, [plan]);

  useEffect(() => {
    if (!filteredProducts) return;

    const product = filteredProducts[0];
    product && setDisplayPlan(product.id);
    product && setFieldValue('plan', product.id);
    product && setFieldValue('planName', product.name);
  }, [filteredProducts?.length]);

  const checkAndRemoveTeamMember = (userId: string) => {
    if (teamDetail?.users.some((t) => t.id === userId)) {
      removeTeamMember({
        variables: {
          id: teamId,
          userId: userId,
        },
        refetchQueries: [{ query: GetTeamDocument, variables: { id: teamId } }],
      });
    }
  };
  const percentageSavedWeb = (product: Product) => {
    const selectedPlan = plans?.[product.id];
    if (!selectedPlan) {
      return undefined;
    }

    const monthPerYear = selectedPlan?.perMonth * 12;
    const annualPrice = selectedPlan?.perYear;
    const percentage = ((monthPerYear - annualPrice) / monthPerYear) * 100;
    return Math.trunc(percentage);
  };

  let upToSave = 20;
  filteredProducts?.forEach((p) => {
    const saved = percentageSavedWeb(p) ?? 0;
    if (upToSave <= saved) upToSave = saved;
  });

  const changed = () => {
    if (teamDetail) {
      if (useNewPaymentMethod && values.paymentComplete) return true;
      if (!teamDetail.plan && plan) return true;
      if (teamDetail.plan && teamDetail.plan.interval !== plan) return true;
      if (teamDetail.users.length != users.length) return true;
      const oldUsersIds = teamDetail.users.map((u) => u.id);

      for (const a of users) {
        if (!oldUsersIds.includes(a.id)) return true;
      }
    }
    return false;
  };

  if (Platform.OS === 'web') {
    return (
      <>
        {showError && (
          <ConfirmModal
            isAlert={true}
            showModal={showError}
            onClose={() => setShowError(false)}
            onPress={() => setShowError(false)}
            buttonText='     OK     '
            title={t('models:teams.subscriptions.failureAlert.title')}
            message={t('models:teams.subscriptions.failureAlert.message')}
          />
        )}
        <ScrollView
          style={{ backgroundColor: 'white' }}
          showsVerticalScrollIndicator={false}>
          <Box backgroundColor='white' maxWidth={560}>
            <Box flexDirection='row' marginBottom='s'>
              <Box flex={1} flexDirection='row'>
                <Text variant='heading2' color='textPrimary' textAlign='left'>
                  {team?.plan && !team.plan.isCanceled
                    ? t('models:profile.subscriptionPlans.editSubscription')
                    : t('models:profile.subscriptionPlans.newSubscription')}
                </Text>
              </Box>
              <Box alignItems='flex-end'>
                <Box flexDirection='row' accessibilityLabel='Close'>
                  <Icon
                    name='X'
                    onPress={() => {
                      setUpgradeTeamPlanOpen(false);
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box mb='l'>
              <Text variant='labelLarge' mb='xs' color='textPrimary'>
                Choose Plan
              </Text>
              <Text variant='webBodySecondary' mb='m' color='greenSecondary'>
                Save up to {upToSave}%{' '}
                <Text variant='webBodySecondary' color='textSecondary'>
                  when you subscribe Annually.
                </Text>
              </Text>
              <Box flex={1}>
                {filteredProducts?.map((product) => {
                  return PlanItemWeb(product, false);
                })}
                {filteredProducts?.map((product) => {
                  return PlanItemWeb(product, true);
                })}
              </Box>
            </Box>

            <Box my='m'>
              <Text mb='m' variant='labelLarge' color='textPrimary'>
                {team?.id ? 'Team' : 'Select Team'}
              </Text>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                borderColor='grey03'
                backgroundColor='grey01'
                borderWidth={1}
                borderRadius='xs'
                height={48}
                mb='m'>
                {team?.avatarFile && (
                  <CachedImage
                    image={team.avatarFile as unknown as LocalFile}
                    width={32}
                    height={32}
                    borderRadius={theme.spacing.xs}
                  />
                )}
                {!team?.avatarFile && name && logo && (
                  <CachedImage
                    image={logo as unknown as LocalFile}
                    height={32}
                    width={32}
                    borderRadius={theme.spacing.xs}
                  />
                )}
                {!team?.avatarFile && !logo && teamIcon()}
                <Text
                  mx='xs'
                  variant='labelSmall'
                  color='textSecondary'
                  numberOfLines={1}>
                  {team?.name || name}
                </Text>
              </Box>
            </Box>

            {!isPersonal && sortedUsers && sortedUsers.length > 0 && (
              <Box
                flexDirection='column'
                alignItems='flex-start'
                justifyContent='center'
                marginBottom='m'>
                <Text variant='labelSmall' marginBottom='xs'>
                  {t('models:teams.subscriptions.paidMembers', {
                    count: sortedUsers.length,
                  })}
                </Text>
                <Box
                  flex={1}
                  flexDirection='row'
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  flexWrap='wrap'>
                  <Box justifyContent='center' alignItems='center'>
                    <TouchableOpacity
                      onPress={() => navigator.navigate('add-team-members')}
                      accessibilityLabel='Add More'>
                      <Box
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        width={56}
                        marginRight='m'>
                        <Box
                          alignItems='center'
                          justifyContent='center'
                          borderRadius='xxl'
                          bg='black'
                          width={56}
                          height={56}>
                          <Icon
                            width={40}
                            height={40}
                            name='Plus'
                            color='white'
                            onPress={() =>
                              navigator.navigate('add-team-members')
                            }
                          />
                        </Box>
                        <Text
                          variant='labelSmall'
                          marginTop='xs'
                          textAlign='center'
                          numberOfLines={1}>
                          {t('models:projects.create.members.addMore')}
                        </Text>
                      </Box>
                    </TouchableOpacity>
                  </Box>

                  {sortedUsers?.map((user) => {
                    if (!user) return;
                    const {
                      id: memberId,
                      firstName,
                      name,
                      avatar,
                      role,
                    } = user;

                    return (
                      <Box key={memberId} marginRight='m' alignItems='center'>
                        <Avatar
                          onDismiss={
                            memberId !== team?.owner?.id && memberId !== me?.id
                              ? () => {
                                  const newUsers = values?.users?.filter(
                                    (u) => u?.id !== memberId
                                  );
                                  setFieldTouched('users');
                                  setFieldValue('users', [...newUsers]);
                                  checkAndRemoveTeamMember(user.id);
                                }
                              : undefined
                          }
                          disableAvatarPress={true}
                          size='large'
                          label={name}
                          avatar={avatar}
                        />
                        <Text
                          variant='labelSmall'
                          marginTop='xs'
                          textAlign='center'
                          style={{ width: 56 }}
                          numberOfLines={1}>
                          {firstName}
                        </Text>
                        <Text
                          variant='metadataSecondary'
                          textAlign='center'
                          style={{ width: 56 }}
                          color='grey04'
                          numberOfLines={1}>
                          {role}
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}

            {/* eslint-disable-next-line no-magic-numbers */}
            {!isPersonal && !!sortedUsers && sortedUsers.length < 1 && (
              <Box mb='m'>
                <Text marginBottom='xs' variant='labelSmall'>
                  {t('models:teams.subscriptions.members')}
                </Text>
                <Button
                  variant='edit'
                  fullWidth
                  marginBottom='s'
                  onPress={() => navigator.navigate('add-team-members')}
                  accessibilityLabel='Add Members'>
                  {t('models:teams.subscriptions.addMembers')}
                </Button>
              </Box>
            )}

            <Box mt='l' mb='m'>
              <Text mb='xs' variant='labelLarge' color='textPrimary'>
                {t('models:teams.subscriptions.paymentMethod')}
              </Text>
              {(teamDetail?.plan?.status ?? '') === 'trialing' && (
                <Text mb='m' variant='webBodySecondary' color='textSecondary'>
                  {t('models:teams.subscriptions.subscriptionTrialMessage', {
                    leftDays:
                      trialingDaysLeft +
                      (trialingDaysLeft !== '1' ? ' days' : ' day'),
                  })}
                </Text>
              )}
            </Box>

            {/* Payment Form */}
            <Box>
              {hasSavedPaymentMethod && (
                <Box>
                  <Box flexDirection='row' mb='m' alignItems='center'>
                    <RadioButton
                      isSelected={
                        !useNewPaymentMethod && !isSavedPaymentMethodExpired
                      }
                      disabled={isSavedPaymentMethodExpired}
                      onPress={() => {
                        setUseNewPaymentMethod(false);
                      }}
                    />
                    <Text variant='labelSmall' ml='xs'>
                      Saved
                    </Text>
                  </Box>
                  {/*Link payment method*/}
                  {teamDetail.plan &&
                    teamDetail.plan.payment?.brand.includes('@') && (
                      <Box
                        flexDirection='row'
                        justifyContent='center'
                        alignItems='center'
                        mb='m'
                        width={400}>
                        <Icon
                          name='LinkPayment'
                          variant='l'
                          color='white'
                          marginLeft='m'
                        />
                        <Text
                          variant='labelSmall'
                          color='textSecondary'
                          ml='xxs'>
                          {`Link  ${maskEmail(
                            teamDetail.plan.payment?.brand ?? ''
                          )}`}
                        </Text>
                        <Box flex={1} />
                      </Box>
                    )}
                  {/*stripe payment method*/}
                  {teamDetail.plan?.payment?.last4 && (
                    <Box
                      flexDirection='row'
                      justifyContent='center'
                      alignItems='center'
                      mb='m'
                      width={400}>
                      <Icon
                        name='Visa'
                        variant='xxl'
                        color='white'
                        marginLeft='m'
                      />
                      <Text variant='labelSmall' color='textSecondary' ml='m'>
                        **** **** **** {teamDetail.plan.payment?.last4}
                      </Text>
                      <Text
                        variant='webBodySecondary'
                        color={
                          isSavedPaymentMethodExpired
                            ? 'alertRed'
                            : 'textSecondary'
                        }
                        ml='xxl'>
                        {expiration}
                      </Text>
                    </Box>
                  )}

                  <Box flexDirection='row' mb='m' alignItems='center'>
                    <RadioButton
                      disabled={isSavedPaymentMethodExpired}
                      isSelected={useNewPaymentMethod}
                      onPress={() => {
                        setUseNewPaymentMethod(true);
                      }}
                    />
                    <Text variant='labelSmall' ml='xs'>
                      Change payment method
                    </Text>
                  </Box>
                </Box>
              )}
              {useNewPaymentMethod && (
                <Box mb='l'>
                  <PaymentElement
                    options={stripeOptions}
                    onReady={() => {
                      const xPayment = elements?.getElement('payment');
                      if (xPayment) setPayment(xPayment);
                    }}
                  />
                </Box>
              )}

              {listProducts?.length && (
                <Box
                  mb='l'
                  flexDirection='row'
                  flex={1}
                  style={{ paddingVertical: 32 }}
                  px='l'
                  borderColor='grey02'
                  borderWidth={1}
                  backgroundColor='grey01'
                  accessibilityLabel={t('models:teams.subscriptions.subtotal')}
                  alignItems='center'>
                  <Text variant='labelLarge' mb='xs' color='textPrimary'>
                    {t('models:teams.subscriptions.subtotal')}
                  </Text>

                  <Box flexDirection='row' flex={1} justifyContent='flex-end'>
                    {billingCycle === 'year' && (
                      <Text
                        color='grey04'
                        textDecorationStyle='solid'
                        textDecorationLine='line-through'
                        mr='xxs'>
                        {originalCostLabel}
                      </Text>
                    )}
                    <Text variant='labelLarge' color='textPrimary'>
                      {subtotalLabel}
                    </Text>
                  </Box>
                </Box>
              )}
              {(!team?.plan || team?.plan.isCanceled) && (
                <Button
                  variant='primary'
                  disabled={
                    !values.paymentComplete || loading || displayPlan == null
                  }
                  onPress={() => {
                    if (plan === null && displayPlan != null)
                      setPlan(displayPlan);
                    submitForm();
                  }}>
                  {loading ? (
                    <ActivityIndicator />
                  ) : (
                    <Text color='background'>
                      {t('models:payments.subscribeNow')}
                    </Text>
                  )}
                </Button>
              )}
              {team?.plan && !team?.plan.isCanceled && (
                <Box flexDirection='row' flex={1}>
                  <Box flex={1}>
                    <Button
                      fullWidth
                      variant='cancel'
                      onPress={() => {
                        setUpgradeTeamPlanOpen(false);
                      }}>
                      <Text color='black'>{t('shared:cancel')}</Text>
                    </Button>
                  </Box>

                  <Box flex={1}>
                    <Button
                      fullWidth
                      marginLeft='xs'
                      variant='primary'
                      disabled={!changed() || loading || displayPlan == null}
                      onPress={() => {
                        if (plan === null && displayPlan != null)
                          setPlan(displayPlan);
                        submitForm();
                      }}>
                      {loading ? (
                        <ActivityIndicator />
                      ) : (
                        <Text color='background'>{t('shared:update')}</Text>
                      )}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </ScrollView>
      </>
    );
  } else {
    return (
      <ScrollView>
        <Box maxWidth={560} padding='l' marginTop='xxl'>
          <HeaderGoBack
            redirectTo='pick-plans'
            titleHeader='Upgrade'
            params={{ isPersonal, teamId: team?.id }}
            noSpacing
          />
          <Box mb='l'>
            <Text variant='labelSmall' mb='xs'>
              Plan Selected
            </Text>
            <Box flexDirection='row' justifyContent='space-between'>
              {filteredProducts?.map((product) => {
                return PlanItem(product);
              })}
            </Box>
          </Box>

          {teamPlanSelected && (
            <Box mb='l'>
              <Text marginBottom='xs' variant='labelSmall'>
                Team Selected
              </Text>
              <Button
                variant='edit'
                marginBottom='s'
                fullWidth
                onPress={() => navigator.navigate('choose-team')}
                accessibilityLabel='Assign Team'
                disabled={false}>
                {selectedTeamName || currentTeam?.name ? (
                  <Box
                    flexDirection='row'
                    alignItems='flex-end'
                    justifyContent='center'>
                    <Icon name='Logo' height={16} marginRight='xs' />
                    <Text variant='labelSmall' color='textSecondary'>
                      {selectedTeamName || currentTeam?.name}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    flexDirection='row'
                    alignItems='flex-end'
                    justifyContent='center'>
                    {name && logo && (
                      <Box mr='xs'>
                        <CachedImage
                          image={logo as unknown as LocalFile}
                          height={16}
                          width={16}
                        />
                      </Box>
                    )}
                    <Text variant='labelSmall' color='textSecondary'>
                      {team?.id && !name && !isPersonal
                        ? team?.name
                        : name || t('models:teams.subscriptions.noTeam')}
                    </Text>
                  </Box>
                )}
              </Button>
              <Text marginBottom='xs' variant='labelSmall'>
                {t('models:teams.subscriptions.noTeamMessage')}
              </Text>
            </Box>
          )}
          <Box mb='l'>
            <Text variant='labelSmall' mb='xs'>
              {t('models:teams.subscriptions.billingCycle')}
            </Text>
            <Box flexDirection='row' justifyContent='space-between'>
              <Box flexDirection='row' alignItems='center'>
                <Box mr='xs'>
                  <RadioButton
                    isSelected={billingCycle === 'year'}
                    onPress={() => setBillingCycle('year')}
                  />
                </Box>
                <Box>
                  <Text variant='labelEmphasized'>
                    {t('models:payments.billingCycleLabel', {
                      context: 'year',
                    })}
                  </Text>
                </Box>
              </Box>
              <Box flexDirection='row' alignItems='center'>
                <Box mr='xs'>
                  <RadioButton
                    isSelected={billingCycle === 'month'}
                    onPress={() => setBillingCycle('month')}
                  />
                </Box>
                <Text variant='labelEmphasized'>
                  {t('models:payments.billingCycleLabel', { context: 'month' })}
                </Text>
              </Box>
            </Box>
            {percentageSaved && (
              <Box flexDirection='row' justifyContent='space-between'>
                <Text
                  variant='metadata'
                  color='greenSecondary'
                  style={{ marginStart: theme.spacing.l + theme.spacing.xs }}>
                  {t('models:payments.annualSavings', {
                    savingPercentage: percentageSaved,
                  })}
                </Text>
              </Box>
            )}
          </Box>

          {sortedUsers && sortedUsers.length > 0 && (
            <Box
              flexDirection='column'
              alignItems='flex-start'
              justifyContent='center'
              marginBottom='l'>
              <Text variant='labelSmall' marginBottom='xs'>
                {t('models:teams.subscriptions.paidMembers', {
                  count: sortedUsers.length,
                })}
              </Text>
              <Box
                flex={1}
                flexDirection='row'
                alignItems='flex-start'
                justifyContent='flex-start'
                flexWrap='wrap'>
                <Box justifyContent='center' alignItems='center'>
                  <TouchableOpacity
                    onPress={() => navigator.navigate('add-members')}
                    accessibilityLabel='Add More'>
                    <Box
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      width={56}
                      marginRight='m'>
                      <Box
                        alignItems='center'
                        justifyContent='center'
                        borderRadius='xxl'
                        bg='black'
                        width={56}
                        height={56}>
                        <Icon
                          width={40}
                          height={40}
                          name='Plus'
                          color='white'
                          onPress={() => navigator.navigate('add-members')}
                        />
                      </Box>
                      <Text
                        variant='labelSmall'
                        marginTop='xs'
                        textAlign='center'
                        numberOfLines={1}>
                        {t('models:projects.create.members.addMore')}
                      </Text>
                    </Box>
                  </TouchableOpacity>
                </Box>
                {sortedUsers?.map((user) => {
                  if (!user) return;
                  const { id: memberId, firstName, name, avatar, role } = user;

                  return (
                    <Box key={memberId} marginRight='m' alignItems='center'>
                      <Avatar
                        onDismiss={
                          user?.role !== TeamRole.Owner
                            ? () => {
                                const newUsers = values?.users?.filter(
                                  (u) => u?.id !== memberId
                                );
                                setFieldTouched('users');
                                setFieldValue('users', [...newUsers]);
                              }
                            : undefined
                        }
                        size='large'
                        label={name}
                        avatar={avatar}
                      />
                      <Text
                        variant='labelSmall'
                        marginTop='xs'
                        textAlign='center'
                        style={{ width: 56 }}
                        numberOfLines={1}>
                        {firstName}
                      </Text>
                      <Text
                        variant='metadataSecondary'
                        textAlign='center'
                        style={{ width: 56 }}
                        color='grey04'
                        numberOfLines={1}>
                        {role}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          {/* eslint-disable-next-line no-magic-numbers */}
          {!!sortedUsers && sortedUsers.length < 1 && (
            <Box mb='l'>
              <Text marginBottom='xs' variant='labelSmall'>
                {t('models:teams.subscriptions.members')}
              </Text>
              <Button
                variant='edit'
                fullWidth
                marginBottom='s'
                onPress={() => navigator.navigate('add-members')}
                accessibilityLabel='Add Members'>
                {t('models:teams.subscriptions.addMembers')}
              </Button>
            </Box>
          )}

          {listProducts?.length && (
            <Box mb='l'>
              <Text variant='labelSmall' mb='xs'>
                {t('models:teams.subscriptions.subtotal')}
              </Text>
              <Box flexDirection='row'>
                {billingCycle === 'year' && (
                  <Text
                    color='grey04'
                    textDecorationStyle='solid'
                    textDecorationLine='line-through'
                    mr='xxs'>
                    {originalCostLabel}
                  </Text>
                )}
                <Text variant='labelEmphasized'>{subtotalLabel}</Text>
              </Box>
            </Box>
          )}
          {!teamPlanSelected && (
            <Box mb='l' flexDirection='row'>
              <Text variant='body'>
                {t('models:teams.subscriptions.workWithATeam.firstPart')}
              </Text>
              <Pressable onPress={switchToTeamPlan}>
                <Text variant='body' color='greenSecondary'>
                  {t('models:teams.subscriptions.workWithATeam.secondPart')}
                </Text>
              </Pressable>
              <Text variant='body'>
                {t('models:teams.subscriptions.workWithATeam.thirdPart')}
              </Text>
            </Box>
          )}
          <Box height={1} backgroundColor='grey02' mb='l' />
          {/* Payment Form */}
          <Box>
            <Button
              variant='primary'
              disabled={!isValid || loading}
              onPress={submitForm}>
              {loading ? (
                <ActivityIndicator />
              ) : (
                <Text color='background'>
                  {t('models:teams.subscriptions.startYourTrial')}
                </Text>
              )}
            </Button>
          </Box>
        </Box>
      </ScrollView>
    );
  }
};
