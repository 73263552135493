import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import React, { useState } from 'react';
import { Platform, RefreshControl } from 'react-native';

import ChatImages from '@components/Chat/ChatImages';
import DocumentCard from '@components/Chat/DocumentCard';
import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import FileActivityCard from '@components/Files/FileActivityCard';
import { Box } from '@components/Restyle';
import {
  useListTagGroupsQuery,
  TagGroup,
  LocalFile,
  Document,
} from '@graphql/generated';
import { useGetDocumentsByClientIdsQuery } from '@hooks/useGetDocumentsByClientIdsQuery';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { openInNewTab } from '@utils/fileUtils';

// NOTE: FlashList strongly recommends setting the estimatedItemSize prop. The value of 293
// is suggested without assigning a value for this prop.
const ESTIMATED_FILE_ITEM_SIZE = 293;

const RecentFiles: React.FC = () => {
  const [refreshing, setRefreshing] = useState(false);

  const { checkDocumentURL } = useGetDocumentsByClientIdsQuery();

  const { data, refetch } = useListTagGroupsQuery({
    variables: { limit: 5 },
    onCompleted: (data) => {
      setRefreshing(false);
      if (data.listTagGroups.length > 0) {
        const docs: Document[] = [];
        data.listTagGroups.forEach((group) => {
          group?.documents?.forEach((doc) => {
            if (!docs.some((d) => d.clientId === doc.clientId)) {
              const { __typename, clientId, isImage, file } = doc;
              docs.push({ __typename, clientId, isImage, file } as Document);
            }
          });
        });
        if (docs.length > 0) {
          checkDocumentURL(docs);
        }
      }
    },
    onError: () => {
      setRefreshing(false);
    },
  });
  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const navigation = useNavigation();
  const { listTagGroups: tagGroups } = data || { listTagGroups: [] };
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);

  const renderItem = ({ item }: { item: TagGroup }) => {
    const { documents } = item;

    if (!documents) return null;

    return (
      <Box
        margin='m'
        key={item.project.id + '_' + item.task?.id + '_' + item.id}>
        <FileActivityCard
          author={item.author}
          actionType='Upload'
          projects={[item.project]}
          tasks={item?.task ? [item?.task] : undefined}
          date={item.updatedAt}>
          {item.isImageGroup && (
            <ChatImages
              isForwarded={false}
              onPress={(image: Document | LocalFile) => {
                const preDocs = (item?.documents || []).map((document) => ({
                  ...document,
                  owner: item.author,
                }));
                setPreviewDocuments(preDocs);
                setActiveIndex(
                  (item?.documents || []).findIndex((i) => i.id === image.id)
                );
                if (Platform.OS === 'web') {
                  setImageCarouselVisible(true);
                } else {
                  navigation.navigate('images-preview');
                }
              }}
              list={item?.documents || []}
            />
          )}

          {!item.isImageGroup &&
            (item?.documents || []).map((document) => {
              return (
                <Box key={document.id} marginVertical='xxs'>
                  <DocumentCard
                    document={document}
                    onPress={(_document: Document) => {
                      if (Platform.OS === 'web') return openInNewTab(_document);

                      setPreviewDocuments(item?.documents || []);
                      navigation.navigate('file-preview');
                    }}
                  />
                </Box>
              );
            })}
        </FileActivityCard>
      </Box>
    );
  };

  return (
    <Box flex={1}>
      <FlashList
        data={tagGroups}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={() => <Box margin='xxs' />}
        ListFooterComponent={() => <Box marginBottom='listFooter' />}
        refreshing={refreshing}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              refetch();
            }}
          />
        }
        ListEmptyComponent={<EmptyStateNoFiles />}
        estimatedItemSize={ESTIMATED_FILE_ITEM_SIZE}
      />
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
    </Box>
  );
};

export default RecentFiles;
