import * as Apollo from '@apollo/client';
import * as Contacts from 'expo-contacts';
import { TFunction } from 'react-i18next';
import { Alert } from 'react-native';

import { ContactType } from '@components/Invite/Contact';
import {
  Exact,
  UploadContactAttributes,
  UploadContactsMutation,
} from '@graphql/generated';
import { AsyncStorage } from '@utils/storage';

const getDisplayName = ({
  firstName,
  lastName,
  company,
}: Pick<ContactType, 'firstName' | 'lastName' | 'company'>): string => {
  let fullName = [firstName, lastName].filter((item) => item).join(' ');

  if ((!fullName || fullName === '') && company) fullName = company;

  if (!fullName || fullName === '') fullName = '';

  return fullName;
};

const getInitials = ({
  firstName,
  lastName,
  company,
}: Pick<ContactType, 'firstName' | 'lastName' | 'company'>): string => {
  return !firstName && !lastName && !!company
    ? company.charAt(0)
    : (firstName?.charAt(0) || '') + (lastName?.charAt(0) || '');
};

export const isContactsPermissionAllowed = async (): Promise<boolean> => {
  const { status } = await Contacts.getPermissionsAsync();
  return status === 'granted';
};

export const getDeviceContacts = async (
  options: {
    requestPermission?: boolean;
  } = { requestPermission: false }
): Promise<ContactType[]> => {
  const { requestPermission } = options;

  let requestedStatus: Contacts.PermissionStatus | undefined = undefined;

  const { status, canAskAgain } = await Contacts.getPermissionsAsync();

  const hasPermission = status === 'granted';

  if (!hasPermission && canAskAgain && requestPermission) {
    await Contacts.requestPermissionsAsync()
      .then((d) => {
        requestedStatus = d.status;
      })
      .catch((e) => console.log(e));
  }

  const requestGranted = requestedStatus === 'granted';

  if (!(hasPermission || requestGranted)) return [];

  const { data } = await Contacts.getContactsAsync({
    fields: [
      Contacts.Fields.Emails,
      Contacts.Fields.PhoneNumbers,
      Contacts.Fields.Image,
    ],
  });

  const contactsWithPhones = data.filter(
    ({ phoneNumbers }) => !!phoneNumbers && phoneNumbers?.length > 0
  ) as ((typeof data)[0] & Required<Pick<(typeof data)[0], 'phoneNumbers'>>)[];

  const contacts: ContactType[] = contactsWithPhones
    .map((item) => {
      const displayName = getDisplayName(item);
      const initials = getInitials(item);

      const { firstName, lastName, image, id, phoneNumbers, company } = item;

      const phoneNumber =
        phoneNumbers?.find((phone) => {
          if (phone?.label?.toLowerCase() === 'mobile') {
            return phone;
          }
        }) || phoneNumbers[0];

      return {
        firstName,
        lastName,
        company,
        initials,
        displayName,
        phoneNumber: phoneNumber.number,
        allPhoneNumbers: phoneNumbers,
        avatarUrl: image?.uri,
        isPhoneContact: true,
        id,
      };
    })
    .filter((item) => item !== null);

  return contacts;
};

export const convertDeviceContactsToPhoneNumbers = (
  deviceContacts: ContactType[]
): string[] => {
  // digits field not available on Android
  const numbers = deviceContacts.flatMap((c) =>
    c.allPhoneNumbers?.map((phoneNumber) => phoneNumber.number)
  );
  return numbers.filter((n) => n) as string[];
};

export const getDeviceContactsNew = (
  setShowRequestContactsPermission?: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setDeviceContacts?: React.Dispatch<React.SetStateAction<ContactType[]>>,
  contactsSyncedAt?: boolean,
  uploadContacts?: (
    options?:
      | Apollo.MutationFunctionOptions<
          UploadContactsMutation,
          Exact<{
            attributes: UploadContactAttributes;
          }>,
          Apollo.DefaultContext
        >
      | undefined
  ) => void
) => {
  getDeviceContacts({ requestPermission: true }).then((d) => {
    setShowRequestContactsPermission?.(false);
    if (d.length > 0 && !contactsSyncedAt) {
      setDeviceContacts?.(d);
      const phoneNumbers = convertDeviceContactsToPhoneNumbers(d);
      uploadContacts &&
        uploadContacts({ variables: { attributes: { phoneNumbers } } });
    }
  });
};

export const getContacts = async (
  setShowRequestContactsPermission?: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setDeviceContacts?: React.Dispatch<React.SetStateAction<ContactType[]>>,
  contactsSyncedAt?: boolean,
  t?: TFunction<'models', undefined>,
  uploadContacts?: (
    options?:
      | Apollo.MutationFunctionOptions<
          UploadContactsMutation,
          Exact<{
            attributes: UploadContactAttributes;
          }>,
          Apollo.DefaultContext
        >
      | undefined
  ) => void
) => {
  const requestContactPermission = await AsyncStorage.getItem(
    'request_contact_permission'
  );
  if (requestContactPermission === '1') {
    getDeviceContactsNew(
      setShowRequestContactsPermission,
      setDeviceContacts,
      contactsSyncedAt,
      uploadContacts
    );
    return;
  }
  t &&
    Alert.alert(
      t('models:chat.contactPermissions'),
      t('models:chat.contactPermission'),
      [
        {
          text: t('shared:continue'),
          style: 'destructive',
          onPress: async () => {
            await AsyncStorage.setItem('request_contact_permission', '1');
            getDeviceContactsNew(
              setShowRequestContactsPermission,
              setDeviceContacts,
              contactsSyncedAt,
              uploadContacts
            );
          },
        },
      ]
    );
};

export const useDeviceContacts = () => {
  return {
    getDeviceContacts,
    convertDeviceContactsToPhoneNumbers,
    isContactsPermissionAllowed,
    getDeviceContactsNew,
    getContacts,
  };
};
