import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { CommonActions } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';

import CustomTabPanel from '@components/CustomTabBar/CustomTabPanel.web';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import CreateJoinTeamModal from '@components/Modals/CreateJoinTeamModal';
import TeamMemberRoleSelectModal from '@components/Modals/TeamMemberRoleSelectModal';
import ProfileCard from '@components/Profile/ProfileCard';
import { SubscriptionPlan } from '@components/Profile/SubscriptionPlan.web';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { createTabNavigator } from '@components/Web/Navigator/TabNavigator';
import { Team } from '@graphql/generated';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import useMe from '@hooks/useMe';
import { useNotification } from '@hooks/useNotification';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { AddTeamMembersStack } from '@navigation/addMembers/add-team-members-stack';
import { modalStyle } from '@navigation/helpers';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { Analytics } from '@screens/Analytics/Analytics';
import { PlanSelect } from '@screens/Payments/PlanSelect';
import { SubscriptionCreatedModal } from '@screens/Payments/SubscriptionCreateModal';
import { MyAccountSettings } from '@screens/Profile/MyAccountSettings.web';
import { AssignNewOwner } from '@screens/Teams/AssignNewOwner';
import TeamDetails from '@screens/Teams/TeamDetails.web';
import TeamMembers from '@screens/Teams/TeamMembers';
import { TeamSubscription } from '@screens/Teams/TeamSubscription';
import { useAuthContext } from '@src/context/authContext';

const Tabs = createTabNavigator();

type MyTeamsStackParamsList = {
  home: undefined;
  'create-join-team-modal': undefined;
  'team-details': { teamId: Team['id'] };
  'team-members': { teamId: Team['id'] };
  'choose-plan': { teamId: Team['id'] };
  'team-member-role-select-modal': undefined;
  'team-subscription': { teamId: Team['id'] };
  'team-analytics': { teamId: Team['id'] };
  'assign-new-owner': { teamId: Team['id'] };
  'add-team-members-stack': { teamId: Team['id'] };
  subscriptionPlanDetail: { teamId: Team['id'] };
};

export const MyAccount: React.FC<any> = ({ route }) => {
  const { removeToken: removeAuthToken } = useAuthContext();
  const { deleteToken: deleteNotificationToken } = useNotification();
  const { me } = useMe();
  const { clearUserInfo } = useGoogleAuth();
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const navigation = useAppNavigation();

  const [isFromCreatedSubscription, setIsFromCreatedSubscription] = useState(
    (route?.name || '') === 'subscription-created'
  );
  const [isFromEditSubscription, setFromEditSubscription] = useState(
    (route?.name || '') === 'subscription-edit'
  );
  const { setSelectedTeam } = useWebDrawerNavigator();
  useEffect(() => {
    const unsubscribe = navigation
      .getParent()
      ?.addListener('drawerItemPress', (e) => {
        setSelectedTeam(undefined);
        navigation.reset({ routes: [{ name: 'tabs' }], index: 0 });
        e.preventDefault();
      });
    return unsubscribe;
  }, [navigation]);

  const logout = async () => {
    try {
      clearUserInfo && clearUserInfo();

      window.sessionStorage.setItem('screenInfo-activeChat', '');

      // eslint-disable-next-line no-console
      await deleteNotificationToken();
    } catch (e) {
      /* empty */
    } finally {
      removeAuthToken();
    }
  };

  if (!me)
    return (
      <Box marginHorizontal='m' marginTop='m'>
        <Text textAlign='center' marginBottom='m' color='grey04'>
          no user found
        </Text>
        <Button onPress={logout} accessibilityLabel='Log Out'>
          <Text variant='buttonLabel' color='alertRed'>
            Log out
          </Text>
        </Button>
      </Box>
    );

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box>
        <Box
          flex={1}
          flexDirection='row'
          alignItems='center'
          style={styles.topShadow}>
          <CustomTabPanel
            panelKey={'myProfile_' + me.id + '_' + props.state.index}
            {...props}
            isProfileTabs
            contentContainerStyle={styles.contentContainerPanelStyle}
          />
          <Box flexDirection='row' mr='l' justifyContent='flex-end'>
            <Button
              accessibilityLabel={t('models:saveProfile.logout')}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              isSmall
              height={48}
              backgroundColor={isHovered ? 'grey01' : 'white'}
              prefix={<Icon variant='s' name='LogOut' color='alertRed' />}
              onPress={logout}
              borderRadius='xs'
              borderColor={isHovered ? 'grey01' : 'white'}
              variant='logout'
              prefixMarginRight='xs'
              paddingHorizontal='m'
              paddingVertical='xs'>
              {t('models:saveProfile.logout')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const panel = (page: React.ReactNode) => {
    return (
      <Box flex={1} marginTop='m'>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps='always'
          scrollEnabled
          style={{ width: '100%' }}>
          <Box flex={1} alignItems='center'>
            {page}
          </Box>
        </ScrollView>
      </Box>
    );
  };

  const Stack1 = createStackNavigator<MyTeamsStackParamsList>();

  const MyProfileStack = () => {
    return (
      <Stack1.Navigator>
        <Stack1.Screen
          name='home'
          children={() => (
            <Box flex={1} alignItems='center' marginTop='m'>
              <ScrollView
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps='always'
                scrollEnabled
                style={{ width: '100%' }}>
                <Box flex={1} alignItems='center' marginBottom='listFooter'>
                  <ProfileCard user={me} isMe />
                </Box>
              </ScrollView>
            </Box>
          )}
          options={{
            headerShown: false,
          }}
        />
        <Stack1.Screen
          name='team-details'
          children={() => panel(<TeamDetails user={me} />)}
          options={{
            headerShown: false,
          }}
        />
        <Stack1.Screen
          name='team-members'
          children={() => <TeamMembers />}
          options={{
            headerShown: false,
          }}
        />
        <Stack1.Screen
          name='choose-plan'
          children={() => <PlanSelect />}
          options={{
            headerShown: false,
          }}
        />
        <Stack1.Screen
          name='team-analytics'
          children={() => <Analytics />}
          options={{
            headerShown: false,
          }}
        />

        <Stack1.Screen
          name='team-member-role-select-modal'
          children={() => <TeamMemberRoleSelectModal />}
          options={{ ...modalStyle }}
        />
        <Stack1.Screen
          name='create-join-team-modal'
          children={() => <CreateJoinTeamModal />}
          options={{ ...modalStyle }}
        />
        <Stack1.Screen
          name='assign-new-owner'
          children={() => <AssignNewOwner />}
          options={{
            header: () => (
              <SecondaryHeader title='Assign New Owner' searchable={false} />
            ),
          }}
        />
        <Stack1.Screen
          getId={({ params }) => params.teamId}
          name='add-team-members-stack'
          children={() => <AddTeamMembersStack isFromMyProfileWeb={true} />}
          options={{ headerShown: false }}
        />
        <Stack1.Screen
          name='team-subscription'
          children={() => <TeamSubscription />}
          options={{
            headerShown: false,
          }}
        />
      </Stack1.Navigator>
    );
  };

  return (
    <Box flex={1} mt='m' minWidth={650}>
      <Tabs.Navigator
        initialRouteName={
          isFromCreatedSubscription ||
          isFromEditSubscription ||
          (route?.name || '') === 'subscription-plans' ||
          window.sessionStorage.getItem('goto_welcome') === '-1'
            ? 'subscription-plans'
            : 'my-profile'
        }
        screenOptions={{ swipeEnabled: false }}
        tabBar={renderTabBar}>
        <Tabs.Screen
          name='my-profile'
          options={{ tabBarLabel: t('models:navigation.myProfile') }}
          children={MyProfileStack}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [
                    {
                      name: 'my-account',
                      params: {
                        screen: 'tabs',
                      },
                    },
                  ],
                })
              );
            },
          })}
        />
        <Tabs.Screen
          name='subscription-plans'
          options={{ tabBarLabel: 'Subscription Plans' }}
          children={() => <SubscriptionPlan />}
        />
        <Tabs.Screen
          name='settings'
          options={{ tabBarLabel: t('shared:settings') }}
          children={() => <MyAccountSettings />}
        />
      </Tabs.Navigator>
      {isFromCreatedSubscription && (
        <SubscriptionCreatedModal
          isFromCreated={true}
          onClose={() => {
            setIsFromCreatedSubscription(false);
          }}
          title={t('shared:congratulations')}
        />
      )}
      {isFromEditSubscription && (
        <SubscriptionCreatedModal
          isFromCreated={false}
          onClose={() => {
            setFromEditSubscription(false);
          }}
          title={t('shared:congratulations')}
        />
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  topShadow: {
    backgroundColor: 'white',
  },
  contentContainerPanelStyle: {
    paddingLeft: 24,
    backgroundColor: '#ffffff',
  },
});
