import React from 'react';

import FileCard from '@components/Files/FileCard.web';
import { Box } from '@components/Restyle';
import GlobalSearchList from '@components/Search/GlobalSearchList.web';
import { Document } from '@graphql/generated';
import { Search } from '@root/types';

interface IDocumentSearch {
  search: string;
  data: Search<Document>[];
  loading?: boolean;
  sectionFooter?: JSX.Element;
  onPress?: (item: Document) => void;
  isMyUploads?: boolean;
  setIsMyUploads?: (value: boolean) => void;
  filesFilter?: string;
  setFilesFilter?: (value: string) => void;
  onEndReached?: (info: { distanceFromEnd: number }) => void;
}

const FilesSearch: React.FC<IDocumentSearch> = ({
  search,
  data,
  loading = false,
  sectionFooter,
  onPress,
  isMyUploads = false,
  setIsMyUploads,
  filesFilter = 'All',
  setFilesFilter,
  onEndReached,
}) => {
  return (
    <GlobalSearchList
      onEndReached={onEndReached}
      search={search}
      loading={loading}
      isViewAllContacts={false}
      onPress={() => {}}
      isFilesSearch={true}
      isMyUploads={isMyUploads}
      setIsMyUploads={setIsMyUploads}
      filesFilter={filesFilter}
      setFilesFilter={setFilesFilter}
      lists={[
        {
          title: 'Files',
          data: data,
          totalCount: data.length > 0 ? data[0].total : data.length,
          render: (item) => {
            const document = item.record as Document;

            return (
              <FileCard
                filterVal={search}
                document={document}
                onPress={() => {
                  onPress?.(document);
                }}
              />
            );
          },
          itemSeparatorComponent: <Box marginBottom='l' />,
          sectionHeaderMarginBottom: 'xs',
          sectionFooter: sectionFooter,
        },
      ]}
    />
  );
};

export default FilesSearch;
