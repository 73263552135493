import React, { useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';

import { Box, Text } from '@components/Restyle';
import { Tag } from '@components/shared/Tags/Tag';
import { useOutsideClick } from '@hooks/useOutsideClick.web';
import useSearch, { searchTags } from '@hooks/useSearch';

import SearchInput from './SearchInput';

type Props = {
  showCancel?: boolean;
  autofocus?: boolean;
  onCancelPress?: () => void;
  isFromSearchHistory?: boolean;
  dismissOutclick?: boolean;
};

export const SearchInputWithTags = ({
  showCancel,
  autofocus,
  onCancelPress,
  isFromSearchHistory = false,
  dismissOutclick = false,
}: Props) => {
  const {
    setSearch,
    setIsSearching,
    isSearching,
    toggleFilter,
    filter,
    search,
  } = useSearch();

  const handleOutsideClick = () => {
    if (dismissOutclick) return;
    setIsSearching(false);
    onCancelPress && onCancelPress();
  };
  const ref = useOutsideClick(handleOutsideClick);

  const searchInputRef = useRef<TextInput>(null);

  return (
    <Box flex={1} ref={ref}>
      <Box flexDirection='row' alignItems='center'>
        <SearchInput
          ref={searchInputRef}
          value={search}
          onFocus={() => setIsSearching(true)}
          autoFocus={autofocus}
          onTextChanged={(t: string) => {
            setSearch(t);
          }}
          onCancel={() => {
            isFromSearchHistory ? setSearch('') : onCancelPress;
          }}
        />
        {showCancel && (
          <TouchableOpacity
            onPress={() => {
              setIsSearching(!isSearching);
            }}>
            <Text marginLeft='m' color='greenSecondary' variant='buttonLabel'>
              Cancel
            </Text>
          </TouchableOpacity>
        )}
      </Box>

      <Box
        flexDirection='row'
        flexWrap='wrap'
        marginTop='xs'
        visible={autofocus && isSearching}>
        {searchTags.map((i) => (
          <Box key={i} marginRight='m' marginBottom='xs'>
            <Tag
              label={i}
              variant={filter.includes(i) ? 'reversed' : 'picker'}
              paddingVertical='xxs'
              paddingHorizontal='xs'
              borderRadius='xxs'
              onPress={() => {
                i === filter.toString() ? toggleFilter('') : toggleFilter(i);
                searchInputRef.current?.focus();
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
