import { useNetInfo } from '@react-native-community/netinfo';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@components/Alert';
import {
  Document,
  ListDraftMessagesDocument,
  Message,
  MessageDetailFragmentDoc,
  MessageProject,
  MessageTask,
  useCreateMessageIndividualMutation,
  useListDraftMessagesQuery,
  ListDraftMessagesQuery,
  MessageDetailFragment,
  ListDraftDocumentsDocument,
} from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';

import useMe from './useMe';

type MessageParams = Pick<
  Message,
  | 'body'
  | 'chatId'
  | 'clientId'
  | 'projectIds'
  | 'replyId'
  | 'replyMessage'
  | 'taskIds'
  | 'publish'
  | 'attachments'
  | 'failedAt'
  | 'checkin'
>;

type ChatProcessingContext = {
  retrySendFailedMessage: (clientId: string, authorId: string) => void;
};

const chatProcessingContext = createContext<ChatProcessingContext | undefined>(
  undefined
);

let messageClientId = '';

export const ChatProcessingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = chatProcessingContext;
  const { client } = useApolloClient();
  const { me } = useMe();
  const { isConnected } = useNetInfo();

  const { data } = useListDraftMessagesQuery();
  const { listDraftMessages } = data || {};
  const { t } = useTranslation();

  const [createMessageIndividual] = useCreateMessageIndividualMutation({
    onCompleted: (data) => {
      removeProcessedMessage(data.createMessageIndividual.clientId);
      markMessageDocument(data.createMessageIndividual);
    },
    onError: (_err, options) => {
      const message = options?.variables?.attributes;
      if (!message) return;
      const clientId = message.clientId;
      removeProcessedMessage(clientId);
      handleFailedMessage(message);
    },
  });

  const [messagesInProcess, setMessagesInProcess] = useState<string[]>([]);

  const removeProcessedMessage = (clientId: string) => {
    setMessagesInProcess((prev) => prev.filter((m) => m != clientId));
    client?.cache.updateQuery<ListDraftMessagesQuery>(
      {
        query: ListDraftMessagesDocument,
      },
      (data) => {
        const { listDraftMessages } = data || {};
        if (!listDraftMessages) return;
        const newMessages = listDraftMessages.filter(
          (m) => m.clientId != clientId
        );

        return {
          listDraftMessages: newMessages,
        };
      }
    );
  };

  const handleFailedMessage = (message: MessageParams) => {
    if (!me?.id) {
      console.log('!!!!something went horribly wrong!!!!!');
      return;
    }

    client?.cache.updateFragment<MessageDetailFragment>(
      {
        id: client.cache.identify({
          ...message,
          authorId: me?.id,
          __typename: 'Message',
        }),
        fragment: MessageDetailFragmentDoc,
        fragmentName: 'MessageDetail',
      },
      (data) => {
        return {
          ...data,
          failedAt: new Date().toISOString(),
          isRetry: true,
        };
      }
    );
  };

  const retrySendFailedMessage = (clientId: string, authorId: string) => {
    if (!client?.cache) return;
    if (!isConnected) {
      Alert.alert(
        t('models:chat.netInfoTitle'),
        t('models:chat.netInfoMessage')
      );
      return;
    }
    const message = client.cache.updateFragment<MessageDetailFragment>(
      {
        id: client.cache.identify({
          clientId,
          authorId,
          __typename: 'Message',
        }),
        fragment: MessageDetailFragmentDoc,
        fragmentName: 'MessageDetail',
      },
      (data: any) => {
        return {
          ...data,
          createdAt: new Date().toISOString(),
          failedAt: null,
          isRetry: false,
        };
      }
    );
    processMessageInBackground(message);
  };

  const processMessageInBackground = (message: any) => {
    const clientId = message.clientId;
    // TODO Figure out how to handle edits
    if (messagesInProcess.some((m) => m == clientId)) return;
    setMessagesInProcess((prev) => [...prev, clientId]);
    if (messageClientId != clientId) {
      if (isConnected) messageClientId = clientId;

      sendDraftMessage(message);
    } else {
      messageClientId = '';
    }
  };

  useEffect(() => {
    if (isConnected) {
      if (!(listDraftMessages || []).filter) return;
      const nonFailedDrafts = (listDraftMessages || []).filter(
        (m) => m.failedAt == null
      );
      nonFailedDrafts.forEach((m) => {
        processMessageInBackground(m);
      });
    }
  }, [listDraftMessages, isConnected]);

  const sendDraftMessage = useCallback(
    (attributes: MessageParams) => {
      const cache = client?.cache;
      if (!cache) return;
      const {
        body,
        chatId,
        clientId,
        attachments,
        publish,
        replyId,
        replyMessage,
        checkin,
      } = attributes;

      const messageTasks = (attachments || []).filter(
        (item) => item.__typename === 'MessageTask'
      ) as MessageTask[];
      const taskIds = messageTasks.map((item) => item.task?.id);

      const messageProjects = (attachments || []).filter(
        (item) => item.__typename === 'MessageProject'
      ) as MessageProject[];
      const projectIds = messageProjects.map((item) => item.project?.id);

      const documents = (attachments || []).filter(
        (item) => item.__typename === 'Document'
      ) as Document[];

      let newCheckin = null;
      if (checkin?.latitude) {
        const { __typename, id: __, ...rest } = checkin;
        newCheckin = { ...rest };
      }
      const params = {
        body,
        chatId,
        clientId,
        projectIds,
        replyId,
        replyMessage,
        taskIds,
        publish,
        checkin: newCheckin,
      };

      delete params.replyMessage;

      createMessageIndividual({
        variables: {
          attributes: {
            ...params,
            publish: documents.length < 1,
          },
        },
      });
    },
    [me?.id, me?.name, me?.avatar?.path]
  );

  const markMessageDocument = (message: any) => {
    if (message.isDraft) {
      client?.cache.updateQuery(
        {
          query: ListDraftDocumentsDocument,
          variables: {
            chatId: message.chatId,
          },
        },
        (data) => {
          if (!data?.listDraftDocuments) return data;
          return {
            listDraftDocuments: data.listDraftDocuments.map((doc: any) =>
              doc.messageClientId === message.clientId && !doc.messageCreateYn
                ? { ...doc, messageCreateYn: true }
                : doc
            ),
          };
        }
      );
    }
  };

  return (
    <Provider
      value={
        {
          retrySendFailedMessage,
        } as ChatProcessingContext
      }>
      {children}
    </Provider>
  );
};

const useChatProcessing = (): ChatProcessingContext => {
  const context = useContext(chatProcessingContext);
  if (context === undefined) {
    throw new Error('useChatProcessing must be used within a Provider');
  }
  return context;
};

export default useChatProcessing;
