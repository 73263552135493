'use strict';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import DayRow from './DataRow';
import { DayPropsType } from './Day';
import DayHeader from './DayHeader';
import { dayJsMod } from './helper';
import { Box, Text } from '../Restyle';

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const areEqual = (prevProps, nextProps) => {
  if (nextProps.minDate != prevProps.minDate) return false;

  if (nextProps.maxDate != prevProps.maxDate) return false;

  if (nextProps.availableDates != prevProps.availableDates) return false;

  if (nextProps.startDate != prevProps.startDate) return false;

  if (nextProps.untilDate != prevProps.untilDate) return false;

  return true;
};

export type MonthPropsType = {
  month: string;
  dayProps: DayPropsType;
  titleStyle: string;
  titleFormat: string;
  capitalizeTitle: string;
  dayHeaderProps: string;
  startDate: string;
  untilDate: string;
  availableDates: string;
  minDate: string;
  maxDate: string;
  ignoreMinDate: string;
  selectFlag: boolean;
};

const Month = memo((props: MonthPropsType) => {
  const {
    month,
    dayProps,
    titleStyle,
    titleFormat,
    capitalizeTitle,
    dayHeaderProps,
    selectFlag,
  } = props;
  const getDayStack = (month) => {
    const res = [];
    const currMonth = dayJsMod(month).month(); //get this month
    let currDate = dayJsMod(month).startOf('month'); //get first day in this month

    const {
      startDate,
      untilDate,
      availableDates,
      minDate,
      maxDate,
      ignoreMinDate,
    } = props;

    do {
      const dayColumn = [];
      for (let i = 0; i < 7; i++) {
        const dayObject: { type: string | null; date: string | null } = {
          type: null,
          date: null,
        };

        if (
          currDate.format('YYYYMMDD') == dayJsMod(new Date()).format('YYYYMMDD')
        ) {
          dayObject.type = 'today';
        }

        if (
          minDate &&
          currDate.isBefore(minDate, 'day') &&
          !(ignoreMinDate && startDate?.isSame(currDate, 'day'))
        ) {
          dayObject.type = 'disabled';
        }

        if (maxDate && currDate.isAfter(maxDate, 'day')) {
          dayObject.type = 'disabled';
        }

        if (
          availableDates &&
          !availableDates.includes(currDate.format('YYYYMMDD'))
        ) {
          dayObject.type = 'blockout';
        }

        if (startDate && currDate.isSame(startDate, 'day')) {
          dayObject.type = !untilDate ? 'single' : 'first';
        } else if (untilDate && currDate.isSame(untilDate, 'day')) {
          dayObject.type = 'last';
        } else if (
          startDate &&
          untilDate &&
          currDate.isBetween(startDate, untilDate, 'day')
        ) {
          dayObject.type = 'between';
        }

        dayObject.date = currDate.clone().format('YYYYMMDD');
        dayColumn.push(dayObject);
        currDate = currDate.add(1, 'day');
      }

      res.push(dayColumn);
    } while (currDate.month() == currMonth);

    return res;
  };

  const dayStack = getDayStack(dayJsMod(month, 'YYYYMM'));

  let counter = 0;

  return (
    <Box>
      <Text style={{ color: props.textColor, ...titleStyle }}>
        {capitalizeTitle
          ? capitalize(dayJsMod(month, 'YYYYMM').format(titleFormat))
          : dayJsMod(month, 'YYYYMM').format(titleFormat)}
      </Text>
      {props.showDaysHeader && <DayHeader {...dayHeaderProps} />}
      <Box>
        {dayStack.map((days) => {
          counter++;
          return (
            <DayRow
              days={days}
              flag={selectFlag}
              dayProps={dayProps}
              key={counter}
              onSelectDate={props.onSelectDate}
              textColor={props.textColor}
            />
          );
        })}
      </Box>
    </Box>
  );
}, areEqual);

Month.defaultProps = {
  titleFormat: 'MMMM',
  titleStyle: { fontSize: 16, fontWeight: '600' },
  dayHeaderProps: {},
  showDaysHeader: false,
  capitalizeTitle: false,
};

Month.propTypes = {
  titleFormat: PropTypes.string,
  titleStyle: PropTypes.object,
  dayHeaderProps: PropTypes.object,
  showDaysHeader: PropTypes.bool,
  capitalizeTitle: PropTypes.bool,
};

export default Month;
