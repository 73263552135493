import { WatchQueryFetchPolicy } from '@apollo/client';
import { useState } from 'react';
import { Platform, RefreshControl } from 'react-native';

import {
  Document,
  DocumentSortOption,
  ListTaskDocumentsQuery,
  Project,
  Task,
  User,
  useListTaskDocumentsQuery,
} from '@graphql/generated';
import { useGetDocumentsByClientIdsQuery } from '@hooks/useGetDocumentsByClientIdsQuery';

const DEFAULT_PER_PAGE_COUNT = Platform.OS === 'web' ? 48 : 12;

export const useListTaskDocumentsFromQuery = ({
  projectIds = undefined,
  taskIds = undefined,
  ownerIds = undefined,
  sortBy = undefined,
  last = undefined,
  after = undefined,
  before = undefined,
  includeFiles = undefined,
  includeMedia = undefined,
  first = DEFAULT_PER_PAGE_COUNT,
  fetchPolicy = 'cache-and-network',
  onCompleted = undefined,
  onFetchMoreCompleted = undefined,
}: {
  projectIds?: Project['id'][];
  taskIds?: Task['id'][];
  ownerIds?: User['id'][];
  sortBy?: DocumentSortOption;
  limit?: number;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  includeFiles?: boolean;
  includeMedia?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
  onCompleted?: (data?: ListTaskDocumentsQuery) => void;
  onFetchMoreCompleted?: () => void;
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [listTaskDocumentsData, setListTaskDocumentsData] = useState<
    ListTaskDocumentsQuery | undefined
  >();

  const { checkDocumentURL } = useGetDocumentsByClientIdsQuery();

  const { loading, refetch, fetchMore } = useListTaskDocumentsQuery({
    variables: {
      projectIds,
      taskIds,
      ownerIds,
      sortBy,
      first,
      last,
      before,
      after,
      includeFiles,
      includeMedia,
    },
    fetchPolicy,
    onCompleted: (data) => {
      setListTaskDocumentsData(data);
      onCompleted && onCompleted(data);
      if ((data.listTaskDocuments.edges?.length ?? 0) > 0) {
        checkDocumentURL(data.listTaskDocuments!.edges ?? []);
      }
    },
  });

  const onRefresh = () => {
    setRefreshing(true);
    refetch();
  };
  const refreshControl = (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refetch();
      }}
    />
  );

  const { listTaskDocuments } = listTaskDocumentsData || {
    listTaskDocuments: undefined,
  };
  const { edges = [], pageInfo } = listTaskDocuments || { edges: [] };
  const documents =
    edges?.reduce((acc, curr) => {
      if (curr?.node !== undefined) {
        return [...acc, curr.node as Document];
      } else {
        return acc;
      }
    }, [] as Document[]) || [];

  const fetchFromCursor = async () => {
    if (!pageInfo?.hasNextPage || isFetchingMore) return;

    setIsFetchingMore(true);

    try {
      const cursor = pageInfo.endCursor;

      await fetchMore({
        variables: {
          after: cursor,
          first,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.listTaskDocuments?.edges?.length) {
            return previousResult;
          }

          const prevEdges = previousResult.listTaskDocuments.edges;
          const newEdges = fetchMoreResult.listTaskDocuments.edges;

          const allEdges = [
            ...(prevEdges || []),
            ...newEdges.filter(
              (newEdge) =>
                !prevEdges?.some(
                  (prevEdge) => prevEdge?.node?.id === newEdge?.node?.id
                )
            ),
          ];

          const mergedResult = {
            listTaskDocuments: {
              __typename: previousResult.listTaskDocuments.__typename,
              edges: allEdges,
              pageInfo: fetchMoreResult.listTaskDocuments.pageInfo,
            },
          };

          setListTaskDocumentsData(mergedResult);

          return mergedResult;
        },
      });
    } catch (err) {
      console.error('Failed to fetch more', err);
    } finally {
      setIsFetchingMore(false);
      onFetchMoreCompleted?.();
    }
  };

  return {
    documents: documents,
    loading,
    pageInfo,
    refetch,
    fetchFromCursor,
    refreshing,
    setRefreshing,
    refreshControl,
    onRefresh,
    isFetchingMore,
  };
};
