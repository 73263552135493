import * as Sentry from '@sentry/react-native';
import fetch from 'cross-fetch';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { InferType } from 'yup';

import {
  DeviceToken,
  TeamAttributes,
  useCreateStripeSubscriptionMutation,
  useCreateTeamMutation,
} from '@graphql/generated';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import { useNotification } from '@hooks/useNotification.web';
import RegisterStackNew from '@navigation/auth/register-stack-new.web';
import registerSchema from '@schemas/registerSchema.web';
import registerSocialSchema from '@schemas/registerSocialSchema.web';
import { useAuthContext } from '@src/context/authContext';
import { guessTimeZone } from '@utils/formatters/date';
import { getApiConfig } from '@utils/getApiConfig';
import { AsyncStorage } from '@utils/storage';

export type RegisterFormValues = InferType<typeof registerSchema>;

const apiConfig = getApiConfig();
const RegisterForm = () => {
  const { setAuthToken, setRegisterLoading } = useAuthContext();
  const { userInfo } = useGoogleAuth();
  const invitationToken =
    window.sessionStorage.getItem('invitation_token') ?? '';

  const initialValues: RegisterFormValues = {
    email: userInfo?.email || '',
    password: '',
    first_name: userInfo?.given_name || '',
    last_name: userInfo?.family_name || '',
    phone: '',
    invitation_token: invitationToken,
    provider: userInfo?.provider,
    uid: userInfo?.id,
    time_zone: guessTimeZone(),
    auth_token: '',
  };

  const [createTeam] = useCreateTeamMutation();
  const [createStripeSubscription] = useCreateStripeSubscriptionMutation();
  const { registerForPushNotificationsAsync } = useNotification();
  const [deviceToken, setDeviceToken] = useState<string | null>(null);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await registerForPushNotificationsAsync();
        setDeviceToken(token || null);
      } catch (error) {
        console.error('Failed to get device token', error);
        setDeviceToken(null);
      }
    };

    fetchToken();
  }, []);

  const register = async (
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    invitation_token: string,
    setErrors: any,
    provider: string,
    uid: string,
    time_zone: string,
    auth_token: string,
    team_name: string,
    team_emails: string[],
    setFieldValue: any
  ) => {
    if (auth_token !== '') {
      await AsyncStorage.setItem('authToken', auth_token);
      setAuthToken(auth_token);
    } else {
      const register = `/api/v3/users.json`;
      const registerPayload = {
        user: {
          email: email.toLowerCase(),
          password: password,
          terms_of_service: true,
          name: [first_name, last_name].filter((item) => item !== '').join(' '),
          phone_number: phone_number,
          invitation_token: invitation_token,
          provider: provider,
          uid: uid,
          time_zone: time_zone,
          device_token: deviceToken,
          token_type: DeviceToken.Firebase.toLowerCase(),
        },
      };
      const authEndpoint = `${apiConfig.apiUrl}${register}`;
      await fetch(authEndpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerPayload),
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response?.error) {
            const errType = Object.keys(response.errors)[0];
            if (
              response.error ===
              'Email has already been taken and Phone number has already been taken'
            ) {
              setErrors({
                email:
                  'This email already exists. Please use other email or login.',
                phone:
                  'This phone number already exists. Please use other phone number or login.',
              });
            } else {
              switch (errType) {
                case 'email':
                  setErrors({
                    email:
                      response.error === 'Email has already been taken'
                        ? 'This email already exists. Please use other email or login.'
                        : response.error,
                  });
                  break;
                case 'password':
                  setErrors({
                    password: response.error,
                  });
                  break;
                case 'phone_number':
                  setErrors({
                    phone:
                      response.error === 'Phone number has already been taken'
                        ? 'This phone number already exists. Please use other phone number or login.'
                        : response.error,
                  });
                  break;
                default:
                  setErrors({
                    email: 'error creating account.',
                  });
              }
            }
          } else {
            try {
              // about goto_welcome
              //  1: go to welcome modal.
              // -1: go to subscription plan page
              //  0: normal state(so after display the page, need to set to 0)
              window.sessionStorage.setItem(
                'goto_welcome',
                team_name ? '1' : '-1'
              );
            } catch (e) {
              // saving error
              // console.log(e);
            } finally {
              setFieldValue('auth_token', response.user.api_tokens[0].token);
              Sentry.setUser({
                email: email,
                username: uid,
              });
              setAuthToken(response.user.api_tokens[0].token);
              await AsyncStorage.setItem(
                'authToken',
                response.user.api_tokens[0].token
              );

              if (team_name) {
                const attributes = {
                  name: team_name,
                  emails: team_emails,
                } as TeamAttributes;

                const response = await createTeam({
                  variables: { attributes },
                  onCompleted: () => {
                    setRegisterLoading(false);
                  },
                });
                const teamCreated = response?.data?.createTeam;

                await createStripeSubscription({
                  variables: {
                    teamAttributes: undefined,
                    stripeSubscriptionAttributes: {
                      name: '13',
                      teamId: teamCreated?.id,
                      quantity: 1,
                      priceId: 'price_1Nuh4dKiMQhaWaB8eqo3pnJr',
                    },
                  },
                });
              } else {
                setRegisterLoading(false);
              }
            }
          }
        })
        .catch((_err) => {
          setErrors({
            email: 'network error',
          });
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      onSubmit={(values, { setErrors, setFieldValue }) => {
        register(
          values.email,
          values.password,
          values.first_name,
          values.last_name,
          values.phone,
          values.invitation_token ?? '',
          setErrors,
          values.provider,
          values.uid,
          values.time_zone,
          values.auth_token,
          values.team_name,
          values.team_emails,
          setFieldValue
        );
      }}
      validationSchema={userInfo ? registerSocialSchema : registerSchema}>
      {() => <RegisterStackNew />}
    </Formik>
  );
};

export default RegisterForm;
