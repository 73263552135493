import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, RefreshControl } from 'react-native';

import ProjectCard from '@components/ProjectCard/ProjectCard';
import { Box, Text } from '@components/Restyle';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import { Project, ProjectMemberRole } from '@graphql/generated';
import { useListProjectsFromQuery } from '@hooks/useListProjectsFromQuery';
import { ContactProfileProps } from '@navigation/contacts/contact-tabs.web';
import { useAppNavigation } from '@navigation/useAppNavigation';

type Header = { id: string; name: string };

type SectionData = {
  sectionHeader: Header;
  data: Project[];
};

export const ContactProjects: React.FC<ContactProfileProps> = ({
  userId,
  getProjectsCount,
}) => {
  const navigation = useAppNavigation();
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const {
    projects = [],
    loading,
    refetch,
  } = useListProjectsFromQuery({
    userId,
    onCompleted: () => setRefreshing(false),
    onError: () => setRefreshing(false),
  });

  useEffect(() => {
    getProjectsCount?.(projects.length);
  }, [projects]);

  if (loading && projects?.length < 1) return <ActivityIndicator />;

  const getRoleName = (value: string) => {
    return Object.entries(ProjectMemberRole).find(
      ([, val]) => val === value
    )?.[0];
  };

  const sectionData = (): SectionData[] => {
    const sectionList: SectionData[] = [
      {
        sectionHeader: { id: '1', name: t('shared:current') },
        data: [],
      },
      {
        sectionHeader: { id: '2', name: t('shared:completed') },
        data: [],
      },
    ];

    projects.map((project) => {
      sectionList[project.archivedAt ? 1 : 0].data.push(project as Project);
    });

    return sectionList;
  };

  const sectionHeader = (item: SectionData['sectionHeader']) => {
    return (
      <Box key={item.id} flex={1}>
        <Text color='textPrimary' variant='labelEmphasized'>
          {item.name}
        </Text>
      </Box>
    );
  };

  const renderItem = (item: Project) => {
    return (
      <Box key={item.id} marginBottom='m'>
        <ProjectCard
          expanded={false}
          project={item}
          onClick={() => {
            navigation.navigateToProject(item);
          }}
          suffixText={getRoleName(item.selectedUserProjectRole)}
        />
      </Box>
    );
  };

  return (
    <CollapsableSectionList<Header, Project>
      data={sectionData()}
      renderSectionHeader={(item) => sectionHeader(item)}
      renderItem={(item) => renderItem(item)}
      dataListHeaderStyle={{
        marginBottom: 'xs',
        backgroundColor: 'grey01',
        paddingVertical: 'xs',
      }}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={refetch} />
      }
    />
  );
};
