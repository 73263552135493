import BottomSheet from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Sentry from '@sentry/react-native';
import {
  dismissNotificationAsync,
  getBadgeCountAsync,
  getPresentedNotificationsAsync,
  setBadgeCountAsync,
} from 'expo-notifications';
import debounce from 'lodash.debounce';
import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Platform,
  KeyboardAvoidingView,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  ViewToken,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ChatMessageList from '@components/Chat/ChatMessageList';
import { MyChatForm } from '@components/Chat/MyChatForm';
import { TagsCollectionType } from '@components/Chat/TagsCollection';
import ChatHeader from '@components/Headers/ChatHeader';
import { TutorialModal } from '@components/Modals/TutorialModal';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import {
  Chat,
  Message,
  useGetChatQuery,
  useSearchLazyQuery,
} from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';
import useChatInput from '@hooks/useChatInput';
import useFeatureFlag from '@hooks/useFeatureFlag';
import { useListRecentProjectsTasksFromQuery } from '@hooks/useListProjectsTasksRecentFromQuery';
import useMe from '@hooks/useMe';
import { usePreviousValue } from '@hooks/usePreviousValue';
import useSearch from '@hooks/useSearch';
import { ChatsStackParamList } from '@navigation/chats/chats-stack';
import theme from '@themes/theme';
import { urlMatchesId } from '@utils/notificationUtils';

type MyChatDetails = {
  messages: Message[];
  fetchBeforeCursor?: (cursor: string) => void;
  fetchAfterCursor: (cursor: string) => void;
  loading: boolean;
  refreshing: boolean;
  onRefresh: () => void;
  chatId: Chat['id'];
  messageCursor?: string;
  messageId?: string;
  showKeyboardFocus?: boolean;
  hasNextPage?: boolean;
};

const callback = () => {};

const MyChatDetails: React.FC<MyChatDetails> = ({
  messages,
  fetchBeforeCursor = () => {},
  fetchAfterCursor,
  loading,
  refreshing,
  onRefresh,
  chatId,
  messageCursor = '',
  messageId = '',
  showKeyboardFocus,
  hasNextPage = true,
}) => {
  const { client } = useApolloClient();
  const navigation =
    useNavigation<StackNavigationProp<ChatsStackParamList, 'chat-detail'>>();
  const insets = useSafeAreaInsets();
  const { me } = useMe();
  // here we need to query the data to get owner info
  const { data: chatData } = useGetChatQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: chatId },
    skip: !chatId,
  });
  const { getChat: chat } = chatData || {};
  const [showTagModalSheet, setShowTagModalSheet] = useState(false);
  const bottomSheetRef = useRef<BottomSheet>(null);
  const [inputHeight, setInputHeight] = useState<number>(0);
  const listRef = createRef<FlatList<Message | string>>();
  const { setSelectedMessage, doNotShowTutorial } = useChatInput();
  const [filteredMessages, setFilteredMessages] = useState<Message[]>([
    ...messages,
  ]);

  const [, setFilterVal] = useState('');
  const { search, filter, setSearch, setIsSearching, isSearching } =
    useSearch();
  const [isTagSelected, setTagSelected] = useState(false);
  const [isFilesSelected, setFilesSelected] = useState(false);

  const [repliedFromMessageId, setRepliedFromMessageId] = useState('');
  const [repliedClicked, setRepliedClicked] = useState<Message | null | string>(
    null
  );

  const { body, tagsCollection } = { body: '', tagsCollection: [] };
  const [globalSearch, { data, loading: loadingSearch }] = useSearchLazyQuery();
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [searchFrom, setSearchFrom] = useState(0);

  const { listFeatureFlags } = useFeatureFlag();

  const editTagLog = listFeatureFlags.find(
    (flag: { name: string; enabled: boolean }) => flag.name === 'edit_tag_log'
  );

  const globalSearchCall = () => {
    if (!search) return;
    globalSearch({
      variables: {
        term: search,
        size: 20,
        from: searchFrom,
        chatId: chatId,
        includeMessages: true,
        includeDocuments: false,
        includeProjects: false,
        includeTasks: false,
        tags: isTagSelected,
        files: isFilesSelected,
        media: false,
      },
      onCompleted: (result) => {
        const searchData = result.search;
        if (
          searchData.length &&
          searchData[0].total > searchFrom + searchData.length
        ) {
          setSearchFrom(searchFrom + searchData.length);
          setCanLoadMore(true);
        } else {
          setCanLoadMore(false);
        }
      },
    });
  };

  useEffect(() => {
    if (me?.id === '114' || me?.id === '281' || me?.id === '196') {
      Sentry.captureMessage(
        'For Debugging TA-4386 Issue : MyChatDetail ::: chatId ' +
          JSON.stringify(chatId) +
          ' message length ::' +
          JSON.stringify(messages.length)
      );
    }
  }, []);

  const debouncedGlobalSearch = useCallback(debounce(globalSearchCall, 1000), [
    search,
    isTagSelected,
    isFilesSelected,
  ]);

  useListRecentProjectsTasksFromQuery({
    chatId: chatId,
    excludeCompleted: true,
    collaboratorsOnly: true,
  });

  useEffect(() => {
    setSearchFrom(0);
    setCanLoadMore(false);
    if (search) {
      debouncedGlobalSearch();
    }
    return () => {
      debouncedGlobalSearch.cancel();
    };
  }, [search]);

  const openEditMessageModal = (message: Message) => {
    setSelectedMessage(message);
    const cacheIdentifier = client?.cache.identify(message);

    if (cacheIdentifier) {
      navigation.navigate('edit-message-highlight-modal', {
        cacheIdentifier,
      });
    }
  };

  const openTagModalSheet = () => {
    setTimeout(() => {
      navigation.navigate('my-tag-modal', { chatId, isFrom: 'Chat' });
    }, 50);
  };

  const closeTagModalSheet = () => {
    setShowTagModalSheet(false);
  };

  const toggleTagModalSheet = useCallback(
    (value: boolean | undefined = undefined) => {
      if (value == undefined) {
        showTagModalSheet ? closeTagModalSheet() : openTagModalSheet();
        return;
      }
      value ? openTagModalSheet() : closeTagModalSheet();
    },
    [showTagModalSheet]
  );

  const debouncedHighlightedGrey = useCallback(
    debounce(() => {
      setRepliedFromMessageId('');
    }, 5000),
    [repliedFromMessageId]
  );

  useEffect(() => {
    if (repliedFromMessageId !== '') {
      debouncedHighlightedGrey();
    }

    return () => {
      debouncedHighlightedGrey.cancel();
    };
  }, [repliedFromMessageId]);

  useEffect(() => {
    if (messageId) {
      setRepliedClicked('true');
      setRepliedFromMessageId(messageId);
    }
  }, [messageId]);

  const scrollToItem = (item: Message | null) => {
    if (!item?.id) return;

    listRef.current?.scrollToItem({
      item,
      animated: true,
    });
  };

  useEffect(() => {
    if (
      filteredMessages.length > 0 &&
      !repliedFromMessageId &&
      repliedClicked
    ) {
      const item = filteredMessages.find((filterMessage) => {
        if (
          typeof repliedClicked !== 'string' &&
          repliedClicked?.replyMessage?.id
        ) {
          return filterMessage?.id === repliedClicked?.replyMessage?.id;
        } else if (messageId) {
          return filterMessage?.id === messageId;
        } else {
          return filterMessage?.id === repliedClicked?.id;
        }
      });

      setRepliedFromMessageId(item?.id || null);

      if (!item?.id) {
        fetchAfterCursor(filteredMessages[filteredMessages.length - 1]?.cursor);
      } else {
        setRepliedClicked('');
        scrollToItem(item);
      }
    }
  }, [repliedFromMessageId, repliedClicked, filteredMessages]);

  const handleScrollToMessage = (message: Message, reply = false) => {
    setRepliedClicked(message);

    const item = filteredMessages.find((filterMessage) =>
      reply
        ? filterMessage?.id === message?.replyMessage?.id
        : filterMessage?.id === message.id
    );

    setRepliedFromMessageId(item?.id || null);
    scrollToItem(item);
  };

  const scrollToReplyMessage = (message: Message) =>
    handleScrollToMessage(message, true);
  const scrollToMessage = (message: Message) => handleScrollToMessage(message);

  useEffect(() => {
    showTagModalSheet
      ? bottomSheetRef.current?.expand()
      : bottomSheetRef.current?.close();
  }, [showTagModalSheet]);

  useEffect(() => {
    if (filter.length > 0) {
      selectedOption(filter.toString());
    } else {
      selectedOption('');
    }
    if (search) {
      const filterData = data?.search.map((item) => {
        return item.record;
      }) as Message[] | undefined;
      if (filterData) {
        if (searchFrom > 0) {
          const newList = [
            ...filteredMessages,
            ...filterData.filter(
              (m) => !filteredMessages.some((m1) => m1.cursor === m.cursor)
            ),
          ];
          setFilteredMessages(newList);
        } else {
          setFilteredMessages(filterData);
        }
      }
    } else {
      setFilteredMessages(messages);
      if (!wasSearch.current) {
        return;
      } else {
        wasSearch.current = false;
      }
    }
  }, [search, filter, messages, data]);

  useEffect(() => {
    debouncedGlobalSearch();
  }, [isFilesSelected, isTagSelected]);

  const selectedOption = (type: string) => {
    switch (type) {
      case 'Tags':
        setTagSelected(true);
        setFilesSelected(false);
        break;
      case 'Files':
        setFilesSelected(true);
        setTagSelected(false);
        break;
      default:
        setFilesSelected(false);
        setTagSelected(false);
        return false;
    }
  };

  const lastChar = (string: string) => {
    return string.charAt(string.length - 1);
  };

  const prevMessage = usePreviousValue({ body });

  useEffect(() => {
    if (!body) return;

    const lastCharOfMessage = lastChar(body);

    if (body.includes('#')) {
      setFilterVal(body.slice(body.lastIndexOf('#') + 1));
    }

    // typed a '#', open the modal
    // do not open if '#' present from a draft (prevMessage undefined)
    if (lastCharOfMessage === '#' && prevMessage) {
      navigation.navigate('my-tag-modal', { chatId });
    } else if (body.trimEnd() !== body) {
      // backspaced into a space, close the modal
      setShowTagModalSheet(false);
    } else if (!body) {
      setShowTagModalSheet(false);
    }
  }, [body]);

  const prevTagsCollection =
    usePreviousValue<TagsCollectionType[]>(tagsCollection);

  useEffect(() => {
    const prevTagsCollectionFlat =
      prevTagsCollection?.flatMap((tagCollection) => {
        return [tagCollection.project, ...tagCollection.tasks];
      }) || [];
    const tagsCollectionFlat =
      tagsCollection?.flatMap((tagCollection) => {
        return [tagCollection.project, ...tagCollection.tasks];
      }) || [];

    if (tagsCollectionFlat.length > prevTagsCollectionFlat.length) {
      setShowTagModalSheet(false);

      setFilterVal('');
    }
  }, [tagsCollection]);

  const closeTagModalOnBack = () => {
    if (!showTagModalSheet) {
      return;
    } else {
      setShowTagModalSheet(false);
    }
  };

  const [showNewMessageIndicator, setShowNewMessageIndicator] = useState(false);
  const [firstNewMsgId, setFirstNewMsgId] = useState('');

  const onViewableMsgsChanged = (items: ViewToken[]) => {
    if (
      items.some(
        (x) => filteredMessages.length && x.item.id == filteredMessages[0].id
      )
    ) {
      setShowNewMessageIndicator(false);
      setFirstNewMsgId('');
    }
  };
  const wasSearch = useRef(false);

  useEffect(() => {
    if (isSearching) {
      wasSearch.current = true;
    }
  }, [isSearching]);

  const scrollToFirstNewMsg = () => {
    setShowNewMessageIndicator(false);
    let xindex = 0;
    filteredMessages.forEach((v, i) => {
      if (v.id == firstNewMsgId) {
        xindex = i;
        setFirstNewMsgId('');
      }
    });
    filteredMessages.length &&
      listRef.current?.scrollToIndex({
        index: xindex,
        animated: false,
      });
  };

  useEffect(() => {
    navigation.addListener('beforeRemove', closeTagModalOnBack);
    return () => {
      navigation.removeListener('beforeRemove', closeTagModalOnBack);
    };
  }, [navigation, showTagModalSheet]);

  const onFormSubmit = () => {
    filteredMessages.length > 0 &&
      listRef.current?.scrollToIndex({
        index: 0,
        animated: false,
      });
  };

  const showNewMessageIndicatorHandler = (newMsgId: string) => {
    if (!isSearching) {
      if (firstNewMsgId === '') {
        setFirstNewMsgId(newMsgId);
      }
      setShowNewMessageIndicator(true);
    } else {
      setShowNewMessageIndicator(false);
    }
  };

  const fetchBeforeCursorHandler = (cursor: string) => {
    fetchBeforeCursor(cursor);
  };

  const onPressChatMessage = (message: Message) => {
    scrollToReplyMessage(message);
  };

  const onMessagePress = (message: Message) => {
    search && setSearch('');
    isSearching && setIsSearching(false);
    scrollToMessage(message);
  };

  const clearNotificationsByChatId = async () => {
    // get current notifications and clear if chat id is preset
    const presentNotifications = await getPresentedNotificationsAsync();
    let badgeCount = 0;
    for (let i = 0; i < presentNotifications.length; i++) {
      const n = presentNotifications[i];
      if (
        n.request.content.data.url &&
        urlMatchesId(n.request.content.data.url as string, chatId)
      ) {
        dismissNotificationAsync(n.request.identifier);
        badgeCount++;
      }
    }
    if (badgeCount > 0) {
      const cnt = await getBadgeCountAsync();
      setBadgeCountAsync(cnt - badgeCount);
    }
  };

  useEffect(() => {
    if (chatId) {
      clearNotificationsByChatId();
    }
  }, [chatId]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    wrapper: {
      flex: 1,
      paddingBottom: insets.bottom ? theme.spacing.m : undefined,
    },
    newMessageContainer: {
      width: 150,
      position: 'absolute',
      bottom: inputHeight + 11,
      right: 17,
    },
    newMessageBox: {
      borderRadius: 21,
      height: 35,
      shadowColor: 'black',
      shadowOpacity: 0.12,
      shadowOffset: { width: 2, height: 2 },
      shadowRadius: 12,
    },
    background: {
      opacity: 1,
      position: 'absolute',
      bottom: 0,
      top: 0,
      right: 0,
      left: 0,
    },
  });

  return (
    <>
      {!doNotShowTutorial && <TutorialModal messages={messages} />}
      <Box style={styles.wrapper}>
        <ChatHeader chatId={chatId} />
        <KeyboardAvoidingView
          style={{ flex: 1 }} // TODO: Move message list up when keyboard appears
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
          {!loading && repliedClicked && <ActivityIndicator />}
          <ChatMessageList
            chatId={chatId}
            hasNextPage={isSearching ? canLoadMore : hasNextPage}
            firstNewMsgId={firstNewMsgId}
            showNewMessageIndicator={showNewMessageIndicatorHandler}
            onViewableMsgItemsChanged={onViewableMsgsChanged}
            list={filteredMessages}
            filterVal={search}
            ref={listRef}
            fetchBeforeCursor={fetchBeforeCursorHandler}
            fetchAfterCursor={(cursor: string) => {
              if (isSearching && searchFrom > 0) {
                globalSearchCall();
              } else {
                fetchAfterCursor(cursor);
              }
            }}
            loading={loading || loadingSearch}
            refreshing={refreshing}
            onRefresh={onRefresh}
            onLongPress={openEditMessageModal}
            onPress={onPressChatMessage}
            onMessagePress={onMessagePress}
            highlightedToGrey01MessageId={repliedFromMessageId}
            scrollToCursor={messageCursor}
            onEditTagPress={() => {
              callback();
              if (editTagLog) {
                Sentry.captureMessage(
                  'For debugging TA-6256 Edit Tag Log Open flag status :::  ',
                  JSON.stringify(isEditTagModalOpen) +
                    '  for the userid : ' +
                    me?.id
                );
              }
            }}
            onForwardPress={callback}
          />

          {showNewMessageIndicator && (
            <TouchableOpacity
              style={styles.newMessageContainer}
              activeOpacity={0.2}
              onPress={scrollToFirstNewMsg}>
              <Box
                borderColor='grey02'
                flexDirection='row'
                style={styles.newMessageBox}
                alignItems='center'
                justifyContent='center'
                pointerEvents='none'
                backgroundColor='grey01'>
                <Text variant='labelSmall' marginLeft='xxs'>
                  New Message
                </Text>
                <Icon
                  name='ArrowDown'
                  variant='m'
                  color='textPrimary'
                  marginTop='xxxs'
                />
              </Box>
            </TouchableOpacity>
          )}
          {chat?.settings?.archivedAt && <Box marginTop='m' />}
          {!chat?.settings?.archivedAt && !isSearching && (
            <>
              <Box flex={1} style={styles.background} pointerEvents='none' />
              <Box
                onLayout={(e) => {
                  setInputHeight(e.nativeEvent.layout.height);
                }}>
                {chatData?.getChat.friendRequestAccepted && (
                  <MyChatForm
                    showKeyboardFocus={showKeyboardFocus}
                    isGroupChat={chat?.isGroupChat}
                    chatInputHeight={inputHeight}
                    chatId={chatId}
                    chatData={chatData}
                    toggleTagModalSheet={toggleTagModalSheet}
                    onTagChange={setFilterVal}
                    disableInput={!!chat?.settings?.archivedAt || chat?.leaveOn}
                    showLeaveMessage={chat?.leaveOn}
                    onFormSubmit={onFormSubmit}
                  />
                )}
              </Box>
            </>
          )}
        </KeyboardAvoidingView>
      </Box>
    </>
  );
};

export const MyChatDetail = memo(MyChatDetails);
