import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal, TouchableOpacity } from 'react-native';

import { DateRange } from '@components/DateRangePicker/DateRangePickerModal';
import { ContactType } from '@components/Invite/Contact';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { PopupTaskFilterAssignee } from '@components/Web/PopupTaskFilterAssignee.web';
import { PopupTaskFilterDuedate } from '@components/Web/PopupTaskFilterDuedate.web';
import { PopupTaskFilterPriority } from '@components/Web/PopupTaskFilterPriority.web';
import { PopupTaskFilterProject } from '@components/Web/PopupTaskFilterProject.web';
import { PopupTaskFilterStatus } from '@components/Web/PopupTaskFilterStatus.web';
import { Project, TaskPriority } from '@graphql/generated';
import { useOutsideClick } from '@hooks/useOutsideClick.web';

type PositionTuple = ['left' | 'right', 'top' | 'bottom'];

type Position = PositionTuple | 'top' | 'bottom' | 'left' | 'right';

type Props = Omit<ComponentProps<typeof TouchableOpacity>, 'onPress'> & {
  title: string;
  onDismiss?: any;

  onSelectPriority?: (item: TaskPriority[]) => void;
  selectedPriorities?: TaskPriority[];

  onSelectAssignee?: (ids: ContactType[]) => void;
  selectedAssignee?: ContactType[];
  initialAssignees?: ContactType[];

  onSelectDuedate?: (dr: DateRange) => void;
  selectedDuedate?: DateRange;

  selectedProjects?: Project[];
  onSelectProjects?: (projects: Project[]) => void;

  selectedStatus?: string;
  onSelectStatus?: (status: string) => void;

  boxProps: Partial<{
    position: Position;
    offset: [number, number] | number;
  }>;
};

export const PopupTaskButtonWeb = ({
  onDismiss,
  boxProps: { position, offset, ...popupPropsRest },
  title: xtitle,

  selectedProjects = [],
  onSelectProjects,
  selectedPriorities = [],
  onSelectPriority,

  initialAssignees,
  selectedAssignee = [],
  onSelectAssignee,

  selectedStatus = '',
  onSelectStatus,

  selectedDuedate,
  onSelectDuedate,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const togglePopup = () => setVisible((v) => !v);

  const ref2 = useRef<HTMLDivElement>();

  const isEventPointInModal = (event: MouseEvent) => {
    const rect = ref2?.current?.getBoundingClientRect();
    return (
      rect &&
      event.x > rect.x &&
      event.x < rect.x + rect.width &&
      event.y > rect.y &&
      event.y < rect.y + rect.height
    );
  };
  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (!isEventPointInModal(event)) {
      setVisible(false);
    }
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  // Store the natural dimensions of the popup container
  const [layout, setLayout] = useState<
    { width: number; height: number } | undefined
  >();

  const [mouseOver, setMouseOver] = useState(false);

  const adjustBounds = (
    position: Props['boxProps']['position'] = 'bottom',
    offset: Props['boxProps']['offset'] = 0,
    client: DOMRect,
    popupLayout: { width: number; height: number }
  ) => {
    const [offsetLeftRight, offsetTopBottom] =
      typeof offset === 'number' ? [offset, offset] : offset;

    const normalizedPosition = (typeof position !== 'string' && position) ||
      ((position === 'left' || position === 'right') && [position, 'bottom']) ||
      ((position === 'top' || position === 'bottom') && ['left', position]) || [
        'left',
        'bottom',
      ];

    const [leftRight, topBottom] = normalizedPosition;
    const bounds = {
      top:
        topBottom === 'top'
          ? client.top - popupLayout.height - offsetTopBottom
          : client.top + client.height + offsetTopBottom,
      left:
        leftRight === 'left'
          ? client.left + client.width - popupLayout.width - offsetLeftRight
          : client.left + offsetLeftRight,
    };

    return bounds;
  };

  const adjustedBounds =
    ref.current &&
    layout &&
    adjustBounds(position, offset, ref.current.getBoundingClientRect(), layout);

  const onModalDismiss = () => {
    onDismiss?.();
    setVisible(false);
  };

  const backgroundColor1 = () => {
    if (mouseOver) return 'grey01';
    return visible ? 'grey02' : 'white';
  };

  return (
    <Box ref={ref}>
      <TouchableOpacity
        accessibilityLabel={xtitle}
        onPress={() => {
          togglePopup();
        }}>
        <Box
          height={32}
          flexDirection='row'
          borderWidth={1}
          py='xs'
          px={onSelectStatus ? 'none' : 'm'}
          style={onSelectStatus && { width: 136, paddingHorizontal: 7 }}
          onMouseEnter={() => {
            setMouseOver(true);
          }}
          onMouseLeave={() => {
            setMouseOver(false);
          }}
          backgroundColor={backgroundColor1()}
          borderColor='grey02'
          borderRadius='xs'
          alignItems='center'
          justifyContent='center'>
          <Text marginRight='xs' variant='webBodySecondary' color='textPrimary'>
            {xtitle}
          </Text>
          <Icon variant='s' name='ChevronDown' color='textPrimary' />
        </Box>
      </TouchableOpacity>

      {!layout && (
        <Box
          left='-200vw'
          position='absolute'
          visible={!layout}
          onLayout={({ nativeEvent: { layout } }) => {
            setLayout((curr) => curr ?? layout);
          }}>
          {onSelectStatus && (
            <PopupTaskFilterStatus
              {...popupPropsRest}
              selectedStatus={selectedStatus}
            />
          )}
          {onSelectPriority && (
            <PopupTaskFilterPriority
              {...popupPropsRest}
              selecteds={selectedPriorities}
            />
          )}
          {onSelectAssignee && (
            <PopupTaskFilterAssignee
              initialAssignees={initialAssignees}
              selecteds={selectedAssignee}
              {...popupPropsRest}
            />
          )}
          {onSelectDuedate && (
            <PopupTaskFilterDuedate
              selecteds={selectedDuedate}
              {...popupPropsRest}
            />
          )}
          {onSelectProjects && (
            <PopupTaskFilterProject
              selecteds={selectedProjects}
              {...popupPropsRest}
            />
          )}
        </Box>
      )}
      <Modal
        visible={visible}
        transparent
        onDismiss={() => onModalDismiss()}
        onRequestClose={() => onModalDismiss()}>
        <Box ref={ref2} style={{ ...adjustedBounds }} width={layout?.width}>
          <Box flexShrink={1}>
            {onSelectStatus && (
              <PopupTaskFilterStatus
                {...popupPropsRest}
                onSelectStatus={(item) => {
                  onSelectStatus && onSelectStatus(item);
                }}
                selectedStatus={selectedStatus}
              />
            )}
            {onSelectPriority && (
              <PopupTaskFilterPriority
                {...popupPropsRest}
                onSelectPriority={(item) => {
                  onSelectPriority && onSelectPriority(item);
                }}
                selecteds={selectedPriorities}
              />
            )}
            {onSelectAssignee && (
              <PopupTaskFilterAssignee
                initialAssignees={initialAssignees}
                selecteds={selectedAssignee}
                {...popupPropsRest}
                onSelectAssignee={(ids) => {
                  onSelectAssignee && onSelectAssignee(ids);
                }}
              />
            )}
            {onSelectDuedate && (
              <PopupTaskFilterDuedate
                selecteds={selectedDuedate}
                {...popupPropsRest}
                onSelectDueDate={(ids) => {
                  onSelectDuedate && onSelectDuedate(ids);
                  onModalDismiss();
                }}
              />
            )}
            {onSelectProjects && (
              <PopupTaskFilterProject
                selecteds={selectedProjects}
                {...popupPropsRest}
                onSelectProject={(ids) => {
                  onSelectProjects && onSelectProjects(ids);
                }}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
