import { useState } from 'react';

import { useListChatRelatedProjectsQuery } from '@graphql/generated';
import { ProjectTagType } from '@src/types/project';
/**
 * Retrieves a list of project tags
 */

interface ListChatProjectsTasksQueryProps {
  archivesOnly?: boolean;
  onCompleted?: () => void;
  onError?: () => void;
  first?: number;
  chatId?: string;
}

export const useListChatProjectsTasksFromQuery = (
  props?: ListChatProjectsTasksQueryProps
) => {
  const {
    onCompleted = undefined,
    onError = undefined,
    first = undefined,
    chatId = undefined,
  } = props || {};

  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, error, refetch, fetchMore } =
    useListChatRelatedProjectsQuery({
      variables: {
        first,
        chatId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setRefreshing(false);
        onCompleted && onCompleted();
      },
      onError: () => {
        setRefreshing(false);
        onError && onError();
      },
    });

  const { listChatRelatedProjects } = data || {
    listChatRelatedProjects: undefined,
  };

  const fetchMoreFromCursor = () => {
    if (!projects || !pageInfo?.hasNextPage || loading) return;
    const cursor = pageInfo?.endCursor;
    fetchMore({
      variables: {
        after: cursor,
        first,
        chatId,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.listChatRelatedProjects.edges;
        const pageInfo = fetchMoreResult.listChatRelatedProjects.pageInfo;
        return newEdges?.length
          ? {
              listChatRelatedProjects: {
                __typename: previousResult.listChatRelatedProjects.__typename,
                edges: [
                  ...previousResult.listChatRelatedProjects.edges,
                  ...newEdges,
                ],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  const { edges = [], pageInfo } = listChatRelatedProjects || { edges: [] };
  // TODO: This needs to be more properly typed to the returned graphql value.
  const projects =
    (edges || []).reduce((acc, curr) => {
      if (curr?.node !== undefined) {
        return [...acc, curr.node as ProjectTagType];
      } else {
        return acc;
      }
    }, [] as ProjectTagType[]) || [];

  // pagination returns duplicate projects when projects are named the same resulting in duplicate key errors
  // can be removed when pagination is fixed
  const projectIds = projects.map((t) => t.id);
  const filterOutDuplicates = projects.filter((p, index) => {
    return !projectIds.includes(p.id.toString(), index + 1);
  });

  return {
    projectsTasks: filterOutDuplicates,
    loading,
    error,
    pageInfo,
    refetch,
    fetchMore,
    fetchMoreFromCursor,
    refreshing,
    setRefreshing,
  };
};
