import dayjs from 'dayjs';
import React, { useState } from 'react';

import { DateRangePicker2 } from '@components/DateRangePicker/DateRangePicker2';
import { DateRange } from '@components/DateRangePicker/DateRangePickerModal';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import { useExportCheckinsMutation } from '@graphql/generated';

interface ExportTimesheetModalProps {
  visible: boolean;
  onClose: () => void;
  projectId: string;
}

const downloadFileFromUrl = (url: string, filename: string) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('An error happened.'));
};

const ExportTimesheetModal: React.FC<ExportTimesheetModalProps> = ({
  visible,
  onClose,
  projectId,
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    start: undefined,
    end: undefined,
  });

  const [exportCheckinMutation] = useExportCheckinsMutation();

  const onExport = async (d1: Date | undefined, d2: Date | undefined) => {
    setSelectedDateRange({
      start: d1 ? dayjs(d1) : undefined,
      end: d2 ? dayjs(d2) : undefined,
    });
    const response = await exportCheckinMutation({
      variables: {
        projectId: projectId,
        from: d1,
        to: d2,
      },
    });
    response.data?.exportCheckins?.url &&
      downloadFileFromUrl(
        response.data.exportCheckins.url,
        response.data.exportCheckins.filename
      );
  };

  return (
    <WebModal
      accessibilityLabel='activity-log-export-modal'
      marginTop='m'
      visible={visible}
      width={640}
      height={640}
      title='Timesheet Export'
      titleColor='textPrimary'
      marginHorizontal='l'
      onClose={onClose}>
      <Box flex={1} mx='l'>
        <Box marginTop='m'>
          <Text variant='webBodySecondary' color='textSecondary'>
            Export your time logs for the selected date range. Choose your start
            and end dates, then click Export to download your data.
          </Text>
        </Box>

        <DateRangePicker2
          onSelect={onExport}
          start={selectedDateRange.start?.toDate()}
          end={selectedDateRange.end?.toDate()}
          confirmButtonText='Export'
        />
      </Box>
    </WebModal>
  );
};

export default ExportTimesheetModal;
