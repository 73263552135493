import { FlashList } from '@shopify/flash-list';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, ShadowBox, Text } from '@components/Restyle';
import { TaskPriorityBadge } from '@components/TaskCard/TaskPriorityBadge';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import { TaskPriority } from '@graphql/generated';
import theme from '@themes/theme';

export type PopupTaskFilterPriorityProps = {
  onSelectPriority?: (o: TaskPriority[]) => void;
  selecteds: TaskPriority[];
};
const ESTIMATED_CONTACT_ITEM_SIZE = 40;

export const PopupTaskFilterPriority: React.FC<
  PopupTaskFilterPriorityProps
> = ({ onSelectPriority, selecteds }) => {
  const list: TaskPriority[] = [
    TaskPriority.High,
    TaskPriority.Medium,
    TaskPriority.Low,
  ];

  const [hoveredId, setHoveredId] = useState('');
  const doSelect = (item: TaskPriority) => {
    const isIn = selecteds.some((t) => t === item);
    const updatedSelected = isIn
      ? selecteds.filter((c) => c !== item)
      : [...selecteds, item];
    const sorted = list.filter((r) => updatedSelected.some((t) => t === r));

    onSelectPriority?.(sorted);
  };
  return (
    <ShadowBox
      flex={1}
      width={150}
      height={148}
      alignItems='center'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <>
        <Box flex={1} style={{ maxHeight: 148, width: 150 }} py='m'>
          <FlashList
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            ListFooterComponent={() => <Box marginBottom='m' />}
            data={list}
            renderItem={({ item, index }) => {
              return (
                <Box
                  style={{
                    paddingVertical: 9,
                    backgroundColor:
                      hoveredId !== '' && hoveredId == index.toString()
                        ? theme.colors.grey01
                        : theme.colors.white,
                  }}
                  onMouseEnter={() => {
                    setHoveredId(index.toString());
                  }}
                  onMouseLeave={() => {
                    setHoveredId('');
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      doSelect(item);
                    }}
                    accessibilityLabel={item.toString()}>
                    <Box
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='space-between'>
                      <Box ml='m' justifyContent='center' marginRight='xs'>
                        <CheckBoxButton
                          checked={selecteds.some((t) => t === item)}
                        />
                      </Box>

                      <Box mr='m' flex={1}>
                        {item !== TaskPriority.Low && (
                          <TaskPriorityBadge priority={item} />
                        )}
                        {item === TaskPriority.Low && (
                          <Text
                            variant='webBodySecondary'
                            color='textPrimary'
                            numberOfLines={1}>
                            No Priority
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </TouchableOpacity>
                </Box>
              );
            }}
            estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
          />
        </Box>
      </>
    </ShadowBox>
  );
};
