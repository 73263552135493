/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  KeyboardAvoidingView,
  SectionList,
  TouchableOpacity,
} from 'react-native';

import { ProjectTagList } from '@components/Chat/ProjectTagList';
import { TaskTagList } from '@components/Chat/TaskTagList.web';
import EmptyStateNoProjectTag from '@components/EmptyState/EmptyStateNoProjectTag';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import {
  SearchableResult,
  Chat,
  useSearchTagsLazyQuery,
  Project,
  useCompleteTaskMutation,
  ListTasksDocument,
  ListMessagesDocument,
  ListProjectsTasksDocument,
  ListRecentProjectsTasksDocument,
} from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import { useListProjectsTasksFromQuery } from '@hooks/useListProjectsTasksFromQuery';
import { useListRecentProjectsTasksFromQuery } from '@hooks/useListProjectsTasksRecentFromQuery';
import useMe from '@hooks/useMe';
import { Search, SearchResultType } from '@root/types';
import { ProjectTagType } from '@src/types/project';
import { TaskTagType } from '@src/types/task';
import theme from '@themes/theme';
import { isProjectSearchResult, isTaskSearchResult } from '@utils/typeGuards';

import { MyTagModalHeader } from './MyTagModalHeader.web';

const DEBOUNCE = 500;

interface MyTagModalProps {
  bottomInset?: number;
  filterVal: string;
  chatId: Chat['id'];
  isFromChatCompleteTask?: boolean;
}

const PER_PAGE_COUNT = 10;

export const MyTagModal: React.FC<MyTagModalProps> = ({
  chatId,
  filterVal: initFilter,
  isFromChatCompleteTask = false,
}) => {
  const { t } = useTranslation();
  const {
    setIsTagModalOpen,
    setTagsCollection,
    getTagsCollection,
    setIsCompletingTask,
    isConfirmCompletingTask,
    setIsConfirmCompletingTask,
  } = useChatInput();
  const { me } = useMe();

  const [filterVal, setFilterVal] = useState(initFilter);
  const [loading, setLoading] = useState(false);

  const tagsCollection = getTagsCollection(chatId);

  const [errorMsg, setErrorMsg] = useState('');
  const [completeTaskId, setCompleteTaskId] = useState<string | undefined>();
  const [completeTask] = useCompleteTaskMutation({
    onCompleted: () => {
      setIsCompletingTask(false);
    },
    onError: (error) => {
      setErrorMsg('Error: ' + error.message);
    },
    refetchQueries: [
      { query: ListTasksDocument },
      { query: ListMessagesDocument, variables: { chatId } },
      {
        query: ListProjectsTasksDocument,
        variables: { chatId, excludeCompleted: true },
      },
      {
        query: ListRecentProjectsTasksDocument,
        variables: { chatId, excludeCompleted: true },
      },
      'listTasks',
    ],
  });

  const updateTagsCollectionProject = (project: ProjectTagType) => {
    if (!me) return;
    const newTagsColl = [
      ...tagsCollection,
      {
        tasks: [],
        project: { ...project },
        author: me,
      },
    ];
    setTagsCollection(chatId, newTagsColl);
    setIsTagModalOpen(false);
  };

  const updateTagsCollectionTasks = (task: TaskTagType) => {
    const collectionToUpdate = tagsCollection?.findIndex(
      (t) => t.project.id === task.project.id
    );

    if (collectionToUpdate !== undefined && collectionToUpdate !== -1) {
      // put task into correct coll based on project

      if (
        !tagsCollection?.[collectionToUpdate].tasks.find(
          (t) => t.id === task.id
        )
      ) {
        const newTagsColl = [...(tagsCollection ?? [])];
        newTagsColl[collectionToUpdate] = {
          ...newTagsColl[collectionToUpdate],
          tasks: [...newTagsColl[collectionToUpdate].tasks, task],
        };

        setTagsCollection(chatId, newTagsColl);
        setIsTagModalOpen(false);
      }
    } else if (me) {
      // new tags coll
      const newTagsColl = [
        ...(tagsCollection ?? []),
        {
          tasks: [task],
          project: task.project,
          author: me,
        },
      ];
      setTagsCollection(chatId, newTagsColl);
      setIsTagModalOpen(false);
    }
  };

  //All project and tasks api

  const {
    projectsTasks: allProjectsTasks,
    refetch: refetchProjects,
    refreshing: refreshingProjects,
    setRefreshing: setRefreshingProjects,
    fetchMoreFromCursor: fetchMoreProjectsFromCursor,
    loading: allProjectsTasksLoading,
    pageInfo,
  } = useListProjectsTasksFromQuery({
    fetchPolicy: isFromChatCompleteTask ? 'network-only' : undefined,
    collaboratorsOnly: isFromChatCompleteTask,
    excludeCompleted: isFromChatCompleteTask,
    first: isFromChatCompleteTask ? PER_PAGE_COUNT * 3 : PER_PAGE_COUNT,
    chatId: chatId,
  });
  //recent project and tasks

  const {
    recentProjectsTasks: recentProjectTasks = [],
    loading: recentProjectsTasksLoading,
  } = useListRecentProjectsTasksFromQuery({
    fetchPolicy: isFromChatCompleteTask ? 'network-only' : undefined,
    collaboratorsOnly: isFromChatCompleteTask,
    excludeCompleted: isFromChatCompleteTask,
    chatId: chatId,
  });

  const projectsTasksData = [
    { title: 'Recent Project & Tasks', data: recentProjectTasks },
    {
      title: 'All Projects & Tasks',
      data: !isFromChatCompleteTask
        ? allProjectsTasks
        : allProjectsTasks?.filter((t) => (t.tasks?.length ?? 0) > 0),
    },
  ];

  const [globalSearch, { data, loading: loadingSearch }] =
    useSearchTagsLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];
  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
    contacts: [],
  };

  const results =
    searchData.reduce<SearchResultType>((previous, result) => {
      if (isProjectSearchResult(result))
        return { ...previous, projects: [...previous.projects, result] };
      if (isTaskSearchResult(result))
        return { ...previous, tasks: [...previous.tasks, result] };

      return previous;
    }, initialResults) || initialResults;

  const searchResults = results.projects
    ? results.projects
        .map((r) => r.record)
        .filter(
          (p) => !isFromChatCompleteTask || (p.tasksTagList?.length ?? 0) > 0
        )
    : [];

  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: filterVal,
        size: 100,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: true,
        includeTasks: false,
        includeChats: false,
        includeTasksTags: true,
        collaboratorsOnly: isFromChatCompleteTask,
        includeCompletedTask: !isFromChatCompleteTask,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [filterVal]
  );

  useEffect(() => {
    if (filterVal?.length) {
      setLoading(true);
      debouncedGlobalSearch();
    }
    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [filterVal]);

  const onPressProject = (newProject: ProjectTagType, isMemberVal: boolean) => {
    if (!isFromChatCompleteTask) {
      isMemberVal && updateTagsCollectionProject(newProject);
    }
  };

  const handleChatCompleteTask = (newTask: TaskTagType) => {
    setCompleteTaskId(newTask.id);
    setIsConfirmCompletingTask(true);
  };

  const renderItem = ({ item, section }: { item: any; section: any }) => {
    const isMember = item.members?.filter((i) => i.user.id === me?.id);
    if (section.title === 'Recent Project & Tasks') {
      const isSelectedTask = tagsCollection?.find((tag) =>
        tag.tasks.find((task) => task.id === item.id)
      );
      return (
        <Box>
          {!item.projectId && (
            <ProjectTagList
              key={item.id}
              loading={false}
              filterVal={filterVal}
              projectTags={[item]}
              isMember={isMember.length > 0}
              isFrom='Chat'
              onSelect={(newProject: ProjectTagType) => {
                onPressProject(newProject, isMember.length > 0);
              }}
            />
          )}

          {item.projectId && !isSelectedTask && (
            <TaskTagList
              key={item.id}
              filterVal={filterVal}
              taskTags={[item]}
              onSelect={(newTask) => {
                if (!isFromChatCompleteTask) {
                  updateTagsCollectionTasks(newTask);
                } else {
                  handleChatCompleteTask(newTask);
                }
              }}
              loading={false}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Box>
          <ProjectTagList
            key={item.id}
            refreshing={refreshingProjects}
            loading={false}
            onRefresh={() => {
              setRefreshingProjects(true);
              refetchProjects();
            }}
            isMember={isMember}
            filterVal={filterVal}
            projectTags={[item]}
            isFrom='Chat'
            onSelect={(newProject: ProjectTagType) => {
              onPressProject(newProject, isMember.length > 0);
            }}
          />
          {item?.tasks?.map((taskObj) => {
            const isSelectedTask = tagsCollection?.find((tag) =>
              tag.tasks.find((task) => task.id === taskObj.id)
            );
            if (isSelectedTask) return;
            return (
              <TaskTagList
                key={taskObj.id}
                filterVal={filterVal}
                taskTags={[taskObj]}
                onSelect={(newTask) => {
                  if (!isFromChatCompleteTask) {
                    updateTagsCollectionTasks(newTask);
                  } else {
                    handleChatCompleteTask(newTask);
                  }
                }}
                loading={false}
              />
            );
          })}
        </Box>
      );
    }
  };

  const sectionHeader = ({ section: { title, data } }) => {
    return (
      data &&
      data.length > 0 && (
        <Box padding='xs' backgroundColor='white'>
          <Text color='grey04' variant='bodySecondary' marginLeft='xs'>
            {title}
          </Text>
        </Box>
      )
    );
  };

  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };
  const completeTaskConfirmModal = () => {
    return (
      <WebModal
        accessibilityLabel='Complete Task'
        visible={isConfirmCompletingTask}
        width={483}
        height={200}
        title='Complete Task'
        titleVariant='labelLarge'
        titleColor='textPrimary'
        marginTop='m'
        marginHorizontal='l'
        onClose={() => setIsConfirmCompletingTask(false)}>
        <Box flex={1} mt='l' mx='l'>
          <Text variant='webBodySecondary' color='textPrimary'>
            Are you sure you want to complete this task? Once completed, the
            task will be moved to the completed section and will be closed.
          </Text>
          <Box
            mt='l'
            mb='m'
            flexDirection='row'
            alignItems='flex-end'
            justifyContent='flex-end'>
            <TouchableOpacity onPress={() => setIsConfirmCompletingTask(false)}>
              <Box
                style={{
                  width: 125,
                  marginRight: theme.spacing.xs,
                  borderColor: 'black',
                  borderRadius: theme.borderRadii.xs,
                  borderWidth: 1,
                }}
                height={48}
                justifyContent='center'
                alignContent='center'
                alignItems='center'
                accessibilityLabel={t('models:teams.removeTeamMember.assign')}>
                <Text variant='labelSmall' color='black'>
                  {t('shared:cancel')}
                </Text>
              </Box>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                setIsConfirmCompletingTask(false);
                if (completeTaskId) {
                  completeTask({
                    variables: {
                      id: completeTaskId,
                      chatId,
                    },
                  });
                }
              }}>
              <Box
                backgroundColor='black'
                borderRadius='xs'
                width={125}
                height={48}
                justifyContent='center'
                alignContent='center'
                alignItems='center'
                accessibilityLabel='Complete'>
                <Text variant='labelSmall' color='white'>
                  Complete
                </Text>
              </Box>
            </TouchableOpacity>
          </Box>
        </Box>
      </WebModal>
    );
  };
  const renderSearch = (item: Project) => {
    const isMember = item.members?.filter((i) => i.user.id === me?.id);
    return (
      <Box>
        <ProjectTagList
          key={item.id}
          refreshing={refreshingProjects}
          loading={false}
          isMember={isMember?.length > 0}
          onRefresh={() => {
            setRefreshingProjects(true);
            refetchProjects();
          }}
          filterVal={filterVal}
          projectTags={[item]}
          isFrom='Chat'
          onSelect={(newProject: ProjectTagType) => {
            onPressProject(newProject, isMember.length > 0);
          }}
        />

        {item?.tasksTagList?.map((taskObj) => {
          if (taskObj) {
            const isSelectedTask = tagsCollection?.find((tag) =>
              tag.tasks.find((task) => task.id === taskObj.id)
            );
            if (isSelectedTask) return;
            return (
              <TaskTagList
                key={taskObj.id}
                filterVal={filterVal}
                taskTags={[taskObj]}
                onSelect={(newTask) => {
                  if (!isFromChatCompleteTask) {
                    updateTagsCollectionTasks(newTask);
                  } else {
                    handleChatCompleteTask(newTask);
                  }
                }}
                loading={false}
              />
            );
          }
        })}
      </Box>
    );
  };

  const showEmptyState =
    !isFromChatCompleteTask &&
    projectsTasksData.every((i) => i.data?.length === 0);

  return (
    <>
      {errorMsg !== '' && (
        <ConfirmModal
          showModal={errorMsg !== ''}
          isAlert={true}
          onPress={() => {
            setErrorMsg('');
            setIsCompletingTask(false);
          }}
          buttonText='OK'
          title='Complete Task'
          message={errorMsg}
        />
      )}
      {isConfirmCompletingTask && completeTaskConfirmModal()}
      <ShadowBox
        accessibilityLabel='Tag modal'
        backgroundColor='white'
        flex={1}
        borderRadius='m'
        borderWidth={1}
        borderColor='grey01'>
        <MyTagModalHeader
          onCancelPress={() => setIsCompletingTask(false)}
          isTagSearch={true}
          filterVal={filterVal}
          onChangeFilterVal={(newFilterVal) => setFilterVal(newFilterVal)}
        />
        <KeyboardAvoidingView style={{ flex: 1 }}>
          {showEmptyState &&
            !allProjectsTasksLoading &&
            !recentProjectsTasksLoading &&
            !filterVal && (
              <Box
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: theme.spacing.listFooter,
                }}>
                <EmptyStateNoProjectTag />
              </Box>
            )}

          {!showEmptyState && (
            <>
              {filterVal.length > 0 && loadingSearch && (
                <ActivityIndicatorLoading />
              )}
              {((filterVal.length > 0 &&
                !loading &&
                !loadingSearch &&
                searchResults.length === 0) ||
                (isFromChatCompleteTask &&
                  !recentProjectsTasksLoading &&
                  !allProjectsTasksLoading &&
                  projectsTasksData.every((i) => i.data?.length === 0))) && (
                <Box
                  flex={1}
                  alignItems='center'
                  justifyContent='center'
                  alignContent='center'>
                  <Box
                    flex={1}
                    justifyContent='center'
                    alignItems='center'
                    alignContent='center'
                    flexDirection='column'
                    alignSelf='center'
                    paddingBottom='xxl'
                    paddingTop='xxl'>
                    <Icon name='NoSearchTask' width={141} height={105}></Icon>
                    <Text
                      variant='heading1'
                      textAlign='center'
                      paddingBottom='xs'
                      color='onSurfaceSecondary'>
                      {t('shared:noResults')}
                    </Text>
                    <Text
                      variant='body'
                      textAlign='center'
                      color='onSurfaceSecondary'>
                      {t('models:tasks.filter.activeTasksEmpty')}
                    </Text>
                  </Box>
                </Box>
              )}
              {allProjectsTasksLoading && recentProjectsTasksLoading && (
                <ActivityIndicatorLoading />
              )}
              {projectsTasksData.length !== 0 && filterVal.length === 0 && (
                <SectionList
                  showsVerticalScrollIndicator={true}
                  sections={projectsTasksData}
                  keyboardShouldPersistTaps='always'
                  renderItem={renderItem}
                  renderSectionHeader={sectionHeader}
                  initialNumToRender={10}
                  ListFooterComponent={() =>
                    renderFooterUI(pageInfo?.hasNextPage || false)
                  }
                  onEndReached={() => {
                    fetchMoreProjectsFromCursor();
                  }}
                />
              )}

              {filterVal.length > 0 && (
                <FlatList
                  data={searchResults}
                  keyboardShouldPersistTaps='always'
                  renderItem={({ item }) => {
                    return <>{renderSearch(item)}</>;
                  }}
                />
              )}
            </>
          )}
        </KeyboardAvoidingView>
      </ShadowBox>
    </>
  );
};
