import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import RelativeTime from 'dayjs/plugin/relativeTime';
import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import 'intl-pluralrules';
import { initReactI18next } from 'react-i18next';

import enFormat from './translations/en-format.json';
import enForms from './translations/en-forms.json';
import enModels from './translations/en-models.json';
import enShared from './translations/en-shared.json';

dayjs.extend(RelativeTime);
dayjs.extend(AdvancedFormat);
dayjs.extend(IsBetween);

const resources = {
  en: {
    shared: enShared,
    forms: enForms,
    models: enModels,
    format: enFormat,
  },
};

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources,
    fallbackLng: 'en',
    ns: ['shared', 'forms', 'models', 'format'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already saves from xss
    },
  });

i18n.services.formatter?.add('phoneNumberFormat', (value, _lng, _options) => {
  let scrubbedValue = value.toString()?.replace(/\D/g, '');

  if (scrubbedValue.length === 10) {
    scrubbedValue = 1 + scrubbedValue;
  }
  if (scrubbedValue.length === 11) {
    return (
      '+' +
      scrubbedValue.substring(0, 1) +
      ' (' +
      scrubbedValue.substring(1, 4) +
      ') ' +
      scrubbedValue.substring(4, 7) +
      '-' +
      scrubbedValue.substring(7)
    );
  }
  return scrubbedValue;
});

i18n.services.formatter?.add('fileSize', (value) => {
  console.log('file size thingy');
  const decimals = 2;
  const bytes = value;
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
});

i18n.services.formatter?.add('todayFormat', (value) => {
  return dayjs(value).format('DD MMMM, YYYY');
});

i18n.services.formatter?.add('timeAgoChatList', (value) => {
  if (dayjs().isSame(dayjs(value), 'minute')) {
    // within 1 min
    return 'a few seconds ago';
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, 'hour'))) {
    // within 59 min
    return dayjs().to(dayjs(value));
  }

  if (dayjs().isSame(dayjs(value), 'day')) {
    // same day
    return 'Today';
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, 'day'))) {
    // within 24hr
    return 'Yesterday';
  }

  return dayjs(value).format('MM/DD/YYYY');
});

i18n.services.formatter?.add('timeAgoMessageList', (value) => {
  if (dayjs().isSame(dayjs(value), 'day')) {
    // same day
    return 'Today';
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, 'day'))) {
    // within 24hr
    return 'Yesterday';
  }

  if (dayjs(value).isAfter(dayjs().subtract(7, 'day'))) {
    // within 1wk
    return dayjs(value).format('dddd, MMMM D');
  }

  if (dayjs().isSame(dayjs(value), 'year')) {
    // within current yr
    return dayjs(value).format('MMM DD');
  }

  return dayjs(value).format('MMM DD, YYYY'); // before current yr
});
i18n.services.formatter?.add('timeAgoFormat', (value) => {
  return dayjs().to(dayjs(value));
});

i18n.services.formatter?.add('timeAgoActivityItem', (value) => {
  if (dayjs().isSame(dayjs(value), 'minute')) {
    // within 1 min
    return 'a few secs ago';
  }
  if (dayjs().isSame(dayjs(value), 'hour')) {
    return dayjs().to(dayjs(value)).replace('minute', 'min');
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, 'hour'))) {
    // within 59 min
    return dayjs().to(dayjs(value)).replace('minute', 'min');
  }

  if (dayjs().isSame(dayjs(value), 'day')) {
    // same day
    return dayjs().to(dayjs(value));
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, 'day'))) {
    // within 24hr
    return 'Yesterday';
  }

  return dayjs(value).format('MM/DD/YYYY');
});

i18n.services.formatter?.add('dateSlashFormat', (value) => {
  return dayjs(value).format('MM/DD/YYYY');
});

i18n.services.formatter?.add('timeAgoActivityItemWeb', (value) => {
  if (dayjs().isSame(dayjs(value), 'minute')) {
    // within 1 min
    return 'a few secs ago';
  }
  if (dayjs().isSame(dayjs(value), 'hour')) {
    return dayjs().to(dayjs(value)).replace('minute', 'min');
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, 'hour'))) {
    // within 59 min
    return dayjs().to(dayjs(value)).replace('minute', 'min');
  }

  if (dayjs().isSame(dayjs(value), 'day')) {
    // same day
    return dayjs().to(dayjs(value));
  }

  const day = dayjs(dayjs().format('MM/DD/YYYY')).diff(
    dayjs(dayjs(value).format('MM/DD/YYYY')),
    'day'
  );
  if (day === 1) {
    return dayjs(value).format('MM/DD/YYYY hh:mm A') + ' Yesterday';
  }
  return day + ' days ago';
});

i18n.services.formatter?.add('timeFormat', (value) => {
  return dayjs(value).format('hh:mm A');
});

i18n.services.formatter?.add('monthAndDayFormat', (value) => {
  const d = dayjs(value).format('MMM D');
  const today =
    dayjs(value).format('MMM D YYYY') === dayjs(new Date()).format('MMM D YYYY')
      ? 'Today, '
      : '';
  const y =
    dayjs(value).format('YYYY') !== dayjs(new Date()).format('YYYY')
      ? `, ${dayjs(value).format('YYYY')}`
      : '';
  return today + d + y;
});

i18n.services.formatter?.add('monthAndYearFormat', (value) => {
  return dayjs(value).format('MMMM YYYY');
});

i18n.services.formatter?.add('monthAndDayFormatDash', (value) => {
  const d = dayjs(value).format('dddd - MMM DD, YYYY');
  return d;
});

i18n.services.formatter?.add('monthAndDayFormatWithOrdinal', (value) => {
  const d = dayjs(value).format('MMM Do');
  const today =
    dayjs(value).format('MMM Do YYYY') ===
    dayjs(new Date()).format('MMM Do YYYY')
      ? 'Today, '
      : '';
  const y =
    dayjs(value).format('YYYY') !== dayjs(new Date()).format('YYYY')
      ? `, ${dayjs(value).format('YYYY')}`
      : '';
  return today + d + y;
});

i18n.services.formatter?.add('abbrMonthAndDayFormat', (value) => {
  const d = dayjs(value).format('MMM D');

  return d;
});

i18n.services.formatter?.add('padZeros', (value) => {
  const length = value.toString().length;
  let padded: string | number = 0;

  switch (length) {
    case 1:
      padded = `00${value}`;
      break;
    case 2:
      padded = `0${value}`;
      break;
    default:
      padded = value;
  }

  return padded.toString();
});

const checkCurrentYear = (
  value: string | number | dayjs.Dayjs | Date | null | undefined
) => {
  const dyear = dayjs(value).year() == dayjs(new Date()).year();
  return dyear;
};

const onCheckYesterdayDate = (
  value: string | number | dayjs.Dayjs | Date | null | undefined
) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const dateValue = new Date(value);
  const checkYesterdayDate =
    dateValue.toDateString() == yesterday.toDateString();
  return checkYesterdayDate;
};

const formatDateAsMonthDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  });
};

i18n.services.formatter?.add('timeAgoTasksTableList', (value) => {
  const checktoday = dayjs().isSame(dayjs(value), 'day');
  const isYesterDay = onCheckYesterdayDate(value);

  if (checktoday && checkCurrentYear(value)) {
    return 'Today';
  }
  if (isYesterDay && checkCurrentYear(value)) {
    return 'Yesterday';
  }
  if (checkCurrentYear(value)) {
    return formatDateAsMonthDate(value);
  }
  return dayjs(value).format('MMM DD, YYYY');
});

export default i18n;
