import { useNavigation, useRoute } from '@react-navigation/native';
import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { InferType } from 'yup';

import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import { Box } from '@components/Restyle';
import {
  useUpdateTeamMembersMutation,
  TeamRole,
  User,
  MemberAttributes,
  GetTeamDocument,
} from '@graphql/generated';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { TeamsStackScreenProps } from '@navigation/teams/teams-stack';
import addMembersSchema from '@schemas/addMembersSchema';
import { AddMembers } from '@screens/Members/AddMembers';
import { EditNewMemberRoles } from '@screens/Members/EditNewMemberRoles';
import { SelectMembersFromTeam } from '@screens/Members/SelectMembersFromTeam';
import theme from '@themes/theme';
import {
  defaultTeamRole,
  getTeamRoleEnumValue,
  selectableTeamRoles,
} from '@utils/teams';

export type FormValues = InferType<typeof addMembersSchema>;
type AddTeamMembersStackParamsList = {
  'add-members': undefined;
  'edit-new-member-roles': undefined;
  'select-members-from-team': undefined;
};

export type AddMembersStackScreenProps<
  T extends keyof AddTeamMembersStackParamsList
> = StackScreenProps<AddTeamMembersStackParamsList, T>;

const Stack = createStackNavigator<AddTeamMembersStackParamsList>();

interface AddTeamMembersStackProps {
  onComplete?: () => void;
  onClose?: () => void;
  isFromMyProfileWeb?: boolean;

  initialUsers?: User[];
  isFromSubscription?: boolean;
  onAddMembers?: (selected: User[]) => void;

  initialRouteName?: keyof AddTeamMembersStackParamsList;
}

export const AddTeamMembersStack: React.FC<AddTeamMembersStackProps> = ({
  onClose,
  isFromMyProfileWeb = false,
  isFromSubscription = false,
  initialUsers,
  onAddMembers,
  initialRouteName,
}) => {
  const { t } = useTranslation();
  const [selected] = useState<AddMemberType[]>();

  const { params: { teamId = '' } = {} } =
    useRoute<TeamsStackScreenProps<'add-team-members-stack'>['route']>();

  const navigation = useNavigation();

  const { team } = useTeamFromQuery({
    teamId,
  });

  const [updateTeamMembers] = useUpdateTeamMembersMutation({
    onCompleted: () => {
      navigation.goBack();
    },
    refetchQueries: [{ query: GetTeamDocument, variables: { id: teamId } }],
  });

  const addMembersToTeam = (values: FormValues) => {
    const attributes: MemberAttributes[] = values?.users.map((u) => {
      return { userId: u.id, role: getTeamRoleEnumValue(u.role) };
    });

    updateTeamMembers({
      variables: {
        teamId,
        attributes,
      },
    });
  };

  const submitForm = (values: FormValues) => {
    if (isFromSubscription) {
      onAddMembers?.(values.users);
      navigation.goBack();
    } else {
      addMembersToTeam(values);
    }
  };

  const initialValues: FormValues = {
    users: isFromSubscription && initialUsers ? initialUsers : [],
    selectedUserIds: selected?.map((u) => u.id) || [],
  };
  const planPrice = team?.plan?.amount
    ? (team.plan.amount / 100).toFixed(0)
    : 0;

  const calculateStartingDate = () => {
    if (team?.plan?.status === 'trialing') {
      const expirationDate = dayjs(team?.plan?.expiration);
      const updatedDate = expirationDate.add(1, 'second');
      return updatedDate.format('MMMM D');
    }

    const startingDate = dayjs();
    return startingDate.format('MMMM D');
  };
  const planStartingDate = calculateStartingDate();
  const upgradePricingInfo: string | undefined = team
    ? `Your subscription for ${team.name} is updated for adding new members. $${planPrice} ${team?.plan?.interval}/ per user starting ${planStartingDate}.`
    : undefined;

  return (
    <Box
      flex={1}
      mt={Platform.OS === 'web' && !isFromSubscription ? 'm' : 'none'}
      style={
        Platform.OS === 'web' && !isFromMyProfileWeb && !isFromSubscription
          ? { marginEnd: 300 + theme.spacing.m }
          : {}
      }>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={addMembersSchema}
        validateOnMount>
        {({ setFieldValue, values, submitForm, isSubmitting }) => {
          const { selectedUserIds: selectedUserIdsRaw, users } = values;
          const selectedUserIds = selectedUserIdsRaw?.filter(
            (s) => !!s
          ) as string[];

          return (
            <Stack.Navigator initialRouteName={initialRouteName || undefined}>
              <Stack.Screen
                name='add-members'
                options={{
                  header: () =>
                    isFromSubscription ? (
                      <Box backgroundColor='white'>
                        <SimpleHeader title='Add Members' onClose={onClose} />
                      </Box>
                    ) : (
                      <SecondaryHeader title='Add Members' searchable={false} />
                    ),
                }}>
                {() => (
                  <AddMembers
                    hideTeamTab={true}
                    disabled={!users.length}
                    onNextPress={() =>
                      navigation.navigate('edit-new-member-roles')
                    }
                    showTaskTagSupportUser={false}
                    selectedUserIds={selectedUserIds}
                    users={users}
                    updateUsers={(newUsers: AddMemberType[]) => {
                      setFieldValue(
                        'users',
                        newUsers.map((u) => {
                          return {
                            ...u,
                            role: u.role || defaultTeamRole,
                          };
                        })
                      );
                    }}
                  />
                )}
              </Stack.Screen>
              <Stack.Screen
                name='select-members-from-team'
                options={{
                  header: () => <SimpleHeader title='Team' />,
                }}>
                {() => (
                  <SelectMembersFromTeam
                    onSubmit={() =>
                      navigation.navigate('edit-new-member-roles')
                    }
                    disabled={!users.length}
                    selectedUserIds={selectedUserIds}
                    users={users}
                    updateUsers={(newUsers: AddMemberType[]) => {
                      setFieldValue(
                        'users',
                        newUsers.map((u) => {
                          return {
                            ...u,
                            role: u.role || defaultTeamRole,
                          };
                        })
                      );
                    }}
                  />
                )}
              </Stack.Screen>

              <Stack.Screen
                name='edit-new-member-roles'
                options={{
                  header: () => {
                    return Platform.OS === 'web' && onClose ? (
                      <Box backgroundColor='white'>
                        <SimpleHeader
                          title={t('models:addMembers.assignRole')}
                          onClose={onClose}
                        />
                      </Box>
                    ) : (
                      <SecondaryHeader
                        title={t('models:addMembers.assignRole')}
                        searchable={false}
                      />
                    );
                  },
                }}>
                {() => (
                  <EditNewMemberRoles<TeamRole>
                    roles={selectableTeamRoles}
                    updateMemberRole={(id: User['id'], role: TeamRole) => {
                      setFieldValue(
                        'users',
                        users.map((u) => {
                          return {
                            ...u,
                            role: u.id === id ? role : u.role,
                          };
                        })
                      );
                    }}
                    isTaskAssignee={false}
                    usersAndRoles={users.map((u) => {
                      return {
                        user: u,
                        role: u.role,
                      };
                    })}
                    onSubmit={submitForm}
                    isSubmitting={isSubmitting}
                    pricingInfo={upgradePricingInfo}
                  />
                )}
              </Stack.Screen>
            </Stack.Navigator>
          );
        }}
      </Formik>
    </Box>
  );
};
