import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FlatList } from 'react-native';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import FileCard from '@components/Files/FileCard';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { Document } from '@graphql/generated';
import { useListProjectDocumentsFromQuery } from '@hooks/useListProjectDocumentsFromQuery';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { ProjectFilesProps } from '@screens/Projects/ProjectFiles.web';
import { openInNewTab } from '@utils/fileUtils';
import { filterByDateRange } from '@utils/tasks';

export const AllProjectFiles: React.FC<ProjectFilesProps> = ({
  projectId,
  ownerId,
  sortBy,
  isSelectMode,
  onSelectedModeChange,
  selectedList,
  handelSelectedItem,
  dateRange,
}) => {
  const { setPreviewDocuments } = usePreviewDocuments();

  const { isPaid, setPaid } = useMe();
  const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
  const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
    useState<boolean>(false);

  const showNotPaidModal = (file: Document) => {
    const createdAt = dayjs(file.createdAt);
    const expired = createdAt?.diff(new Date(), 'day');
    if (!isPaidUser && expired < -1000) {
      setShowNotPaidConfirmModal(true);
      return true;
    }
    return false;
  };

  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);

  const { documents, loading, pageInfo, fetchFromCursor } =
    useListProjectDocumentsFromQuery({
      projectIds: [projectId],
      ownerIds: ownerId ? [ownerId] : undefined,
      sortBy,
      includeFiles: true,
    });

  const filteredDocuments = dateRange
    ? filterByDateRange(documents, ({ createdAt }) => createdAt, dateRange)
    : documents;

  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };

  if (loading) return <ActivityIndicatorLoading />;

  const renderItem = ({ item }: { item: Document }) => {
    return (
      <Box marginHorizontal='m'>
        <FileCard
          key={item.id}
          document={item}
          onPress={() => {
            if (showNotPaidModal(item)) return;
            if (isSelectMode) {
              handelSelectedItem(item);
            } else {
              setPreviewDocuments([{ ...item }]);
              if (item.contentType?.startsWith('image/')) {
                return setImageCarouselVisible(true);
              } else {
                return openInNewTab(item);
              }
            }
          }}
          showTags={false}
          selectedList={selectedList}
          isSelectMode={isSelectMode}
          onLongPress={() => {
            onSelectedModeChange(true);
            handelSelectedItem(item);
          }}
        />
      </Box>
    );
  };

  return (
    <Box flex={1}>
      <FlatList
        data={filteredDocuments}
        renderItem={renderItem}
        ItemSeparatorComponent={() => <Box marginBottom='l' />}
        ListHeaderComponent={() => <Box marginTop='l' />}
        ListFooterComponent={() => renderFooterUI(pageInfo?.hasNextPage)}
        ListEmptyComponent={() => <EmptyStateNoFiles />}
        contentContainerStyle={{ flexGrow: 1 }}
        onEndReachedThreshold={0.1}
        onEndReached={() => {
          fetchFromCursor();
        }}
      />
      {showNotPaidConfirmModal && (
        <NotPaidConfirmModal
          showModal={showNotPaidConfirmModal}
          onClose={() => setShowNotPaidConfirmModal(false)}
          onPress={() => {
            setShowNotPaidConfirmModal(false);
            setPaid(true);
            setPaidUser(true);
          }}
          title='Project'
        />
      )}
      {isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
    </Box>
  );
};
