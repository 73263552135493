import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import CachedImage from '@components/CachedImage/CachedImage';
import { SkillSelect } from '@components/FormModals/SkillSelect.web';
import {
  LocationSearch,
  Location,
} from '@components/LocationSearch/LocationSearch.web';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import TextField from '@components/shared/TextField/TextField';
import { LocalFile } from '@graphql/generated';
import { useSkills } from '@hooks/useSkills';
import { FormValues } from '@navigation/teams/create-team-stack';
import theme from '@themes/theme';
import { useFilePicker } from '@utils/filePicker';

type Props = {
  personal?: boolean;
  editing?: boolean;
  onAddLogo?: () => void;
  onClose?: () => void;
  showCreateButton?: boolean;
  showCancelButton?: boolean;
};

type RootStackParamList = {
  'expo-camera-photo-add-file': {
    onCallback: (r: LocalFile[]) => Promise<void>;
    photoCount: number;
  };

  'image-picker-modal': {
    openCamera: () => void;
    openGallery: () => void;
  };

  'team-location-search': undefined;
  'skill-select': undefined;
};

type NavigationProp = StackNavigationProp<RootStackParamList>;

const CreateTeamNewWeb: React.FC<Props> = ({
  personal = false,
  editing = false,
  onAddLogo,
  onClose,
  showCreateButton = true,
}) => {
  const navigation = useNavigation<NavigationProp>();
  const { t } = useTranslation();
  const skills = useSkills();
  const {
    values,
    touched,
    setTouched,
    submitForm,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<FormValues>();
  const { skillIds, logo, location } = values;
  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);
  const [skillsModalOpen, setSkillsModalOpen] = useState<boolean>(false);
  const { launchImageSelection } = useFilePicker();

  const openCamera = async () => {
    navigation.navigate('expo-camera-photo-add-file', {
      onCallback: onPictureTaken,
      photoCount: 1,
    });
  };

  const onPictureTaken = async (r: LocalFile[]) => {
    r && setFieldValue('logo', r[0]);
  };

  const openGallery = async () => {
    await launchImageSelection().then((r) => {
      r && setFieldValue('logo', r[0]);
    });
  };

  const openImageModal = () => {
    if (onAddLogo) {
      onAddLogo();
      return;
    }
    navigation.navigate('image-picker-modal', {
      openCamera: openCamera,
      openGallery: openGallery,
    });
  };

  return (
    <Box flex={1}>
      <ScrollView style={{ flex: 1 }}>
        <Box marginHorizontal='m'>
          <Box marginBottom='m' marginTop='l' alignItems='center'>
            {logo ? (
              <CachedImage
                image={logo as unknown as LocalFile}
                width={48}
                height={48}
                borderRadius={theme.spacing.xs}
              />
            ) : (
              <Box
                width={48}
                height={48}
                backgroundColor='greenSecondary'
                alignItems='center'
                justifyContent='center'
                borderRadius='xs'>
                <Icon
                  name='Office'
                  variant='l'
                  color='white'
                  onPress={openImageModal}
                />
              </Box>
            )}

            <TouchableOpacity
              onPress={openImageModal}
              accessibilityLabel={
                logo
                  ? t('models:teams.placeholders.editLogo')
                  : t('models:teams.placeholders.addLogo')
              }>
              <Text
                marginTop={logo ? 'xs' : 'm'}
                color='greenSecondary'
                variant='labelSmall'>
                {logo
                  ? t('models:teams.placeholders.editLogo')
                  : t('models:teams.placeholders.addLogo')}
              </Text>
            </TouchableOpacity>
          </Box>
          <Field
            component={TextField}
            label='Name'
            accessibilityLabel='Name'
            name='name'
            isRequired={!personal}
            placeholder={t('models:teams.placeholders.name')}
            textInputProps={{ editable: !personal }}
          />
          <Text color='textPrimary' variant='webSmall'>
            {t('models:saveProfile.location')}
          </Text>
          <TouchableOpacity
            accessibilityLabel={t('models:saveProfile.location')}
            onPress={() => setLocationModalOpen(true)}>
            <Box
              flexDirection='row'
              borderColor='grey02'
              borderWidth={1}
              height={48}
              alignItems='center'
              borderRadius='xs'
              paddingHorizontal='m'
              mt='xs'
              mb='m'>
              {location ? (
                <Text
                  variant='webBodySecondary'
                  color='black'
                  numberOfLines={1}
                  mr='m'>
                  {location.address}
                </Text>
              ) : (
                <Text
                  variant='webBodySecondary'
                  color='grey04'
                  numberOfLines={1}
                  mr='m'>
                  Search by a city or zip
                </Text>
              )}
              <Box flex={1}></Box>
              <Icon name='MapPin' variant='l' color='black' />
            </Box>
          </TouchableOpacity>
          <Box marginBottom='xs' flexDirection='row'>
            <Text marginRight='xs' variant='webSmall' color='textPrimary'>
              {t('models:saveProfile.skills')}
            </Text>
            <Icon name='AlertCircle' variant='s' color='textPrimary' />
          </Box>
          {/* eslint-disable-next-line no-magic-numbers */}
          {skillIds && skillIds.length > 0 && (
            <Box flexDirection='row' flexWrap='wrap' marginBottom='s'>
              <Tag
                label='+ Add Skills'
                variant='reversed'
                marginRight='xs'
                padding='xxs'
                borderRadius='xxs'
                onPress={() => setSkillsModalOpen(true)}
              />

              {skillIds.map((item) => {
                const skill = skills.find((i) => i.id === item);

                if (!skill) return null;

                const { id: skillId, name: skillName } = skill;

                return (
                  <Tag
                    key={skillId}
                    label={skillName}
                    variant='picker'
                    marginRight='xs'
                    padding='xxs'
                    borderRadius='xxs'
                    onDismiss={() => {
                      const skillIds = values.skillIds.filter(
                        (i) => i !== skillId
                      );
                      setTouched({ ...touched, skillIds: true });
                      setFieldValue('skillIds', [...skillIds]);
                    }}
                    marginBottom='xs'
                  />
                );
              })}
            </Box>
          )}
          {/* eslint-disable-next-line no-magic-numbers */}
          {!!skillIds && skillIds.length < 1 && (
            <>
              <Button
                variant='edit'
                fullWidth
                onPress={() => setSkillsModalOpen(true)}
                isSmall
                accessibilityLabel='Add Skills'>
                Add Skills
              </Button>
            </>
          )}
        </Box>
      </ScrollView>
      <Box padding='m' flexDirection='row' justifyContent='flex-end'>
        {showCreateButton && (
          <Button
            variant='textweb'
            isSmall
            marginRight='xs'
            onPress={() => {
              setTouched({ ...touched, name: false });
              setFieldValue('name', undefined);
              setFieldValue('location', undefined);
              setFieldValue('skillIds', []);
              onClose?.();
            }}
            color='black'
            borderColor='black'
            backgroundColor='white'
            accessibilityLabel={t('shared:cancel')}>
            {t('shared:cancel')}
          </Button>
        )}
        {showCreateButton && (
          <Box width={107}>
            <Button
              variant='webprimary'
              isSmall
              fullWidth
              onPress={() => {
                submitForm();
              }}
              disabled={
                !values.name ||
                Object.entries(touched).length < 1 ||
                isSubmitting
              }
              accessibilityLabel={
                editing ? t('models:teams.edit') : t('shared:next')
              }>
              {editing ? t('models:teams.edit') : t('shared:next')}
            </Button>
          </Box>
        )}
      </Box>

      <WebModal
        onClose={() => setLocationModalOpen(false)}
        visible={locationModalOpen}
        width={504}
        title='Location'
        height={512}>
        <Box flex={1} margin='m'>
          <LocationSearch
            isShowCancel={false}
            onClose={() => setLocationModalOpen(false)}
            onSelect={(location: Location) => {
              setFieldValue('location', location);
              setTouched({ ...touched, location: true });
            }}
          />
        </Box>
      </WebModal>
      {skillsModalOpen && (
        <SkillSelect onClose={() => setSkillsModalOpen(false)} />
      )}
    </Box>
  );
};

export default CreateTeamNewWeb;
