import { FlashList } from '@shopify/flash-list';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Box, ShadowBox, Text } from '@components/Restyle';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import theme from '@themes/theme';

export type PopupTaskFilterStatusProps = {
  onSelectStatus?: (status: string) => void;
  selectedStatus: string;
};
const ESTIMATED_CONTACT_ITEM_SIZE = 40;

export const PopupTaskFilterStatus: React.FC<PopupTaskFilterStatusProps> = ({
  onSelectStatus,
  selectedStatus,
}) => {
  const { t } = useTranslation('models');
  const [selected, setSelected] = useState<string>(selectedStatus);
  const [hoveredId, setHoveredId] = useState('');

  const onPressStatus = (status: string) => {
    setSelected(status);
    onSelectStatus?.(status);
  };

  const dataList: string[] = [
    t('projects.taskList.allStatus'),
    t('projects.taskList.section_TODAY'),
    t('projects.taskList.section_UPCOMING'),
    t('projects.taskList.section_OVERDUE'),
    t('projects.taskList.section_COMPLETED'),
  ];

  return (
    <ShadowBox
      flex={1}
      width={136}
      height={214}
      alignItems='flex-start'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <>
        <Box mt='s' mb='s' flex={1} style={{ height: 190, width: 136 }}>
          <FlashList
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            data={dataList}
            renderItem={({ item: status }) => {
              return (
                <Box
                  style={{
                    paddingVertical: 9,
                    backgroundColor:
                      hoveredId !== '' && hoveredId == status
                        ? theme.colors.grey01
                        : theme.colors.white,
                  }}
                  onMouseEnter={() => {
                    setHoveredId(status);
                  }}
                  onMouseLeave={() => {
                    setHoveredId('');
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      onPressStatus(status);
                    }}
                    accessibilityLabel={status}>
                    <Box flexDirection='row' ml='m' alignItems='center'>
                      <CheckBoxButton checked={selected == status} />
                      <Text
                        ml='xs'
                        variant='webBodySecondary'
                        color='textPrimary'>
                        {status}
                      </Text>
                    </Box>
                  </TouchableOpacity>
                </Box>
              );
            }}
            estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
          />
        </Box>
      </>
    </ShadowBox>
  );
};
