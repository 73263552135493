import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  PanResponder,
} from 'react-native';
import Carousel from 'react-native-snap-carousel';

import Avatar from '@components/Avatar/Avatar';
import CachedImageNoLogin from '@components/CachedImage/CachedImageNoLogin';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

export const cdnBaseUrl = 'https://ik.imagekit.io/nzfp9w26m';

export const ImagesPreviewNotLogin: React.FC<{
  onDismiss?: () => void;
}> = ({ onDismiss }) => {
  const cdnBaseURLnew =
    process.env.AASA_MODE === 'production' || process.env.PROD_APIS === 'true'
      ? cdnBaseUrl + '/production'
      : cdnBaseUrl;
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const imagesPath = window.sessionStorage.getItem('images_path');
  const images = imagesPath?.split(',');

  const userAvatar = window.sessionStorage.getItem('user_avatar');
  const userName = window.sessionStorage.getItem('user_name');

  const screenWidth = Dimensions.get('screen').width;
  const carouselRef = useRef<any>(null);
  const [imgPercentage, setImgPercentage] = useState(100);
  const MAX_PERCENTAGE = 200;
  const MIN_PERCENTAGE = 10;

  document.addEventListener('keydown', function (event) {
    if (images && activeIndex < images?.length && event.keyCode === 39) {
      carouselRef.current.snapToNext();
    } else if (activeIndex > 0 && event.keyCode === 37) {
      carouselRef.current.snapToPrev();
    }
  });

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_evt, gestureState) => {
        setOffset({
          x: offset.x + gestureState.dx,
          y: offset.y + gestureState.dy,
        });
      },
      onPanResponderRelease: (_evt, gestureState) => {
        setOffset({
          x: offset.x + gestureState.dx,
          y: offset.y + gestureState.dy,
        });
      },
    })
  ).current;

  const downloadImage = async () => {
    const url = images?.[activeIndex];
    url &&
      fetch(cdnBaseURLnew + '/tr:,f-auto,dpr-2,q-60,h-618/' + url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = images[activeIndex];
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('An error happened.'));
  };

  const carouselItem = (item: string) => {
    return (
      <Box {...panResponder.panHandlers}>
        <CachedImageNoLogin
          image={item}
          height={window.innerHeight - 293}
          screenWidth={screenWidth}
          style={{
            transform: [
              { scale: imgPercentage / 100 },
              { translateX: imgPercentage > 100 ? offset.x : 0 },
              { translateY: imgPercentage > 100 ? offset.y : 0 },
            ],
          }}
          resizeMode='contain'
        />
      </Box>
    );
  };

  const marginRight = Dimensions.get('window').width >= 1280 ? 'm' : 's';

  return (
    <WebModal
      visible
      width={window.innerWidth}
      height={window.innerHeight}
      isFullScreen={true}>
      <>
        <Box
          style={{ height: 79 }}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          backgroundColor='grey05'
          paddingHorizontal={marginRight}>
          <Box
            justifyContent='flex-start'
            alignItems='center'
            flexDirection='row'
            style={{ height: 43 }}>
            {Dimensions.get('window').width >= 1280 && (
              <Icon
                name='ChevronLeft'
                variant='m'
                color='white'
                onPress={onDismiss}
              />
            )}
            <Avatar
              avatar={{
                cdnBaseUrl: cdnBaseURLnew,
                id: '',
                path: userAvatar ? '/' + userAvatar : '',
                url:
                  cdnBaseURLnew +
                  '/tr:,f-auto,dpr-2,q-60,w-40,h-40/' +
                  userAvatar,
              }}
              size='small'
              marginLeft={theme.spacing.xs}
            />
            {Dimensions.get('window').width >= 1280 && (
              <Text
                variant='labelEmphasized'
                color='white'
                marginLeft='xs'
                marginBottom='xxxs'>
                {userName} {t('models:projects.files.shareImagesWithYou')}
              </Text>
            )}
            {Dimensions.get('window').width < 1280 && (
              <Box style={{ height: 43 }} marginLeft='xs'>
                <Text
                  variant='labelEmphasized'
                  color='white'
                  marginBottom='xxxs'>
                  {userName}
                </Text>
                <Text variant='metadataSecondary' color='grey02'>
                  {t('models:projects.files.shareImagesWithYou')}
                </Text>
              </Box>
            )}
          </Box>
          <Box
            justifyContent='flex-end'
            alignItems='center'
            flexDirection='row'
            style={{ height: 40 }}>
            <Icon
              name='Download'
              variant='m'
              color='white'
              marginRight={marginRight}
              onPress={downloadImage}
            />
            <Icon
              name='ZoomOut'
              variant='m'
              color={imgPercentage == MIN_PERCENTAGE ? 'grey04' : 'white'}
              marginRight={marginRight}
              onPress={() => {
                const newPercentage = imgPercentage - 10;
                if (newPercentage >= MIN_PERCENTAGE) {
                  setImgPercentage(newPercentage);
                  setOffset({ x: 0, y: 0 });
                }
              }}
            />
            <Icon
              name='ZoomIn'
              variant='m'
              color={imgPercentage == MAX_PERCENTAGE ? 'grey04' : 'white'}
              marginRight={marginRight}
              onPress={() => {
                const newPercentage = imgPercentage + 10;
                if (newPercentage <= MAX_PERCENTAGE) {
                  setImgPercentage(newPercentage);
                  setOffset({ x: 0, y: 0 });
                }
              }}
            />
            <Text variant='labelSmall' color='white' style={{ minWidth: 40 }}>
              {imgPercentage}%
            </Text>
          </Box>
        </Box>
        <Box flex={1} backgroundColor='black'>
          <Box flex={1} justifyContent='center'>
            <Box justifyContent='center' alignItems='center'>
              <Carousel
                data={images}
                renderItem={({ item }: { item: string }) => {
                  return carouselItem(item);
                }}
                itemWidth={screenWidth * 2}
                sliderWidth={screenWidth}
                itemHeight={screenWidth}
                sliderHeight={screenWidth}
                onBeforeSnapToItem={() => {
                  setImgPercentage(100);
                  setOffset({ x: 0, y: 0 });
                }}
                onSnapToItem={(index) => {
                  setActiveIndex(index);
                }}
                firstItem={activeIndex}
                initialNumToRender={images?.length}
                ref={carouselRef}
                scrollEnabled={images && images.length > 1}
              />

              {images && images.length > 1 && (
                <>
                  {activeIndex !== 0 && (
                    <Box style={[styles.arrow, styles.leftArrow]}>
                      <Icon
                        color='white'
                        style={{ width: 42, height: 42 }}
                        name='ArrowLeftCircle'
                        onPress={() => carouselRef.current.snapToPrev()}
                      />
                    </Box>
                  )}
                  {images && activeIndex !== images.length - 1 && (
                    <Box style={[styles.arrow, styles.rightArrow]}>
                      <Icon
                        color='white'
                        style={{ width: 42, height: 42 }}
                        name='ArrowRightCircle'
                        onPress={() => carouselRef.current.snapToNext()}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
          {images && (
            <Box justifyContent='center' alignItems='center'>
              <Box
                style={{
                  height: 62,
                  marginBottom: 42,
                  width: images.length * 36 + (images.length + 1) * 8,
                  backgroundColor: '#222222',
                }}
                borderRadius='xxs'
                flexDirection='row'
                justifyContent='space-between'
                paddingHorizontal='xs'
                paddingVertical='xs'>
                {images.map((item, index) => {
                  return activeIndex !== index ? (
                    <Box
                      width='xl'
                      height='xxl'
                      justifyContent='center'
                      alignItems='center'
                      pointerEvents='auto'>
                      <TouchableOpacity
                        onPress={() => {
                          carouselRef.current.snapToItem(index);
                        }}>
                        <CachedImageNoLogin
                          image={item}
                          width={theme.spacing.xl}
                          height={theme.spacing.xxl}
                        />
                      </TouchableOpacity>
                    </Box>
                  ) : (
                    <Box
                      width='xl'
                      height='xxl'
                      justifyContent='center'
                      alignItems='center'
                      pointerEvents='auto'>
                      <TouchableOpacity
                        onPress={() => {
                          carouselRef.current.snapToItem(index);
                        }}>
                        <CachedImageNoLogin
                          style={{
                            borderColor: theme.colors.greenSecondary,
                            borderWidth: theme.spacing.xxxs,
                          }}
                          image={item}
                          width={theme.spacing.xl}
                          height={theme.spacing.xxl}
                        />
                      </TouchableOpacity>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </>
    </WebModal>
  );
};

const styles = StyleSheet.create({
  arrow: {
    position: 'absolute',
    top: `50%`,
    zIndex: 99,
  },
  rightArrow: {
    right: theme.spacing.m,
  },
  leftArrow: {
    left: theme.spacing.m,
  },
});
