import { useState } from 'react';
import { ListRenderItemInfo, TouchableOpacity } from 'react-native';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import EmptyStateNoSearch from '@components/EmptyState/EmptyStateNoSearch';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/RestyleIcon';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { Tag } from '@components/shared/Tags/Tag';
import { File } from '@graphql/generated';
import useColors from '@hooks/useColors';
import theme from '@themes/theme';

const ITEM_HEIGHT = 32;

export type TagSelectType = {
  id: string;
  name: string;
  color?: string;
  avatar?: File;
};

type Props<T = unknown> = {
  singleSelect?: boolean;
  list: TagSelectType[];
  emptyContentMessage: string;
  showAvatar?: boolean;
  showIcon?: boolean;
  showBackground?: boolean;
  isBottomSheet?: boolean;
  isProjectSelect?: boolean;
  loading?: boolean;
  fetchMore?: () => void;
  refreshControl?: JSX.Element;
  refreshing?: boolean;
} & (SingleSelectProps<T> | MultiSelectProps<T>);

type SingleSelectProps<T = unknown> = {
  singleSelect: true;
  setSelected: (id: TagSelectType['id']) => void;
  value?: T;
};

type MultiSelectProps<T = unknown> = {
  singleSelect?: false;
  setSelected: (ids: TagSelectType['id'][]) => void;
  value?: T[];
};

export const SelectModalContent = ({
  list,
  isBottomSheet = false,
  isProjectSelect = false,
  emptyContentMessage,
  singleSelect,
  setSelected,
  value,
  showAvatar = false,
  showIcon = true,
  showBackground = true,
  loading,
  fetchMore,
  refreshControl,
  refreshing,
}: Props) => {
  const [searchVal, setText] = useState('');
  const { getMappedColor } = useColors();
  if (!list.length) {
    return (
      <Text variant='labelSmall' margin='m'>
        {emptyContentMessage}
      </Text>
    );
  }

  const valueAsArray = Array.isArray(value) ? value : [value];

  const filteredList = list.filter((t: TagSelectType) => {
    const isInSearch =
      t.name.toLowerCase().indexOf(searchVal.toLowerCase()) > -1;

    if (singleSelect) {
      return isInSearch;
    }

    const isInList = [...valueAsArray].some((value) => value === t.id);
    return isInSearch && !isInList;
  });

  const renderItem = ({ item }: ListRenderItemInfo<TagSelectType>) => {
    const { id, name, color, avatar } = item;
    const isInList = [...valueAsArray].some((value) => value === item.id);
    const mappedColor = getMappedColor(color);

    return (
      <TouchableOpacity
        onPress={() => {
          if (singleSelect) {
            setSelected(id);
          } else {
            const ids = [...new Set([...(value ? value : []), id])];
            setSelected(ids);
          }
        }}
        disabled={isInList}
        accessibilityLabel='Select'>
        <Box alignItems='center' marginVertical='xs' flexDirection='row'>
          {showIcon && mappedColor && (
            <Box
              style={{ backgroundColor: mappedColor }}
              padding='xxxs'
              borderRadius='xxxs'
              marginRight='xs'>
              <Icon name='Folder' variant='xs' color='white' />
            </Box>
          )}
          {showAvatar && (
            <Box
              style={{
                backgroundColor: showBackground ? mappedColor : 'white',
              }}
              padding='xxxs'
              borderRadius='xxxs'
              marginRight='xs'>
              <Avatar size='xs' label={name} avatar={avatar} />
            </Box>
          )}
          <Text
            numberOfLines={1}
            variant='labelSmall'
            color={isInList ? 'grey04' : undefined}>
            <Highlighter
              autoEscape
              highlightStyle={{
                color: theme.colors.greenSecondary,
                backgroundColor: theme.colors.background,
              }}
              searchWords={searchVal.split(' ')}
              textToHighlight={name}
            />
          </Text>
        </Box>
      </TouchableOpacity>
    );
  };

  return (
    <Box flexDirection='column' flex={1}>
      <Box
        flexDirection='row'
        alignItems='center'
        marginTop='s'
        marginBottom='m'
        marginHorizontal='m'>
        <SearchInput
          onTextChanged={(newText) => setText(newText)}
          value={searchVal}
        />
      </Box>
      {!singleSelect && (
        <>
          {!!value?.length && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ maxHeight: value?.length > 3 ? 100 : 50 }}>
              <Box marginBottom='s' marginHorizontal='m'>
                <Box
                  flexDirection='row'
                  flexWrap='wrap'
                  marginBottom='s'
                  overflow='visible'>
                  {value.map((item) => {
                    const tag = list.find((i) => i.id === item);
                    const { name: tagName } = tag || { name: '' };

                    return (
                      <Tag
                        key={item}
                        onDismiss={() => {
                          const userIds = value.filter(
                            (i: string) => i !== item
                          );
                          setSelected(userIds);
                        }}
                        label={tagName}
                        variant='picker'
                        marginRight='xs'
                        marginBottom='xs'
                      />
                    );
                  })}
                </Box>
              </Box>
            </ScrollView>
          )}

          <Box>
            <Text
              variant='labelSmall'
              color='grey04'
              marginTop='s'
              marginBottom='xs'
              marginLeft='m'>
              Suggested
            </Text>
          </Box>
        </>
      )}

      {loading ? (
        <Box
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicatorLoading />
        </Box>
      ) : (
        <Box flex={1}>
          <FlatList
            showsVerticalScrollIndicator={false}
            style={{
              marginHorizontal: theme.spacing.m,
              marginBottom: theme.spacing.m,
            }}
            data={filteredList}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            getItemLayout={(_data, index) => ({
              length: ITEM_HEIGHT,
              offset: ITEM_HEIGHT * index,
              index,
            })}
            ListEmptyComponent={
              <Box px='l' style={{ marginTop: -theme.spacing.listFooter }}>
                <EmptyStateNoSearch showSmall />
              </Box>
            }
            refreshing={refreshing}
            onEndReachedThreshold={0.9}
            initialNumToRender={25}
            ListFooterComponent={() => (
              <Box marginBottom={isBottomSheet ? 'listFooter' : 's'} />
            )}
            refreshControl={refreshControl}
            onEndReached={fetchMore}
          />
        </Box>
      )}
      <Box
        marginBottom={isBottomSheet && !isProjectSelect ? 'listFooter' : 'xxxs'}
      />
    </Box>
  );
};
