import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';

import { SimpleHeader } from '@components/Headers/SimpleHeader';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';

import { ContactType } from './Contact';
import ContactList from './ContactList';
import theme from '../../../themes/theme';
import { convertUsersToContacts } from '../../utils/convertUsersToContacts';
import Avatar from '../Avatar/Avatar';
import { Box, Text } from '../Restyle';
import SearchInput from '../shared/SearchInput/SearchInput';

interface SelectTeamMembersProps {
  onSelect?: (item: ContactType) => void;

  selectedUserIds?: string[];
  users?: AddMemberType[];
  updateUsers?: (newUsers: AddMemberType[]) => void;
}

export const SelectTeamMembersList: React.FC<SelectTeamMembersProps> = ({
  onSelect,
  selectedUserIds,
  users,
  updateUsers,
}) => {
  const route = useRoute();
  const navigation = useNavigation();
  const [search, setSearch] = useState<string>('');

  const {
    params: { teamId },
  } = route || {};

  const { team, loading } = useTeamFromQuery({
    teamId,
  });

  const { users: teamUsers } = team || { users: [] };

  const roleLabel = 'projects.roles.member';

  const listUsersToContacts = convertUsersToContacts(teamUsers).map((c) => {
    if (selectedUserIds?.includes(c.id)) {
      return {
        ...c,
        roleLabel,
      };
    } else {
      return c;
    }
  });

  useEffect(() => {
    if (team) {
      navigation.setOptions({
        header: () => <SimpleHeader title={`${team?.name} Team`} />,
      });
    }
  }, [team]);

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  if (!team) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Team not found</Text>
      </Box>
    );
  }
  return (
    <>
      <Box
        marginHorizontal='m'
        marginTop='m'
        flexDirection='row'
        alignItems='center'>
        <SearchInput onTextChanged={(t: string) => setSearch(t)} />
      </Box>
      <Box>
        {users?.length ? (
          <FlatList
            style={{
              marginTop: theme.spacing.m,
              paddingBottom: theme.spacing.m,
            }}
            horizontal
            data={users}
            ItemSeparatorComponent={() => <Box marginRight='m' />}
            ListHeaderComponent={() => <Box marginRight='m' />}
            ListFooterComponent={() => <Box marginRight='m' />}
            renderItem={({ item }) => {
              const { id, avatar, name, firstName } = item;

              return (
                <Box key={item.id} alignItems='center' alignSelf='flex-start'>
                  <Avatar
                    avatar={avatar}
                    label={name}
                    onDismiss={() => {
                      const newUsers = users.filter((i) => i.id !== id);
                      updateUsers?.(newUsers);
                    }}
                  />
                  <Text
                    variant='metadata'
                    marginTop='xs'
                    style={{ maxWidth: 64 }}
                    numberOfLines={1}>
                    {firstName}
                  </Text>
                </Box>
              );
            }}
          />
        ) : (
          <Box marginTop='m' />
        )}
      </Box>
      <Box flex={1}>
        <ContactList
          disabledRoles={[roleLabel]}
          contacts={
            search
              ? listUsersToContacts.filter((c) =>
                  c?.firstName?.toLowerCase().includes(search.toLowerCase())
                )
              : listUsersToContacts
          }
          alphabetize
          filterVal={search}
          onPress={(user: ContactType) => {
            if (onSelect) {
              onSelect(user);
              return;
            }

            if (!updateUsers) return;

            if (users?.find((u) => u.id === user.id)) {
              const selectedUsers = users?.filter((u) => u.id !== user.id);
              updateUsers(selectedUsers);
            } else {
              const newSelection: AddMemberType = {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.name,
                avatar: user.avatar,
              };
              updateUsers([...(users || []), newSelection]);
            }
          }}
          isEditing={!onSelect}
          selected={users?.map((u) => u.id?.toString() || '')}
        />
      </Box>
    </>
  );
};
