import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import {
  TaskMember,
  TaskMemberRole,
  TaskPriority,
  TaskStatus,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import { ChatsStackScreenProps } from '@navigation/chats/chats-stack';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';

import TaskFormStack, { FormValues } from '../Tasks/TaskFormStack';

interface CreateTaskProps {
  isShortModal?: boolean;
}

const CreateTask: React.FC<CreateTaskProps> = ({ isShortModal }) => {
  const {
    params: {
      projectId = '',
      isAssignTask = false,
      chatId = '',
      task,
      isCopyTask = false,
      selectedList = [],
    } = {},
  } = useRoute<
    | ChatsStackScreenProps<'task-form-modal'>['route']
    | TasksStackScreenProps<'task-create'>['route']
    | TasksStackScreenProps<'task-copy-modal'>['route']
  >();
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    description: '',
    projectId: projectId,
    users: [],
    completedAt: undefined,
    skillIds: [],
    phaseId: undefined,
    dependencyIds: [],
    fixedOrder: false,
    recurring: false,
    priority: TaskPriority.Low,
    status: TaskStatus.Ongoing,
    frequency: undefined,
    startDate: undefined,
    dueDate: undefined,
    checkList: [],
  });

  const [forceUpdate, setForceUpdate] = useState(false);
  const { setSelectedMembers } = useSelectedMembers();
  const { me } = useMe();
  useEffect(() => {
    setForceUpdate((prev) => !prev);
  }, [initialValues]);
  const [checkList, setCheckList] = useState<any>(null);

  useEffect(() => {
    if (isCopyTask && task) {
      const dependencyIds = task.dependencies?.map((i) => i.id) || [];
      const includeCreator = selectedList[1].list[0].isSelected;
      const includeCheckList = selectedList[0].list[0].isSelected;
      const includeAssignees = selectedList[1].list[1].isSelected;
      const includeViewers = selectedList[1].list[2].isSelected;
      const members = task.members
        .filter((i: TaskMember) => {
          if (i.user.id === me.id) return false;
          if (i.role === TaskMemberRole.Owner && includeCreator) {
            return true;
          }
          if (i.role === TaskMemberRole.Assignee && includeAssignees) {
            return true;
          }
          if (i.role === TaskMemberRole.Viewer && includeViewers) {
            return true;
          }
          return false;
        })

        .map((member: TaskMember) => {
          return {
            ...member.user,
            role:
              member.role === TaskMemberRole.Owner
                ? TaskMemberRole.Assignee
                : member.role,
          };
        });

      setSelectedMembers([...members]);
      const updatedInitialValues: FormValues = {
        name: task.name,
        description: task.description,
        projectId: task.projectId,
        users: members,
        completedAt: task.completedAt,
        skillIds: task.skillIds,
        phaseId: task.phaseId,
        dependencyIds: dependencyIds,
        fixedOrder: task.fixedOrder,
        recurring: task.recurring,
        priority: task.priority,
        status: TaskStatus.Ongoing,
        frequency: task.frequency,
        startDate: task.startDate,
        dueDate: task.dueDate,
      };
      setCheckList(includeCheckList ? task.checklists : []);
      setInitialValues(updatedInitialValues);
    } else {
      setSelectedMembers([]);
    }
  }, [task]);

  return (
    <TaskFormStack
      screenName='Create'
      key={forceUpdate}
      selectedList={selectedList}
      initialValues={initialValues}
      isShortModal={isShortModal}
      isAssignTask={isAssignTask}
      chatId={chatId}
      taskId={task?.id}
      isCopyTask={true}
      openModal={true}
      checkList={checkList}
    />
  );
};

export default CreateTask;
