import React, { useState } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import { Box, ShadowBox } from '@components/Restyle';
import Text from '@components/Text/Text';
import theme from '@themes/theme';
import timezones from '@utils/dictionaries/timezones.json';

export type TimeZonePopupProps = {
  onDismiss?: any;
  setTimeZone: (value: string) => void;
  width?: number;
  maxHeight?: number;
};

export const TimeZonePopup: React.FC<TimeZonePopupProps> = ({
  onDismiss,
  setTimeZone,
  width = 412,
  maxHeight = 120,
}) => {
  const [hoveredId, setHoveredId] = useState('');
  return (
    <ShadowBox
      flex={1}
      maxHeight={maxHeight}
      alignItems='flex-start'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <Box flex={1} marginVertical='s' style={{ maxHeight: maxHeight }}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={timezones}
          renderItem={({ item: { zone, label } }) => {
            return (
              <Box
                width={width}
                paddingHorizontal='m'
                style={{
                  paddingVertical: 9,
                  backgroundColor:
                    hoveredId !== '' && hoveredId == zone
                      ? theme.colors.grey01
                      : theme.colors.white,
                }}
                onMouseEnter={() => {
                  setHoveredId(zone);
                }}
                onMouseLeave={() => {
                  setHoveredId('');
                }}>
                <TouchableOpacity
                  onPress={() => {
                    setTimeZone(zone);
                    onDismiss?.();
                  }}>
                  <Text variant='webBodySecondary'>{label}</Text>
                </TouchableOpacity>
              </Box>
            );
          }}
          keyExtractor={({ zone }) => zone}
        />
      </Box>
    </ShadowBox>
  );
};
