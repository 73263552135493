import React from 'react';

import ProjectCard from '@components/ProjectCard/ProjectCard.web';
import { Box } from '@components/Restyle';
import GlobalSearchList from '@components/Search/GlobalSearchList.web';
import { Project } from '@graphql/generated';
import { Search } from '@root/types';

interface IProjectsSearch {
  search: string;
  data: Search<Project>[];
  loading?: boolean;
  sectionFooter?: JSX.Element;
  filterPart?: JSX.Element;
  onPress?: (item: Project) => void;
  onEndReached?: (info: { distanceFromEnd: number }) => void;
}

const ProjectsSearch: React.FC<IProjectsSearch> = ({
  search,
  data,
  loading = false,
  sectionFooter,
  onPress,
  filterPart,
  onEndReached,
}) => {
  return (
    <GlobalSearchList
      onEndReached={onEndReached}
      search={search}
      loading={loading}
      isViewAllContacts={false}
      onPress={() => {}}
      lists={[
        {
          title: 'Projects',
          data: data,
          totalCount: data.length > 0 ? data[0].total : data.length,
          headerRight: filterPart,
          render: (item) => {
            const project = item.record as Project;

            return (
              <Box accessibilityLabel='Project Item'>
                <ProjectCard
                  filterVal={search}
                  project={project}
                  onClick={() => {
                    onPress?.(project);
                  }}
                  expanded={false}
                  isGlobalSearch
                />
              </Box>
            );
          },
          sectionHeaderMarginBottom: 'xs',
          sectionFooter: sectionFooter,
        },
      ]}
    />
  );
};

export default ProjectsSearch;
