import React, { createContext, useContext, useState } from 'react';

import { Project, Team } from '@graphql/generated';

type WebDrawerNavigatorContext = {
  /** Web Drawer Navigator Header title */
  headerTitle?: string;
  setHeaderTitle: (header: WebDrawerNavigatorContext['headerTitle']) => void;
  selectedProject?: Project;
  setSelectedProject: (
    project: WebDrawerNavigatorContext['selectedProject']
  ) => void;
  selectedTeam?: Team;
  setSelectedTeam: (team: WebDrawerNavigatorContext['selectedTeam']) => void;
  showProjectColorAndIconModal?: boolean;
  setShowProjectColorAndIconModal: (
    show: WebDrawerNavigatorContext['showProjectColorAndIconModal']
  ) => void;
};

const webDrawerNavigatorContext = createContext<WebDrawerNavigatorContext>({
  setHeaderTitle: () => void 0,
  setSelectedProject: () => void 0,
  setShowProjectColorAndIconModal: () => void 0,
  setSelectedTeam: () => void 0,
});

type Props = {
  children: React.ReactNode;
};

export const WebDrawerNavigatorProvider = ({ children }: Props) => {
  const { Provider } = webDrawerNavigatorContext;

  const [headerTitle, setHeaderTitle] = useState<string | undefined>();
  const [selectedProject, setSelectedProject] = useState<Project | undefined>();
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
  const [showProjectColorAndIconModal, setShowProjectColorAndIconModal] =
    useState<boolean | undefined>();

  return (
    <Provider
      value={{
        headerTitle,
        setHeaderTitle,
        selectedProject,
        setSelectedProject,
        showProjectColorAndIconModal,
        setShowProjectColorAndIconModal,
        selectedTeam,
        setSelectedTeam,
      }}>
      {children}
    </Provider>
  );
};

export const useWebDrawerNavigator = (): WebDrawerNavigatorContext => {
  const context = useContext(webDrawerNavigatorContext);

  if (context === undefined) {
    throw new Error('useWebDrawerNavigator must be used within a Provider');
  }
  return context;
};
