import {
  ProjectMemberRole,
  Task,
  TaskMemberRole,
  TeamMemberRole,
} from '@graphql/generated';
import useMe from '@hooks/useMe';

type TaskMembership = {
  isMember: boolean;
  isOwner: boolean;
  isMemberOrOwner: boolean;
  canEdit: boolean;
};

export const useTaskMembership = (
  task?: Pick<
    Task,
    | 'userIds'
    | 'creator'
    | 'currentUserProjectRole'
    | 'currentUserRole'
    | 'currentUserProjectTeamRole'
  >
): TaskMembership => {
  const { me } = useMe();

  const isMember = !!task?.userIds?.some((id) => id === me?.id);
  const isOwner = task?.currentUserRole === TaskMemberRole.Owner;
  const isMemberOrOwner = isMember || isOwner;
  const canEdit =
    task?.currentUserRole === TaskMemberRole.Assignee ||
    task?.currentUserRole === TaskMemberRole.Owner ||
    task?.currentUserProjectRole === ProjectMemberRole.Owner ||
    task?.currentUserProjectRole === ProjectMemberRole.Admin ||
    task?.currentUserProjectRole === ProjectMemberRole.Editor ||
    task?.currentUserProjectTeamRole === TeamMemberRole.Owner ||
    task?.currentUserProjectTeamRole === TeamMemberRole.Admin;

  return {
    isMember,
    isOwner,
    isMemberOrOwner,
    canEdit,
  };
};
