import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { AvatarProps } from '@components/Avatar/Avatar';
import TagsCollection from '@components/Chat/TagsCollection';
import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { TaskPriorityBadge } from '@components/TaskCard/TaskPriorityBadge';
import Text from '@components/Text/Text';
import {
  Message,
  MessageTaskComplete,
  TaskMember,
  TaskPriority,
} from '@graphql/generated';
import { generateTagCollections } from '@utils/tagCollectionService';

type TaskCompletedProps = {
  message: Message;
};

export const TaskCompleted: React.FC<TaskCompletedProps> = ({ message }) => {
  const { t } = useTranslation('format');
  const { attachments } = message;
  const tagsByAuthorCollections = generateTagCollections(attachments || []);

  const navigation = useNavigation();

  const taskCompletes = attachments?.filter((item) => {
    return item.__typename === 'MessageTaskComplete';
  }) as MessageTaskComplete[];

  const userAvatars = (members: TaskMember[]): AvatarProps[] => {
    const completor = taskCompletes?.[0].completor;
    if (completor) {
      const { name, id, avatar } = completor;
      return [
        {
          id: id,
          label: name,
          avatar: avatar,
          size: 'memberList',
        },
      ];
    }
    return (
      members?.map((u) => {
        const { user } = u;
        const { name, id, avatar } = user;
        return {
          id: id,
          label: name,
          avatar: avatar,
          size: 'memberList',
        };
      }) || []
    );
  };

  const navigateToTaskMembers = () => {
    if (taskCompletes?.[0].completor) {
      navigation.navigate('user-profile', {
        userId: taskCompletes[0].completor?.id,
      });
    }
  };
  const completedTag = () => {
    if (
      tagsByAuthorCollections.length > 0 &&
      tagsByAuthorCollections[0].length > 0
    ) {
      const { tasks: tasks1 } = tagsByAuthorCollections[0][0];
      if (tasks1.length > 0 && tasks1[0].completedAt) {
        return (
          <Text color='greenDark' variant='labelAssignedTask'>
            {t('format:monthAndDay', {
              val: tasks1[0].completedAt ?? new Date(),
            })}
          </Text>
        );
      }
    }
    return <></>;
  };

  return (
    <Box
      borderColor='grey02'
      backgroundColor='grey01'
      borderWidth={1}
      borderRadius='xs'>
      <Tag
        label={t('models:chat.completedThisTask')}
        labelVariant='labelCompletedTask'
        variant='completedTask'
        prefixMarginRight='xxs'
        prefix={<Icon name='StateYes' variant='s' color='greenSecondary' />}
        padding='xs'
        suffix={completedTag()}
        borderTopLeftRadius='xxs'
        borderTopRightRadius='xxs'
        borderBottomLeftRadius='none'
        borderBottomRightRadius='none'
        isCompleted={true}
      />
      {tagsByAuthorCollections.map((tagCollections) => {
        return tagCollections.map((item) => {
          const { project, tasks, author } = item;
          const priority = tasks[0].priority;

          return (
            <Box key={project?.id}>
              <TagsCollection
                tagsCollection={{ project, tasks, author }}
                showAuthor={false}
                borderless={true}
                type='TaskComplete'
              />
              <Box
                paddingLeft='xxs'
                paddingRight='xs'
                paddingBottom='xs'
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'>
                {priority && priority !== TaskPriority.Low && (
                  <TaskPriorityBadge priority={priority} />
                )}
                {priority && priority == TaskPriority.Low && (
                  <Box paddingLeft='xxs'></Box>
                )}
                <Text
                  letterSpacing={0.2}
                  variant='labelAssignedTask'
                  color='textPrimary'>
                  {tasks[0].dueDate && 'Due:'}
                </Text>
                <Text
                  letterSpacing={0.2}
                  variant='labelAssignedTask'
                  color='textPrimary'>
                  {tasks[0].dueDate &&
                    ' ' +
                      t('format:monthAndDay', {
                        val: tasks[0].dueDate,
                      })}
                </Text>
                <Box flexDirection='row' flex={1} justifyContent='flex-end'>
                  <MemberListSummary
                    avatars={userAvatars(tasks[0].members)}
                    onPress={() => navigateToTaskMembers(tasks[0])}
                    maxAvatars={2}
                    isSmall={true}
                  />
                </Box>
              </Box>
            </Box>
          );
        });
      })}
    </Box>
  );
};
