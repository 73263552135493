import {
  HeaderOptions,
  Layout,
  ResourceSavingView,
  Screen,
} from '@react-navigation/elements';
import {
  CommonActions,
  createNavigatorFactory,
  DrawerRouter,
  Link,
  NavigationHelpers,
  NavigationState,
  ParamListBase,
  RouteProp,
  useNavigationBuilder,
} from '@react-navigation/native';
import React, { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { useSafeAreaFrame } from 'react-native-safe-area-context';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import RestyleIcon from '@components/shared/Icon/RestyleIcon';
import { ChatListPanel } from '@components/Web/Chats/ChatListPanel.web';
import { ChatPanel } from '@components/Web/Chats/ChatPanel.web';
import {
  ChatDrawerScreen,
  WebDrawerProvider,
} from '@components/Web/Drawer/WebDrawerContext';
import { DrawerHeader } from '@components/Web/Navigator/DrawerHeader';
import { MainPanel } from '@components/Web/Navigator/MainPanel.web';
import { useListChatsLazyQuery } from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useGlobalSearch from '@hooks/useGlobalSearch';
import { useNotification } from '@hooks/useNotification';
import useTabFocus from '@hooks/useTabFocus';
import {
  useWebDrawerNavigator,
  WebDrawerNavigatorProvider,
} from '@hooks/useWebDrawerNavigator';

export type DrawerHeaderProps = {
  layout: Layout;
  options: ScreenOptions;
  route: RouteProp<ParamListBase>;
  navigation: NavigationHelpers<ParamListBase, NavDrawerEventMap>;
};

type ScreenOptions = {
  /** Props for the link icon. If no `name` is provided, no icon is displayed. */
  iconProps?: Pick<ComponentProps<typeof RestyleIcon>, 'name' | 'variant'>;
  /**
   * Whether the screen should be mounted immediately or wait until first navigation.
   *
   * Defaults to `true`
   */
  lazy?: boolean;
  /**
   * True if the screen should be unmounted when navigated away from.
   *
   * Defaults to `false`
   */
  unmountOnBlur?: boolean;
  /**
   * Title string of a screen displayed in the drawer.
   * When `undefined`, the scene title is used.
   */
  drawerLabel?: string;
  /** Custom component for the screen icon. */
  drawerIcon?: () => ReactNode;
  /** Title text for the screen. */
  title?: string;
  /** True if the menu item should be displayed at the bottom of the drawer
   *
   * Defaults to `false`
   */
  showBelowTheFold?: boolean;
  /**
   * Whether to show the header. Setting this to `false` hides the header.
   * Defaults to `true`.
   */
  headerShown?: boolean;
  /**
   * Whether to hide the associated menu item.
   * Used when a should exist but not be directly accessible.
   */
  hideMenuItem?: boolean;
} & Pick<HeaderOptions, 'headerTransparent'>;

export type NavDrawerEventMap = {
  drawerItemPress: { data: undefined; canPreventDefault: true };
};

type Props = {
  initialRouteName?: string;
  screenOptions?: ScreenOptions;
  children: ReactNode;
};

const WebDrawerNavigator = ({
  initialRouteName,
  screenOptions,
  children,
}: Props) => {
  const { tabFocus, setTabFocus } = useTabFocus();
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder(DrawerRouter, {
      children,
      initialRouteName,
      screenOptions,
    });
  const { setSearch, setIsSearching, setIsSearchFocus } = useGlobalSearch();
  const {
    setActiveChat,
    activeChat,
    setActiveMessageCursor,
    drawerCollapsed,
    setDrawerCollapsed,
  } = useActiveChat();
  const [getListChats, { data }] = useListChatsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { setSelectedTeam } = useWebDrawerNavigator();
  const {
    notificationData: { chatId: notificationChatId } = {},
    clearNotificationData,
  } = useNotification();

  const { chatId = '', messageCursor = '' } =
    (state.routes[state.index].params as {
      chatId: string;
      messageCursor?: string;
    }) || {};

  useEffect(() => {
    if (messageCursor) setActiveMessageCursor(messageCursor);
  }, [messageCursor]);

  useEffect(() => {
    if (Dimensions.get('window').width <= 1440) {
      !drawerCollapsed && setDrawerCollapsed(true);
    } else {
      drawerCollapsed && setDrawerCollapsed(false);
    }
  }, [Dimensions.get('window').width]);

  useEffect(() => {
    const id = notificationChatId || chatId;
    if (id && (!activeChat?.id || id !== activeChat?.id)) {
      // Start a promise chain so we can capture `id` in a closer.
      Promise.resolve(id)
        .then(async (id) => {
          const { listChats = [] } = data || (await getListChats()).data || {};
          return listChats.find(({ id: listId }) => id === listId);
        })
        .then(setActiveChat);
    }
    // Since we have captured `id` already, we can safely clear it.
    navigation.setParams({ chatId: undefined });
    clearNotificationData();
  }, [chatId, notificationChatId, data, activeChat]);

  const focusedFouteKey = state.routes[state.index].key;
  const [loaded, setLoaded] = useState([focusedFouteKey]);
  const dimensions = useSafeAreaFrame();

  if (!loaded.includes(focusedFouteKey)) {
    setLoaded([...loaded, focusedFouteKey]);
  }

  const renderLink = (route: (typeof state.routes)[0], index: number) => {
    const focused = index === state.index;
    const { options } = descriptors[route.key];
    const { iconProps, title, drawerLabel, drawerIcon } = options;
    const DrawerIconComponent = drawerIcon ? drawerIcon() : null;

    const onPress = () => {
      setSelectedTeam(undefined);
      const event = navigation.emit({
        type: 'drawerItemPress',
        target: route.key,
        canPreventDefault: true,
      });

      if (!('defaultPrevented' in event) || !event.defaultPrevented) {
        navigation.dispatch({
          ...CommonActions.reset({
            routes: [
              {
                key: route.key,
                name: route.name,
                params: route.params,
                path: route.path,
              },
            ],
            index: 0,
          }),
          target: state.key,
        });

        setIsSearching(false);
      }

      if (route.name === 'projects-stack') {
        setTabFocus('Projects');
      } else if (route.name === 'tasks-stack') {
        setTabFocus('Tasks');
      } else if (route.name === 'files-stack') {
        setTabFocus('Files');
      } else if (route.name === 'contacts-stack') {
        setTabFocus('Contacts');
      } else if (route.name === 'teams-stack') {
        setTabFocus('Teams');
      } else {
        setTabFocus('');
      }

      setIsSearchFocus(false);
      setSearch('');
    };

    const isFocused = state.index === 5 ? getFocused(route.name) : false;

    return (
      <Link
        key={route.key}
        to={{ screen: route.name, params: {} }}
        onPress={onPress}>
        <Box
          width='100%'
          borderRadius='xs'
          flexDirection='row'
          justifyContent={drawerCollapsed ? 'center' : 'flex-start'}
          padding='m'
          backgroundColor={
            focused || isFocused ? 'greenSecondaryMild' : undefined
          }
          alignItems='center'>
          {DrawerIconComponent ? (
            <Box style={{ marginLeft: drawerCollapsed ? 0 : -10 }} mr='xxs'>
              {DrawerIconComponent}
            </Box>
          ) : (
            iconProps?.name && (
              <RestyleIcon
                {...iconProps}
                width={20}
                height={20}
                marginRight={!drawerCollapsed ? 'm' : undefined}
                color={focused || isFocused ? 'greenSecondary' : undefined}
              />
            )
          )}
          {!drawerCollapsed && (
            <Text
              variant='labelEmphasized'
              opacity={1}
              color={focused || isFocused ? 'greenSecondary' : undefined}>
              {drawerLabel || title || route.name}
            </Text>
          )}
        </Box>
      </Link>
    );
  };

  const getFocused = (routeName: string) => {
    if (routeName === 'home' && tabFocus === '') {
      return true;
    } else if (routeName === 'projects-stack' && tabFocus === 'Projects') {
      return true;
    } else if (routeName === 'tasks-stack' && tabFocus === 'Tasks') {
      return true;
    } else if (routeName === 'files-stack' && tabFocus === 'Files') {
      return true;
    } else if (routeName === 'contacts-stack' && tabFocus === 'Contacts') {
      return true;
    } else if (routeName === 'teams-stack' && tabFocus === 'Teams') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <NavigationContent>
      <WebDrawerProvider
        screen={(activeChat && ChatDrawerScreen.details) || undefined}>
        <WebDrawerNavigatorProvider>
          <Box
            flexDirection='row'
            flex={1}
            flexWrap='nowrap'
            style={{ overflow: 'hidden' }}>
            <Box
              padding={drawerCollapsed ? 's' : 'l'}
              py={drawerCollapsed ? 'l' : undefined}
              backgroundColor='grey01'
              width={drawerCollapsed ? 86 : 256}>
              <Box flexDirection='row' justifyContent='space-between'>
                <Box flex={1} alignItems='center' justifyContent='flex-start'>
                  <Box alignSelf='flex-start'>
                    <Icon
                      name={drawerCollapsed ? 'LogoInitials' : 'Logo'}
                      width={drawerCollapsed ? 25 : 80}
                      height={24}
                      color='textPrimary'
                    />
                  </Box>
                </Box>
                <Box flex={1} alignItems='center' justifyContent='flex-end'>
                  <Box alignSelf='flex-end'>
                    <Icon
                      name={drawerCollapsed ? 'ChevronsRight' : 'ChevronsLeft'}
                      variant='l'
                      onPress={() => setDrawerCollapsed((e) => !e)}
                      color='textPrimary'
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                flexDirection='column'
                justifyContent='space-between'
                alignContent='center'
                flex={1}
                my='l'>
                <Box flexDirection='column' alignContent='center'>
                  {state.routes.map((route, index) => {
                    const { options = {} } = descriptors[route.key];
                    const { showBelowTheFold = false, hideMenuItem = false } =
                      options;
                    return (
                      (!hideMenuItem &&
                        !showBelowTheFold &&
                        renderLink(route, index)) ||
                      null
                    );
                  })}
                </Box>
                <Box flexDirection='column' alignSelf='flex-end' width='100%'>
                  <Box
                    width='100%'
                    borderColor='grey02'
                    borderTopWidth={1}
                    marginVertical='xs'
                  />
                  <Box flexDirection='column' alignContent='center'>
                    {state.routes.map((route, index) => {
                      const { options = {} } = descriptors[route.key];
                      const { showBelowTheFold = false, hideMenuItem = false } =
                        options;
                      return (
                        (!hideMenuItem &&
                          showBelowTheFold &&
                          renderLink(route, index)) ||
                        null
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
            <MainPanel>
              {state.routes.map((route, index) => {
                const descriptor = descriptors[route.key];
                const {
                  lazy = true,
                  unmountOnBlur = false,
                  headerShown,
                } = descriptor.options;
                const isFocused = index === state.index;

                if (unmountOnBlur && !isFocused) {
                  return null;
                }

                if (lazy && !loaded.includes(route.key) && !isFocused) {
                  return null;
                }

                return (
                  <ResourceSavingView visible={isFocused} key={route.key}>
                    <Screen
                      focused={isFocused}
                      navigation={descriptor.navigation}
                      route={descriptor.route}
                      header={
                        <DrawerHeader
                          {...{
                            layout: dimensions,
                            route: descriptor.route,
                            navigation:
                              descriptor.navigation as unknown as NavigationHelpers<
                                ParamListBase,
                                NavDrawerEventMap
                              >,
                            options: descriptor.options,
                          }}
                        />
                      }
                      headerShown={headerShown}>
                      <Box
                        flex={1}
                        flexDirection='row'
                        justifyContent='space-between'>
                        <Box flex={1}>{descriptor.render()}</Box>
                      </Box>
                    </Screen>
                  </ResourceSavingView>
                );
              })}
            </MainPanel>
            <ChatPanel />
            <ChatListPanel />
          </Box>
        </WebDrawerNavigatorProvider>
      </WebDrawerProvider>
    </NavigationContent>
  );
};

export const createWebDrawerNavigator = createNavigatorFactory<
  NavigationState,
  ScreenOptions,
  NavDrawerEventMap,
  typeof WebDrawerNavigator
>(WebDrawerNavigator);
