import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { SectionList, RefreshControl, Platform } from 'react-native';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import ImageGrid from '@components/shared/ImageGrid';
import { Document, useNewListDocumentsQuery } from '@graphql/generated';
import { useGetDocumentsByClientIdsQuery } from '@hooks/useGetDocumentsByClientIdsQuery';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';

interface SectionData {
  title: string;
  data: Document[][];
}

export const getDocumentList = (documents: Document[], t: TFunction) => {
  const list = documents.reduce(
    (acc: Record<string, Document[]>, obj: Document) => {
      const { createdAt: date } = obj;
      const dateAsKey = t('monthAndYear', { val: date });

      if (!acc[dateAsKey]) {
        acc[dateAsKey] = [];
      }

      acc[dateAsKey].push(obj);
      return acc;
    },
    {}
  );

  const documentList = Object.entries(list).map(([key, value]) => {
    return { title: key, data: [value] };
  });

  return documentList;
};

const GroupChatMedia: React.FC = () => {
  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const navigation = useNavigation();
  const route = useRoute();
  const { t } = useTranslation('format');
  const [sortedDocuments, setSortedDocuments] = useState<SectionData[]>([]);

  const [documents, setDocuments] = useState<Document[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const pageCount = 20;
  const {
    params: { chatId = '' },
  } = route || {};

  const { checkDocumentURL } = useGetDocumentsByClientIdsQuery();
  const { loading, refetch } = useNewListDocumentsQuery({
    variables: {
      chatId: chatId,
      page: pageIndex,
      limit: pageCount,
      includeMedia: true,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTotalPage(data?.newListDocuments.metadata.totalPages ?? 1);
      const docs: Document[] = data?.newListDocuments.collection.filter(
        (t) => t.__typename === 'Document'
      );
      if (pageIndex === 1) {
        setDocuments(docs);
      } else {
        setDocuments([...documents, ...docs]);
      }
      if (docs.length > 0) {
        checkDocumentURL(docs);
      }
    },
  });

  useEffect(() => {
    setSortedDocuments(getDocumentList(documents, t));
  }, [setSortedDocuments, documents.length]);

  const renderItem = ({ item }: { item: Document[] }) => {
    return (
      <Box marginHorizontal='m' marginBottom='xs'>
        <ImageGrid
          list={item}
          onPress={(image) => {
            setPreviewDocuments(item);
            setActiveIndex(item.findIndex((i) => i.id === image.id));

            if (Platform.OS === 'web') {
              return setImageCarouselVisible(true);
            } else {
              navigation.navigate('images-preview');
            }
          }}
        />
      </Box>
    );
  };
  if (loading && documents.length === 0) return <ActivityIndicatorLoading />;

  return (
    <Box flex={1}>
      <SectionList
        stickySectionHeadersEnabled={false}
        sections={documents.length ? sortedDocuments : []}
        renderItem={renderItem}
        ItemSeparatorComponent={() => <Box marginBottom='l' />}
        ListFooterComponent={() =>
          loading && documents.length > 0 ? (
            <Box height={100}>
              <ActivityIndicatorLoading />
              <Box marginBottom='listFooter' />
            </Box>
          ) : (
            <Box marginBottom='listFooter' />
          )
        }
        ListHeaderComponent={() => <Box marginTop='s' />}
        renderSectionHeader={({ section: { title } }) => (
          <Box marginBottom='s' marginHorizontal='m'>
            <Text variant='labelEmphasized' color='textPrimary'>
              {title}
            </Text>
          </Box>
        )}
        ListEmptyComponent={() => <EmptyStateNoFiles />}
        contentContainerStyle={{ flexGrow: 1 }}
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
        onEndReached={() => {
          if (
            pageIndex <= totalPage &&
            documents.length == pageIndex * pageCount
          ) {
            setPageIndex(pageIndex + 1);
            refetch();
          }
        }}
      />
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
    </Box>
  );
};

export default GroupChatMedia;
