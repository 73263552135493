import { PlatformPressable } from '@react-navigation/elements';
import React, {
  ReactNode,
  useState,
  PropsWithChildren,
  ComponentProps,
} from 'react';
import {
  FlatList,
  Animated,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon, { RestyleProps } from '@components/shared/Icon/RestyleIcon';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';

type AnimatedSection = {
  height: Animated.Value;
  opacity: Animated.Value;
  rotation: Animated.Value;
  id: string;
  isOpen: boolean;
};

type InitialHeight = {
  height: number;
  id: string;
};

type sectionData<SectionHeaderType, DataType> = {
  sectionHeader: SectionHeaderType;
  data: DataType[];
};

type CollapsableSectionListProps<SectionHeaderType, DataType> = {
  renderSectionHeader: (item: SectionHeaderType) => ReactNode;
  data: sectionData<SectionHeaderType, DataType>[];
  renderItem: (item: DataType, index: number) => ReactNode;
  dataListHeaderStyle: RestyleProps;
  refreshControl?: JSX.Element;
  fetchMore?: () => void;
  refreshing?: boolean;
  tagSearch?: boolean;
  renderEmptyComponent?: JSX.Element;
  renderSectionFooterComponent?: (item: SectionHeaderType) => ReactNode;
  isArrowLeft?: boolean;
  showArrow?: boolean;
  showEmptyMessage?: boolean;
  fromChatTags?: boolean;
} & Pick<
  ComponentProps<typeof FlatList>,
  | 'ListHeaderComponent'
  | 'ListFooterComponent'
  | 'onEndReached'
  | 'onEndReachedThreshold'
>;

export const CollapsableSectionList = <
  SectionHeaderType extends { id: string },
  DataType extends { id: string }
>(
  props: PropsWithChildren<
    CollapsableSectionListProps<SectionHeaderType, DataType>
  >
) => {
  const {
    renderSectionHeader,
    data,
    renderItem,
    dataListHeaderStyle,
    refreshControl,
    fetchMore,
    refreshing,
    renderEmptyComponent,
    renderSectionFooterComponent,
    ListHeaderComponent = () => <Box marginTop='s' />,
    ListFooterComponent = () => <Box marginBottom='listFooter' />,
    onEndReached = () => {},
    onEndReachedThreshold,
    fromChatTags,
    isArrowLeft,
    showArrow,
    showEmptyMessage,
  } = props;

  const [animatedSections, setAnimatedSections] = useState<AnimatedSection[]>(
    []
  );
  const [initialHeights, setInitialHeights] = useState<InitialHeight[]>([]);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [isInitialFinsh, setIsInitialFinsh] = useState<boolean>(false);
  const { width } = Dimensions.get('window');
  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
  } = useWebDrawer();
  const { selectedProject } = useWebDrawerNavigator();

  const getRotation = (id: string) => {
    const section = animatedSections.find((s) => s.id === id);

    if (section) {
      return {
        transform: [
          {
            rotate: section.rotation.interpolate({
              inputRange: [0, 1],
              outputRange: ['0deg', '180deg'],
            }),
          },
        ],
      };
    }
    return undefined;
  };

  const animate = (id: string) => {
    !isInitialFinsh && setIsInitialFinsh(true);

    const section = animatedSections.find((s) => s.id === id);

    if (section) {
      const { isOpen } = section;
      setIsAnimating(true);
      const newHeight = isOpen
        ? 0
        : initialHeights.find((i) => i.id === id)?.height ?? 0;
      const newOpacity = isOpen ? 0 : 1;
      const newRotation = isOpen ? 0 : 1;

      Animated.parallel([
        Animated.timing(section.height, {
          toValue: newHeight,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(section.opacity, {
          toValue: newOpacity,
          duration: 50,
          useNativeDriver: false,
        }),
        Animated.timing(section.rotation, {
          toValue: newRotation,
          duration: 200,
          useNativeDriver: false,
        }),
      ]).start(() => {
        setIsAnimating(false);
        const sectionIndex = animatedSections.findIndex((s) => s.id === id);
        const newState = [...animatedSections];
        newState[sectionIndex].isOpen = !section.isOpen;
        setAnimatedSections(newState);
      });
    }
  };

  const getAnimatedStyles = (id: string) => {
    const section = animatedSections.find((s) => s.id === id);
    if (section) {
      const { isOpen } = section;

      const open = Platform.OS === 'web' ? isOpen && 1 : 1;
      return {
        height: section.height,
        opacity: section.opacity,
        flex: open,
      };
    }
    return undefined;
  };

  const getVisible = (id: string) => {
    if (Platform.OS === 'web') {
      const section = animatedSections.find((s) => s.id === id);
      if (section) {
        const { isOpen } = section;
        return isOpen;
      }
    }

    return true;
  };

  const renderSectionItem = (
    item: sectionData<SectionHeaderType, DataType>
  ) => {
    const { sectionHeader, data } = item;
    const { id } = sectionHeader;
    return (
      <Box accessibilityLabel={item.sectionHeader.id.toLowerCase()}>
        <TouchableOpacity onPress={() => animate(id)} disabled={isAnimating}>
          <Box
            flexDirection='row'
            alignItems='center'
            paddingHorizontal='m'
            {...dataListHeaderStyle}>
            {isArrowLeft && showArrow && (
              <Animated.View style={getRotation(id)}>
                <Icon name='ChevronDown' color='black' />
              </Animated.View>
            )}
            {renderSectionHeader(sectionHeader)}
            {!isArrowLeft && (
              <Animated.View style={getRotation(id)}>
                <Icon name='ChevronDown' color='black' />
              </Animated.View>
            )}
          </Box>
        </TouchableOpacity>
        <Animated.View
          onLayout={(e) => {
            const height = e.nativeEvent.layout.height;
            const initialHeightIndex = initialHeights?.findIndex(
              (a) => a.id === id
            );

            if (initialHeightIndex === undefined || initialHeightIndex === -1) {
              // if animated sections does not have this task, add it
              showArrow
                ? setAnimatedSections((h) => [
                    ...h,
                    {
                      height:
                        data.length > 0
                          ? new Animated.Value(height)
                          : new Animated.Value(0),
                      opacity:
                        data.length > 0
                          ? new Animated.Value(1)
                          : new Animated.Value(0),
                      rotation:
                        data.length > 0
                          ? new Animated.Value(1)
                          : new Animated.Value(0),
                      id: id,
                      isOpen: data.length > 0,
                    },
                  ])
                : setAnimatedSections((h) => [
                    ...h,
                    {
                      height: new Animated.Value(height),
                      opacity: new Animated.Value(1),
                      rotation: new Animated.Value(1),
                      id: id,
                      isOpen: true,
                    },
                  ]);

              setInitialHeights((h) => [...h, { height, id: id }]);
            } else if (height > initialHeights[initialHeightIndex].height) {
              // update height if the height is higher than current height
              const newHeights = [...initialHeights];
              newHeights[initialHeightIndex].height = height;
              setInitialHeights(newHeights);
            }
          }}
          style={isInitialFinsh && getAnimatedStyles(id)}>
          <Box paddingHorizontal='m'>
            {showEmptyMessage && data.length === 0 && renderEmptyComponent}
            {data.map((item, index) => (
              <Box key={item.id}>
                {getVisible(id) && renderItem(item, index)}

                {showArrow &&
                  (id == 'TODAY' || id == 'UPCOMING' || id == 'OVERDUE') &&
                  index === data.length - 1 && (
                    <Box
                      style={{
                        borderBottomWidth: 1,
                        height: 40,
                        justifyContent: 'center',
                        paddingLeft: 36,
                        marginBottom: 20,
                      }}
                      borderColor='grey02'>
                      <PlatformPressable
                        onPress={() => {
                          setIdTaskDetailOpen('');
                          setIdProjectOfTaskDetailOpen(
                            selectedProject ? selectedProject?.id : ''
                          );
                          setIsTaskWebPanelOpen(true);
                        }}>
                        <Text variant='taskBtnLabel'>Add a task...</Text>
                      </PlatformPressable>
                    </Box>
                  )}
                {index === data.length - 1 &&
                  renderSectionFooterComponent?.(sectionHeader)}
              </Box>
            ))}
          </Box>
        </Animated.View>
      </Box>
    );
  };

  return (
    <Box flex={1}>
      <FlatList
        data={data}
        renderItem={({ item }) => renderSectionItem(item)}
        ListFooterComponent={ListFooterComponent}
        ListHeaderComponent={ListHeaderComponent}
        ListEmptyComponent={renderEmptyComponent}
        contentContainerStyle={
          width < 1025 ? { flexGrow: 1, marginBottom: 20 } : { flexGrow: 1 }
        }
        onEndReached={fromChatTags ? onEndReached : fetchMore}
        onEndReachedThreshold={onEndReachedThreshold}
        refreshControl={refreshControl ? refreshControl : undefined}
        refreshing={refreshing}
        keyExtractor={(item, index) => item.toString() + index}
        showsVerticalScrollIndicator={false}
      />
    </Box>
  );
};
