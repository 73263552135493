import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView } from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import { Team, useGetProfileQuery } from '@graphql/generated';
import useMe from '@hooks/useMe';
import { ContactProfileProps } from '@navigation/contacts/contact-tabs.web';
import {
  convertTeamsToTeamAndIsMember,
  sortTeamsByIsMember,
} from '@utils/teams';

export const ContactInfo: React.FC<ContactProfileProps> = ({ userId }) => {
  const { t } = useTranslation();
  const { me } = useMe();

  const { data } = useGetProfileQuery({
    variables: { id: userId || '' },
  });

  const { getProfile: user } = data || {
    getProfile: undefined,
  };

  const { email, phoneNumber, address, skills, teams, id } = user || {};

  const teamsAndIsMember = convertTeamsToTeamAndIsMember(
    (teams as Team[]) || [],
    me?.id || ''
  );
  const sortedTeamsAndIsMember = sortTeamsByIsMember(teamsAndIsMember);

  const renderSkills = skills?.map((item) => {
    return (
      <Box
        key={item.id}
        marginBottom='xxs'
        marginRight='xxs'
        padding='xxs'
        borderRadius='xxs'
        backgroundColor='grey01'>
        <Text variant='labelSmall' color='textSecondary'>
          {item.name}
        </Text>
      </Box>
    );
  });

  return (
    <ScrollView>
      {me?.id === id && (
        <>
          <Box flexDirection='row' my='l'>
            <Text variant='labelEmphasized' color='textPrimary'>
              {t('shared:email')}
            </Text>

            <Box flex={1} ml='m' alignItems='flex-end'>
              <Text variant='body' color='textSecondary'>
                {email}
              </Text>
            </Box>
          </Box>
          <Box flexDirection='row' mb='l'>
            <Text variant='labelEmphasized' color='textPrimary'>
              {t('shared:phoneNumber')}
            </Text>
            <Box flex={1} ml='m' alignItems='flex-end'>
              <Text variant='body' color='textSecondary'>
                {t('format:phoneNumber', { val: phoneNumber })}
              </Text>
            </Box>
          </Box>
        </>
      )}
      {address && (
        <Box flexDirection='row' mb='l'>
          <Text variant='labelEmphasized' color='textPrimary'>
            {t('shared:location')}
          </Text>

          <Box flex={1} ml='m' alignItems='flex-end'>
            <Text
              variant='body'
              color='textSecondary'
              numberOfLines={1}
              ellipsizeMode='tail'>
              {address}
            </Text>
          </Box>
        </Box>
      )}
      {skills && skills?.length > 0 && (
        <Box mb='s'>
          <Text variant='labelLarge' color='textPrimary'>
            {t('shared:skills')}
          </Text>
          <Box flexDirection='row' flexWrap='wrap' marginTop='xxs'>
            {renderSkills}
          </Box>
        </Box>
      )}
      {teams && (
        <Box flexDirection='column' justifyContent='space-between'>
          <Text variant='labelEmphasized' color='textPrimary'>
            {t('shared:teams')}
          </Text>
          <Box flexDirection='column' marginTop='xs'>
            {sortedTeamsAndIsMember?.map((team) => {
              return (
                <TouchableOpacity key={team.id} disabled={!team.isMember}>
                  <Box flexDirection='row' mb='s'>
                    <Box
                      width={40}
                      height={40}
                      backgroundColor='black'
                      borderRadius='xs'
                      alignItems='center'
                      justifyContent='center'>
                      <Icon name='TT' width={40} height={40} />
                    </Box>
                    <Box ml='xs' opacity={team.isMember ? 1 : 0.5}>
                      <Text variant='labelSmall'>{team.name}</Text>
                      <Text variant='metadata' mt='xs'>
                        {team.usersCount} {t('shared:members')}
                      </Text>
                    </Box>
                  </Box>
                </TouchableOpacity>
              );
            })}
          </Box>
        </Box>
      )}
    </ScrollView>
  );
};
