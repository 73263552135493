import React from 'react';
import { useTranslation } from 'react-i18next';

import Contact, { ContactType } from '@components/Invite/Contact.web';
import { Box, Text } from '@components/Restyle';
import GlobalSearchList from '@components/Search/GlobalSearchList';
import { User } from '@graphql/generated';
import { Search } from '@root/types';

interface IContactSearch {
  search: string;
  contacts: Search<User>[];
  taskTagUsers?: ContactType[];
  loading: boolean;
  onPress?: (item: User) => void;
  onMessagePress?: (item: User) => void;
  onEndReached?: (info: { distanceFromEnd: number }) => void;
  sectionFooter?: JSX.Element;
}
const ContactsSearch: React.FC<IContactSearch> = ({
  search,
  contacts,
  taskTagUsers,
  loading,
  onPress,
  onMessagePress,
  onEndReached,
  sectionFooter,
}) => {
  const { t } = useTranslation();
  const taskTagUserIds = taskTagUsers?.map((i) => i.id);

  const contactsAndUsersList = () => {
    return [...contacts, t('shared:moreOnTaskTag'), ...(taskTagUsers || [])];
  };

  return (
    <GlobalSearchList
      onEndReached={onEndReached}
      loading={loading}
      search={search}
      onPress={() => {}}
      isViewAllContacts={false}
      lists={[
        {
          title: 'Contacts',
          totalCount: contacts.length,
          data: contactsAndUsersList(),
          render: (contact) => {
            if (typeof contact === 'string') {
              return (
                <Box my='xs' mx='l'>
                  <Text variant='labelSmall'>{contact}</Text>
                </Box>
              );
            } else {
              let isTaskTagUser = false;
              if (contact?.id) {
                isTaskTagUser = !!taskTagUserIds?.includes(contact?.id);
              }
              const item = contact?.record
                ? {
                    ...contact?.record,
                    firstName: contact?.record?.firstName ?? undefined,
                    lastName: contact?.record?.lastName ?? undefined,
                    displayName: contact?.record?.fullName ?? '',
                    initials: '',
                    avatar: contact?.record?.avatar ?? undefined,
                    avatarSize: 'small',
                  }
                : {
                    ...contact,
                    firstName: contact?.firstName ?? undefined,
                    lastName: contact?.lastName ?? undefined,
                    displayName: contact?.name ?? '',
                    initials: '',
                    avatar: contact?.avatar ?? undefined,
                    avatarSize: 'small',
                  };
              return (
                <Contact
                  style={{ paddingHorizontal: 'l', paddingVertical: 'xs' }}
                  key={item?.id}
                  isTaskTagUser={isTaskTagUser}
                  contact={item}
                  filterVal={search}
                  onPress={() => {
                    onPress?.(item);
                  }}
                  showMessageIcon={!isTaskTagUser}
                  onMessagePress={() => {
                    onMessagePress?.(item);
                  }}
                />
              );
            }
          },
          itemSeparatorComponent: (
            <Box
              borderBottomWidth={1}
              borderColor='grey01'
              marginHorizontal='m'
            />
          ),
          sectionHeaderMarginBottom: 'xs',
          sectionFooter: sectionFooter,
        },
      ]}
    />
  );
};

export default ContactsSearch;
