import React from 'react';

import { ShadowBox } from '@components/Restyle';
import { Message } from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';
import { useGetMessageFromCache } from '@hooks/useGetMessageFromCache';
import { ForwardMessageContent } from '@screens/Chats/ForwardMessageContent';

interface ForwardMessageProps {
  messageData: Message;
  onCancelPress?: () => void;
  onForwardCompleted: () => void;
}
export const ForwardMessage: React.FC<ForwardMessageProps> = ({
  messageData,
  onCancelPress,
  onForwardCompleted,
}) => {
  const { client } = useApolloClient();
  const cacheIdentifier = client?.cache.identify(messageData);
  const message = useGetMessageFromCache(cacheIdentifier);
  return (
    <ShadowBox
      accessibilityLabel='Forward Message Modal'
      backgroundColor='white'
      flex={1}
      borderRadius='m'
      borderWidth={1}
      borderColor='grey01'
      paddingBottom='l'>
      <ForwardMessageContent
        messageData={message}
        onCancelPress={onCancelPress}
        onForwardCompleted={onForwardCompleted}
      />
    </ShadowBox>
  );
};
