import React from 'react';

import Popup, { MenuItem } from '@components/shared/Popup/Popup';

type ProjectDetailPopupProps = {
  closePopFn?: () => void;
};

export const ProjectTasksPopup: React.FC<ProjectDetailPopupProps> = ({
  closePopFn,
}) => {
  const menuList: MenuItem[] = [
    {
      iconName: 'Edit',
      name: 'Edit',
    },
  ];
  return <Popup width={188} menuList={menuList} closePopFn={closePopFn} />;
};
