import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal, TouchableOpacity } from 'react-native';

import { ContactType } from '@components/Invite/Contact';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { ProjectsMemberPopup } from '@components/Web/PopupProjectsMember.web';
import { ProjectsOwnerPopup } from '@components/Web/PopupProjectsOwner.web';
import { ProjectsStatusPopup } from '@components/Web/PopupProjectsStatus.web';
import { ProjectsTeamPopup } from '@components/Web/PopupProjectsTeam.web';
import { Team } from '@graphql/generated';
import { useOutsideClick } from '@hooks/useOutsideClick.web';

type PositionTuple = ['left' | 'right', 'top' | 'bottom'];

type Position = PositionTuple | 'top' | 'bottom' | 'left' | 'right';

type Props = Omit<ComponentProps<typeof TouchableOpacity>, 'onPress'> & {
  title: string;
  onDismiss?: any;
  onSelectTeam?: (
    item: Pick<Team, 'id' | 'name' | 'avatar' | 'usersCount' | 'personal'>
  ) => void;
  onSelectOwner?: (ids: ContactType[]) => void;
  onSelectMember?: (ids: ContactType[]) => void;
  onPressActiveProjects?: () => void;
  onPressArchiveProjects?: () => void;
  boxProps: Partial<{
    position: Position;
    offset: [number, number] | number;
  }>;
  selectedOwners?: ContactType[];
  selectedMembers?: ContactType[];
  selectedTeamIds?: string[];
};

export const PopupProjectButtonWeb = ({
  onDismiss,
  onSelectTeam,
  onSelectOwner,
  onSelectMember,
  onPressArchiveProjects,
  onPressActiveProjects,
  boxProps: { position, offset, ...popupPropsRest },
  title,
  selectedOwners = [],
  selectedMembers = [],
  selectedTeamIds = [],
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [xtitle, setXTitle] = useState(title);

  const togglePopup = () => setVisible((v) => !v);

  const ref2 = useRef<HTMLDivElement>();

  const isEventPointInModal = (event: MouseEvent) => {
    const rect = ref2?.current?.getBoundingClientRect();
    return (
      rect &&
      event.x > rect.x &&
      event.x < rect.x + rect.width &&
      event.y > rect.y &&
      event.y < rect.y + rect.height
    );
  };
  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (!isEventPointInModal(event)) {
      setVisible(false);
    }
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  // Store the natural dimensions of the popup container
  const [layout, setLayout] = useState<
    { width: number; height: number } | undefined
  >();

  const [mouseOver, setMouseOver] = useState(false);

  const adjustBounds = (
    position: Props['boxProps']['position'] = 'bottom',
    offset: Props['boxProps']['offset'] = 0,
    client: DOMRect,
    popupLayout: { width: number; height: number }
  ) => {
    const [offsetLeftRight, offsetTopBottom] =
      typeof offset === 'number' ? [offset, offset] : offset;

    const normalizedPosition = (typeof position !== 'string' && position) ||
      ((position === 'left' || position === 'right') && [position, 'bottom']) ||
      ((position === 'top' || position === 'bottom') && ['left', position]) || [
        'left',
        'bottom',
      ];

    const [leftRight, topBottom] = normalizedPosition;
    const bounds = {
      top:
        topBottom === 'top'
          ? client.top - popupLayout.height - offsetTopBottom
          : client.top + client.height + offsetTopBottom,
      left:
        leftRight === 'left'
          ? client.left + client.width - popupLayout.width - offsetLeftRight
          : client.left + offsetLeftRight,
    };

    return bounds;
  };

  const adjustedBounds =
    ref.current &&
    layout &&
    adjustBounds(position, offset, ref.current.getBoundingClientRect(), layout);

  const onModalDismiss = () => {
    onDismiss?.();
    setVisible(false);
  };

  const backgroundColor1 = () => {
    if (mouseOver) return 'grey01';
    return visible ? 'grey02' : 'white';
  };

  return (
    <Box ref={ref}>
      <TouchableOpacity
        accessibilityLabel={xtitle}
        onPress={() => {
          togglePopup();
        }}>
        <Box
          height={32}
          flexDirection='row'
          borderWidth={1}
          px='m'
          py='xs'
          onMouseEnter={() => {
            setMouseOver(true);
          }}
          onMouseLeave={() => {
            setMouseOver(false);
          }}
          backgroundColor={backgroundColor1()}
          borderColor='grey02'
          borderRadius='xs'
          alignItems='center'
          justifyContent='center'>
          <Box alignItems='center' justifyContent='center' style={{ flex: 1 }}>
            <Text variant='webBodySecondary' color='textPrimary'>
              {xtitle}
            </Text>
          </Box>
          <Box
            width={16}
            height={16}
            marginLeft='xs'
            alignItems='center'
            justifyContent='center'
            pointerEvents='none'>
            <Icon variant='s' name='ChevronDown' color='textPrimary' />
          </Box>
        </Box>
      </TouchableOpacity>

      {!layout && (
        <Box
          position='absolute'
          left='-200vw'
          visible={!layout}
          onLayout={({ nativeEvent: { layout } }) => {
            setLayout((curr) => curr ?? layout);
          }}>
          {onSelectTeam && <ProjectsTeamPopup {...popupPropsRest} />}
          {onSelectOwner && (
            <ProjectsOwnerPopup
              selecteds={selectedOwners}
              {...popupPropsRest}
            />
          )}
          {onSelectMember && (
            <ProjectsMemberPopup
              selecteds={selectedMembers}
              {...popupPropsRest}
            />
          )}
          {onPressActiveProjects && <ProjectsStatusPopup {...popupPropsRest} />}
        </Box>
      )}
      <Modal
        visible={visible}
        transparent
        onDismiss={() => onModalDismiss()}
        onRequestClose={() => onModalDismiss()}>
        <Box ref={ref2} style={{ ...adjustedBounds }} width={layout?.width}>
          <Box flexShrink={1}>
            {onSelectTeam && (
              <ProjectsTeamPopup
                {...popupPropsRest}
                onSelectTeam={(item) => {
                  onSelectTeam && onSelectTeam(item);
                }}
                selectedTeamIds={selectedTeamIds}
              />
            )}
            {onSelectOwner && (
              <ProjectsOwnerPopup
                selecteds={selectedOwners}
                {...popupPropsRest}
                onSelectOwner={(ids) => {
                  onSelectOwner && onSelectOwner(ids);
                }}
              />
            )}
            {onSelectMember && (
              <ProjectsMemberPopup
                selecteds={selectedMembers}
                {...popupPropsRest}
                onSelectMember={(ids) => {
                  onSelectMember && onSelectMember(ids);
                }}
              />
            )}
            {onPressActiveProjects && (
              <ProjectsStatusPopup
                {...popupPropsRest}
                onPressActiveProjects={(newTitle) => {
                  onModalDismiss();
                  if (xtitle !== newTitle) {
                    setXTitle(newTitle);
                    onPressActiveProjects && onPressActiveProjects();
                  }
                }}
                onPressArchiveProjects={(newTitle) => {
                  onModalDismiss();
                  if (xtitle !== newTitle) {
                    setXTitle(newTitle);
                    onPressArchiveProjects && onPressArchiveProjects();
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
