import { useNavigation, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';

import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { WebModal } from '@components/Modals/WebModal';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/RestyleIcon';
import { Team, useGetTeamQuery } from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { ProfileStackScreenProps } from '@navigation/profile/profile-stack.web';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { SESSION_SELECTED_TEAM_NAME } from '@src/constants/constants';

type SubscriptionCreatedModalProps = {
  title?: string;
  onClose?: () => void;
  isFromCreated?: boolean;
};

export const SubscriptionCreatedModal: React.FC<
  SubscriptionCreatedModalProps
> = ({
  title = 'Congratulations',
  onClose: onCloseProp,
  isFromCreated = true,
}) => {
  const { t } = useTranslation();
  const navigator = useNavigation();
  const appnavigation = useAppNavigation();
  const [visible, setVisible] = useState(true);
  const { params } = isFromCreated
    ? useRoute<ProfileStackScreenProps<'subscription-created'>['route']>()
    : useRoute<ProfileStackScreenProps<'subscription-edit'>['route']>();

  const { teamId } = params;

  const { setSelectedTeam } = useWebDrawerNavigator();

  const { data, loading } = useGetTeamQuery({
    variables: { id: teamId, includePending: true },
    skip: teamId?.length < 1,
  });
  const { me } = useMe();

  const { getTeam: team } = data || {};

  const onClose = onCloseProp || navigator.goBack;

  const goToSubscriptionDetailPage = () => {
    if (team) {
      setSelectedTeam(team as Team);
      const x = window.sessionStorage.getItem(SESSION_SELECTED_TEAM_NAME);
      if (!x || x.length < 1) {
        window.sessionStorage.setItem(
          SESSION_SELECTED_TEAM_NAME,
          '1' + team.name
        );
      }
      appnavigation.navigateToMyprofileSubscriptionDetail(team?.id ?? '');
    }
  };

  const redirectByEvent = () => {
    goToSubscriptionDetailPage();
    setVisible(false);
  };

  // Get the current date
  const currentDate = dayjs();
  // Add 30 days to the current date
  const futureDate = currentDate.add(30, 'day');
  const futureDateLabel = futureDate.format('MMMM DD[th], YYYY');

  useEffect(() => {
    if (!window) return;

    window.addEventListener('actions-modal', redirectByEvent);

    return () => {
      window.addEventListener('actions-modal', redirectByEvent);
    };
  }, []);

  if (team && team.owner.id != me?.id) {
    navigator.navigate('my-account');
    return;
  }

  return (
    <WebModal visible={visible} onClose={onClose} width={400}>
      <SimpleHeader
        title={title}
        titleVariant='labelLarge'
        titleColor='textPrimary'
        marginTop='m'
        marginHorizontal='l'
        onClose={onClose}
      />

      {loading && (
        <Box mx='l' minHeight={320}>
          <ActivityIndicator />
        </Box>
      )}
      {!loading && (
        <Box mx='l' minHeight={320}>
          <Box>
            <Box alignSelf='center' mt='l' mb='l'>
              <Icon
                name='Trophy'
                style={{ aspectRatio: 1 }}
                width={200}
                height={150}
              />
            </Box>
            <Text style={{ textAlign: 'center', lineHeight: 16 }}>
              {isFromCreated && (
                <Text variant='webBodySecondary' style={{ lineHeight: 16 }}>
                  {team?.plan?.status === 'trialing'
                    ? t('models:teams.subscriptions.subscriptionsStr', {
                        teamName: team?.name,
                        duration: 'will be free for 30 days',
                      })
                    : t('models:teams.subscriptions.subscriptionsCreatedStr', {
                        teamName: team?.name,
                      })}
                </Text>
              )}
              {isFromCreated && team?.plan?.status === 'trialing' && (
                <Text
                  style={{
                    lineHeight: 16,
                    fontFamily: 'Inter_500Medium',
                    fontSize: 14,
                  }}>
                  {futureDateLabel}
                </Text>
              )}
              {!isFromCreated && (
                <Text variant='webBodySecondary' style={{ lineHeight: 16 }}>
                  {t('models:teams.subscriptions.subscriptionsUpdatedStr')}
                </Text>
              )}
            </Text>
            <Box pt='l' pb='m'>
              <Button
                isSmall={true}
                height={48}
                fullWidth={true}
                variant='primary'
                onPress={() => {
                  onClose();
                  goToSubscriptionDetailPage();
                }}>
                {t('models:teams.subscriptions.LetsStart')}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </WebModal>
  );
};
