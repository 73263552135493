import ReactNativeAsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Platform } from 'react-native';

import { useApolloClient } from '@hooks/useApolloClient';
import { GoogleAuthProvider } from '@hooks/useGoogleAuth';
import { AsyncStorage } from '@utils/storage';

export type AuthContextType = {
  token: string | undefined;
  setAuthToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  registerLoading: boolean;
  setRegisterLoading: React.Dispatch<React.SetStateAction<boolean>>;
  removeToken: () => void;
  /** `true` while the auth context is initializing. `false` if the auth context has completed loading */
  loading: boolean;
};

const AuthContext = createContext<AuthContextType>({
  token: undefined,
  setAuthToken: () => null,
  registerLoading: false,
  setRegisterLoading: () => null,
  removeToken: () => null,
  loading: false,
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // NOTE: We need to distinguish between unknown token values and invalid values.
  // `undefined` is being treated as "unknown". In `getToken()`, we attempt to retrieve
  // the token from AsyncStorage. A value of `null` is considered unauthenticated.
  // Without distinguishing between "unknown" and "invalid", the cache will be cleared
  // every time the app restarts.
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const { clearCache, recentStatusCode } = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(false);

  const getToken = async () => {
    try {
      const value = await AsyncStorage.getItem('authToken');
      setAuthToken(value);

      const version = Constants?.expoConfig?.version;
      const cacheClearVersion = await AsyncStorage.getItem('cacheClearVersion');

      if (version && clearCache && version !== cacheClearVersion) {
        clearCache();
        await AsyncStorage.setItem('cacheClearVersion', version);
      }
      return value;
    } catch (e) {
      console.error(e);
      // error reading value
    }
  };

  const removeToken = async () => {
    try {
      await AsyncStorage.deleteItem('authToken');
    } catch (e) {
      /* empty */
    } finally {
      setAuthToken('');
    }
  };

  const checkUserSession = async () => {
    if (Platform.OS !== 'web') {
      const alreadyInstall = await ReactNativeAsyncStorage.getItem(
        'alreadyInstall'
      );
      if (!alreadyInstall) {
        removeToken();
      }
    }
    setLoading(true);
    getToken().finally(() => setLoading(false));
  };
  const checkKeepSignedIn = async () => {
    const setKeepSignedIn =
      window.sessionStorage.getItem('setKeepSignedIn') ?? '0';

    if (setKeepSignedIn && setKeepSignedIn === '0') {
      const keepSignedIn =
        (await AsyncStorage.getItem('keepSignedIn')) ?? 'false';

      if (keepSignedIn && keepSignedIn !== 'true') {
        removeToken();
      } else {
        window.sessionStorage.setItem('setKeepSignedIn', '1');
      }
    }
  };

  useEffect(() => {
    checkUserSession();
    if (Platform.OS === 'web') {
      checkKeepSignedIn();
    }
  }, []);
  const asyncClearCache = async () => {
    if (clearCache) await clearCache();
  };
  useEffect(() => {
    if (
      Platform.OS === 'web' &&
      (authToken === null || authToken === '') &&
      clearCache
    ) {
      asyncClearCache().catch(console.error);
    }
  }, [authToken]);

  useEffect(() => {
    if (recentStatusCode && recentStatusCode === 401) {
      removeToken();
    }
  }, [recentStatusCode]);

  return (
    <AuthContext.Provider
      value={{
        token: authToken,
        setAuthToken,
        removeToken,
        loading,
        registerLoading,
        setRegisterLoading,
      }}>
      <GoogleAuthProvider>{children}</GoogleAuthProvider>
    </AuthContext.Provider>
  );
};
