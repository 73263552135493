import { FlashList } from '@shopify/flash-list';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import { ContactType } from '@components/Invite/Contact';
import { Box, ShadowBox, Text } from '@components/Restyle';
import SearchInput from '@components/shared/SearchInput/SearchInput.web';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import { useContacts } from '@hooks/useContacts';
import useMe from '@hooks/useMe';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

export type ProjectsOwnerFilterPopupProps = {
  onSelectOwner?: (owners: ContactType[]) => void;
  selecteds: ContactType[];
};
const ESTIMATED_CONTACT_ITEM_SIZE = 40;
export const ProjectsOwnerPopup: React.FC<ProjectsOwnerFilterPopupProps> = ({
  onSelectOwner,
  selecteds,
}) => {
  const { contacts } = useContacts();
  const { me } = useMe();
  const listUsersToContacts = convertUsersToContacts(
    !me || contacts.some((c) => c.id === me!.id) ? contacts : [...contacts, me!]
  );

  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<ContactType[]>(selecteds);
  const [hoveredId, setHoveredId] = useState('');

  const sortedListUsersToContacts = [...listUsersToContacts]
    .filter(
      (u) =>
        search.length === 0 ||
        u.displayName.toLowerCase().includes(search.toLowerCase().trim())
    )
    .sort((a, b) => (a.firstName ?? '')?.localeCompare(b.firstName ?? ''));

  const onPressContact = (contact: ContactType) => {
    const isIn = selected.some((c) => c.id === contact.id);
    const updatedSelected = isIn
      ? selected.filter((c) => c.id !== contact.id)
      : [...selected, contact];
    setSelected(updatedSelected);
    onSelectOwner?.(updatedSelected);
  };

  return (
    <ShadowBox
      flex={1}
      width={295}
      alignItems='flex-start'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <>
        <Box
          marginHorizontal='m'
          marginTop='m'
          style={{ width: 263, backgroundColor: 'grey02' }}
          justifyContent='center'
          flexDirection='row'
          alignItems='flex-start'>
          <SearchInput
            value={search}
            height={40}
            onTextChanged={(value: string) => {
              setSearch(value);
            }}
            placeHolder='Search Owner'
          />
        </Box>
        <Box mt='s' mb='s' flex={1} style={{ maxHeight: 300, width: 295 }}>
          <FlashList
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            ListFooterComponent={() => <Box marginBottom='m' />}
            data={sortedListUsersToContacts}
            renderItem={({ item: contact }) => {
              const { id, avatar, displayName, name } = contact;
              return (
                <Box
                  style={{
                    paddingVertical: 9,
                    backgroundColor:
                      hoveredId !== '' && hoveredId == contact.id
                        ? theme.colors.grey01
                        : theme.colors.white,
                  }}
                  onMouseEnter={() => {
                    setHoveredId(id);
                  }}
                  onMouseLeave={() => {
                    setHoveredId('');
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      onPressContact(contact);
                    }}
                    accessibilityLabel={displayName}>
                    <Box
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='space-between'>
                      <Box ml='m' justifyContent='center' marginRight='xs'>
                        <CheckBoxButton
                          checked={selected.some((s) => s.id === contact.id)}
                        />
                      </Box>
                      <Avatar
                        id={id}
                        label={name}
                        avatar={avatar}
                        size='small'
                      />
                      <Box mr='m' marginLeft='xs' flex={1}>
                        <Text
                          variant='webBodySecondary'
                          color={displayName ? 'textPrimary' : 'grey04'}>
                          {displayName ? (
                            <Highlighter
                              highlightStyle={{
                                backgroundColor: theme.colors.yellowBright,
                              }}
                              autoEscape
                              searchWords={search?.split(' ') || ''}
                              textToHighlight={displayName}
                            />
                          ) : (
                            'No name'
                          )}
                        </Text>
                      </Box>
                    </Box>
                  </TouchableOpacity>
                </Box>
              );
            }}
            estimatedItemSize={ESTIMATED_CONTACT_ITEM_SIZE}
          />
        </Box>
      </>
    </ShadowBox>
  );
};
