import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { AvatarProps } from '@components/Avatar/Avatar';
import TagsCollection from '@components/Chat/TagsCollection';
import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { TaskPriorityBadge } from '@components/TaskCard/TaskPriorityBadge';
import Text from '@components/Text/Text';
import { Message, TaskMember, TaskPriority, User } from '@graphql/generated';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { TaskTagType } from '@src/types/task';
import { generateTagCollections } from '@utils/tagCollectionService';

type TaskAssignedProps = {
  message: Message;
};

export const TaskAssigned: React.FC<TaskAssignedProps> = ({ message }) => {
  const { t } = useTranslation('format');
  const { attachments } = message;
  const tagsByAuthorCollections = generateTagCollections(attachments || []);

  const navigation = useNavigation();
  const { navigateToTask } = useAppNavigation();

  const taskAssignments = attachments?.filter((item) => {
    return item.__typename === 'MessageTaskAssignment';
  });

  const assigneeList = taskAssignments?.[0].assignees;

  const userAvatars = (members: TaskMember[]): AvatarProps[] => {
    if (assigneeList && assigneeList.length > 0) {
      return (
        assigneeList?.map((user: User) => {
          const { name, id, avatar } = user;
          return {
            id: id,
            label: name,
            avatar: avatar,
            size: 'memberList',
          };
        }) || []
      );
    }
    return (
      members?.map((u) => {
        const { user } = u;
        const { name, id, avatar } = user;
        return {
          id: id,
          label: name,
          avatar: avatar,
          size: 'memberList',
        };
      }) || []
    );
  };

  const navigateToTaskMembers = (task: TaskTagType) => {
    if (Platform.OS === 'web') {
      navigateToTask(task);
      setTimeout(() => {
        navigation.navigate('members', { taskId: task.id });
      }, 1500);
      return;
    }
    if (task.members.length === 1) {
      navigation.navigate('user-profile', {
        userId: task.members[0].id,
      });
    } else {
      navigation.navigate('task-members', { taskId: task.id });
    }
  };

  return (
    <Box
      borderColor='grey02'
      borderWidth={1}
      borderRadius='xs'
      backgroundColor='white'>
      <Tag
        label={t('models:chat.assignedThisTask')}
        labelVariant='labelAssignedTask'
        variant='assignedTask'
        prefixMarginRight='xxs'
        prefix={<Icon name='Clipboard' variant='s' color='pastelMagenta' />}
        padding='xs'
        borderTopLeftRadius='xxs'
        borderTopRightRadius='xxs'
        borderBottomLeftRadius='none'
        borderBottomRightRadius='none'
      />
      {tagsByAuthorCollections.map((tagCollections) => {
        return tagCollections.map((item) => {
          const { project, tasks, author } = item;
          const priority = tasks[0]?.priority;

          return (
            <Box key={project?.id}>
              <TagsCollection
                type='TaskAssign'
                tagsCollection={{ project, tasks, author }}
                showAuthor={false}
                borderless={true}
              />
              <Box
                paddingLeft='xxs'
                paddingRight='xs'
                paddingBottom='xs'
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'>
                {priority && priority !== TaskPriority.Low && (
                  <TaskPriorityBadge priority={priority} />
                )}
                {priority && priority == TaskPriority.Low && (
                  <Box paddingLeft='xxs'></Box>
                )}
                <Text
                  letterSpacing={0.2}
                  variant='labelAssignedTask'
                  color='textPrimary'>
                  {tasks[0]?.dueDate && 'Due:'}
                </Text>

                <Text
                  letterSpacing={0.2}
                  variant='labelAssignedTask'
                  color='textPrimary'>
                  {tasks[0]?.dueDate &&
                    ' ' +
                      t('format:monthAndDay', {
                        val: tasks[0]?.dueDate,
                      })}
                </Text>
                <Box flexDirection='row' flex={1} justifyContent='flex-end'>
                  <MemberListSummary
                    avatars={userAvatars(tasks[0]?.members)}
                    onPress={() => navigateToTaskMembers(tasks[0])}
                    maxAvatars={2}
                    isSmall={true}
                  />
                </Box>
              </Box>
            </Box>
          );
        });
      })}
    </Box>
  );
};
