import React from 'react';
import { FlatList } from 'react-native';

import { Box, Text } from '@components/Restyle';
import RecentSearchItem from '@components/Search/RecentSearchItem.web';
import useGlobalSearch from '@hooks/useGlobalSearch';

export type RecentSearchesProps = {
  onSelect?: () => void;
  boxRef: React.MutableRefObject<HTMLDivElement | undefined>;
};

export const RecentSearches: React.FC<RecentSearchesProps> = ({
  onSelect,
  boxRef,
}) => {
  const { setSearch, history, deleteHistoryItem } = useGlobalSearch();

  return (
    <Box
      accessibilityLabel='Recent Search'
      ref={boxRef}
      zIndex={1}
      flexDirection='column'>
      <Box paddingVertical='s' paddingLeft='l'>
        <Text variant='webSmall'>Recent Search</Text>
      </Box>
      <FlatList
        keyboardDismissMode='on-drag'
        keyboardShouldPersistTaps='always'
        ListEmptyComponent={() => (
          <Text
            accessibilityLabel='No recent searches found'
            variant='webBodySecondary'
            color='grey04'
            textAlign='center'>
            No recent searches found
          </Text>
        )}
        data={history}
        renderItem={({ item, index }) => (
          <RecentSearchItem
            key={index}
            text={item.value}
            onPress={() => {
              setSearch(item.value);

              onSelect?.();
            }}
            onDelete={() => {
              deleteHistoryItem(item.id);
            }}
          />
        )}
      />
    </Box>
  );
};
