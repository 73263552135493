import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';

import { Alert } from '@components/Alert';

export const useSaveImage = () => {
  const { t } = useTranslation('models');

  const [loading, setLoading] = useState(false);

  const saveImage = async (uri: string, name: string, showMsg?: boolean) => {
    if (showMsg === undefined) {
      showMsg = true;
    }
    setLoading(true);
    const extension = name?.includes('.jpg') ? '' : '.jpg';
    const fileUri = FileSystem.documentDirectory + name + extension;
    try {
      const { uri: downloadedUri } = await FileSystem.downloadAsync(
        uri,
        fileUri
      );
      return await save(downloadedUri, showMsg);
    } catch (error) {
      console.error('Error downloading image:', error);
    } finally {
      setLoading(false);
    }
  };

  const save = async (uri: string, _showMsg?: boolean) => {
    const { status } = await MediaLibrary.requestPermissionsAsync();

    if (status === 'granted') {
      const asset = await MediaLibrary.createAssetAsync(uri);

      if (Platform.OS === 'android') {
        const albumName = 'TaskTag';
        const albums = await MediaLibrary.getAlbumsAsync();
        const downloadAlbum = albums.find((album) => album.title === albumName);

        if (!downloadAlbum) {
          await MediaLibrary.createAlbumAsync(albumName, asset, false);
        } else {
          await MediaLibrary.addAssetsToAlbumAsync(asset, downloadAlbum);
        }
      } else {
        // On iOS, add the asset to the 'TaskTag' album directly
        const taskTagAlbum = await MediaLibrary.getAlbumAsync('TaskTag');
        if (!taskTagAlbum) {
          await MediaLibrary.createAlbumAsync('TaskTag', asset, false);
        } else {
          await MediaLibrary.addAssetsToAlbumAsync(asset, taskTagAlbum);
        }
      }
      return true;
    } else {
      setLoading(false);
      Alert.alert(
        t('models:accessDenied.media.title'),
        t('models:accessDenied.media.message'),
        [
          { text: t('shared:cancel') },
          {
            text: t('shared:settings'),
            onPress: () => Linking.openSettings(),
          },
        ]
      );
      return false;
    }
  };
  return {
    saveImage,
    loading,
  };
};
