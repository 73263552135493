import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import CryptoJs from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import OnboardingModal from '@components/Modals/OnboardingModal';
import { modalStyle } from '@navigation/helpers';
import CreateProfile from '@screens/Auth/CreateProfile.web';
import { EmailLogin } from '@screens/Auth/EmailLogin.web';
import { ForgotPassword } from '@screens/Auth/ForgotPassword.web';
import InvitedUser from '@screens/Auth/InvitedUser';
import { LoadingScreen } from '@screens/Auth/LoadingScreen.web';
import Onboarding from '@screens/Auth/Onboarding';
import RegisterForm from '@screens/Auth/RegisterForm.web';
import { ResetPassword } from '@screens/Auth/ResetPassword.web';
import { ImagesPreviewNotLogin } from '@screens/Chats/ImagesPreviewNotLogin.web';

export const secretKey = 'tasktag123321#';

export const setImagesAndUser = () => {
  const encryptedData = decodeURIComponent(window.location.pathname)?.replace(
    '/images-preview/',
    ''
  );
  const decryptedData = CryptoJs.AES.decrypt(encryptedData, secretKey);
  const path = decryptedData.toString(CryptoJs.enc.Utf8);

  const imagesPath = path?.substring(0, path?.indexOf(';'));
  window.sessionStorage.setItem('images_path', imagesPath);

  const user = path?.substring(path?.indexOf(';') + 1);
  const userAvatar = user?.substring(0, user?.indexOf(';'));
  const userName = user?.substring(user?.indexOf(';') + 1);
  window.sessionStorage.setItem('user_avatar', userAvatar);
  window.sessionStorage.setItem('user_name', userName);
};

type AuthStackParamList = {
  onboarding: undefined;
  'onboarding-modal': undefined;
  'invited-user': undefined;
  'onboarding-web': { isLogin?: boolean };
  login: { fromReset?: boolean };
  'password-reset': undefined;
  'create-profile': { socialSignUp?: boolean };
  register: { routeName: string };
  'forgot-password': { email: string };
  LoadingScreen: undefined;
  'images-preview': undefined;
};

export type AuthStackScreenProps<T extends keyof AuthStackParamList> =
  StackScreenProps<AuthStackParamList, T>;

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack: React.FC = () => {
  const { t } = useTranslation();
  const title = t('shared:appName');
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  let invitationToken = queryParameters.get('invitation_token');
  const type = queryParameters.get('type') ?? '';
  const id = queryParameters.get('id') ?? '';
  if (invitationToken == null) {
    invitationToken = window.sessionStorage.getItem('invitation_token') ?? '';
    // this from when user signup -> create team -> invite user ->
    // invited user click invitation link from email inbox, so I put it here -- april TA-5972
    const path = window.location.pathname;
    if (path.startsWith('/account_invitations/')) {
      const code = path.substring('/account_invitations/'.length);
      window.sessionStorage.setItem('invitation_token', code);
      window.sessionStorage.setItem('link_type', 'team');
    }
  } else {
    window.sessionStorage.setItem('invitation_token', invitationToken);
    window.sessionStorage.setItem('link_type', type);
    window.sessionStorage.setItem('task_or_project_id', id);
  }

  const isRegister =
    window.location.pathname === '/register' ||
    window.location.pathname === '/register/signup-with-email';
  const isLogin = window.location.pathname === '/log-in';

  const isInvitedUser =
    window.location.pathname === '/invited-user' ||
    window.location.pathname.startsWith('/account_invitations');
  const isImagesPreview =
    window.location.pathname.startsWith('/images-preview/');

  useEffect(() => {
    if (isImagesPreview) {
      setImagesAndUser();
      setImageCarouselVisible(isImagesPreview);
    }
  }, [isImagesPreview]);

  const getInitialRouteName = () => {
    if (isLogin) {
      return 'login';
    }
    if (isInvitedUser) {
      return 'invited-user';
    }
    if (invitationToken || isRegister) {
      return 'register';
    }
    if (isImageCarouselVisible) {
      return 'images-preview';
    }
    return 'login';
  };

  return (
    <>
      <Stack.Navigator
        screenOptions={{ title }}
        initialRouteName={getInitialRouteName()}>
        <Stack.Screen
          name='login'
          component={EmailLogin}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='LoadingScreen'
          component={LoadingScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='forgot-password'
          component={ForgotPassword}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='password-reset'
          component={ResetPassword}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='register'
          component={RegisterForm}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='onboarding'
          component={Onboarding}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name='onboarding-modal'
          component={OnboardingModal}
          options={{ ...modalStyle }}
        />
        <Stack.Screen
          name='onboarding-web'
          component={OnboardingModal}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name='invited-user'
          component={InvitedUser}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name='create-profile'
          component={CreateProfile}
          options={{
            headerShown: false,
          }}
        />
        {isImageCarouselVisible && (
          <Stack.Screen
            name='images-preview'
            component={EmailLogin}
            options={{ headerShown: false }}
          />
        )}
      </Stack.Navigator>
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreviewNotLogin
          onDismiss={() => setImageCarouselVisible(false)}
        />
      )}
    </>
  );
};

export default AuthStack;
