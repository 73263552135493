import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { ProjectMemberRole } from '@graphql/generated';

export const MemberListActions = ({
  onAddMemberClick,
  role,
  onCopyLinkClick,
  currentUserRole = 'VIEWER',
}: {
  onAddMemberClick: () => void;
  role?: string;
  onCopyLinkClick?: () => void;
  currentUserRole: string;
}) => {
  const { t } = useTranslation();

  const isProjectViewer = role === ProjectMemberRole.Viewer;
  return (
    <Box
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      mx='m'
      my='xs'>
      {!isProjectViewer && (
        <Box mr='l'>
          <TouchableOpacity
            disabled={currentUserRole === 'VIEWER'}
            onPress={onCopyLinkClick}>
            <Box flexDirection='row' alignItems='center'>
              <Icon
                name='LinkColour'
                variant='l'
                color={
                  currentUserRole === 'VIEWER' ? 'grey06' : 'greenSecondary'
                }
              />
              <Text
                variant='labelSmall'
                ml='xxxs'
                color={
                  currentUserRole === 'VIEWER' ? 'grey06' : 'greenSecondary'
                }>
                {t('shared:copyInviteLink')}
              </Text>
            </Box>
          </TouchableOpacity>
        </Box>
      )}
      <Box>
        <TouchableOpacity
          disabled={currentUserRole === 'VIEWER'}
          onPress={onAddMemberClick}>
          <Box
            accessibilityLabel='Add Members'
            flexDirection='row'
            alignItems='center'>
            <Icon
              name='PlusColor'
              variant='l'
              marginTop='s'
              color={currentUserRole === 'VIEWER' ? 'grey06' : 'greenSecondary'}
            />
            <Text
              variant='labelSmall'
              color={
                currentUserRole === 'VIEWER' ? 'grey06' : 'greenSecondary'
              }>
              {t('shared:addMembers')}
            </Text>
          </Box>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};
