import { useNavigation } from '@react-navigation/native';
import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { InferType } from 'yup';

import Box from '@components/Box/Box';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import {
  ListProjectsDocument,
  ListTasksDocument,
  Project,
  Team,
  Task,
  GetTaskMembersDocument,
  useGetTaskQuery,
  User,
  useUpdateTaskMembersMutation,
  TaskMemberRole,
  TaskMemberAttributes,
} from '@graphql/generated';
import useSelectedMembers from '@hooks/useSelectedMembers';
import addMembersSchema from '@schemas/addMembersSchema';
import { AddMembers } from '@screens/Members/AddMembers';
import { EditNewMemberRoles } from '@screens/Members/EditNewMemberRoles';
import { SelectMembersFromTeam } from '@screens/Members/SelectMembersFromTeam';
import {
  defaultTaskRole,
  getTaskRoleEnumValue,
  selectableTaskRoles,
} from '@utils/tasks';

export type FormValues = InferType<typeof addMembersSchema>;
export type AddMembersStackParamsList = {
  'add-members': undefined;
  'add-project-members': { projectId: Project['id'] };
  'add-task-members': { taskId?: Task['id'] };
  'edit-new-member-roles': { isTaskAssignee?: boolean };
  'select-members-from-team': undefined;
  'add-team-members': { projectId?: Project['id']; teamId?: Team['id'] };
};

export type AddMembersStackScreenProps<
  T extends keyof AddMembersStackParamsList
> = StackScreenProps<AddMembersStackParamsList, T>;

const Stack = createStackNavigator<AddMembersStackParamsList>();

interface AddTaskMembersStackProps {
  onComplete?: () => void;
  onClose?: () => void;
  initialRouteName?: keyof AddMembersStackParamsList;
  taskId: string;
  isTaskAssignee: boolean;
  projectId?: string;
  isCreateTask?: boolean;
  members?: AddMemberType[];
  roleCanBeAssigned?: string;
}

export const AddTaskMembersStack: React.FC<AddTaskMembersStackProps> = ({
  onComplete,
  onClose,
  initialRouteName,
  taskId = '',
  isTaskAssignee,
  projectId = '',
  isCreateTask = false,
  members,
  roleCanBeAssigned,
}) => {
  const { t } = useTranslation();

  const { setSelectedMembers, selectedMembers } = useSelectedMembers();

  const setMembersWithRoles = () => {
    const membersWithRoles = selected?.map((s) => {
      return { ...s, role: s.role || defaultTaskRole };
    });

    setSelectedMembers(membersWithRoles || []);
  };

  useEffect(() => {
    setSelected(selectedMembers);
  }, []);

  const { data: taskData, loading } = useGetTaskQuery({
    variables: {
      id: taskId,
    },
    skip: !taskId,
  });

  const { getTask: task } = taskData || {};
  const navigation = useNavigation();
  const [selected, setSelected] = useState<AddMemberType[]>();

  const addMembersCompleted = () => {
    onComplete ? onComplete() : navigation.goBack();
  };

  const [updateTaskMembers] = useUpdateTaskMembersMutation({
    onCompleted: () => {
      addMembersCompleted();
    },
    refetchQueries: [
      { query: GetTaskMembersDocument, variables: { id: taskId } },
      { query: ListTasksDocument },
      { query: ListProjectsDocument },
    ],
  });

  const addMembersToTask = (values: FormValues) => {
    const attributes: TaskMemberAttributes[] = values?.users.map((u) => {
      return {
        userId: u.id,
        role: getTaskRoleEnumValue(u.role),
      };
    });

    updateTaskMembers({
      variables: {
        id: taskId,
        attributes,
      },
    });
  };

  const submitForm = (values: FormValues) => {
    addMembersToTask(values);
  };

  const initialValues: FormValues = {
    users: isCreateTask && members ? members : [],
    selectedUserIds:
      isCreateTask && members
        ? members?.map((u) => u.id) || []
        : task?.members?.map((u) => u.user.id) || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submitForm(values)}
      validationSchema={addMembersSchema}
      validateOnMount>
      {({ setFieldValue, values, submitForm, isSubmitting }) => {
        const { selectedUserIds: selectedUserIdsRaw, users } = values;
        const selectedUserIds = selectedUserIdsRaw?.filter(
          (s) => !!s
        ) as string[];

        return (
          <Stack.Navigator
            initialRouteName={initialRouteName || undefined}
            screenOptions={{
              cardStyle: {
                backgroundColor: 'white',
              },
            }}>
            <Stack.Screen
              name='add-members'
              options={{
                header: () => {
                  return Platform.OS === 'web' && onClose ? (
                    <SimpleHeader title='Add Members' onClose={onClose} />
                  ) : (
                    <SecondaryHeader title='Add Members' searchable={false} />
                  );
                },
              }}>
              {() =>
                !isCreateTask &&
                (!task || (task && !task.members) || loading) ? (
                  <Box flex={1} backgroundColor='white'>
                    <ActivityIndicatorLoading />
                  </Box>
                ) : (
                  <AddMembers
                    onNextPress={() => {
                      setFieldValue(
                        'users',
                        selected?.map((u) => {
                          return {
                            ...u,
                            role:
                              u.role || roleCanBeAssigned || defaultTaskRole,
                          };
                        })
                      );
                      navigation.navigate('edit-new-member-roles');
                    }}
                    disabled={!selected?.length}
                    selectedUserIds={selectedUserIds}
                    users={selected}
                    updateUsers={(newUsers) => setSelected(newUsers)}
                    projectId={projectId}
                    isCreateTask={isCreateTask}
                  />
                )
              }
            </Stack.Screen>
            <Stack.Screen
              name='select-members-from-team'
              options={{
                header: () => <SimpleHeader title='Team' />,
              }}>
              {() => (
                <SelectMembersFromTeam
                  onSubmit={() => {
                    setFieldValue(
                      'users',
                      selected?.map((u) => {
                        return {
                          ...u,
                          role: u.role || defaultTaskRole,
                        };
                      })
                    );
                    navigation.navigate('edit-new-member-roles');
                  }}
                  disabled={!selected?.length}
                  selectedUserIds={selectedUserIds}
                  users={selected}
                  updateUsers={(newUsers) => setSelected(newUsers)}
                />
              )}
            </Stack.Screen>

            <Stack.Screen
              name='edit-new-member-roles'
              options={{
                header: () => {
                  return Platform.OS === 'web' && onClose ? (
                    <SimpleHeader
                      title={t('models:addMembers.assignRole')}
                      onClose={onClose}
                    />
                  ) : (
                    <SecondaryHeader
                      title={t('models:addMembers.assignRole')}
                      searchable={false}
                    />
                  );
                },
              }}>
              {() => (
                <EditNewMemberRoles<TaskMemberRole>
                  roles={selectableTaskRoles}
                  updateMemberRole={(id: User['id'], role: TaskMemberRole) => {
                    const newUsers = users.map((u) => {
                      return {
                        ...u,
                        role: u.id === id ? role : u.role,
                      };
                    });
                    setFieldValue('users', newUsers);
                    setSelected(newUsers);
                  }}
                  usersAndRoles={users.map((u) => {
                    return {
                      user: u,
                      role: u.role,
                    };
                  })}
                  onSubmit={() => {
                    if (isCreateTask) {
                      setMembersWithRoles();
                      setSelected([]);
                      onClose?.();
                    } else {
                      submitForm();
                    }
                  }}
                  isTaskAssignee={isTaskAssignee}
                  isSubmitting={isSubmitting}
                />
              )}
            </Stack.Screen>
          </Stack.Navigator>
        );
      }}
    </Formik>
  );
};
