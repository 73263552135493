'use strict';
import React from 'react';

import Day, { DayPropsType } from './Day';
import { Box } from '../Restyle';

export type DayRowPropsType = {
  dayProps: DayPropsType;
  onSelectDate: string;
  textColor: string;
};

const DayRow = (props: DayRowPropsType) => {
  return (
    <Box
      style={{
        marginBottom: 8,
        marginTop: 8,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flex: 1,
      }}>
      {props.days.map((day) => {
        return (
          <Day
            key={day}
            dayProps={props.dayProps}
            onSelectDate={props.onSelectDate}
            day={day}
            flag={props.flag}
            textColor={props.textColor}
          />
        );
      })}
    </Box>
  );
};

export default DayRow;
