import React, { ComponentProps } from 'react';

import { PopupIcon } from '@components/Web/PopupIcon';
import { Theme } from '@themes/theme';
type Props = Pick<ComponentProps<typeof PopupIcon>, 'backgroundColor'> & {
  onPress: () => void;
  onCompletedTask: () => void;
  iconColor?: keyof Theme['colors'];
};

const ChatAttachments = (props: Props) => {
  const { onPress, onCompletedTask, iconColor = 'grey04' } = props;
  const menuList: ComponentProps<typeof PopupIcon>['popupProps']['menuList'] = [
    {
      name: 'Check in / Location',
      iconName: 'MapPin',
      onSelect: () => {},
      numberOfLines: 1,
      isComingSoon: true,
    },
    {
      name: 'Complete Task',
      iconName: 'Check',
      onSelect: onCompletedTask,
      numberOfLines: 1,
    },
    {
      name: 'Assign Task',
      iconName: 'Clipboard',
      onSelect: () => {},
      numberOfLines: 1,
      isComingSoon: true,
    },
  ];

  return (
    <PopupIcon
      enableHoverEffect={false}
      {...props}
      onPopModal={onPress}
      name='Plus'
      needChangeBgColorHovered={true}
      variant='m'
      color={iconColor}
      popupProps={{
        menuList,
        position: ['right', 'top'],
        offset: [-12, 8],
      }}
    />
  );
};

export default ChatAttachments;
