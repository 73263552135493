import {
  StackActions,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  ScrollView,
  useWindowDimensions,
} from 'react-native';

import { useListProductsQuery, useListTeamsQuery } from '@graphql/generated';
import useRevenueCat from '@hooks/useRevenueCat';
import { MOBILE_PLAN_NAME } from '@src/constants/constants';

import theme from '../../../themes/theme';
import { Plan } from '../../../types';
import BenefitList from '../../components/Payments/BenefitList';
import PaymentOptionCard from '../../components/Payments/PaymentOptionCard';
import PlanTitle from '../../components/Payments/PlanTitle';
import { Box, Text } from '../../components/Restyle';
import Button from '../../components/shared/Button/Button';

const UpgradePlus: React.FC = () => {
  const { t } = useTranslation();

  const { data: teamsData, loading: teamsLoading } = useListTeamsQuery();

  const { data: listProductsData } = useListProductsQuery();

  const mobilePlanList = listProductsData?.listProducts.filter((t) =>
    t.name.toLowerCase().includes(MOBILE_PLAN_NAME)
  );

  const {
    newCustomer,
    setCurrentPlan,
    billingCycle,
    setBillingCycle,
    loading: subscriptionLoading,
    billedAnuallyCost,
    billedAnuallyMonthlyCost,
    billedMonthlyCost,
    annualSavingsPercentage,
    startSubscription,
  } = useRevenueCat();

  const navigation = useNavigation();

  const popAction = StackActions.pop(1);

  useFocusEffect(
    useCallback(() => {
      setCurrentPlan(MOBILE_PLAN_NAME);
    }, [setCurrentPlan])
  );

  const PLUS_PLAN: Plan | undefined = mobilePlanList?.map((plan) => {
    return {
      name: plan.name,
      color: 'orange',
      icon: 'Zap',
      description: plan.allowMembers ? 'For team' : 'For individuals',
      benefits: [
        plan.features.projects_amount + ' projects',
        plan.features.storage_gb_amount + ' GB storage',
        'Unlimited messages',
        plan.features.attachment_expiration_in_days === 'unlimited'
          ? 'Chat attachments do not expire'
          : '',
        'Create ' + plan.features.tasks_amount + ' Tasks',
      ],
      monthlyPayment:
        plan.prices.filter((p) => p.interval === 'month')[0].amount / 100,
      yearlyPayment:
        plan.prices.filter((p) => p.interval === 'year')[0].amount / 100,
      switchPrefix: t('models:payments.teamPlan.cta.prefix'),
      switchSuffix: t('models:payments.teamPlan.cta.suffix'),
    };
  })[0];

  const { width } = useWindowDimensions();

  const PAYMENT_OPTION_HEIGHT = width / 2 - theme.spacing.m * 3;

  const purchasePlus = () => {
    const teamId = teamsData?.listTeams.find((t) => t.personal)?.id;
    startSubscription(teamId, () => {
      navigation.dispatch(popAction);
    });
  };

  const purchaseButtonText = () => {
    return newCustomer
      ? t('models:payments.freeTrialCta')
      : t('models:payments.subscribeNow');
  };

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
        }}>
        {!PLUS_PLAN && (
          <Text variant='labelLarge'>No available plan found.</Text>
        )}
        {PLUS_PLAN && (
          <Box
            flex={1}
            marginTop='s'
            paddingBottom='listFooter'
            paddingHorizontal='m'
            paddingTop='m'>
            <PlanTitle planName={PLUS_PLAN.name} {...PLUS_PLAN} />
            <Box
              style={{ marginTop: 32 }}
              paddingBottom='m'
              borderBottomColor='grey02'
              borderBottomWidth={1}>
              <Text variant='labelLarge'>
                {t('models:payments.benefits.prefix')}{' '}
                <Text
                  variant='labelLarge'
                  fontFamily='Inter_600SemiBold'
                  color={PLUS_PLAN.color}>
                  {PLUS_PLAN.name}
                </Text>{' '}
                {t('models:payments.benefits.suffix')}
              </Text>
            </Box>
            <Box marginTop='m'>
              <BenefitList {...PLUS_PLAN} />
            </Box>
            <Box flexDirection='row' style={{ marginTop: 32 }}>
              <Box
                style={{ height: PAYMENT_OPTION_HEIGHT, flex: 1 }}
                paddingRight='xs'>
                {billedAnuallyCost && (
                  <PaymentOptionCard
                    billingCycle='annually'
                    monthlyCost={parseInt(
                      billedAnuallyMonthlyCost?.toFixed(0) || ''
                    )}
                    setBillingCycle={(cycle) => setBillingCycle(cycle)}
                    totalCost={parseInt(billedAnuallyCost?.toFixed(1) || '0')}
                    isSelected={billingCycle === 'annually'}
                    savingPercentage={annualSavingsPercentage}
                  />
                )}
              </Box>
              <Box
                style={{ height: PAYMENT_OPTION_HEIGHT, flex: 1 }}
                paddingLeft='xs'>
                {billedMonthlyCost && (
                  <PaymentOptionCard
                    billingCycle='monthly'
                    monthlyCost={parseInt(billedMonthlyCost.toFixed(0) || '')}
                    setBillingCycle={(cycle) => setBillingCycle(cycle)}
                    isSelected={billingCycle === 'monthly'}
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </ScrollView>
      {PLUS_PLAN && (
        <Button
          variant='primary'
          float='bottom-inset'
          onPress={purchasePlus}
          disabled={teamsLoading}>
          {!subscriptionLoading ? purchaseButtonText() : <ActivityIndicator />}
        </Button>
      )}
    </>
  );
};

export default UpgradePlus;
