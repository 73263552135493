import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Platform } from 'react-native';
import Purchases, {
  CustomerInfo,
  PurchasesOfferings,
} from 'react-native-purchases';

import { BillingCycle, PlanName, SubscriptionStatus } from '@root/types';
import {
  MOBILE_PLAN_NAME,
  SUBSCRIPTION_STATUS,
} from '@src/constants/constants';

type RevenueCatContext = {
  customerInfo: CustomerInfo | null;
  managementUrl: string | null;
  newCustomer: boolean;
  subscriptionStatus: SubscriptionStatus;
  loading: boolean;
  startSubscription: (teamId?: string, callback?: () => void) => void;
  offerings: PurchasesOfferings | null;
  currentPlan: typeof MOBILE_PLAN_NAME;
  setCurrentPlan: React.Dispatch<React.SetStateAction<PlanName>>;
  billingCycle: BillingCycle;
  setBillingCycle: React.Dispatch<React.SetStateAction<BillingCycle>>;
  billedAnuallyCost: number | undefined;
  billedAnuallyMonthlyCost: number | undefined;
  billedMonthlyCost: number | undefined;
  annualSavingsPercentage: number | undefined;
};

const revenueCatContext = createContext<RevenueCatContext | undefined>(
  undefined
);

export const RevenueCatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = revenueCatContext;
  const [managementUrl, setManagementUrl] = useState<string | null>(null);
  const [currentPlan, setCurrentPlan] = useState<PlanName>(MOBILE_PLAN_NAME);
  const [newCustomer, setNewCustomer] = useState(true);
  const [billingCycle, setBillingCycle] = useState<BillingCycle>('annually');
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus>(SUBSCRIPTION_STATUS.UNSUBSCRIBED);
  const [loading, setLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | null>(null);
  const [offerings, setOfferings] = useState<PurchasesOfferings | null>(null);

  const getCustomerInfo = async () => {
    const customerInfo = await Purchases.getCustomerInfo();
    setCustomerInfo(customerInfo);
  };

  // here revenue set the identifier to this
  const identifier = currentPlan.toString().startsWith('tasktag')
    ? currentPlan
    : 'tasktag_' + currentPlan.toString();

  const getOfferings = async () => {
    try {
      const offerings = await Purchases.getOfferings();
      if (offerings.current?.availablePackages.length) {
        setOfferings(offerings);
      }
    } catch (e) {
      console.log({ error: e });
    }
  };

  const startSubscription = useCallback(
    async (teamId?: string, callback?: () => void) => {
      console.log('starting subscription...', {
        teamId,
        currentPlan,
        billingCycle,
        offerings,
      });
      if (!offerings) {
        console.log('no offerings');
        return;
      }
      if (!teamId) {
        console.log('no teamID');
        return;
      }

      setLoading(true);

      const offeringName =
        billingCycle === 'annually' ? 'annual_offering' : 'monthly_offering';

      const rcPackage = offerings.all[offeringName]?.availablePackages.find(
        (p) => p.identifier === identifier
      );

      if (!rcPackage) {
        console.log('no package found');
        setLoading(false);
        return;
      }

      try {
        await Purchases.setAttributes({
          team_id: teamId,
          accountId: teamId,
          account_id: teamId,
        });

        const { customerInfo, productIdentifier } =
          await Purchases.purchasePackage(rcPackage);

        console.log('purchased package');
        console.log({ customerInfo, productIdentifier });

        if (
          typeof customerInfo?.entitlements?.active[identifier] !== 'undefined'
        ) {
          setLoading(false);
          callback?.();
        }
        console.log({ entitlementID: customerInfo.entitlements.active });
      } catch (error) {
        console.log('error purchasing package', error);
        setLoading(false);
      }
    },
    [billingCycle, currentPlan, offerings]
  );

  const customerInfoUpdateHandler = useCallback((info: CustomerInfo) => {
    console.log('setting customer info from handler');
    setCustomerInfo(info);
  }, []);

  const annualPlan = useCallback(() => {
    if (!offerings) return;
    const rcPackage = offerings.all['annual_offering']?.availablePackages.find(
      (p) => p.identifier === identifier
    );
    return rcPackage?.product;
  }, [offerings, currentPlan]);

  const monthlyPlan = useCallback(() => {
    if (!offerings) return;
    const rcPackage = offerings.all['monthly_offering']?.availablePackages.find(
      (p) => p.identifier === identifier
    );
    return rcPackage?.product;
  }, [offerings, currentPlan]);

  const billedAnuallyCost = annualPlan()?.price;
  const billedAnuallyMonthlyCost = billedAnuallyCost && billedAnuallyCost / 12;
  const billedMonthlyCost = monthlyPlan()?.price;

  const calculateAnnualSavingPercentage = useCallback(() => {
    if (!billedAnuallyCost || !billedMonthlyCost) {
      return undefined;
    }
    const percentage =
      ((billedMonthlyCost * 12 - billedAnuallyCost) /
        (billedMonthlyCost * 12)) *
      100;
    return Math.trunc(percentage);
  }, [billedAnuallyCost, billedMonthlyCost]);
  const annualSavingsPercentage = calculateAnnualSavingPercentage();

  useEffect(() => {
    if (!customerInfo) return;
    const customerIsNew = customerInfo.allPurchasedProductIdentifiers.some(
      (ident) => ident === MOBILE_PLAN_NAME
    );
    setNewCustomer(customerIsNew);
    if (customerInfo.activeSubscriptions.length < 1) {
      setSubscriptionStatus(SUBSCRIPTION_STATUS.UNSUBSCRIBED);
    } else {
      setSubscriptionStatus(SUBSCRIPTION_STATUS.SUBSCRIBED);
    }
    setManagementUrl(customerInfo.managementURL);
  }, [customerInfo]);

  useEffect(() => {
    if (Platform.OS === 'web') return;

    const checkTrialOrIntroductoryPriceEligibility = async () => {
      if (Platform.OS !== 'ios') return;
      const eligible = await Purchases.checkTrialOrIntroductoryPriceEligibility(
        [identifier]
      );

      if (eligible) {
        setNewCustomer(true);
      }
    };
    checkTrialOrIntroductoryPriceEligibility();
    getCustomerInfo();
    getOfferings();

    Purchases.addCustomerInfoUpdateListener(customerInfoUpdateHandler);

    return () => {
      Purchases.removeCustomerInfoUpdateListener(customerInfoUpdateHandler);
    };
  }, [customerInfoUpdateHandler]);

  return (
    <Provider
      value={{
        customerInfo,
        managementUrl,
        newCustomer,
        subscriptionStatus,
        loading,
        startSubscription,
        offerings,
        currentPlan,
        setCurrentPlan,
        billingCycle,
        setBillingCycle,
        billedAnuallyCost,
        billedAnuallyMonthlyCost,
        billedMonthlyCost,
        annualSavingsPercentage,
      }}>
      {children}
    </Provider>
  );
};

const useRevenueCat = (): RevenueCatContext => {
  const context = useContext(revenueCatContext);
  if (context === undefined) {
    throw new Error('useRevenueCat must be used within a Provider');
  }
  return context;
};

export default useRevenueCat;
