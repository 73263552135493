import { WatchQueryFetchPolicy } from '@apollo/client';
import { useState } from 'react';

import { useListRecentProjectsTasksQuery } from '@graphql/generated';
/**
 * Retrieves a list of project tags
 */

interface ListRecentProjectsTasksQueryProps {
  archivesOnly?: boolean;
  onCompleted?: () => void;
  onError?: () => void;
  chatId?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  excludeCompleted?: boolean;
  collaboratorsOnly?: boolean;
}

export const useListRecentProjectsTasksFromQuery = (
  props?: ListRecentProjectsTasksQueryProps
) => {
  const {
    onCompleted = undefined,
    onError = undefined,
    chatId = undefined,
    excludeCompleted = false,
    collaboratorsOnly = false,
    fetchPolicy = 'cache-and-network',
  } = props || {};

  const [, setRefreshing] = useState(false);

  const { data, loading, error } = useListRecentProjectsTasksQuery({
    variables: {
      chatId,
      excludeCompleted,
      collaboratorsOnly,
    },

    refetchQueries: [
      { query: 'listRecentProjectsTasks', variable: { chatId: chatId } },
    ],
    fetchPolicy: fetchPolicy,
    onCompleted: () => {
      setRefreshing(false);
      onCompleted && onCompleted();
    },
    onError: () => {
      setRefreshing(false);
      onError && onError();
    },
  });

  const { listRecentProjectsTasks } = data || {
    listRecentProjectsTasks: undefined,
  };

  // pagination returns duplicate projects when projects are named the same resulting in duplicate key errors
  // can be removed when pagination is fixed

  return {
    recentProjectsTasks: listRecentProjectsTasks,
    loading,
    error,
  };
};
