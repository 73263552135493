import { useNavigation, useRoute } from '@react-navigation/native';
import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { InferType } from 'yup';

import { Alert } from '@components/Alert';
import { AddMembersSection } from '@components/Common/AddMembersSection';
import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import {
  GetProjectDocument,
  Project,
  useGetProjectMembersQuery,
  Team,
  useUpdateProjectMembersMutation,
  ProjectMemberAttributes,
  ProjectMemberRole,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import { ProjectsStackScreenProps } from '@navigation/projects/projects-stack';
import addMembersSchema from '@schemas/addMembersSchema';
import { AddMembers } from '@screens/Members/AddMembers';
import { EditNewMemberRoles } from '@screens/Members/EditNewMemberRoles';
import { SelectMembersFromTeam } from '@screens/Members/SelectMembersFromTeam';
import { defaultProjectRole, selectableProjectRoles } from '@utils/projects';

export type FormValues = InferType<typeof addMembersSchema>;
export type AddMembersStackParamsList = {
  'add-members': undefined;
  'add-project-members': { projectId: Project['id'] };
  'add-task-members': undefined;
  'edit-new-member-roles': undefined;
  'add-team-members': { projectId?: Project['id']; teamId?: Team['id'] };
  'select-members-from-team': undefined;
};

export type AddMembersStackScreenProps<
  T extends keyof AddMembersStackParamsList
> = StackScreenProps<AddMembersStackParamsList, T>;

const Stack = createStackNavigator<AddMembersStackParamsList>();
interface Avatar {
  __typename: string;
  cdnBaseUrl: string;
  id: string;
  path: string;
  url: string;
}

interface User {
  avatar: Avatar;
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  phoneNumber: string;
  role: string;
  type?: 'GROUP' | 'INDIVIDUAL';
}
interface AddProjectMembersStackProps {
  members?: AddMemberType[];
  isCreateProject?: boolean;
  isCreateContactGroup?: boolean;
  teamId?: string;
  onComplete?: () => void;
  onClose?: () => void;
  initialRouteName?: keyof AddMembersStackParamsList;
  showContactGroups?: boolean;
}

export const AddProjectMembersStack: React.FC<AddProjectMembersStackProps> = ({
  members,
  isCreateProject = false,
  isCreateContactGroup = false,
  teamId,
  onComplete,
  onClose,
  initialRouteName,
}) => {
  const { t } = useTranslation();
  const { me } = useMe();
  const {
    params: {
      projectId = '',
      selectedTeamId = '',
      isCreateProjectForMobile = false,
      memberCount,
    } = {},
  } =
    useRoute<ProjectsStackScreenProps<'project-add-members-stack'>['route']>();
  const [selected, setSelected] = useState<AddMemberType[]>();

  const { setSelectedMembers, selectedMembers } = useSelectedMembers();
  const [isLoading, setLoading] = useState(false);

  const setMembersWithRoles = () => {
    const membersWithRoles = selected?.map((s) => {
      return { ...s, role: s.role || defaultProjectRole };
    });

    setSelectedMembers(membersWithRoles || []);
  };

  useEffect(() => {
    setSelected(selectedMembers);
  }, [selectedMembers.length]);

  const { data: { getProject: project } = {} } = useGetProjectMembersQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId,
  });
  const navigation = useNavigation();

  const addMembersCompleted = () => {
    setLoading(false);

    onComplete ? onComplete() : navigation.goBack();
  };

  const [updateProjectMembers] = useUpdateProjectMembersMutation({
    onCompleted: () => addMembersCompleted(),
    // eslint-disable-next-line no-console
    onError: (e) => console.error('err updating project', e),
    refetchQueries: [
      { query: GetProjectDocument, variables: { id: projectId } },
      'listProjects',
      'listProjectsRecent',
    ],
  });

  const addMembersToProject = (values: FormValues) => {
    const attributes: ProjectMemberAttributes[] = values?.users.map((u) => {
      const role = u.role as ProjectMemberRole;
      const id = u?.type === 'GROUP' ? me?.id : u.id;
      const groupId = u?.type === 'GROUP' ? u.id : '';

      return {
        userId: id,
        groupId: groupId,
        role: role,
      };
    });

    updateProjectMembers({
      variables: {
        id: projectId,
        attributes,
      },
    });
  };

  const submitForm = (values: FormValues) => {
    setLoading(true);
    addMembersToProject(values);
  };
  if (projectId && (!project || (project && !project.members)))
    return <ActivityIndicatorLoading />;

  const initialValues: FormValues = {
    users: isCreateProject && members ? members : [],
    selectedUserIds: isCreateProject
      ? members?.map((u) => u.id) || []
      : project?.members?.map((u) => u.user.id) || [],
  };
  const currentRole = () => {
    const roleArr = project?.members
      ?.filter((u) => u.user.id == me?.id)
      .map((u) => u.role);
    return roleArr && roleArr.length > 0 ? roleArr[0] : null;
  };

  const updateMember = (userId: string, role: string) => {
    const members = selectedMembers?.map((u) => {
      if (u.id !== userId) return u;
      else {
        return { ...u, role: getProjectRoleEnumValue(role) };
      }
    });
    setSelectedMembers(members);
  };

  const removeMemberAlert = (memberId: string) => {
    Alert.alert(
      t('models:editMembers.removeMember.removeTitle'),
      t('models:editMembers.removeMember.messageGeneric'),
      [
        {
          text: t('shared:cancel'),
        },
        {
          text: t('shared:remove'),

          onPress: () => {
            setSelectedMembers(
              selectedMembers?.filter((u) => u.id !== memberId)
            );
          },
        },
      ]
    );
  };

  const projectOwner = {
    id: me?.id || '',
    firstName: me?.firstName || '',
    lastName: me?.lastName || '',
    name: me?.name || '',
    avatar: me?.avatar,
    phoneNumber: me?.phoneNumber || '',
    role: ProjectMemberRole.Owner,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submitForm(values)}
      validationSchema={addMembersSchema}
      validateOnMount>
      {({ setFieldValue, values, isSubmitting }) => {
        const { selectedUserIds: selectedUserIdsRaw, users } = values;
        const selectedUserIds = selectedUserIdsRaw?.filter(
          (s) => !!s
        ) as string[];

        return (
          <Stack.Navigator
            initialRouteName={initialRouteName || undefined}
            screenOptions={{
              cardStyle: {
                backgroundColor: 'white',
              },
            }}>
            <Stack.Screen
              name='add-members'
              options={{
                header: () => {
                  return Platform.OS === 'web' && onClose ? (
                    <SimpleHeader title='Add Members' onClose={onClose} />
                  ) : (
                    <SecondaryHeader
                      title='Add Members'
                      searchable={false}
                      goBack
                    />
                  );
                },
              }}>
              {() =>
                memberCount && memberCount > 0 ? (
                  <AddMembersSection
                    isCreateProject
                    isFromTaskModal
                    members={selectedMembers}
                    users={[projectOwner, ...selectedMembers]}
                    updateMember={(member, role) =>
                      updateMember(member.id, role)
                    }
                    removeMember={(member) => removeMemberAlert(member.id)}
                    addMembers={() => {
                      navigation.navigate('project-add-members-stack', {
                        selectedTeamId: teamId,
                        isCreateProjectForMobile: true,
                      });
                    }}
                  />
                ) : (
                  <AddMembers
                    isCreateProject={
                      isCreateProject || isCreateProjectForMobile
                    }
                    disabled={!selected?.length}
                    isFromProjectOnly={true}
                    onNextPress={() => {
                      setFieldValue(
                        'users',
                        selected?.map((u) => ({
                          ...u,
                          role: u.role || defaultProjectRole,
                        }))
                      );
                      if (isCreateContactGroup) {
                        setMembersWithRoles();
                        setSelected([]);
                        onClose?.();
                      } else {
                        navigation.navigate('edit-new-member-roles');
                      }
                    }}
                    selectedUserIds={selectedUserIds}
                    updateUsers={(newUsers) => setSelected(newUsers)}
                    users={selected}
                    isFromEditProject={!!projectId || isCreateProjectForMobile}
                    teamId={teamId || selectedTeamId}
                  />
                )
              }
            </Stack.Screen>

            <Stack.Screen
              name='select-members-from-team'
              options={{
                header: () => <SimpleHeader title='Team' />,
              }}>
              {() => (
                <SelectMembersFromTeam
                  onSubmit={() => {
                    setFieldValue(
                      'users',
                      selected?.map((u) => {
                        return {
                          ...u,
                          role: u.role || defaultProjectRole,
                        };
                      })
                    );
                    navigation.navigate('edit-new-member-roles');
                  }}
                  disabled={!selected?.length}
                  selectedUserIds={selectedUserIds}
                  users={selected}
                  updateUsers={(newUsers) => setSelected(newUsers)}
                />
              )}
            </Stack.Screen>

            <Stack.Screen
              name='edit-new-member-roles'
              options={{
                header: () => {
                  return Platform.OS === 'web' && onClose ? (
                    <SimpleHeader
                      title={t('models:addMembers.assignRole')}
                      onClose={onClose}
                    />
                  ) : (
                    <SecondaryHeader
                      title={t('models:addMembers.assignRole')}
                      searchable={false}
                    />
                  );
                },
              }}>
              {() => (
                <EditNewMemberRoles<ProjectMemberRole>
                  onSubmit={() => {
                    if (isCreateProject) {
                      setMembersWithRoles();
                      setSelected([]);
                      onClose?.();
                    } else if (isCreateProjectForMobile) {
                      setMembersWithRoles();
                      setSelected([]);
                      navigation.goBack();
                    } else {
                      submitForm(values);
                    }
                  }}
                  isSubmitting={isSubmitting || isLoading}
                  roles={selectableProjectRoles}
                  currentRole={currentRole()}
                  updateMemberRole={(
                    id: User['id'],
                    role: ProjectMemberRole
                  ) => {
                    const newUsers = users.map((u) => {
                      return {
                        ...u,
                        role: u.id === id ? role : u.role,
                      };
                    });
                    setFieldValue('users', newUsers);
                    setSelected(newUsers);
                  }}
                  usersAndRoles={users.map((u) => {
                    return {
                      user: u,
                      role: u.role,
                    };
                  })}
                />
              )}
            </Stack.Screen>
          </Stack.Navigator>
        );
      }}
    </Formik>
  );
};
