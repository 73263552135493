import { getApiConfig } from '@utils/getApiConfig';
import { AsyncStorage } from '@utils/storage';
export const useFetchContentTypes = () => {
  const fetchAllowedContentTypes = async () => {
    const apiConfig = getApiConfig();
    const allowedContentTypesEndpoint = `/api/v3/allowed_content_types`;

    const apiEndpoint = `${apiConfig.apiUrl}${allowedContentTypesEndpoint}`;

    try {
      const response = await fetch(apiEndpoint);
      const data = await response.json();
      const mimeTypesArray = Object.keys(data);
      await AsyncStorage.setItem(
        'allowedContentTypes',
        JSON.stringify(mimeTypesArray)
      );
      await AsyncStorage.setItem('allContentTypes', JSON.stringify(data));
    } catch (err) {
      console.log('Content Types Fetch Error:', err);
    }
  };
  return { fetchAllowedContentTypes };
};
