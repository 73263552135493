import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Box, ShadowBox } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import Text from '@components/Text/Text';
import Images from '@themes/images';
import theme from '@themes/theme';

export type ProjectsStatusPopupProps = {
  onPressActiveProjects?: (newTitle: string) => void;
  onPressArchiveProjects?: (newTitle: string) => void;
};

export const ProjectsStatusPopup: React.FC<ProjectsStatusPopupProps> = ({
  onPressActiveProjects,
  onPressArchiveProjects,
}) => {
  const { t } = useTranslation('models');

  const [hoveredId] = useState('');

  const renderItem = (
    title: string,
    icon: keyof typeof Images,
    onPress: () => void
  ) => {
    return (
      <TouchableOpacity onPress={onPress} accessibilityLabel={title}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-start'
          style={{
            height: 32,
            width: 175,
            backgroundColor:
              hoveredId !== '' && hoveredId == title
                ? theme.colors.grey01
                : theme.colors.white,
          }}>
          <Icon
            variant='s'
            marginLeft='xs'
            name={icon}
            backgroundColor={
              hoveredId !== '' && hoveredId == title ? 'grey01' : 'white'
            }
            color='textPrimary'
          />
          <Text variant='webBodySecondary' mr='xs'>
            {title}
          </Text>
        </Box>
      </TouchableOpacity>
    );
  };
  return (
    <ShadowBox
      flex={1}
      width={175}
      alignItems='center'
      justifyContent='center'
      borderRadius='xs'
      variant='base'
      backgroundColor='white'>
      <Box marginVertical='s'>
        {renderItem(t('projects.buttons.activeProjects'), 'RefreshCcw', () => {
          onPressActiveProjects?.(t('projects.buttons.activeProjects'));
        })}
        {renderItem(t('projects.buttons.archivedProjects'), 'Archive', () => {
          onPressArchiveProjects?.(t('projects.buttons.archivedProjects'));
        })}
      </Box>
    </ShadowBox>
  );
};
