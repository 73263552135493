import { CommonActions, useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Box, Text } from '@components/Restyle';
import RestyleIcon from '@components/shared/Icon/RestyleIcon';

type Props<T> = {
  params?: T;
  redirectTo: string;
  titleHeader: string;
  noSpacing?: boolean;
};

export const HeaderGoBack = <T,>({
  params,
  redirectTo,
  titleHeader,
  noSpacing,
}: Props<T>) => {
  const navigation = useNavigation();

  return (
    <Box
      flexDirection='row'
      marginHorizontal={noSpacing ? 'none' : 'm'}
      marginTop={noSpacing ? 'none' : 'l'}
      marginBottom='m'>
      <TouchableOpacity
        style={Platform.OS === 'web' && { cursor: 'pointer' }}
        onPress={() => {
          if (Platform.OS === 'web' && redirectTo === 'my-profile') {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [
                  {
                    name: 'my-account',
                    params: {
                      screen: 'tabs',
                    },
                  },
                ],
              })
            );
          } else navigation.navigate(redirectTo, { ...(params || {}) });
        }}
        hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
        <RestyleIcon name='ChevronLeft' variant='l' color='textPrimary' />
      </TouchableOpacity>

      <Box flex={1} flexDirection='row' marginLeft='xs'>
        <Text variant='labelLarge' color='textPrimary' textAlign='left'>
          {titleHeader}
        </Text>
      </Box>
    </Box>
  );
};
