import { useFetchContentTypes } from '@utils/fetchContentTypes';
import { AsyncStorage } from '@utils/storage';

export const useFileValidation = () => {
  const { fetchAllowedContentTypes } = useFetchContentTypes();
  const isValidExtension = async (contentType: string) => {
    const validExtensionTypes = await AsyncStorage.getItem(
      'allowedContentTypes'
    );
    if (!validExtensionTypes) {
      await fetchAllowedContentTypes();
      return isValidExtension(contentType);
    }
    return validExtensionTypes?.includes(contentType);
  };

  return { isValidExtension };
};
