import React, { useState } from 'react';
import { Dimensions, ScrollView, TouchableOpacity } from 'react-native';

import ProjectCard from '@components/ProjectCard/ProjectCard.web';
import { Box } from '@components/Restyle';
import { Project } from '@graphql/generated';
import theme from '@themes/theme';

interface ProjectCardGridProps {
  numColumns: number;
  highlightedValue?: string;
  list: Project[];
  onPress: (project: Project) => void;
}

export const ProjectCardGrid: React.FC<ProjectCardGridProps> = ({
  numColumns,
  highlightedValue,
  list,
  onPress,
}) => {
  const [bodyWidth, setBodyWidth] = useState(0);
  const marginRight =
    Dimensions.get('window').width <= 1280 ? theme.spacing.xs : theme.spacing.m;
  const cardWidth = (bodyWidth - (numColumns - 1) * marginRight) / numColumns;

  return (
    <Box
      flex={1}
      alignContent='center'
      justifyContent='center'
      onLayout={(e) => setBodyWidth(e.nativeEvent.layout.width)}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Box flexDirection='row' flexWrap='wrap' marginBottom='listFooter'>
          {list.map((item, index) => {
            return (
              <TouchableOpacity key={item.id} onPress={() => onPress?.(item)}>
                <Box
                  style={[
                    {
                      width: cardWidth,
                      flexDirection: 'row',
                      marginRight: (index + 1) % 4 ? marginRight : 0,
                      marginBottom: theme.spacing.m,
                    },
                  ]}>
                  <ProjectCard
                    width={cardWidth}
                    expanded={true}
                    project={item}
                    onClick={() => onPress(item)}
                    filterVal={highlightedValue || ''}
                  />
                </Box>
              </TouchableOpacity>
            );
          })}
        </Box>
      </ScrollView>
    </Box>
  );
};
