import dayjs from 'dayjs';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, FlatList, TouchableOpacity } from 'react-native';

import { ContactType } from '@components/Invite/Contact';
import ProjectCard from '@components/ProjectCard/ProjectCard.web';
import ProjectList from '@components/ProjectList/ProjectList.web';
import {
  getShowProjectLimitSnackBarNextTime,
  saveShowProjectLimitSnackBarNextTime,
} from '@components/Projects/ProjectForm.web';
import { Box } from '@components/Restyle/index';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { MultiTags } from '@components/shared/Tags/MultiTags.web';
import { SnackbarForProject } from '@components/Snackbar/SnackbarForProject';
import Text from '@components/Text/Text';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupProjectButtonWeb } from '@components/Web/PopupProjectButton.web';
import { PopupProjectsFiltersButtonWeb } from '@components/Web/PopupProjectsFiltersButton.web';
import { ProjectSortOption, Team } from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { useListProjectsFromQuery } from '@hooks/useListProjectsFromQuery';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';

const PROJECT_LIMIT = 4;

export const AllProjects: React.FC = () => {
  const { t } = useTranslation('models');
  const navigation = useAppNavigation();
  const { isOpenChat } = useActiveChat();
  const {
    chatListOpen,
    setIsCreateProjectOpen,
    setEditProjectId,
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
  } = useWebDrawer();
  const { me } = useMe();
  const { teams } = useListTeamsFromQuery();
  const [hasActiveOrTrialingTeam, setHasActiveOrTrialingTeam] =
    useState<boolean>(false);
  const [projectLeft, setProjectLeft] = useState<number>(0);
  const [showProjectLimitSnackBar, setShowProjectLimitSnackBar] =
    useState(false);
  const [hideProjectLimitSnackBar, setHideProjectLimitSnackBar] =
    useState(false);
  const [archivesOnly, setArchivesOnly] = useState(false);
  const { selectedProject, setSelectedProject } = useWebDrawerNavigator();
  useEffect(() => {
    selectedProject && setSelectedProject(undefined);
  }, []);
  const [selectedTeams, setSeletectedTeams] = useState<
    Pick<Team, 'id' | 'name' | 'avatar' | 'usersCount'>[]
  >([]);
  const [selectedOwners, setSelectedOwners] = useState<ContactType[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<ContactType[]>([]);

  const [barWidth, setBarWidth] = useState(0);
  const [filterTagAreaWidth, setFilterTagAreaWidth] = useState(0);
  const [filterOwnerTagWidth, setFilterOwnerTagWidth] = useState(0);
  const [filterTeamTagWidth, setFilterTeamTagWidth] = useState(0);
  const [filterMemberTagWidth, setFilterMemberTagWidth] = useState(0);
  const [clearButtonWidth, setClearButtonWidth] = useState(30);
  const [isHovered, setIsHovered] = useState(false);
  const [bodyWidth, setBodyWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [search] = useState('');
  const [showGrid, setShowGrid] = useState(false);
  const marginRight =
    Dimensions.get('window').width <= 1280 ? theme.spacing.xs : theme.spacing.m;
  const cardWidth =
    (bodyWidth - marginRight * (PROJECT_LIMIT - 1)) / PROJECT_LIMIT;
  const recentProjectsRef = createRef<FlatList>();

  const windowWidth = Dimensions.get('window').width;
  const { setMainPanelWidth } = useWebDrawer();

  const offsetPopButton1: Partial<any> = {
    position: 'right',
    offset: [0, 6],
  };

  const offsetPopButton: Partial<any> = {
    position: 'left',
    offset: [0, 6],
  };
  const spaceBetweenTags = 6;

  const { projects, loading, refreshControl, fetchFromCursor, refreshing } =
    useListProjectsFromQuery({
      first: 25,
      sortBy: ProjectSortOption.NameAsc,
      archivesOnly: archivesOnly,
      ownerIds: [...selectedOwners.map((t) => t.id)],
      memberIds: [...selectedMembers.map((t) => t.id)],
      teamIds: [...selectedTeams.map((t) => t.id)],
    });

  const { projects: projectsRecent } = useListProjectsFromQuery({
    first: PROJECT_LIMIT,
    sortBy: ProjectSortOption.ActivityDesc,
  });

  const type = window.sessionStorage.getItem('link_type');
  const id = window.sessionStorage.getItem('task_or_project_id');

  setTimeout(() => {
    if (type === 'project') {
      navigation.navigateToProject({ id: id });
    } else if (type === 'task') {
      navigation.navigateToTask({ id: id });
    }
    window.sessionStorage.setItem('link_type', '');
    window.sessionStorage.setItem('task_or_project_id', '');
  }, 1);

  const needShowProjectLimitSnackBar = async () => {
    const savedTimes = await getShowProjectLimitSnackBarNextTime();
    const savedTime = savedTimes?.find(
      (item) => item.userId === me?.id && item.teamId === '0'
    );
    if (savedTime) {
      if (dayjs(Date()).diff(new Date(savedTime.nextTime)) < 0) {
        return;
      }
    }
    setShowProjectLimitSnackBar(true);
  };

  useEffect(() => {
    let projectLeft = 0;
    teams.forEach((item) => {
      if (
        item &&
        item.plan?.name === 'Team' &&
        (item.plan?.status === 'trialing' || item.plan?.status === 'active')
      ) {
        setShowProjectLimitSnackBar(false);
        setHasActiveOrTrialingTeam(true);
        return;
      }
      if (item?.maxNumberOfProjects) {
        const projectLeft0 =
          (item.maxNumberOfProjects ?? 0) - (item.numberOfProjectsUsed ?? 0);
        if (projectLeft0 > 0) projectLeft += projectLeft0;
      }
    });
    setProjectLeft(projectLeft);
    if (!hasActiveOrTrialingTeam && projectLeft < 4) {
      if (projectLeft < 1) {
        setShowProjectLimitSnackBar(true);
      } else {
        needShowProjectLimitSnackBar();
      }
    } else {
      setShowProjectLimitSnackBar(false);
    }
  }, [teams]);

  if (loading && projects?.length < 1 && projectsRecent.length < 1)
    return <ActivityIndicatorLoading />;

  const OwnersTag = () => {
    if (selectedOwners.length === 0) return <></>;
    return (
      <Box
        onLayout={(event) => {
          setFilterOwnerTagWidth(event.nativeEvent.layout.width);
        }}>
        <MultiTags
          prefixText='Owner: '
          key='dateRange'
          labelList={selectedOwners}
          labelVariant='metadata'
          variant='pickerForWeb'
          padding='xxs'
          marginTop='xxs'
          borderRadius='xxs'
          onPressClose={(ownerid) => {
            setSelectedOwners(selectedOwners.filter((c) => c.id != ownerid));
          }}
        />
      </Box>
    );
  };

  const TeamsTag = () => {
    if (selectedTeams.length == 0) return <></>;
    return (
      <Box
        onLayout={(event) => {
          setFilterTeamTagWidth(event.nativeEvent.layout.width);
        }}>
        <MultiTags
          prefixText='Team: '
          key='dateRange'
          labelList={selectedTeams}
          labelVariant='metadata'
          variant='pickerForWeb'
          padding='xxs'
          marginTop='xxs'
          borderRadius='xxs'
          onPressClose={(teamid) => {
            setSeletectedTeams(selectedTeams.filter((c) => c.id != teamid));
          }}
        />
      </Box>
    );
  };

  const MembersTag = () => {
    if (selectedMembers.length === 0) return <></>;
    return (
      <Box
        onLayout={(event) => {
          setFilterMemberTagWidth(event.nativeEvent.layout.width);
        }}>
        <MultiTags
          prefixText='Member: '
          key='dateRange'
          labelList={selectedMembers}
          labelVariant='metadata'
          variant='pickerForWeb'
          padding='xxs'
          marginTop='xxs'
          borderRadius='xxs'
          onPressClose={(mid) => {
            setSelectedMembers(selectedMembers.filter((c) => c.id != mid));
          }}
        />
      </Box>
    );
  };

  const ClearButton = () => {
    return (
      <Box
        mt='xs'
        onLayout={(e) => {
          setClearButtonWidth(e.nativeEvent.layout.width + 8);
        }}>
        <TouchableOpacity
          onPress={() => {
            setSeletectedTeams([]);
            setSelectedMembers([]);
            setSelectedOwners([]);
          }}>
          <Text variant='metadata' color='grey04'>
            {t('shared:clear')}
          </Text>
        </TouchableOpacity>
      </Box>
    );
  };

  const isOwnerInFirstLine = () => {
    if (windowWidth <= 1440) return false;
    const x =
      selectedMembers.length > 0 || selectedTeams.length > 0
        ? 0
        : clearButtonWidth + theme.spacing.xs;
    return (
      selectedOwners.length > 0 && filterOwnerTagWidth + x <= filterTagAreaWidth
    );
  };

  const isTeamInFirstLine = () => {
    if (windowWidth <= 1440) return false;
    const x =
      selectedTeams.length > 0 ? 0 : clearButtonWidth + theme.spacing.xs;
    return (
      selectedTeams.length > 0 &&
      (selectedOwners.length > 0 ? filterOwnerTagWidth + spaceBetweenTags : 0) +
        filterTeamTagWidth +
        x <=
        filterTagAreaWidth
    );
  };

  const isMemberInFirstLine = () => {
    if (windowWidth <= 1440) return false;
    const x = clearButtonWidth + theme.spacing.xs;
    return (
      selectedMembers.length > 0 &&
      (selectedOwners.length > 0 ? filterOwnerTagWidth + spaceBetweenTags : 0) +
        (selectedTeams.length > 0 ? filterTeamTagWidth + spaceBetweenTags : 0) +
        x +
        filterMemberTagWidth <=
        filterTagAreaWidth
    );
  };

  const isClearButtonInFirstLine = () => {
    if (windowWidth <= 1440) return false;
    return (
      (selectedOwners.length > 0 ||
        selectedTeams.length > 0 ||
        selectedMembers.length > 0) &&
      (selectedOwners.length > 0 ? filterOwnerTagWidth + spaceBetweenTags : 0) +
        (selectedTeams.length > 0 ? filterTeamTagWidth + spaceBetweenTags : 0) +
        (selectedMembers.length > 0
          ? filterMemberTagWidth + spaceBetweenTags
          : 0) +
        clearButtonWidth +
        2 <=
        filterTagAreaWidth
    );
  };

  const goToSetSeletectedTeam = (
    item: Pick<Team, 'id' | 'name' | 'avatar' | 'usersCount' | 'personal'>
  ) => {
    const isIn = selectedTeams.some((c) => c.id === item.id);
    const updatedSelected = isIn
      ? selectedTeams.filter((c) => c.id !== item.id)
      : [...selectedTeams, item];
    setSeletectedTeams(updatedSelected);
  };

  const goToUpgradeTeam = () => {
    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    const redirectUrl = `${process.env.STRIPE_REDIRECT_BASE_URL}/my-account/subscription-plans`;
    window.location.href = redirectUrl;
  };

  return (
    <Box
      px='l'
      my='m'
      flex={1}
      onLayout={(e) => setMainPanelWidth(e.nativeEvent.layout.width)}
      style={{ cursor: 'pointer' }}
      minWidth={Dimensions.get('window').width <= 1280 ? 639 : 742}>
      {!hasActiveOrTrialingTeam &&
        showProjectLimitSnackBar &&
        !hideProjectLimitSnackBar && (
          <Box pb='l' backgroundColor='white'>
            <SnackbarForProject
              projectLeft={projectLeft}
              title={t('models:projects.snackBar.projectsRemainingWithCount', {
                count: projectLeft < 0 ? 0 : projectLeft,
              })}
              description={t(
                'models:projects.snackBar.upgradeToCreateMoreProjects'
              )}
              iconName='Trophy'
              onPress={() => {
                goToUpgradeTeam();
              }}
              onClose={() => {
                setShowProjectLimitSnackBar(false);
                setHideProjectLimitSnackBar(true);
                saveShowProjectLimitSnackBarNextTime(me?.id, '0');
              }}
            />
          </Box>
        )}
      {!search && (
        <Box
          mb='l'
          alignItems='flex-start'
          onLayout={(e) => setBodyWidth(e.nativeEvent.layout.width)}>
          <Text variant='labelEmphasized' mb='m'>
            {t('projects.sections.recent')}
          </Text>
          <Box
            style={{
              width: bodyWidth,
              paddingBottom: Dimensions.get('window').width <= 1280 ? 10 : 0,
            }}>
            <FlatList
              horizontal
              ref={recentProjectsRef}
              showsHorizontalScrollIndicator={false}
              indicatorStyle='white'
              data={projectsRecent}
              renderItem={({ item }) => (
                <Box
                  key={item.id}
                  width={
                    (chatListOpen || isOpenChat) &&
                    Dimensions.get('window').width <= 1280
                      ? 240
                      : cardWidth
                  }>
                  <ProjectCard
                    isRecent={true}
                    project={item}
                    onClick={() => navigation.navigateToProject(item)}
                  />
                </Box>
              )}
              ItemSeparatorComponent={() => (
                <Box
                  marginRight={
                    Dimensions.get('window').width <= 1280 ? 'xs' : 'm'
                  }
                />
              )}
            />
          </Box>
          {(chatListOpen || isOpenChat) &&
            Dimensions.get('window').width <= 1280 &&
            currentIndex > 0 && (
              <Icon
                color='black'
                style={{
                  position: 'absolute',
                  left: -11,
                  top: -86,
                  width: 24,
                  height: 24,
                  opacity: 0.5,
                }}
                name='ArrowLeftCircle'
                onPress={() => {
                  recentProjectsRef.current?.scrollToIndex({
                    index: currentIndex > 0 ? currentIndex - 1 : 0,
                    animated: true,
                  });
                  setCurrentIndex(currentIndex - 1);
                }}
              />
            )}
          {(chatListOpen || isOpenChat) &&
            Dimensions.get('window').width <= 1280 &&
            currentIndex < 2 && (
              <Icon
                color='black'
                style={{
                  position: 'absolute',
                  left: Dimensions.get('window').width - 110 - 24 - 11 - 480,
                  top: -86,
                  width: 24,
                  height: 24,
                  opacity: 0.5,
                }}
                name='ArrowRightCircle'
                onPress={() => {
                  recentProjectsRef.current?.scrollToIndex({
                    index: currentIndex < 2 ? currentIndex + 1 : currentIndex,
                    animated: true,
                  });
                  currentIndex < 2 && setCurrentIndex(currentIndex + 1);
                }}
              />
            )}
        </Box>
      )}
      <Box flex={1}>
        {!search && (
          <>
            <Box
              flexDirection='row'
              justifyContent='flex-start'
              onLayout={(event) => {
                setBarWidth(event.nativeEvent.layout.width);
              }}>
              <PopupProjectButtonWeb
                title={
                  archivesOnly
                    ? t('projects.buttons.archivedProjects')
                    : t('projects.buttons.activeProjects')
                }
                boxProps={offsetPopButton1}
                onPressArchiveProjects={() => {
                  setArchivesOnly(true);
                }}
                onPressActiveProjects={() => {
                  setArchivesOnly(false);
                }}
              />
              <Box mr='xs'></Box>
              <Box
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
                onLayout={(e) => {
                  setFilterTagAreaWidth(e.nativeEvent.layout.width);
                }}>
                {isOwnerInFirstLine() && (
                  <Box style={{ marginRight: spaceBetweenTags }}>
                    {OwnersTag()}
                  </Box>
                )}
                {isTeamInFirstLine() && (
                  <Box style={{ marginRight: spaceBetweenTags }}>
                    {TeamsTag()}
                  </Box>
                )}
                {isMemberInFirstLine() && (
                  <Box style={{ marginRight: spaceBetweenTags }}>
                    {MembersTag()}
                  </Box>
                )}
                {isClearButtonInFirstLine() && ClearButton()}
              </Box>

              <Box
                ml='xs'
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'>
                {windowWidth <= 1280 && (
                  <Box
                    ml='xs'
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='flex-start'>
                    <PopupProjectsFiltersButtonWeb
                      title={t('shared:filters')}
                      boxProps={{
                        position: 'left',
                        offset: [150, 0],
                      }}
                      selectedOwners={selectedOwners}
                      onSelectOwner={setSelectedOwners}
                      onSelectTeam={goToSetSeletectedTeam}
                      selectedTeamIds={selectedTeams.map((t) => t.id)}
                      selectedMembers={selectedMembers}
                      onSelectMember={setSelectedMembers}
                    />
                  </Box>
                )}
                {windowWidth > 1280 && (
                  <>
                    <PopupProjectButtonWeb
                      selectedOwners={selectedOwners}
                      title={t('shared:owner')}
                      boxProps={offsetPopButton}
                      onSelectOwner={setSelectedOwners}
                    />
                    <Box ml='xs'></Box>
                    <PopupProjectButtonWeb
                      title={t('shared:team')}
                      boxProps={offsetPopButton}
                      onSelectTeam={goToSetSeletectedTeam}
                      selectedTeamIds={selectedTeams.map((t) => t.id)}
                    />
                    <Box ml='xs'></Box>
                    <PopupProjectButtonWeb
                      selectedMembers={selectedMembers}
                      title={t('projects.roles.member')}
                      boxProps={offsetPopButton}
                      onSelectMember={setSelectedMembers}
                    />
                  </>
                )}

                <Box style={{ marginRight: 10 }}></Box>
                <Icon
                  name={showGrid ? 'List' : 'Grid'}
                  color='black'
                  variant='s'
                  padding='xs'
                  marginRight='xxxs'
                  onPress={() => setShowGrid(!showGrid)}
                />
                <Box width={152}>
                  <Button
                    accessibilityLabel='Create Project'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    marginLeft='xs'
                    isSmall
                    height={32}
                    backgroundColor={isHovered ? 'grey05' : 'black'}
                    prefix={
                      <Icon variant='s' name='FolderPlus' color='white' />
                    }
                    onPress={() => {
                      if (projectLeft < 4) {
                        setShowProjectLimitSnackBar(true);
                        setHideProjectLimitSnackBar(false);
                      }
                      setTimeout(
                        () => setIsCreateProjectOpen(true),
                        projectLeft > 0 && projectLeft < 4 ? 1000 : 0
                      );
                    }}
                    borderRadius='xs'
                    borderColor={isHovered ? 'grey05' : 'black'}
                    variant='create'
                    prefixMarginRight='xs'
                    paddingHorizontal='m'
                    paddingVertical='xs'>
                    {t('projects.create.title')}
                  </Button>
                </Box>
              </Box>
            </Box>
            {(selectedOwners.length > 0 ||
              selectedTeams.length > 0 ||
              selectedMembers.length > 0) &&
              !isClearButtonInFirstLine() && (
                <Box
                  mt='xxxs'
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                  ml='none'>
                  {selectedOwners.length > 0 && !isOwnerInFirstLine() && (
                    <Box
                      style={{
                        marginRight:
                          filterOwnerTagWidth < barWidth
                            ? Math.min(
                                barWidth - filterOwnerTagWidth,
                                spaceBetweenTags
                              )
                            : '0',
                        maxWidth: `100%`,
                      }}>
                      {OwnersTag()}
                    </Box>
                  )}
                  {selectedTeams.length > 0 && !isTeamInFirstLine() && (
                    <Box
                      style={{
                        marginRight:
                          filterTeamTagWidth < barWidth
                            ? Math.min(
                                barWidth - filterTeamTagWidth,
                                spaceBetweenTags
                              )
                            : '0',
                        maxWidth: `100%`,
                      }}>
                      {TeamsTag()}
                    </Box>
                  )}
                  {selectedMembers.length > 0 && !isMemberInFirstLine() && (
                    <Box
                      style={{
                        marginRight:
                          filterMemberTagWidth < barWidth
                            ? Math.min(
                                barWidth - filterMemberTagWidth,
                                spaceBetweenTags
                              )
                            : '0',
                        maxWidth: `100%`,
                      }}>
                      {MembersTag()}
                    </Box>
                  )}
                  {ClearButton()}
                </Box>
              )}
          </>
        )}
        <Box mt='m' />
        <ProjectList
          alphabetize={false}
          alphabetical={false}
          projects={projects}
          expanded={showGrid}
          onClick={navigation.navigateToProject}
          loading={refreshing}
          fetchMore={fetchFromCursor}
          refreshControl={refreshControl}
        />
      </Box>
    </Box>
  );
};
