import { PlatformPressable } from '@react-navigation/elements';
import { useRoute } from '@react-navigation/native';
import React, {
  ComponentProps,
  ReactNode,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Platform,
} from 'react-native';

import { AvatarProps } from '@components/Avatar/Avatar';
import EmptyStateNoActiveTasks from '@components/EmptyState/EmptyStateNoActiveTask';
import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import { BoxHover } from '@components/Restyle/BoxHover.web';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import Icon from '@components/shared/Icon/Icon';
import { TaskPriorityBadge } from '@components/TaskCard/TaskPriorityBadge';
import { TaskSection } from '@components/TaskList/TaskListTable';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  ProjectMemberRole,
  Task,
  TaskGroup,
  TaskMember,
  TaskMemberRole,
  TaskStatus,
  TeamMemberRole,
} from '@graphql/generated';
import useColors from '@hooks/useColors';
import { useToggleCompleteTask } from '@hooks/useToggleCompleteTask';
import { TasksTabStackScreenProps } from '@navigation/tasks/tasks-tab-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { TaskTagType } from '@src/types/task';
import Images from '@themes/images';
import theme from '@themes/theme';
import { localDateFromUTCDate } from '@utils/formatters/date';
const { width } = Dimensions.get('window');

type Header = { id: TaskGroup; name: string; count: number };

type SectionData = {
  sectionHeader: Header;
  data: Task[];
};

export type TaskSectionNew = {
  sectionData: SectionData;
  loadMore?: () => void;
  members?: [];
};

export interface TaskListProps {
  tasks: Task[] | TaskSectionNew[];
  onPress?: (task: Task, comeForm?: boolean) => void;
  selectedIds?: string[];
  isEditing?: boolean;
  onDependencyPress?: (dependencyIds: string[]) => void;
  filterVal?: string;
  isSearch?: boolean;
  isFromMyTask?: boolean;
  refreshControl?: ComponentProps<typeof FlatList>['refreshControl'];
  onLoadMore?: () => void;
  isProjectShown?: boolean;
  isHeaderTabsShow?: boolean;
  isArrowVisible?: boolean;
}
export const TaskListNewTable = ({
  tasks,
  onPress,
  isFromMyTask,
  onLoadMore,
  isProjectShown: showProject,
  isHeaderTabsShow,
  isArrowVisible,
}: TaskListProps) => {
  const { params: { taskId } = {} } =
    useRoute<TasksTabStackScreenProps<'tasks'>['route']>();
  const isProjectShown = showProject && !taskId;
  const { t } = useTranslation('models');
  const listTableRef = useRef();
  const [widthChanged, setWidthChanged] = useState(false);
  const { t: f } = useTranslation('format');
  const { getMappedColor } = useColors();

  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>();
  const [pressTaskId, setPressTaskId] = useState<string | undefined>();
  const [hoverTaskId, setHoverTaskId] = useState<string | undefined>();
  const [hoverTitileTaskId, setHoverTitileTaskId] = useState<
    string | undefined
  >();

  const { idTaskDetailOpen, isTaskWebPanelOpen } = useWebDrawer();
  const [showCompleteTaskModal, setShowCompleteTaskModal] = useState<boolean>();
  const [pressTask, setPressTask] = useState<Task | undefined>();

  const navigation = useAppNavigation();

  useEffect(() => {
    if (
      Platform.OS === 'web' &&
      isTaskWebPanelOpen &&
      idTaskDetailOpen != '' &&
      idTaskDetailOpen !== selectedTaskId
    ) {
      setSelectedTaskId(idTaskDetailOpen);
    }
  }, [isTaskWebPanelOpen]);

  const sectionHeader = (item: Header) => {
    const loadMoreData: (() => void) | undefined = getLoadMoreDataFuction(item);
    return (
      <Box style={styles.headerMainContainer}>
        <Box
          flexDirection='row'
          flex={width < 1025 ? 1.1 : 2.7}
          alignSelf='flex-start'
          alignItems='center'>
          {isHeaderTabsShow &&
            (width < 1025 ? (
              <Text
                color='textSecondary'
                variant={
                  isHeaderTabsShow ? 'labelEmphasizedResponsive' : 'headerLabel'
                }
                ml={isHeaderTabsShow ? 'xxs' : 'm'}>
                {t('projects.taskList.section', {
                  context: item.name,
                })}
              </Text>
            ) : (
              <Text
                color='textSecondary'
                variant={isHeaderTabsShow ? 'labelEmphasized' : 'headerLabel'}
                ml={isHeaderTabsShow ? 'xxs' : 'm'}>
                {t('projects.taskList.section', {
                  context: item.name,
                })}
              </Text>
            ))}
          <Box marginHorizontal='l' alignItems='center'>
            <Text
              color='grey04'
              variant={
                width < 1025
                  ? 'labelEmphasizedResponsive'
                  : 'labelTabHeaderText'
              }
              textTransform='uppercase'>
              {item.count}
              {loadMoreData && '+'}{' '}
              {item.count === 0 || item.count === 1 ? 'Task' : 'Tasks'}
            </Text>
          </Box>
        </Box>
        {isHeaderTabsShow ? (
          <>
            {isProjectShown && (
              <Box flex={1} alignSelf='center'>
                <Text
                  variant={
                    width < 1025
                      ? 'labelEmphasizedResponsive'
                      : 'labelTabHeaderText'
                  }
                  style={{ maxWidth: '100px' }}
                  textTransform='uppercase'
                  accessibilityLabel={t('projects.taskList.project')}
                  paddingLeft='s'>
                  {t('projects.taskList.project')}
                </Text>
              </Box>
            )}

            <>
              <Box flex={0.5} alignItems='center' justifyContent='center'>
                <Text
                  variant={
                    width < 1025
                      ? 'labelEmphasizedResponsive'
                      : 'labelTabHeaderText'
                  }
                  textTransform='uppercase'
                  accessibilityLabel={t('projects.taskList.priority')}>
                  {t('projects.taskList.priority')}
                </Text>
              </Box>
              <Box flex={0.5} alignItems='center' justifyContent='center'>
                <Text
                  variant={
                    width < 1025
                      ? 'labelEmphasizedResponsive'
                      : 'labelTabHeaderText'
                  }
                  textTransform='uppercase'
                  accessibilityLabel={t('projects.taskList.dueDate')}>
                  {t('projects.taskList.dueDate')}
                </Text>
              </Box>
              <Box
                flex={0.5}
                alignItems='center'
                justifyContent='center'
                paddingRight={isProjectShown ? 'none' : 'xs'}
                accessibilityLabel={t('projects.taskList.assignee')}>
                <Text
                  variant={
                    width < 1025
                      ? 'labelEmphasizedResponsive'
                      : 'labelTabHeaderText'
                  }
                  textTransform='uppercase'>
                  {t('projects.taskList.assignee')}
                </Text>
              </Box>
            </>
          </>
        ) : (
          <>
            <Box alignItems='center' flex={0.5}>
              <Text
                variant={
                  width < 1025
                    ? 'labelEmphasizedResponsive'
                    : 'labelTabHeaderText'
                }
                textTransform='uppercase'
                accessibilityLabel={t('projects.taskList.priority')}>
                {t('projects.taskList.priority')}
              </Text>
            </Box>
            <Box flex={0.5} alignItems='center'>
              <Text
                variant={
                  width < 1025
                    ? 'labelEmphasizedResponsive'
                    : 'labelTabHeaderText'
                }
                textTransform='uppercase'
                accessibilityLabel={t('projects.taskList.dueDate')}>
                {t('projects.taskList.dueDate')}
              </Text>
            </Box>
            <Box alignItems='center' flex={0.5}>
              <Text
                variant={
                  width < 1025
                    ? 'labelEmphasizedResponsive'
                    : 'labelTabHeaderText'
                }
                textTransform='uppercase'
                accessibilityLabel={t('projects.taskList.assignee')}>
                {t('projects.taskList.assignee')}
              </Text>
            </Box>
          </>
        )}
      </Box>
    );
  };
  const { toggleCompleteTask } = useToggleCompleteTask();

  const userAvatars = (members: TaskMember[]): AvatarProps[] => {
    return (
      members?.map((u) => {
        const { user } = u;
        const { name, id, avatar } = user;
        return {
          id: id,
          label: name,
          avatar: avatar,
          size: 'memberList',
        };
      }) || []
    );
  };

  const navigateToTaskMembers = (task: TaskTagType) => {
    onPress?.(task, isFromMyTask);
    return;
  };
  const renderItem = (item: Task) => {
    const {
      currentUserRole,
      currentUserProjectRole,
      currentUserProjectTeamRole,
      status,
      completedAt,
    } = item;
    const isEditorOrHigher =
      currentUserProjectRole !== ProjectMemberRole.Viewer;
    const canComplete =
      currentUserRole === TaskMemberRole.Assignee ||
      currentUserRole === TaskMemberRole.Owner ||
      currentUserProjectTeamRole === TeamMemberRole.Owner ||
      currentUserProjectTeamRole === TeamMemberRole.Admin ||
      currentUserProjectTeamRole === TeamMemberRole.Member;

    const completed = status === TaskStatus.Completed || !!completedAt;
    const complete = (
      <RadioButton
        disabled={isEditorOrHigher ? !isEditorOrHigher : !canComplete}
        onPress={
          completed
            ? () => toggleCompleteTask(item)
            : () => {
                setPressTask(item);
                setShowCompleteTaskModal(true);
              }
        }
        isSelected={!!item.completedAt}
        iconVariant='m'
      />
    );
    const nameAndComplete = (
      <Box
        accessibilityLabel={item.name}
        flexDirection='row'
        alignItems='center'
        onMouseEnter={() => {
          setHoverTitileTaskId(item.id);
        }}
        onMouseLeave={() => {
          setHoverTitileTaskId(undefined);
        }}>
        <Box justifyContent='center' marginRight='xs'>
          {complete}
        </Box>
        <Text
          accessibilityLabel='Task'
          variant={width < 1025 ? 'labelEmphasizedResponsive' : 'bodyTaskName'}
          textDecorationLine={
            hoverTitileTaskId === item.id ? 'underline' : undefined
          }
          numberOfLines={1}>
          {item.name}
        </Text>
      </Box>
    );
    const project = (
      <TouchableOpacity
        onPress={() => navigation.navigateToProject(item.project)}>
        <Box style={styles.projectContainer}>
          {/* <ProjectAvatar
            color={
              item?.project?.archivedAt
                ? theme.colors.grey04
                : getMappedColor(item?.project?.color)
            }
            initials={item?.project?.initials}
            dimensions={{
              width: 42,
              height: 42,
            }}
            personal={item?.project?.team?.personal ?? false}
          /> */}
          <Box
            alignItems='center'
            justifyContent='center'
            marginRight='xs'
            style={{
              borderRadius: 5,
              padding: 6,
              backgroundColor: item?.project?.archivedAt
                ? theme.colors.grey03
                : getMappedColor(item?.project?.color),
            }}>
            <Icon
              name={
                item?.project.icon
                  ? (item?.project.icon as keyof typeof Images)
                  : 'BuildingHomeHouse'
              }
              color='white'
              variant='m'
            />
          </Box>
          <Text
            style={styles.imageContainer}
            variant={
              width < 1025 ? 'labelEmphasizedResponsive' : 'webBodySecondary'
            }>
            {item.project.name}
          </Text>
        </Box>
      </TouchableOpacity>
    );

    const priority = (
      <Box style={styles.priorityBadgeContainer}>
        <TaskPriorityBadge priority={item.priority} />
      </Box>
    );

    const members = (
      <Box style={styles.membersContainer}>
        <MemberListSummary
          avatars={userAvatars(item.members)}
          onPress={() => navigateToTaskMembers(item)}
          maxAvatars={2}
        />
      </Box>
    );

    //Date region start
    const dateAsKey = f('timeAgoTasksTableList', {
      val: localDateFromUTCDate(item.dueDate),
    });

    const getColor = () => {
      if (dateAsKey === 'Today') {
        return 'greenSecondary';
      }
      if (item.status === 'OVERDUE') {
        return 'alertRed';
      }
      return 'black';
    };

    const end = !!item.dueDate && (
      <Box style={styles.dueDateContainer}>
        <Text
          color={getColor()}
          variant={
            width < 1025 ? 'labelEmphasizedResponsive' : 'labelDueDateText'
          }>
          {dateAsKey === 'Today' || dateAsKey === 'Yesterday'
            ? dateAsKey
            : f('monthAndDay', {
                val: localDateFromUTCDate(item.dueDate),
              })}
        </Text>
      </Box>
    );

    //date region end

    return (
      <PlatformPressable
        onPress={() => {
          setSelectedTaskId(item.id);
          setTimeout(() => {
            onPress?.(item);
          }, 500);
        }}
        onPressIn={() => {
          setPressTaskId(item.id);
        }}
        onPressOut={() => {
          setPressTaskId(undefined);
        }}>
        <Row
          nameAndComplete={nameAndComplete}
          project={project}
          priority={priority}
          end={end}
          members={members}
          isProjectShown={isProjectShown}
          isHeaderTabsShow={isHeaderTabsShow}
          isHovered={hoverTaskId === item.id}
          isPressing={pressTaskId === item.id}
          isSelected={selectedTaskId === item.id}
          onMouseEnter={() => {
            setHoverTaskId(item.id);
          }}
          onMouseLeave={() => {
            setHoverTaskId(undefined);
          }}
        />
      </PlatformPressable>
    );
  };

  const tasksAreGrouped = (
    tasks: Task[] | TaskSection[]
  ): tasks is TaskSection[] => {
    return (tasks as TaskSection[])[0]?.sectionData !== undefined;
  };

  const getLoadMoreDataFuction = (item?: Header) => {
    if (tasksAreGrouped(tasks)) {
      const taskSections = tasks as TaskSection[];

      const taskSection = taskSections.find(
        (t) => t.sectionData.sectionHeader.id === item?.id
      );

      const { loadMore, sectionData } = taskSection || {
        loadMore: undefined,
        sectionData: undefined,
      };
      if (sectionData?.hasNextPage) {
        return loadMore;
      }
    } else {
      return onLoadMore || undefined;
    }
  };

  const renderLoadMoreComponent = (item?: Header) => {
    const loadMoreData: (() => void) | undefined = getLoadMoreDataFuction(item);

    if (!loadMoreData) return;

    return (
      <Box alignItems='center'>
        <TouchableOpacity
          accessibilityLabel={t('shared:loadMore')}
          onPress={loadMoreData}>
          <Box
            marginVertical='s'
            backgroundColor='grey01'
            p='s'
            borderRadius='xs'
            alignSelf='flex-start'>
            <Text variant='labelSmall' textAlign='center'>
              {t('shared:loadMore')}
            </Text>
          </Box>
        </TouchableOpacity>
      </Box>
    );
  };

  useCallback(() => {
    if (!listTableRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setWidthChanged(!widthChanged);
    });
    resizeObserver.observe(listTableRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <Box
      marginTop='xxs'
      ref={listTableRef}
      style={styles.mainListContainer}
      key={
        tasksAreGrouped(tasks)
          ? tasks.flatMap((t) => t.sectionData.data).toString()
          : tasks.length
      }>
      {tasksAreGrouped(tasks) && (
        <CollapsableSectionList<Header, Task>
          data={tasks.map((d) => d.sectionData)}
          renderSectionHeader={sectionHeader}
          ListHeaderComponent={() => <Box marginTop='none' />}
          renderItem={renderItem}
          dataListHeaderStyle={{
            backgroundColor: 'grey01',
            paddingVertical: 'xxs',
            paddingHorizontal: 'xs',
            borderRadius: 'xs',
            height: 48,
            marginLeft: !isArrowVisible ? 'none' : 'm',
            marginRight: isArrowVisible && !isProjectShown ? 'xs' : 's',
            marginBottom: 'xs',
          }}
          isArrowLeft={true}
          showArrow={isArrowVisible}
          renderEmptyComponent={<EmptyStateNoActiveTasks />}
          showEmptyMessage={true}
          ListFooterComponent={onLoadMore && renderLoadMoreComponent()}
          renderSectionFooterComponent={renderLoadMoreComponent}
        />
      )}
      {!tasksAreGrouped(tasks) && (
        <FlatList
          data={tasks}
          renderItem={({ item }) => <Box mx='m'>{renderItem(item)}</Box>}
          ListFooterComponent={onLoadMore && renderLoadMoreComponent()}
          extraData={widthChanged}
        />
      )}
      {showCompleteTaskModal && (
        <ConfirmModal
          showModal={showCompleteTaskModal}
          onClose={() => setShowCompleteTaskModal(false)}
          onPress={() => {
            pressTask && toggleCompleteTask(pressTask);
            setShowCompleteTaskModal(false);
          }}
          buttonText={t('models:tasks.complete.title')}
          title={t('models:tasks.complete.title')}
          message={t('models:tasks.complete.message_short')}
        />
      )}
    </Box>
  );
};

const Row = ({
  nameAndComplete,
  priority,
  end,
  members,
  project,
  isProjectShown,
  isHeaderTabsShow,
  isHovered,
  isPressing,
  isSelected,
  onMouseEnter,
  onMouseLeave,
}: {
  nameAndComplete: ReactNode;
  priority: ReactNode;
  end: ReactNode;
  members: ReactNode;
  project: ReactNode;
  isProjectShown: ReactNode;
  isHeaderTabsShow: ReactNode;
  isHovered?: boolean;
  isPressing?: boolean;
  isSelected?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  return (
    <BoxHover
      paddingHorizontal='xxs'
      isHovered={isHovered}
      isPressing={isPressing}
      isSelected={isSelected}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Box
        justifyContent='center'
        flex={width < 1025 ? 1.1 : 3}
        alignContent='center'>
        {nameAndComplete}
      </Box>
      {isProjectShown && (
        <Box
          flex={1}
          alignSelf='flex-start'
          justifyContent='center'
          marginRight='l'>
          {project}
        </Box>
      )}
      <Box
        flex={0.5}
        alignContent='center'
        justifyContent='center'
        paddingLeft={!isHeaderTabsShow ? 'none' : 'none'}>
        {priority}
      </Box>
      <Box
        justifyContent='center'
        flex={0.5}
        alignContent='center'
        paddingLeft={!isHeaderTabsShow ? 'none' : 'none'}>
        {end}
      </Box>
      <Box flex={0.5} alignContent='center' justifyContent='center'>
        {members}
      </Box>
    </BoxHover>
  );
};

const styles = StyleSheet.create({
  headerMainContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    width: '100%',
  },
  bodyMainContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    borderBottomWidth: 1,
    width: '100%',
  },
  mainListContainer: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    marginHorizontal: 8,
  },
  priorityBadgeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  membersContainer: {
    alignItems: 'flex-end',
  },
  dueDateContainer: {
    alignItems: 'center',
    alignContent: 'center',
    marginLeft: '10',
  },
  projectContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 5,
  },
});
