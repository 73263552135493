import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionList, RefreshControl, Platform, Alert } from 'react-native';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import ImageGrid from '@components/shared/ImageGrid';
import { Document } from '@graphql/generated';
import { useListTaskDocumentsFromQuery } from '@hooks/useListTaskDocumentsFromQuery';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { FilterType } from '@screens/Projects/ProjectFiles';
import { getDocumentList } from '@screens/Tasks/GroupChatMedia';
import { TasksFilesProps } from '@screens/Tasks/TaskFiles';

interface SectionData {
  title: string;
  data: Document[][];
}

const TaskMedia: React.FC<TasksFilesProps> = ({
  taskId,
  filterBy,
  sortBy,
  isSelectMode,
  onSelectedModeChange,
  selectedList,
  handelSelectedItem,
}) => {
  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const navigation = useNavigation();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const { t } = useTranslation('format');
  const { isPaid, setPaid, me } = useMe();

  const {
    documents,
    loading,
    refetch,
    pageInfo,
    fetchFromCursor,
    refreshing,
    isFetchingMore,
  } = useListTaskDocumentsFromQuery({
    taskIds: [taskId],
    ownerIds:
      filterBy === FilterType.MyUploads && me?.id ? [me?.id] : undefined,
    sortBy,
    includeMedia: true,
  });

  const [sortedDocuments, setSortedDocuments] = useState<SectionData[]>([]);

  const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
  const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
    useState<boolean>(false);

  const showNotPaidModal = (file: Document) => {
    const createdAt = dayjs(file.createdAt);
    const expired = createdAt?.diff(new Date(), 'day');
    if (!isPaidUser && expired < -1000) {
      if (Platform.OS === 'web') {
        setShowNotPaidConfirmModal(true);
      } else {
        Alert.alert(
          'Task ' + t('shared:attachmentsExpired'),
          t('shared:attachmentsExpiredMessage'),
          [
            { text: 'Cancel' },
            {
              text: t('shared:upgrade'),
              style: 'destructive',
              onPress: () => {
                setPaid(true);
                setPaidUser(true);
              },
            },
          ]
        );
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    setSortedDocuments(getDocumentList(documents, t));
  }, [setSortedDocuments, documents?.length]);

  useEffect(() => {
    if (!loading && pageInfo?.hasNextPage && !isFetchingMore) {
      setTimeout(() => {
        fetchFromCursor();
      }, 100);
    }
  }, [documents.length, loading, pageInfo?.hasNextPage, isFetchingMore]);

  if (loading && !refreshing) return null;

  const renderItem = ({ item }: { item: Document[] }) => {
    return (
      <Box marginHorizontal='m' marginBottom='xs'>
        <ImageGrid
          list={item}
          isSelectMode={isSelectMode}
          selectedList={selectedList}
          onPress={(image) => {
            if (showNotPaidModal(image as Document)) return;
            if (isSelectMode) {
              handelSelectedItem(image);
            } else {
              setPreviewDocuments(item);
              setActiveIndex(item.findIndex((i) => i.id === image.id));
              if (Platform.OS === 'web') {
                setImageCarouselVisible(true);
              } else {
                navigation.navigate('images-preview', {
                  deleteCall: 'task',
                  taskId: taskId,
                });
              }
            }
          }}
          onLongPress={(image) => {
            onSelectedModeChange(true);
            handelSelectedItem(image);
          }}
        />
      </Box>
    );
  };

  const renderEmptyUI = () => {
    return !loading && <EmptyStateNoFiles />;
  };

  const renderFooterUI = (isLoadingMore: boolean) => {
    return isLoadingMore ? (
      <>
        <ActivityIndicatorLoading />
        <Box marginBottom='listFooter' />
      </>
    ) : (
      <Box marginBottom='listFooter' />
    );
  };

  if (loading) return <ActivityIndicatorLoading />;
  return (
    <Box flex={1}>
      <SectionList
        stickySectionHeadersEnabled={false}
        sections={documents.length ? sortedDocuments : []}
        renderItem={renderItem}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (!pageInfo?.hasNextPage || isFetchingMore) return;
          fetchFromCursor();
        }}
        ItemSeparatorComponent={() => <Box marginBottom='l' />}
        ListHeaderComponent={() => <Box marginTop='s' />}
        ListFooterComponent={() => renderFooterUI(isFetchingMore)}
        ListEmptyComponent={() => renderEmptyUI()}
        renderSectionHeader={({ section: { title } }) => (
          <Box marginBottom='s' marginHorizontal='m'>
            <Text variant='labelEmphasized' color='textPrimary'>
              {title}
            </Text>
          </Box>
        )}
        contentContainerStyle={{ flexGrow: 1 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={refetch} />
        }
      />
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
      {showNotPaidConfirmModal && (
        <NotPaidConfirmModal
          showModal={showNotPaidConfirmModal}
          onClose={() => setShowNotPaidConfirmModal(false)}
          onPress={() => {
            setShowNotPaidConfirmModal(false);
            setPaid(true);
            setPaidUser(true);
          }}
          title='Task'
        />
      )}
    </Box>
  );
};

export default TaskMedia;
