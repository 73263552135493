import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  RefreshControl,
} from 'react-native';

import { Box, Text } from '@components//Restyle';
import { ActivityItem } from '@components/ActivityList/ActivityItem';
import ProjectActivityItem, {
  getProjectActivityMessage,
  ProjectActivityType,
} from '@components/ActivityList/ProjectActivityItems';
import TaskActivityItem, {
  getTaskActivityMessage,
  TaskActivityType,
} from '@components/ActivityList/TaskActivityItems';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import {
  ChatDrawerScreen,
  useNavigateToChatDrawer,
} from '@components/Web/Drawer/WebDrawerContext';
import {
  Audit,
  SearchableResult,
  useSearchLazyQuery,
} from '@graphql/generated';
import useGlobalSearch from '@hooks/useGlobalSearch';
import useMe from '@hooks/useMe';
import useSearch from '@hooks/useSearch';
import { Search } from '@root/types';
import theme from '@themes/theme';

type ActivityListProps = {
  audits: Audit[];
  activityType: 'Task' | 'Project';
  projectId?: string;
  taskId?: string;
  onRefresh?: () => void;
  /** Controls loading indicator when fetching additional projects */
  refreshing?: boolean;
  fetchMore?: () => void;
  loadingMore?: boolean;
  listFooterComponent?: () => JSX.Element;
  renderEmptyActiveComponent?: () => JSX.Element;
  onEndReached?: () => void;
  listHeaderComponent?: () => JSX.Element;
};

const ActivityList: React.FC<ActivityListProps> = ({
  audits,
  activityType,
  projectId,
  taskId,
  onRefresh,
  refreshing = false,
  listFooterComponent,
  onEndReached,
  renderEmptyActiveComponent,
  listHeaderComponent = () => <Box marginTop='l' />,
}) => {
  const DEBOUNCE = 500;
  const { me } = useMe();
  const { setIsSearching } = useGlobalSearch();
  const navigation = useNavigateToChatDrawer();
  const { t } = useTranslation();
  const filteredAudits = audits
    ?.filter((a) => {
      return getProjectActivityMessage(a.event as ProjectActivityType) !== '';
    })
    .sort((a, b) => (+b.id > +a.id ? 1 : -1));

  const { search } = useSearch();

  const [globalSearch, { data, loading: loadingSearch }] = useSearchLazyQuery();
  const [loading, setLoading] = useState(false);

  let fileResults = (data?.search as Search<SearchableResult>[]) || [];
  const globalSearchCall = () => {
    globalSearch({
      variables: {
        term: search,
        size: 20,
        projectId: projectId ? projectId : null,
        taskId: taskId ? taskId : null,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: false,
        includeTasks: false,
        includeContacts: false,
        includeChats: false,
        includeActivities: true,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });
  };
  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search]
  );
  useEffect(() => {
    fileResults = [];
    if (search.trim.length == 0) {
      fileResults = [];
      debouncedGlobalSearch();
      setLoading(false);
    }
    if (search) {
      fileResults = [];
      setLoading(true);
      debouncedGlobalSearch();
    }
    return () => {
      debouncedGlobalSearch.cancel();
    };
  }, [search]);

  const capitalizeFirstLetter = (str) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return '';
  };

  const getMessage = (item: Audit) => {
    const { attachment, user } = item;
    const entityTask = attachment?.entityTask || null;
    const members = entityTask ? entityTask.members : [];

    const role =
      members
        ?.find((member: any) => {
          return member.user?.id === user?.id;
        })
        ?.role.toLowerCase() || '';
    if (activityType === 'Project') {
      return (
        getProjectActivityMessage(
          item.event as ProjectActivityType,
          item.projectRole && capitalizeFirstLetter(item.projectRole),
          role && capitalizeFirstLetter(role),
          item?.attachment?.body
        ) || ''
      );
    } else if (activityType === 'Task') {
      return (
        getTaskActivityMessage(
          item.event as TaskActivityType,
          role && capitalizeFirstLetter(role),
          item?.attachment?.body
        ) || ''
      );
    } else {
      return '';
    }
  };

  const getChatId = (item: Audit) => {
    if (item?.attachment?.__typename === 'Message') {
      return item.attachment.chatId;
    }
  };

  const renderItem = ({ item, index }: ListRenderItemInfo<Audit>) => {
    const chatId = getChatId(item);
    const userId = item.user.id;
    const userExistInChat = item?.attachment?.chat?.users?.filter(
      (u) => u.id === me?.id
    );

    return (
      <ActivityItem
        user={item.user}
        message={getMessage(item)}
        createdAt={item.createdAt}
        filterVal={search}
        onChatPress={
          chatId && userId !== me?.id && userExistInChat?.length > 0
            ? () => {
                setIsSearching(true),
                  navigation({
                    screen: ChatDrawerScreen.details,
                    activeChat: item?.attachment?.chat,
                    messageCursor: item?.attachment?.cursor,
                  });
              }
            : undefined
        }
        index={filteredAudits.length - index}>
        {activityType === 'Project' ? (
          <ProjectActivityItem audit={item} />
        ) : (
          <TaskActivityItem audit={item} taskId={taskId} />
        )}
      </ActivityItem>
    );
  };

  const activityDataList = search ? fileResults.map((r) => r.record) : [];
  if (search && (loadingSearch || loading)) return <ActivityIndicatorLoading />;
  return (
    <Box flex={1}>
      {filteredAudits.length == 0 && !search && renderEmptyActiveComponent ? (
        <Box flex={1} alignSelf='center' justifyContent='center'>
          {renderEmptyActiveComponent()}
        </Box>
      ) : (
        <FlatList
          style={{
            paddingHorizontal:
              Platform.OS !== 'web' ? theme.spacing.m : theme.spacing.none,
            height: `100%`,
          }}
          data={search ? activityDataList : filteredAudits}
          renderItem={renderItem}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.1}
          keyExtractor={(item) => item.id}
          ListHeaderComponent={listHeaderComponent}
          ItemSeparatorComponent={() => <Box marginTop='m' />}
          ListFooterComponent={
            !search && listFooterComponent
              ? listFooterComponent
              : () => <Box paddingBottom='listFooter' />
          }
          ListEmptyComponent={() =>
            search && !loading ? (
              <Box alignItems='center'>
                <Text>{t('NoRecordFound')}</Text>
              </Box>
            ) : null
          }
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          showsVerticalScrollIndicator={false}
        />
      )}
    </Box>
  );
};

export default ActivityList;
